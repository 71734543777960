<template>
    <v-data-table
        :headers="headers"
        :items="versions"
        item-key="index"
        class="elevation-0"
        :mobile-breakpoint="0"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-row
                no-gutter
                class="mt-0 mx-0 pt-4 pb-0 pb-4"
                :style="{ 'background-color': '#eeeeee' }"
            >
                <v-col cols="12" class="d-flex mb-n3">
                    <h1 class="mr-4">LOGS</h1>
                </v-col>
            </v-row>
        </template>
        <!--ITEMS-->
        <template v-slot:[`item.updatedOn`]="{ item }">
            <p class="my-0">
                {{ formatDate(item) }}
            </p>
        </template>
        <template v-slot:[`item.updatedBy`]="{ item }">
            <p class="my-0">
                {{
                    typeof item.updatedBy == 'string'
                        ? `${item.updatedBy}`
                        : item.updatedBy != null
                        ? `${item.updatedBy.name} (${item.updatedBy.role})`
                        : ''
                }}
            </p>
        </template>
        <template v-slot:[`item.changes`]="{ item }">
            <p
                class="my-0 font-weight-black"
                v-for="(key, index) of Object.keys(item.changes || {})"
                :key="index"
            >
                {{ key }}:
                <span class="font-weight-regular">{{ item.changes[key] }}</span>
            </p>
        </template>
    </v-data-table>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
// import { objDiff } from '@/helpers/objDiff'
import moment from 'moment'

export default {
    name: 'QuoteLogs',
    props: {
        id: {
            type: String,
            required: true,
        },
        quote: {
            type: Object,
            default: () => ({}),
        },
        settings: {
            type: Array,
            default: () => ({}),
        },
    },
    data: () => ({
        statusColor: {
            OPEN: 'primary',
            AWARDED: 'success',
            LOST: 'error',
            SENT: 'warning',
            APPROVAL: 'orange',
            CLOSED: 'black',
        },
        loading: false,
        logs: [],
        headers: [
            {
                text: 'DATE',
                value: 'updatedOn',
                align: 'center',
                sortable: false,
                width: '200',
            },
            {
                text: 'MODIFIED BY',
                value: 'updatedBy',
                align: 'center',
                sortable: false,
                width: '200',
            },
            {
                text: 'CHANGES',
                value: 'changes',
                align: 'left',
                sortable: false,
            },
        ],
        users: [],
        clients: [],
        versions: [],
    }),
    async created() {
        try {
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    async mounted() {
        try {
            this.loading = true
            await this.getQuoteChangelog()
            this.handleDifferences()
            this.autocompleteData()
            this.sortData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        sortData() {
            this.versions.sort(
                (a, b) =>
                    (b.updatedOn
                        ? b.updatedOn._seconds
                        : b.createdOn._seconds) -
                    (a.updatedOn ? a.updatedOn._seconds : a.createdOn._seconds)
            )
        },
        autocompleteData() {
            this.versions.forEach(log => {
                log.updatedBy = this.getUser(log.updatedBy)
                if (log.changes) {
                    if (
                        log.changes.collaborators &&
                        log.changes.collaborators.length > 0
                    ) {
                        log.changes.collaborators = log.changes.collaborators
                            .map(collaborator => {
                                let user = this.getUser(collaborator)
                                return typeof user == 'string'
                                    ? user
                                    : user
                                    ? user.name
                                    : ''
                            })
                            .join(', ')
                    }
                    if (log.changes.accountManager) {
                        const user = this.getUser(log.changes.accountManager)
                        log.changes.accountManager =
                            typeof user == 'string'
                                ? user
                                : user
                                ? user.name
                                : ''
                    }
                    if (log.changes.clientId) {
                        const user = this.getClient(log.changes.clientId)
                        log.changes.clientId =
                            typeof user == 'string'
                                ? user
                                : user
                                ? user.name
                                : ''
                    }
                    if (log.changes.totalCosts) {
                        log.changes.totalCosts = this.formatNumber(
                            log.changes.totalCosts
                        )
                    }
                    if (log.changes.exchange) {
                        log.changes.exchange = this.formatNumber(
                            log.changes.exchange
                        )
                    }
                    if (log.changes.commissions) {
                        log.changes.commissions = log.changes.commissions
                            .map(commission => {
                                return `${commission.details} (${commission.percentage} %)`
                            })
                            .join(', ')
                    }
                }
            })
        },
        handleDifferences() {
            let current = undefined
            let versions = []
            this.logs.forEach(log => {
                if (!current) {
                    current = log
                    versions.push(log)
                } else {
                    let changes = this.quoteDifference(current, log)
                    if (Object.keys(changes).length > 0) {
                        versions.push({ ...log, changes })
                        current = log
                    }
                }
            })
            this.versions = versions
        },
        async getQuoteChangelog() {
            try {
                this.logs = await API.getQuoteLogs(this.id)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        quoteDifference(originalQuote, newQuote) {
            let oldQuote = {
                archive: originalQuote.archive,
                clientId: originalQuote.clientId,
                collaborators: originalQuote.collaborators,
                commissions: originalQuote.commissions,
                currency: originalQuote.currency,
                exchange: originalQuote.exchange,
                name: originalQuote.name,
                status: originalQuote.status,
                totalCosts: originalQuote.totalCosts,
                accountManager: originalQuote.accountManager,
            }
            let currentQuote = {
                archive: newQuote.archive,
                clientId: newQuote.clientId,
                collaborators: newQuote.collaborators,
                commissions: newQuote.commissions,
                currency: newQuote.currency,
                exchange: newQuote.exchange,
                name: newQuote.name,
                status: newQuote.status,
                totalCosts: newQuote.totalCosts,
                accountManager: newQuote.accountManager,
            }
            let changes = {}
            Object.keys(currentQuote).forEach(key => {
                if (
                    (oldQuote[key] === undefined &&
                        currentQuote[key] !== undefined) ||
                    (currentQuote[key] !== undefined &&
                        JSON.stringify(currentQuote[key]) !==
                            JSON.stringify(oldQuote[key]))
                ) {
                    changes[key] = currentQuote[key]
                }
            })
            return changes
        },
        formatDate(item) {
            let time = item.updatedOn
                ? item.updatedOn._seconds +
                  Number(`0.${item.updatedOn._nanoseconds}`)
                : item.createdOn._seconds +
                  Number(`0.${item.createdOn._nanoseconds}`)
            return moment.unix(time).format('YYYY-MM-DD, h:mm:ss a')
        },
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(
                Math.ceil(number != undefined ? number : 0)
            )
        },
        getUser(userId) {
            const user = this.users.find(user => user.id == userId)
            return user || userId
        },
        getClient(userId) {
            const client = this.clients.find(client => client.id == userId)
            return client || userId
        },
    },
}
</script>

<style>
thead {
    background: #eeeeee;
}
</style>
