<template>
    <v-container fluid>
        <v-card-title class="d-flex justify-end pa-0 ">
            <v-btn
                v-if="setting"
                @click="openForm"
                depressed
                rounded
                class="primary mb-2 mt-1"
            >
                <v-icon left>mdi-plus</v-icon>
                New Reason
            </v-btn>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="setting.rejectionReasons"
            class="elevation-0"
            :mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <!--ITEMS-->
            <template v-slot:[`item.reason`]="{ item }">
                <p class="d-flex justify-center">
                    {{ item.reason }} 
                </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openUpdateReason(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small icon @click="openDeleteReason(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!-- New Reason -->
        <v-dialog
            :retain-focus="false"
            v-if="setting"
            v-model="openNewReason"
            persistent
            max-width="300px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ createForm ? 'Add Reason' : 'Update Reason' }}
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="form">
                        <v-text-field
                            v-model="reason"
                            prepend-icon="mdi-text"
                            label="Reason"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="createForm"
                        @click="addReason"
                        :disabled="!valid"
                        color="primary"
                        text
                        :loading="loading"
                    >
                        Add
                    </v-btn>
                    <v-btn
                        v-else
                        @click="updateReason"
                        :disabled="!valid"
                        color="primary"
                        text
                        :loading="loading"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Reason -->
        <v-dialog
            v-model="openDeleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openDeleteWarning">
                <v-card-title class="text-h5">Delete Reason</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this reason?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteReason">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteReason"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    name: 'settingsReasons',
    props: {
        setting: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            reason: null,
            openNewReason: false,
            openDeleteWarning: false,
            reasonToDelete: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => (v <= 100 && v >= 0) || 'Required',
            },
            error: null,
            errorMsg: null,
            headers: [
                {
                    text: 'REASON',
                    value: 'reason',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                },
            ],
            createForm: false,
            selectedReason: {},
        }
    },
    mounted() {
        this.setting.rejectionReasons = this.setting.rejectionReasons
            ? this.setting.rejectionReasons
            : []
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async updateReason() {
            try {
                this.loading = true
                const response = await API.updateReason({
                    settingId: this.setting.id,
                    id: this.selectedReason.id,
                    reason: this.reason,
                })
                this.setting.rejectionReasons =
                    response.data.process.rejectionReasons
                this.openNewReason = false
                this.reason = ''
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.selectedReason = {}
            }
        },
        openForm() {
            this.createForm = true
            this.openNewReason = true
        },
        onResize() {
            this.height = window.innerHeight - this.$vuetify.application.top
        },
        openUpdateReason(item) {
            this.createForm = false
            this.selectedReason = _.cloneDeep(item)
            this.reason = item.reason
            this.openNewReason = true
        },
        async addReason() {
            try {
                this.loading = true
                const response = await API.createReason({
                    settingId: this.setting.id,
                    reason: this.reason,
                })
                this.setting.rejectionReasons =
                    response.data.process.rejectionReasons
                this.openNewReason = false
                this.reason = ''
                this.setting = _.cloneDeep(this.setting)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openDeleteReason(reason) {
            this.reasonToDelete = reason
            this.openDeleteWarning = true
        },
        closeDeleteReason() {
            this.reasonToDelete = null
            this.openDeleteWarning = false
        },
        async deleteReason() {
            try {
                this.loading = true
                const index = this.setting.rejectionReasons.findIndex(
                    x => x == this.reasonToDelete
                )
                await API.deleteReason({
                    settingId: this.setting.id,
                    id: this.setting.rejectionReasons[index].id,
                })
                this.setting.rejectionReasons.splice(index, 1)
                this.setting = _.cloneDeep(this.setting)
                this.openDeleteWarning = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDialog() {
            this.openNewReason = false
            this.$refs.form.reset()
            this.section = {}
        },
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
    max-height: 60vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px; 
}
.v-data-table__wrapper thead tr {
    position: sticky; 
    top: 0;
    z-index: 10;
}
</style>
