<template>
    <div>
        <v-card id="details" :loading="loading" height="100vh">
            <v-card-title ref="title">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>
                    {{
                        $vuetify.breakpoint.smAndDown
                            ? `${originalOrder.code}`
                            : `Packing List (${originalOrder.code})`
                    }}
                    - {{ project.name }}
                </h3>
                <v-btn
                    v-if="packingList.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn small icon color="primary" @click="print">
                    <v-icon>mdi-printer-pos</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                class="pt-0"
                id="virtual-scroll-table"
                :style="{ height: '81vh' }"
            >
                <v-form v-model="valid">
                    <v-row no-gutters class="mt-4" ref="projectSelector">
                        <v-col cols="3" class="pl-2">
                            <v-text-field
                                v-model="project.name"
                                prepend-icon="mdi-account-hard-hat"
                                label="Project"
                                readonly
                                disabled
                            />
                        </v-col>
                        <v-col
                            cols="
                                3
                            "
                            class="pl-2"
                        >
                            <v-text-field
                                v-model="deliveryInfoString"
                                hide-details
                                label="Delivery Information"
                                prepend-icon="mdi-map-marker"
                                readonly
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="3" class="pl-2">
                            <v-text-field
                                v-model="packingList.requestedShipmentDate"
                                label="Requested Date For Shipment"
                                prepend-icon="mdi-calendar-blank"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="
                                3
                            "
                            class="pl-2"
                        >
                            <v-text-field
                                v-model="packingList.notes"
                                rows="1"
                                hide-details
                                label="Notes"
                                prepend-icon="mdi-text"
                                class="mx-2"
                                readonly
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-0" ref="projectSelector">
                        <v-col cols="3" class="pl-2">
                            <v-text-field
                                v-model="createdBy"
                                prepend-icon="mdi-account"
                                label="Created By"
                                readonly
                                disabled
                            />
                        </v-col>
                        <v-row
                            cols="12"
                            xl="9"
                            lg="9"
                            md="9"
                            sm="9"
                            class="py-0"
                            v-if="
                                packingList.packages &&
                                    packingList.packages.length > 0
                            "
                        >
                            <v-col
                                cols="12"
                                xl="6"
                                lg="6"
                                md="6"
                                sm="6"
                                class="py-3"
                            >
                                <v-combobox
                                    v-model="packingList.selectedWorkOrders"
                                    :items="projectWorkOrders"
                                    item-text="code"
                                    label="WorkOrders"
                                    prepend-icon="mdi-hammer-wrench"
                                    multiple
                                    chips
                                    small-chips
                                    readonly
                                    hide-details
                                    disabled
                                ></v-combobox>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="6"
                                lg="6"
                                md="6"
                                sm="6"
                                class="py-3"
                            >
                                <v-combobox
                                    v-model="packingList.selectedBOMs"
                                    :items="projectBOMs"
                                    item-text="code"
                                    label="BOMs"
                                    prepend-icon="mdi-list-box-outline"
                                    multiple
                                    chips
                                    small-chips
                                    readonly
                                    hide-details
                                    disabled
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row no-gutters class="mt-0" ref="projectSelector">
                        <v-col cols="3" class="pl-2">
                            <v-text-field
                                v-model="packingList.scheduledFor"
                                label="Scheduled For"
                                prepend-icon="mdi-calendar-clock"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pl-2">
                            <v-text-field
                                v-model="packingList.remission"
                                rows="1"
                                hide-details
                                label="Remission Code"
                                prepend-icon="mdi-file-document-arrow-right"
                                required
                                :rules="[rules.required]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row ref="files" class="px-3 py-2">
                    <v-col cols="4" class="pt-0">
                        <div class="d-flex my-2">
                            <div class="d-flex align-center">
                                <strong>ATTACHED: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="transparent"
                                    elevation="0"
                                    small
                                    class="ma-1 text-capitalize text--darken-2  pa-2"
                                    v-for="(file, i) in packingList.attachments"
                                    :key="i"
                                >
                                    <v-icon
                                        class="mr-1"
                                        color="primary"
                                        :style="{ 'font-size': '17px' }"
                                    >
                                        mdi-paperclip
                                    </v-icon>
                                    <span
                                        @click="openFile(file)"
                                        :style="{
                                            'font-size': '11px',
                                            color: '#2b81d6',
                                            'text-decoration': 'underline',
                                            cursor: 'pointer',
                                        }"
                                        >{{ file }}</span
                                    >
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                        <div class="d-flex my-2">
                            <div class="d-flex align-center">
                                <strong>REMISSION: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="transparent"
                                    elevation="0"
                                    small
                                    class="ma-1 text-capitalize text--darken-2  pa-2"
                                    v-for="(file, i) in packingList.remissions"
                                    :key="i"
                                >
                                    <v-icon
                                        class="mr-1"
                                        color="primary"
                                        :style="{ 'font-size': '17px' }"
                                    >
                                        mdi-paperclip
                                    </v-icon>
                                    <span
                                        @click="openFile(file)"
                                        :style="{
                                            'font-size': '11px',
                                            color: '#2b81d6',
                                            'text-decoration': 'underline',
                                            cursor: 'pointer',
                                        }"
                                        >{{ file }}</span
                                    >
                                    <v-icon
                                        @click="deleteFile(file)"
                                        class="ml-1"
                                        small
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    @click="onButtonClick"
                                    depressed
                                    fab
                                    x-small
                                    color="primary"
                                    class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <input
                                    ref="uploader2"
                                    class="d-none"
                                    type="file"
                                    @change="onFileChanged"
                                />
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-container fluid px-0>
                    <v-row>
                        <v-col
                            cols="12"
                            v-for="(packageElement,
                            packageIndex) in packingList.packages"
                            :key="packageIndex"
                            class="mb-5"
                        >
                            <v-data-table
                                :headers="headers"
                                :items="packageElement.items"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                disable-pagination
                                hide-default-footer
                                multiple-expand
                                :expanded.sync="packageElement.items"
                            >
                                <template v-slot:top>
                                    <v-row
                                        no-gutter
                                        :style="{
                                            'background-color': '#eeeeee',
                                        }"
                                        class="mt-0 mx-0"
                                    >
                                        <v-col cols="12" class="d-flex">
                                            <h2 class="mt-1">
                                                Package
                                                {{ packageIndex + 1 }}
                                            </h2>
                                            <h4
                                                class="d-flex align-end pl-2 pb-1"
                                            >
                                                {{
                                                    `(W${
                                                        packageElement
                                                            .packageDimensions
                                                            .width
                                                    } x H${
                                                        packageElement
                                                            .packageDimensions
                                                            .height
                                                    } x L${
                                                        packageElement
                                                            .packageDimensions
                                                            .depth
                                                    } ${
                                                        packageElement
                                                            .packageDimensions
                                                            .units
                                                    }) - BOM:${
                                                        packageElement.packageBOM
                                                            ? packageElement.packageBOM
                                                            : ''
                                                    }`
                                                }}
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </template>
                                <!-- ITEMS -->
                                <template v-slot:[`item.type`]="{ item }">
                                    <p class="my-0">
                                        {{
                                            item['bom'] != undefined
                                                ? 'BOM'
                                                : 'WorkOrder'
                                        }}
                                    </p>
                                </template>
                                <template v-slot:[`item.code`]="{ item }">
                                    <p class="my-0">
                                        {{ item.workOrder || item.bom }}
                                    </p>
                                </template>
                                <template v-slot:[`item.partNumber`]="{ item }">
                                    <p class="my-0">
                                        {{ item.partNumber || item.code }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.finishAndUCCode`]="{ item }"
                                >
                                    <p class="my-0">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <span
                                                    v-on="on"
                                                    style="cursor: pointer;"
                                                >
                                                    {{
                                                        replaceItem(
                                                            item,
                                                            'description'
                                                        )
                                                    }}
                                                </span>
                                            </template>
                                            <span>
                                                {{ replaceItem(item, 'code') }}
                                            </span>
                                        </v-tooltip>
                                    </p>
                                </template>
                                <!--FOOTER-->
                                <template v-slot:[`body.append`]>
                                    <tr
                                        :style="{
                                            'background-color': '#eeeeee',
                                        }"
                                    >
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td
                                            class="font-weight-bold"
                                            :style="{ 'text-align': 'center' }"
                                        >
                                            {{
                                                workOrderTotalPacking(
                                                    packageElement
                                                )
                                            }}
                                        </td>
                                        <td
                                            class="font-weight-bold"
                                            :style="{ 'text-align': 'center' }"
                                        >
                                            {{
                                                workOrderTotalMass(
                                                    packageElement
                                                ).toFixed(2)
                                            }}
                                            Kg
                                        </td>
                                        <td />
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row
                    no-gutters
                    :style="{ 'background-color': '#eeeeee' }"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2 font-weight-black ml-4">TOTALS:</p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2 font-weight-black">
                            QUANTITY OF PACKAGES:
                            {{ packingList.packages.length }}
                        </p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2 font-weight-black">
                            QUANTITY OF ITEMS: {{ totalItemsQty() }}
                        </p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2 font-weight-black">
                            TOTAL MASS: {{ totalMass().toFixed(2) }} Kg
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-btn
                    color="red"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="rejectForm = true"
                >
                    REJECT
                </v-btn>
                <v-spacer />
                <v-btn
                    color="primary"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="markPLAsReferred"
                    :disabled="!valid"
                >
                    MARK AS REFERRED
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Reject Work Orders-->
        <v-dialog
            v-model="rejectForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this
                            packing list
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="rejectForm = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectPackingList"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedPackingListTable
                v-if="rejectionTable"
                :items="packingList.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Error Alert-->
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { printPackingPDF } from '@/components/Packing/packingPDF.js'

export default {
    name: 'PackingForm',
    props: {
        originalOrder: {
            type: Object,
            required: true,
            default: () => {},
        },
        project: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedPackingListTable: () =>
            import('@/components/Packing/RejectedPackingListTable.vue'),
    },
    data: () => ({
        packingList: {
            packages: [],
        },
        loading: false,
        projectWorkOrders: [],
        headers: [
            {
                text: 'PARENT',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ITEM CODE',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING QTY',
                value: 'packingQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING MASS',
                value: 'packingMass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
        valid: false,
        menu: false,
        rules: {
            required: v => !!v || 'Required',
        },
        rejectForm: false,
        rejectedNotes: null,
        createdBy: '',
        users: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        rejectionTable: false,
        deliveryInfoString: '',
        settingFinish: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingFinish = settings.find(s => s.name == 'FinishAndUCCode')
            this.users = users
            if (this.originalOrder) {
                this.packingList = _.cloneDeep(this.originalOrder)
                this.createdBy = this.findUserName(this.packingList.createdBy)
                if (this.packingList.deliveryInformation) {
                    this.deliveryInfoString =
                        this.packingList.deliveryInformation.location +
                        ' - ' +
                        this.packingList.deliveryInformation.contact +
                        ' - ' +
                        this.packingList.deliveryInformation.phone
                }
            }
            this.projectWorkOrders = await API.getWorkOrdersByProject(
                this.project.id
            )
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        replaceItem(item, option) {
            if (item.finishAndUCCode) {
                if (this.settingFinish) {
                    let dataFinishAndUCCode = this.settingFinish.finishes.find(
                        e => e.id == item.finishAndUCCode
                    )
                    if (option == 'description') {
                        return (
                            dataFinishAndUCCode &&
                            dataFinishAndUCCode.description
                        )
                    } else if (option == 'code') {
                        return dataFinishAndUCCode && dataFinishAndUCCode.code
                    }
                }
            }
        },
        totalItemsQty() {
            return this.packingList.packages.reduce(
                (accumulator, packageInfo) =>
                    accumulator +
                    packageInfo.items.reduce(
                        (itemAccumulator, item) =>
                            itemAccumulator + Number(item.packingQty),
                        0
                    ),
                0
            )
        },
        totalMass() {
            return this.packingList.packages.reduce(
                (accumulator, packageInfo) =>
                    accumulator +
                    packageInfo.items.reduce(
                        (itemAccumulator, item) =>
                            itemAccumulator + Number(item.packingMass),
                        0
                    ),
                0
            )
        },
        workOrderTotalPacking(packageElement) {
            return packageElement.items.reduce(
                (itemAccumulator, item) =>
                    itemAccumulator + Number(item.packingQty),
                0
            )
        },
        workOrderTotalMass(packageElement) {
            return packageElement.items.reduce(
                (itemAccumulator, item) =>
                    itemAccumulator + Number(item.packingMass),
                0
            )
        },
        findUserName(id) {
            let userName = ''
            const user = this.users.find(u => u.id == id)
            if (user) {
                userName = user.name
            }
            return userName
        },
        async openFile(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.packingList.projectId}/packingLists`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteFile(file) {
            try {
                this.loading = true
                await deleteFile(
                    file,
                    `${this.companyId}/projects/${this.packingList.projectId}/packingLists`
                )
                this.packingList.remissions = this.packingList.remissions.filter(
                    f => f != file
                )
                await API.packingListRemissions({
                    id: this.packingList.id,
                    projectId: this.packingList.projectId,
                    files: this.packingList.remissions,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async onFileChanged(e) {
            this.selectedFile = e.target.files[0]
            await this.saveFile(this.selectedFile)
        },
        async saveFile(file) {
            try {
                this.loading = true
                await saveFile(
                    file,
                    `${this.companyId}/projects/${this.packingList.projectId}/packingLists`
                )
                if (!this.packingList.remissions) {
                    this.packingList.remissions = [file.name]
                } else {
                    this.packingList.remissions.push(file.name)
                }
                await API.packingListRemissions({
                    id: this.packingList.id,
                    projectId: this.packingList.projectId,
                    files: this.packingList.remissions,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onButtonClick() {
            this.$refs.uploader2.click()
        },
        async markPLAsReferred() {
            try {
                this.loading = true
                const packingList = await API.markPLAsReferred({
                    projectId: this.project.id,
                    packingListId: this.originalOrder.id,
                    remission: this.packingList.remission,
                })
                this.$emit('replacePackingList', packingList)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async rejectPackingList() {
            try {
                this.loading = true
                const packingList = await API.rejectPackingList({
                    projectId: this.project.id,
                    packingListId: this.originalOrder.id,
                    notes: this.rejectedNotes,
                })
                this.$emit('replacePackingList', packingList)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async print() {
            try {
                this.loading = true
                await printPackingPDF(this.packingList, this.project)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.quote_tables {
    width: 100%;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
