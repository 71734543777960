<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">{{
            'Reprocess For ' + workOrder.code
        }}</v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mb-2 mx-1 mt-3">
                <v-col>
                    <v-data-table
                        id="virtual-scroll-table"
                        disable-pagination
                        hide-default-footer
                        :headers="headers"
                        :items="filteredItems"
                        class="elevation-0"
                        :mobile-breakpoint="0"
                        fixed-header
                        style="width: 100%;"
                        :height="
                            $vuetify.breakpoint.mdAndDown ? '50vh' : '35vh'
                        "
                        item-key="index"
                        :loading="loading"
                    >
                        <template v-slot:top>
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                                style="background-color: #eeeeee"
                            >
                                <v-col cols="9">
                                    <h2 class="ml-4">WORK ORDER ITEMS</h2>
                                    <v-spacer />
                                </v-col>
                            </v-row>
                        </template>
                        <!--HEADERS-->
                        <template v-slot:[`header.partNumber`]="{ header }">
                            <v-text-field
                                class="py-2 mt-2 custom-label"
                                :label="header.text"
                                v-model="partNumberToFilter"
                                dense
                            />
                        </template>
                        <!--ITEMS-->
                        <template v-slot:[`item.selectedQuantity`]="{ item }">
                            <div class="d-flex justify-center">
                                <v-text-field
                                    type="number"
                                    class="my-0"
                                    v-model="item.selectedQuantity"
                                    :rules="[
                                        () =>
                                            rules.maxValue(
                                                item.selectedQuantity,
                                                item
                                            ),
                                    ]"
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.releasedQty`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{
                                        item.quality
                                            ? item.quality.reduce(
                                                  (accum, entry) =>
                                                      accum +
                                                      (entry.releaseQty || 0),
                                                  0
                                              )
                                            : 0
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.reprocessQty`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{
                                        item.quality
                                            ? item.quality.reduce(
                                                  (accum, entry) =>
                                                      accum +
                                                      (entry.reprocessQty || 0),
                                                  0
                                              )
                                            : 0
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.rejectedQty`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{
                                        item.quality
                                            ? item.quality.reduce(
                                                  (accum, entry) =>
                                                      accum +
                                                      (entry.rejectQty || 0),
                                                  0
                                              )
                                            : 0
                                    }}
                                </p>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0 mx-6">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :disabled="buttonConditions()"
                @click="action"
                :loading="loading"
            >
                Create
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'ReprocessForm',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        warranty: {
            type: Boolean,
            default: () => false,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        rules: {
            required: v => !!v || 'The value is required',
            minValue: v => v > 0 || 'The value must be greater than 0',
            maxValue: (v, item) => {
                let min = 0
                let max = item.totalQty
                if (Number(v || 0) > max) {
                    return `The value cannot be greater than ${max}.`
                } else if (Number(v || 0) < min) {
                    return `The value cannot be less than ${min}.`
                } else {
                    return true
                }
            },
        },
        headers: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RELEASED',
                value: 'releasedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REPROCESS',
                value: 'reprocessQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REJECTED',
                value: 'rejectedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL QTY',
                value: 'totalQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'selectedQuantity',
                align: 'center',
                sortable: false,
                width: 80,
            },
        ],
        partNumberToFilter: null,
        selectAll: false,
    }),
    computed: {
        filteredItems() {
            let conditions = []

            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }

            if (conditions.length > 0) {
                return this.workOrder.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return this.workOrder.items
        },
    },
    async mounted() {
        try {
            this.loading = true
            let count = 0
            this.workOrder.items.forEach(item => {
                item.totalQty = item.quantity
                item.index = count
                count++
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },

        async action() {
            try {
                this.loading = true
                let itemsToCreate = []
                const filteredItems = this.workOrder.items.filter(
                    i =>
                        i.selectedQuantity != undefined &&
                        i.selectedQuantity != null &&
                        i.selectedQuantity > 0
                )
                filteredItems.forEach(fi => {
                    itemsToCreate.push({
                        partNumber: fi.partNumber,
                        quantity: fi.selectedQuantity,
                    })
                })
                await API.createReprocessWorkOrder({
                    parentWorkOrderId: this.workOrder.id,
                    notes: this.workOrder.notes,
                    items: itemsToCreate,
                    warranty: this.warranty,
                })
                this.$emit('closeSuccess')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        buttonConditions() {
            return !this.valid
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
</style>
