<template>
    <v-data-table
        :headers="headers"
        :items="filteredChanges"
        item-key="index"
        class="elevation-0 mt-2"
        :mobile-breakpoint="0"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-row
                no-gutter
                class="mt-0 mx-0 pt-4 pb-0 pb-4"
                :style="{ 'background-color': '#eeeeee' }"
            >
                <v-col cols="12" class="d-flex mb-n6">
                    <h1 class="mr-4">{{ id ? 'USER' : 'USERS' }} LOGS</h1>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateRangeText"
                                label="Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :style="{ 'max-width': '250px' }"
                                class="pt-1"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" range scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                :loading="loading"
                                @click="usersChangeLog()"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </template>
        <!--HEADERS-->
        <template v-slot:[`header.updatedOn`]="{ header }">
            <v-text-field :label="header.text" v-model="searchByDate" />
        </template>
        <template v-slot:[`header.updatedBy`]="{ header }">
            <v-text-field :label="header.text" v-model="searchByUpdatedBy" />
        </template>
        <template v-slot:[`header.userUpdated`]="{ header }">
            <v-text-field :label="header.text" v-model="searchByUserUpdated" />
        </template>
        <!--ITEMS-->
        <template v-slot:[`item.updatedOn`]="{ item }">
            <p class="my-0">
                {{ formatDate(item.updatedOn.seconds) }}
            </p>
        </template>
        <template v-slot:[`item.updatedBy`]="{ item }">
            <p class="my-0">
                {{ item.updatedBy }}
            </p>
        </template>
        <template v-slot:[`item.userUpdated`]="{ item }">
            <p class="my-0">
                {{ item.userModified }}
            </p>
        </template>

        <template v-slot:[`item.differences`]="{ item }">
            <p
                class="my-0 font-weight-black"
                v-for="(key, index) of Object.keys(item.differences || {})"
                :key="index"
            >
                {{ key }}:
                <span class="font-weight-regular">{{
                    item.differences[key]
                }}</span>
            </p>
        </template>
    </v-data-table>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff'
import moment from 'moment'

export default {
    name: 'UsersLogs',
    props: {
        id: {
            type: String,
            default: () => undefined,
        },
    },
    data: () => ({
        loading: false,
        logs: [],
        headers: [
            {
                text: 'DATE',
                value: 'updatedOn',
                align: 'center',
                sortable: false,
                width: '200',
            },
            {
                text: 'USER MODIFIED',
                value: 'userUpdated',
                align: 'left',
                sortable: false,
                width: 200,
            },
            {
                text: 'MODIFIED BY',
                value: 'updatedBy',
                align: 'left',
                sortable: false,
                width: 200,
            },
            {
                text: 'CHANGES',
                value: 'differences',
                align: 'left',
                sortable: false,
            },
        ],
        users: [],
        changes: [],
        changesWithOutDate: [],
        searchByDate: null,
        searchByUpdatedBy: null,
        searchByUserUpdated: null,
        menu: false,
        dates: [],
        startDate: null,
        endDate: null,
    }),
    computed: {
        filteredChanges() {
            const conditions = []
            if (this.searchByDate) {
                conditions.push(this.filterByDate)
            }
            if (this.searchByUserUpdated) {
                conditions.push(this.filterByUserUpdated)
            }
            if (this.searchByUpdatedBy) {
                conditions.push(this.filterByUpdatedBy)
            }
            if (conditions.length > 0) {
                return this.changes.filter(change => {
                    return conditions.every(condition => {
                        return condition(change)
                    })
                })
            }
            return this.changes
        },
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            let currentDate = new Date()
            let date1 = new Date(currentDate)
            date1.setMonth(date1.getMonth() - 2)
            date1.setHours(0, 0, 0, 0)
            const initialDates = [
                date1.toISOString().split('T')[0],
                currentDate.toISOString().split('T')[0],
            ]
            this.dates = initialDates
            await this.usersChangeLog()
            this.sortData()
            this.changes = this.changes.concat(this.changesWithOutDate)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async usersChangeLog() {
            try {
                this.loading = true
                this.menu = false
                this.changes = []
                this.setDate()
                this.logs = await API.usersLogs({
                    userId: this.id,
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                this.logs.forEach(log => {
                    if (log.old_data && log.data) {
                        let differences = objDiff(log.data, log.old_data)

                        delete differences.updatedOn
                        delete differences.picture
                        delete differences.FCMToken
                        delete differences.companyId

                        if (differences.signCode) {
                            delete differences.signCode
                            differences.signCodeUpdated = true
                        }
                        if (differences.process) {
                            differences.process = differences.process.name
                        }
                        let userModified = this.getUser(log.id)
                        userModified = userModified.name
                            ? userModified.name
                            : 'No User Data'
                        let updatedBy = 'No User Data'

                        if (log.data.updatedBy) {
                            const user = this.getUser(log.data.updatedBy)
                            if (user.name) {
                                updatedBy = user.name
                            }
                        }
                        if (differences.permissions) {
                            differences.addedPermissions = log.data.permissions.filter(
                                item => !log.old_data.permissions.includes(item)
                            )

                            differences.deletedPermissions = log.old_data.permissions.filter(
                                item => !log.data.permissions.includes(item)
                            )

                            delete differences.permissions

                            if (differences.deletedPermissions.length == 0) {
                                delete differences.deletedPermissions
                            }

                            if (differences.addedPermissions.length == 0) {
                                delete differences.addedPermissions
                            }
                        }

                        if (Object.keys(differences).length > 0) {
                            this.changes.push({
                                differences,
                                userModified,
                                updatedOn: log.timestamp,
                                updatedBy,
                            })
                        }
                    } else if (log.data) {
                        let differences = objDiff(log.data, {})

                        delete differences.updatedOn
                        delete differences.picture
                        delete differences.FCMToken
                        delete differences.companyId

                        if (differences.signCode) {
                            delete differences.signCode
                            differences.signCodeUpdated = true
                        }
                        if (differences.process) {
                            differences.process = differences.process.name
                        }
                        let userModified = this.getUser(log.id)
                        userModified = userModified.name
                            ? userModified.name
                            : 'No User Data'
                        let updatedBy = 'No User Data'

                        if (log.data.updatedBy) {
                            const user = this.getUser(log.data.updatedBy)
                            if (user.name) {
                                updatedBy = user.name
                            }
                        }
                        differences.created = true
                        if (Object.keys(differences).length > 0) {
                            this.changes.push({
                                differences,
                                userModified,
                                updatedOn: log.timestamp,
                                updatedBy,
                            })
                        }
                    }
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        sortData() {
            this.changes.sort(
                (a, b) => b.updatedOn.seconds - a.updatedOn.seconds
            )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm:ss')
        },
        getUser(userId) {
            const user = this.users.find(user => user.id == userId)
            return user || userId
        },
        setDate() {
            if (this.dates[0] > this.dates[1]) {
                this.startDate = this.dates[1]
                this.endDate = this.dates[0]
            } else {
                this.startDate = this.dates[0]
                this.endDate = this.dates[1]
            }
            this.menu = false
        },
        filterByDate(item) {
            return this.formatDate(item.updatedOn.seconds)
                .toLowerCase()
                .includes(this.searchByDate.toLowerCase())
        },
        filterByUserUpdated(item) {
            return item.userModified
                .toLowerCase()
                .includes(this.searchByUserUpdated.toLowerCase())
        },
        filterByUpdatedBy(item) {
            return item.updatedBy
                .toLowerCase()
                .includes(this.searchByUpdatedBy.toLowerCase())
        },
    },
}
</script>

<style>
thead {
    background: #eeeeee;
    position: sticky;
    top: 0;
}
</style>
