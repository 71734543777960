<template>
    <div class="mt-2">
        <v-data-table
            :headers="headers"
            :items="filters"
            class=" round-data-table elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                    :style="{ 'background-color': '#eeeeee !important' }"
                    :height="$vuetify.breakpoint.smAndDown ? '100%' : '65%'"
                    class="round-data-table pr-0"
                >
                    <v-col
                        class="pt-5 pt-sm-3 pt-md-3 pt-lg-3 pt-xl-3"
                        cols="9"
                        sm="7"
                        md="7"
                        lg="9"
                        xl="9"
                    >
                        <v-toolbar-title>SCOPES </v-toolbar-title>
                    </v-col>
                    <v-spacer />
                    <v-btn
                        v-if="createItemBtn && lead.status != 'rejected'"
                        depressed
                        color="primary"
                        @click="openCreateCost(lead)"
                        rounded
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ !$vuetify.breakpoint.xs ? 'Create scope' : '' }}
                    </v-btn>
                </v-toolbar>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.reference`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="referenceToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.reviewer`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="reviewerToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.category`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="categoryToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.type`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="typeToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.measure`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="measureToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.quantity`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="quantityToFilter"
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.description`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="descriptionToFilter"
                    class="pt-2"
                />
            </template>

            <template v-slot:[`header.reviewed`]="{ header }">
                <h3>{{ header.text }}</h3>
            </template>
            <template v-slot:[`header.action`]="{ header }">
                <h3>{{ header.text }}</h3>
            </template>

            <!--ITEMS-->
            <template v-slot:[`item.reviewer`]="{ item }">
                <p>
                    {{ getUserName(item.reviewer) }}
                </p>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <div
                    v-if="lead.status != 'rejected'"
                    class="d-flex justify-center"
                >
                    <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                depressed
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-if="lead.status != 'rejected'"
                                @click.stop="openEditCost({ lead, item })"
                            >
                                <v-list-item-title>
                                    Edit
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="lead.status != 'rejecetd'"
                                @click.stop="openDeleteCost({ lead, item })"
                            >
                                <v-list-item-title>
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <!-- Create New Item Cost Detail  -->
        <v-dialog
            v-model="createItem"
            width="500px"
            persistent
            :retain-focus="false"
        >
            <LeadCostsForm
                v-if="createItem"
                :lead="selectedLead"
                :title="title"
                :createForm="createForm"
                :originalCost="originalItemCost"
                @closeDialog="closeFormCost"
                @addCost="addCost"
                @replaceCost="replaceCost"
            />
        </v-dialog>
        <!--ALERT MESSAGES-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!-- DELETE -->
        <v-dialog
            :retain-focus="false"
            v-model="deleteCostWarning"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this scope?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="deleteCostWarning = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteScope()"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import LeadCostsForm from '@/components/Leads/LeadCostsForm'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'LeadsCosts',
    props: {
        lead: Object,
        showTitle: Boolean,
        createItemBtn: Boolean,
        user: Object,
        users: Array,
        default() {
            false
        },
    },
    components: {
        LeadCostsForm,
    },

    data() {
        return {
            headers: [
                {
                    text: 'Reference',
                    value: 'reference',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Reviewer By',
                    value: 'reviewer',
                    align: 'center',
                    sortable: false,
                    width: 70,
                },
                {
                    text: 'Category',
                    value: 'category',
                    align: 'center',
                    sortable: false,
                    width: 50,
                },
                {
                    text: 'Measure',
                    value: 'measure',
                    align: 'center',
                    sortable: false,
                    width: 55,
                },
                {
                    text: 'Units',
                    value: 'quantity',
                    align: 'center',
                    sortable: false,
                    width: 60,
                },
                {
                    text: 'Description',
                    value: 'description',
                    align: 'center',
                    sortable: false,
                    width: 200,
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                    width: 50,
                },
            ],
            createItem: false,
            createForm: null,
            activateAlert: false,
            referenceToFilter: '',
            reviewerToFilter: '',
            categoryToFilter: '',
            measureToFilter: '',
            quantityToFilter: '',
            descriptionToFilter: '',
            newItemCost: {
                measure: 'ft2',
            },
            loading: false,
            isEditing: false,
            title: '',
            originalItemCost: {},
            selectedLead: {},
            deleteCostWarning: false,
        }
    },
    computed: {
        filters() {
            let conditions = []

            if (this.referenceToFilter) {
                conditions.push(this.filterForReference)
            }
            if (this.reviewerToFilter) {
                conditions.push(this.filterForReviewer)
            }
            if (this.categoryToFilter) {
                conditions.push(this.filterForCategory)
            }
            if (this.typeToFilter) {
                conditions.push(this.filterForType)
            }
            if (this.measureToFilter) {
                conditions.push(this.filterForMeasure)
            }
            if (this.quantityToFilter) {
                conditions.push(this.filterForQuantity)
            }
            if (this.descriptionToFilter) {
                conditions.push(this.filterForDescription)
            }
            if (conditions.length > 0) {
                return this.lead.costs.filter(cost => {
                    return conditions.every(condition => {
                        return condition(cost)
                    })
                })
            }
            return this.lead.costs
        },
    },
    async mounted() {
        this.lead.costs = this.lead.costs ? this.lead.costs : []
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openCreateCost(item) {
            this.selectedLead = _.cloneDeep(item)
            this.title = 'New Scope'
            this.createForm = true
            this.createItem = true
        },
        closeFormCost() {
            this.createItem = false
            this.createForm = false
            this.title = ''
            this.originalItemCost = {}
            this.selectedLead = {}
        },
        filterForReference(item) {
            if (item.reference) {
                return item.reference
                    .toLowerCase()
                    .includes(this.referenceToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForReviewer(item) {
            if (item.reviewer) {
                return this.getUserName(item.reviewer)
                    .toLowerCase()
                    .includes(this.reviewerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCategory(item) {
            if (item.category) {
                return item.category
                    .toLowerCase()
                    .includes(this.categoryToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForType(item) {
            if (item.type) {
                return item.type
                    .toLowerCase()
                    .includes(this.typeToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForMeasure(item) {
            if (item.measure) {
                return item.measure
                    .toLowerCase()
                    .includes(this.measureToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForQuantity(item) {
            if (item.quantity) {
                return item.quantity == Number(this.quantityToFilter)
            } else {
                return false
            }
        },
        filterForDescription(item) {
            if (item.description) {
                return item.description
                    .toLowerCase()
                    .includes(this.descriptionToFilter.toLowerCase())
            } else {
                return false
            }
        },

        openEditCost({ lead, item }) {
            this.title = 'Edit Cost'
            this.originalItemCost = _.cloneDeep(item)
            this.selectedLead = _.cloneDeep(lead)
            this.createItem = true
            this.createForm = false
        },
        addCost(cost) {
            if (this.lead.costs == undefined) {
                this.lead.costs = [cost]
            } else {
                this.lead.costs.push(cost)
            }
            this.lead.costs = _.cloneDeep(this.lead.costs)
            this.closeFormCost()
        },
        replaceCost(cost) {
            const index = this.lead.costs.findIndex(
                c => c.id == this.originalItemCost.id
            )
            this.lead.costs.splice(index, 1, {
                ...this.lead.costs[index],
                ...cost,
            })
            this.closeFormCost()
        },
        getUserName(userId) {
            let userName = ''
            const userFoud = this.users.find(u => u.id == userId)
            if (userFoud) {
                userName = userFoud.name
            }
            return userName
        },
        async onCheckboxClicked(item) {
            try {
                if (this.lead.status == 'rejected') {
                    return null
                }
                this.loading = true
                await API.updateCostsDetails({
                    leadId: this.lead.id,
                    costId: item.id,
                    costDiff: { reviewed: item.reviewed },
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                return error
            } finally {
                this.loading = false
            }
        },
        openDeleteCost({ lead, item }) {
            this.originalItemCost = _.cloneDeep(item)
            this.selectedLead = _.cloneDeep(lead)
            this.deleteCostWarning = true
        },
        closeDeleteCost() {
            this.deleteCostWarning = false
            this.originalItemCost = null
            this.selectedLead = null
        },
        async deleteScope() {
            try {
                this.loading = true
                await API.deleteLeadScope({
                    leadId: this.selectedLead.id,
                    costId: this.originalItemCost.id,
                })
                const newCosts = this.lead.costs.filter(
                    cost => cost.id != this.originalItemCost.id
                )
                this.lead.costs = _.cloneDeep(newCosts)
                this.closeDeleteCost()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                return error
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
<style scoped>
.round-data-table {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
</style>
