<template>
    <div>
        <v-card id="details" :loading="loading" height="100vh">
            <v-card-title ref="title">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>NEW RESERVE</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-subtitle class="mb-2" ref="subtitle">
                <div>
                    <p class="pa-0 ma-0">
                        <strong>CODE: </strong>{{ item.code }}
                    </p>
                </div>
                <div>
                    <p class="pa-0 ma-0">
                        <strong>Description: </strong>{{ item.description }}
                    </p>
                </div>
            </v-card-subtitle>
            <v-card-text class="mt-n5">
                <v-row no-gutters class="mb-6" ref="searchField">
                    <v-col cols="4">
                        <v-autocomplete
                            v-model="selectedProject"
                            :items="projects"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-account-hard-hat"
                            hide-details
                            label="Select Project"
                            :readonly="!!projectId"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="item.items"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    :height="height"
                    calculate-widths
                >
                    <!--ITEMS-->
                    <template v-slot:[`item.project_name`]="{ item }">
                        <p class="my-0">
                            {{
                                item.project_name == ''
                                    ? 'Free'
                                    : item.project_name
                            }}
                        </p>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <p class="my-0">$ {{ item.price }}</p>
                    </template>
                    <template v-slot:[`item.usable_free_value`]="{ item }">
                        <p class="my-0">
                            $ {{ numberFormat(item.usable_free_value) }}
                        </p>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-center">
                            <v-btn
                                v-if="item.free_use != '' && item.free_use > 0"
                                small
                                icon
                                color="primary"
                                @click="openReserveForm(item)"
                                hide-details
                                :disabled="
                                    item.project_name != '' ||
                                        !selectedProject ||
                                        validateProject(item)
                                "
                            >
                                <v-icon>mdi-database-edit-outline</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                icon
                                @click="openDialogReserve(item)"
                                v-if="
                                    projects.length > 0 &&
                                        validateProjectManager(item)
                                "
                            >
                                <v-icon>mdi-database-arrow-left-outline</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <!-- ADD RESERVE -->
        <v-dialog
            :retain-focus="false"
            persistent
            v-model="reserveForm"
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <AddReserve
                v-if="reserveForm"
                :item="selectedItem"
                @closeDialog="closeReserveForm"
                @reserve="reserve"
            />
        </v-dialog>
        <!--Release Reserve-->
        <v-dialog
            :retain-focus="false"
            v-model="openReserve"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <ReleaseReserve
                v-if="openReserve"
                :item="selectedItem"
                @closeDialog="closeDialogReserve"
                @release="release"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import AddReserve from '@/components/StockRoom/AddReserve.vue'
import _ from 'lodash'
import { auth } from '@/services/firebase'
import ReleaseReserve from '@/components/StockRoom/ReleaseReserve.vue'

export default {
    name: 'ItemReserve',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        AddReserve,
        ReleaseReserve,
    },
    data: () => ({
        height: 0,
        loading: false,
        headers: [
            {
                text: 'PROJECT',
                value: 'project_name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'STORE',
                value: 'storage',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'free_use',
                align: 'left',
            },
            {
                text: 'PRICE (UNIT)',
                value: 'price',
                align: 'left',
            },
            {
                text: 'TOTAL PRICE',
                value: 'usable_free_value',
                align: 'left',
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
                width: '100px',
            },
        ],
        selectedProject: null,
        projects: [],
        selectedItem: undefined,
        reserveForm: false,
        userId: '',
        openReserve: false,
    }),
    async mounted() {
        try {
            this.userId = auth().currentUser.uid
            this.projects = await API.getLiteProjectsByUser()
            if (this.projectId) {
                this.selectedProject = this.projectId
            }
            this.item.items.forEach(item => {
                const total = Number(
                    typeof item.usable_free_value == 'string'
                        ? item.usable_free_value.replace(/\./gi, '')
                        : item.usable_free_value
                )
                const available = Number(
                    typeof item.free_use == 'string'
                        ? item.free_use.replace(/\./gi, '')
                        : item.free_use
                )
                if (available == 0) {
                    item.price = 0
                } else {
                    item.price = new Intl.NumberFormat('de-DE').format(
                        Math.ceil(total / available)
                    )
                }
            })
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        validateProjectManager(item) {
            const index = this.projects.findIndex(project => {
                return (
                    (item.order || item.orderNumber) == `${project.sapCode}` ||
                    item.project_name
                        .toLowerCase()
                        .includes(`${project.reference}`.toLowerCase())
                )
            })
            if (index >= 0) {
                const project = this.projects[index]
                return !(project.projectManager !== this.userId)
            }
            return false
        },
        async release(release) {
            try {
                this.loading = true
                await API.releaseReserve({
                    ...(this.selectedItem.project_name
                        ? { stockItem: this.selectedItem }
                        : {
                              reserveId: this.selectedItem.id,
                              projectId: this.selectedItem.projectId,
                          }),
                    targetProjectId: release.projectId,
                    quantity: release.quantity,
                    notes: release.notes,
                })
                this.closeDialogReserve()
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openDialogReserve(item) {
            this.openReserve = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeDialogReserve() {
            this.openReserve = false
            this.selectedItem = {}
        },
        validateProject() {
            const index = this.projects.findIndex(
                project => project.id == this.selectedProject
            )
            if (index >= 0) {
                const project = this.projects[index]
                return (
                    project.accountManager !== this.userId &&
                    project.projectManager !== this.userId &&
                    !project.collaborators.includes(this.userId)
                )
            }
            return false
        },
        closeReserveForm() {
            this.reserveForm = false
            this.selectedItem = undefined
        },
        openReserveForm(item) {
            this.selectedItem = _.cloneDeep(item)
            this.reserveForm = true
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
                searchField: { clientHeight: searchFieldHeight },
            } = this.$refs
            this.height =
                window.innerHeight -
                titleHeight -
                subtitleHeight -
                searchFieldHeight -
                35
        },
        async reserve() {
            try {
                this.loading = true
                const itemToReserve = {
                    order: this.selectedItem.order,
                    free_use: Number(
                        typeof this.selectedItem.free_use == 'string'
                            ? this.selectedItem.free_use.replace(/\./gi, '')
                            : this.selectedItem.free_use
                    ),
                    price: Number(this.selectedItem.price.replace(/\./gi, '')),
                    project_name: this.selectedItem.project_name,
                    reserve: Number(this.selectedItem.reserve),
                    storage: Number(this.selectedItem.storage),
                    usable_free_value: Number(
                        typeof this.selectedItem.usable_free_value == 'string'
                            ? this.selectedItem.usable_free_value.replace(
                                  /\./gi,
                                  ''
                              )
                            : this.selectedItem.usable_free_value
                    ),
                }
                const project = this.projects.find(
                    project => project.id === this.selectedProject
                )
                const reserve = {
                    projectId: this.selectedProject,
                    projectName: project.name,
                    code: this.item.code,
                    description: this.item.description,
                    quantity: itemToReserve.reserve,
                    price: itemToReserve.price,
                    total: itemToReserve.reserve * itemToReserve.price,
                    item: itemToReserve,
                    notes: this.selectedItem.notes || '',
                }
                await API.createReserve(reserve)
                this.closeReserveForm()
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        numberFormat(value) {
            return new Intl.NumberFormat('de-DE').format(Math.ceil(value))
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
