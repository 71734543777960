<template>
    <div>
        <v-row v-resize="onResize" no-gutters class="fill-height">
            <v-col
                ref="menu"
                cols="12"
                md="3"
                sm="12"
                lg="3"
                class="border-right d-flex flex-column flex-nowrap"
                :style="{ height: `${height}px` }"
            >
                <v-row no-gutters class="border-bottom flex-grow-0">
                    <!-- Search -->
                    <v-col cols="9" class="pa-2 pb-0">
                        <v-text-field
                            v-model="search"
                            clearable
                            dense
                            filled
                            hide-details="auto"
                            placeholder="Search"
                            prepend-inner-icon="mdi-magnify"
                            rounded
                        />
                    </v-col>
                    <v-col
                        cols="3"
                        class="px-1 py-2 d-flex align-center justify-center"
                    >
                        <v-btn
                            depressed
                            fab
                            small
                            color="primary"
                            @click="openCreateDialog"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <v-divider />
                    </v-col>
                    <v-col
                        cols="6"
                        class="px-1 py-2 d-flex align-center justify-center"
                    >
                        <v-switch
                            v-model="sapStatusSwitch"
                            label="SAP Status"
                            color="primary"
                            class="my-0 py-0"
                            hide-details
                            inset
                        />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col
                        cols="6"
                        class="px-2 py-2 d-flex align-center justify-center"
                    >
                        <v-btn
                            depressed
                            color="primary"
                            rounded
                            @click="openInformDialog"
                        >
                            <v-icon left>mdi-file-excel</v-icon>
                            <span>Inform</span>
                        </v-btn>

                        <SupplierForm
                            v-model="createDialog"
                            title="New Supplier"
                            :create-form="true"
                            @closeDialog="closeCreateDialog"
                            @addSupplier="addSupplier"
                        />
                    </v-col>
                </v-row>
                <div
                    v-if="loading"
                    class="d-flex align-center justify-center flex-grow-1"
                >
                    <v-progress-circular color="primary" indeterminate />
                </div>
                <!-- Quotes list -->
                <!-- v-scroll:#scroll-target="onScroll" -->
                <v-list
                    v-if="!loading"
                    class="overflow-y-auto pa-0"
                    id="scroll-target"
                >
                    <template v-if="filteredSuppliers.length > 0">
                        <v-list-item
                            v-for="(supplier, i) in filteredSuppliers2"
                            :key="supplier.id"
                            class="pa-0"
                        >
                            <CardSupplier
                                @click="hide"
                                :class="{
                                    'border-bottom':
                                        i < filteredSuppliers.length - 1,
                                }"
                                :supplier="supplier"
                                @supplierDetail="supplierDetail"
                            >
                            </CardSupplier>
                        </v-list-item>
                    </template>
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title class="text-center">
                                No clients
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col
                v-if="!$vuetify.breakpoint.smAndDown"
                ref="content"
                cols="12"
                sm="0"
                md="9"
                lg="9"
                :style="{ height: `${height}px` }"
            >
                <router-view @show="show" />
            </v-col>
            <v-dialog
                v-else
                :retain-focus="false"
                v-model="supplierDialog"
                persistent
                fullscreen
            >
                <div v-if="supplierDialog" :style="{ height: '100%' }">
                    <router-view @show="show" />
                </div>
            </v-dialog>
        </v-row>
        <v-dialog
            :retain-focus="false"
            v-model="contactDialog"
            persistent
            max-width="600px"
        >
            <ContactSupplierForm
                v-if="contactDialog"
                title="New Contact"
                :create-form="true"
                @closeDialog="closeContactDialog"
                @addContact="addContact"
                :supplierId="supplier.id"
            />
        </v-dialog>

        <!-- INFORM -->
        <v-dialog
            v-model="informDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading" v-if="informDialog">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeInformDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        INFORM
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="py-0">
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="headers"
                            :items="auxSuppliers"
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    :style="{ 'background-color': '#eeeeee' }"
                                >
                                    <v-col cols="6" class="d-flex align-center">
                                        <h2 class="my-n3 d-flex align-center">
                                            SUPPLIERS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--HEADERS-->
                            <template v-slot:[`header.selected`]="{ header }">
                                <div class="d-flex justify-center align-center">
                                    <span class="ma-0 pr-2">{{
                                        header.text
                                    }}</span>
                                    <v-simple-checkbox
                                        color="primary"
                                        v-model="selectAll"
                                        @click="selectAllSuppliers()"
                                    ></v-simple-checkbox>
                                </div>
                            </template>

                            <template v-slot:[`item.selected`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-simple-checkbox
                                        color="primary"
                                        v-model="item.selected"
                                        @click="selectItem()"
                                    ></v-simple-checkbox>
                                </div>
                            </template>
                        </v-data-table>
                        <div class="d-flex align-center py-0 mt-4">
                            <v-simple-checkbox
                                color="primary"
                                v-model="includeContacts"
                            ></v-simple-checkbox>
                            <span class="mt-1 mr-2">Include contacts</span>
                        </div>
                    </v-form>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateXLS"
                        color="primary"
                        :loading="loading"
                        rounded
                        :disabled="
                            !this.auxSuppliers.filter(s => s.selected == true)
                                .length > 0
                        "
                    >
                        GENERATE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import SupplierForm from '@/components/Suppliers/SupplierForm'
import CardSupplier from '@/components/Suppliers/CardSupplier'
import ContactSupplierForm from '@/components/Suppliers/ContactSupplierForm'
import * as XLSX from 'xlsx/xlsx.mjs'
import _ from 'lodash'
export default {
    name: 'Suppliers',
    components: {
        SupplierForm,
        CardSupplier,
        ContactSupplierForm,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'supplier_pictures',
            folderUsersPictures: 'users_pictures',
            folderLogo: 'company_logo',
            loading: false,
            height: 0,
            search: null,
            createDialog: false,
            sortOptions: [
                {
                    text: 'Updated',
                    value: 'updatedOn',
                },
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'City',
                    value: 'city',
                },
            ],
            sortBy: 'name',
            desc: false,
            numberSuppliers: 15,
            completed: true,
            suppliers: [],
            contactDialog: false,
            supplier: {},
            informDialog: false,
            headers: [
                {
                    text: 'NIT',
                    value: 'nit',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'NAME',
                    value: 'name',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'SELECTED',
                    value: 'selected',
                    align: 'center',
                    sortable: false,
                },
            ],
            selectAll: true,
            includeContacts: true,
            valid: false,
            auxSuppliers: [],
            informHeight: 0,
            supplierDialog: false,
            sapStatusSwitch: false,
        }
    },
    provide() {
        return {
            removeSupplier: this.removeSupplier,
            closeDetail: this.closeDetail,
        }
    },
    watch: {
        suppliers: function(newSuppliers) {
            const params = this.$router.currentRoute.params
            if (params.id) {
                if (!params.supplier) {
                    const supplier = newSuppliers.find(i => i.id == params.id)
                    this.$router.currentRoute.params.supplier = supplier
                }
            }
        },
    },
    computed: {
        filteredSuppliers() {
            function compare(key, desc) {
                return function innerSort(a, b) {
                    if (
                        !Object.prototype.hasOwnProperty.call(a, key) ||
                        !Object.prototype.hasOwnProperty.call(b, key)
                    ) {
                        return 0
                    }

                    const varA =
                        typeof a[key] === 'string'
                            ? a[key].toUpperCase()
                            : a[key]
                    const varB =
                        typeof b[key] === 'string'
                            ? b[key].toUpperCase()
                            : b[key]

                    let comparison = 0
                    if (varA._seconds) {
                        if (varA._seconds < varB._seconds) {
                            comparison = 1
                        } else if (varA._seconds > varB._seconds) {
                            comparison = -1
                        }
                    } else {
                        if (varA > varB) {
                            comparison = 1
                        } else if (varA < varB) {
                            comparison = -1
                        }
                    }

                    return desc ? comparison * -1 : comparison
                }
            }
            return this.suppliers
                .filter(q => {
                    if (this.search)
                        return q.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    return true
                })
                .filter(q => {
                    if (this.sapStatusSwitch) {
                        return q.sapStatus
                    }
                    return true
                })
                .map(q => {
                    if (!q.updatedOn) {
                        q.updatedOn = q.createdOn
                    }
                    return q
                })
                .sort(compare(this.sortBy, this.desc))
        },
        filteredSuppliers2: function() {
            return this.filteredSuppliers
            // return (
            //     this.completed &&
            //     this.filteredSuppliers.slice(0, this.numberSuppliers)
            // )
        },
    },
    async created() {
        try {
            this.loading = true
            await this.getSuppliers()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        removeSupplier(id) {
            this.suppliers = this.suppliers.filter(
                supplier => supplier.id != id
            )
        },
        closeDetail(value) {
            this.supplierDialog = value
        },
        onScroll: async function(e) {
            let bottomOfWindow =
                e.target.scrollHeight - Math.abs(e.target.scrollTop) ===
                e.target.clientHeight
            if (bottomOfWindow && this.completed) {
                this.completed = false
                this.numberClients += 15
                this.completed = true
            }
        },
        supplierDetail() {
            this.supplierDialog = true
        },
        onResize() {
            this.height =
                window.innerHeight - this.$vuetify.application.top - 40
            this.informHeight = window.innerHeight - 255
        },
        getSuppliers: async function() {
            try {
                this.loading = true
                this.suppliers = await API.getSuppliers()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        openCreateDialog: function() {
            this.createDialog = true
        },
        closeCreateDialog: function() {
            this.createDialog = false
        },
        addSupplier: function(supplier) {
            this.suppliers.push(supplier)
            this.contactDialog = true
            this.supplier = supplier
        },
        closeContactDialog() {
            this.contactDialog = false
        },
        addContact(contact) {
            this.supplier.contacts = [contact]
        },
        async generateXLS() {
            try {
                this.loading = true
                const {
                    data: { contacts },
                } = await API.getContacts(this.companyId)
                const selectedSuppliers = this.auxSuppliers.filter(
                    s => s.selected == true
                )
                const suppliersData = []
                const contactsData = []
                for (const supplier of selectedSuppliers) {
                    let row = {}
                    const {
                        name,
                        country,
                        state,
                        city,
                        language,
                        currency,
                        nit,
                        note,
                    } = supplier
                    row.Name = name
                    row.NIT = nit ? nit : ''
                    row.Country = country
                    row.State = state
                    row.City = city ? city : ''
                    row.Language = language
                    row.Currency = currency
                    row.Note = note ? note : ''
                    suppliersData.push(row)
                    let supplierInfo = supplier
                    if (this.includeContacts) {
                        const supplierContacts = contacts.filter(
                            c => c.supplierId == supplier.id
                        )
                        for (const contact of supplierContacts) {
                            let row = {}
                            const {
                                name,
                                email,
                                phone,
                                phone2,
                                country,
                                state,
                                city,
                                role,
                                note,
                            } = contact
                            row.Supplier = supplierInfo.name
                            row.NIT = supplierInfo.nit
                            row.Name = name
                            row.Email = email
                            row.Phone = phone ? phone : ''
                            row.Phone2 = phone2 ? phone2 : ''
                            row.Country = country
                            row.State = state
                            row.City = city ? city : ''
                            row.Role = role ? role : ''
                            row.Note = note ? note : ''
                            contactsData.push(row)
                        }
                    }
                }

                // only array possible
                const suppliersWS = XLSX.utils.json_to_sheet(suppliersData)
                const contactsWS = XLSX.utils.json_to_sheet(contactsData)

                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, suppliersWS, 'suppliers') // sheetAName is name of Worksheet
                if (contactsData.length > 0) {
                    XLSX.utils.book_append_sheet(wb, contactsWS, 'contacts')
                }

                // export Excel file
                XLSX.writeFile(wb, `suppliersInform.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeInformDialog()
            }
        },

        openInformDialog() {
            this.selectAll = true
            this.auxSuppliers = _.cloneDeep(this.suppliers)
            if (this.selectAll) {
                this.auxSuppliers.forEach(s => (s.selected = true))
            }
            this.informDialog = true
        },

        closeInformDialog() {
            this.informDialog = false
        },

        selectAllSuppliers() {
            this.auxSuppliers.forEach(s => (s.selected = this.selectAll))
            this.auxSuppliers = _.cloneDeep(this.auxSuppliers)
        },

        selectItem() {
            this.auxSuppliers = _.cloneDeep(this.auxSuppliers)
            this.selectAll = !this.auxSuppliers.find(s => s.selected == false)
        },
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
