<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Process work order
                <span class="font-weight-black"
                    >{{ order.code }} | {{ order.project.reference }}
                    {{ order.project.name }}</span
                >
            </h2>
            <v-spacer />
            <div class="d-flex">
                <v-btn small icon color="primary" @click="downloadPDFs">
                    <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-btn small icon color="primary" @click="downloadXLS">
                    <v-icon>mdi-file-excel</v-icon>
                </v-btn>
                <v-btn small icon color="primary" @click="print">
                    <v-icon>mdi-printer-pos</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text class="px-4 mt-5">
            <v-container fluid ref="container" class="pb-0">
                <v-row no-gutters ref="details">
                    <v-col cols="4">
                        <div>
                            <p>
                                <strong>ID: </strong>
                                {{ order.code.split('-')[1] }}
                            </p>

                            <p>
                                <strong>CREATION DATE: </strong
                                >{{
                                    ` ${formatDate(
                                        order.createdOn.seconds ||
                                            order.createdOn._seconds
                                    )}`
                                }}
                            </p>
                            <p
                                v-if="
                                    order.productionEnd &&
                                        (order.status == 'finished' ||
                                            order.status == 'closed')
                                "
                            >
                                <strong>PRODUCTION END: </strong
                                >{{
                                    ` ${formatDate(
                                        order.productionEnd.seconds ||
                                            order.productionEnd._seconds
                                    )}`
                                }}
                            </p>
                            <p class="text-capitalize">
                                <strong>CREATED BY: </strong>
                                {{
                                    ` ${
                                        order.createdBy && order.createdBy.name
                                            ? order.createdBy.name
                                            : order.createdBy
                                    }`
                                }}
                            </p>
                            <p class="text-capitalize">
                                <strong>PROJECT MANAGER:</strong
                                >{{ ` ${projectManagerName}` }}
                            </p>
                            <div class="d-flex">
                                <div class="d-flex align-center">
                                    <strong>ATTACHED: </strong>
                                </div>
                                <div class="d-flex align-center flex-wrap">
                                    <v-btn
                                        color="grey lighten-3"
                                        elevation="0"
                                        x-small
                                        class="ma-1 text-capitalize grey--text text--darken-1  "
                                        v-for="(file, i) in order.attachments"
                                        :key="i"
                                    >
                                        <span @click="openFileAttached(file)">{{
                                            file
                                        }}</span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="pl-xl-2 pl-lg-2 pl-md-2"
                    >
                        <v-textarea
                            v-model="order.notes"
                            prepend-icon="mdi-text"
                            prefix="Notes: "
                            hide-details
                            rows="1"
                            :disabled="true"
                        >
                        </v-textarea>
                    </v-col>
                    <v-spacer />
                    <v-col cols="4" class="d-flex justify-end">
                        <canvas id="qr"></canvas>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-8">
                    <v-col cols="12">
                        <v-data-table
                            id="virtual-scroll-table"
                            :headers="headers"
                            :items="filterWorkOrders"
                            class="elevation-0 overflow-y-auto"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="{ height: `${height}px` }"
                            v-if="!order.assemblyWorkOrder"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    :style="{ 'background-color': '#eeeeee' }"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="4" class="d-flex">
                                        <h2 class="mx-4 mt-1">
                                            PRODUCTION ITEMS
                                        </h2>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="4" class="d-flex justify-end">
                                        <div
                                            v-if="order.status == 'approved'"
                                            class="d-flex justify-center pr-3"
                                        >
                                            <v-btn
                                                rounded
                                                color="primary"
                                                class="mx-2"
                                                @click="openMultipleRegister"
                                                v-if="user.process"
                                            >
                                                <v-icon class="mr-1">
                                                    mdi-tools
                                                </v-icon>
                                                {{
                                                    $vuetify.breakpoint
                                                        .smAndDown
                                                        ? ''
                                                        : 'REGISTER ITEMS'
                                                }}
                                            </v-btn>
                                            <v-btn
                                                rounded
                                                color="primary"
                                                class="mx-2"
                                                @click="openMultipleSend"
                                                v-if="user.process"
                                            >
                                                <v-icon class="mr-1">
                                                    mdi-send
                                                </v-icon>
                                                {{
                                                    $vuetify.breakpoint
                                                        .smAndDown
                                                        ? ''
                                                        : 'SEND ITEMS'
                                                }}
                                            </v-btn>
                                            <v-btn
                                                rounded
                                                color="primary"
                                                class="mx-2"
                                                @click="openReceiveItems"
                                                v-if="
                                                    order.status ==
                                                        'approved' &&
                                                        user.process
                                                "
                                            >
                                                <v-icon class="mr-1">
                                                    mdi-database-arrow-down-outline
                                                </v-icon>
                                                {{
                                                    $vuetify.breakpoint
                                                        .smAndDown
                                                        ? ''
                                                        : 'RECEIVE ITEMS'
                                                }}
                                            </v-btn>
                                            <v-btn
                                                rounded
                                                color="primary"
                                                class="mx-2"
                                                @click="openMultipleQuality"
                                                v-if="
                                                    markQualityRegistersPermission
                                                "
                                            >
                                                <v-icon class="mr-1">
                                                    mdi-check-bold
                                                </v-icon>
                                                {{
                                                    $vuetify.breakpoint
                                                        .smAndDown
                                                        ? ''
                                                        : 'QUALITY'
                                                }}
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>
                            <!-- HEADERS -->
                            <template v-slot:[`header.partNumber`]="{ header }">
                                <v-text-field
                                    class="py-2 mt-2"
                                    :label="header.text"
                                    v-model="partNumberToFilter"
                                    dense
                                    :style="{ width: '80px' }"
                                />
                            </template>
                            <template v-slot:[`header.processes`]="{ header }">
                                <div class="d-flex justify-center align-center">
                                    <v-text-field
                                        class="py-2 mt-2"
                                        :label="header.text"
                                        v-model="processToFilter"
                                        dense
                                        :style="{ width: '80px' }"
                                    />
                                    <v-spacer />
                                    <v-menu
                                        rounded
                                        offset-y
                                        v-if="user.process"
                                    >
                                        <template
                                            v-slot:activator="{ attrs, on }"
                                        >
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                depressed
                                            >
                                                <v-icon>mdi-sort</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                @click="
                                                    sortByAvailable = !sortByAvailable
                                                    sortByProduced = false
                                                    sortByDelivered = false
                                                    sortByPending = false
                                                    sortByReleased = false
                                                    sortByReprocess = false
                                                    sortByRejected = false
                                                "
                                                :class="{
                                                    'selected-item': sortByAvailable,
                                                }"
                                            >
                                                <v-list-item-title>
                                                    Sort By Available
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="
                                                    sortByAvailable = false
                                                    sortByProduced = !sortByProduced
                                                    sortByDelivered = false
                                                    sortByPending = false
                                                    sortByReleased = false
                                                    sortByReprocess = false
                                                    sortByRejected = false
                                                "
                                                :class="{
                                                    'selected-item': sortByProduced,
                                                }"
                                            >
                                                <v-list-item-title>
                                                    Sort By Produced
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="
                                                    sortByAvailable = false
                                                    sortByProduced = false
                                                    sortByDelivered = !sortByDelivered
                                                    sortByPending = false
                                                    sortByReleased = false
                                                    sortByReprocess = false
                                                    sortByRejected = false
                                                "
                                                :class="{
                                                    'selected-item': sortByDelivered,
                                                }"
                                            >
                                                <v-list-item-title>
                                                    Sort By Delivered
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                            <template v-slot:[`header.status`]="{ header }">
                                <div class="d-flex justify-center align-center">
                                    <p class="ma-0 pa-0 mr-2">
                                        {{ header.text }}
                                    </p>
                                    <v-spacer />
                                    <v-menu rounded offset-y>
                                        <template
                                            v-slot:activator="{ attrs, on }"
                                        >
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                depressed
                                            >
                                                <v-icon>mdi-sort</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                @click="
                                                    sortByPending = !sortByPending
                                                    sortByReleased = false
                                                    sortByReprocess = false
                                                    sortByRejected = false
                                                    sortByAvailable = false
                                                    sortByProduced = false
                                                    sortByDelivered = false
                                                "
                                                :class="{
                                                    'selected-item': sortByPending,
                                                }"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon color="#2b81d6"
                                                        >mdi-circle</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Sort By Pending
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="
                                                    sortByPending = false
                                                    sortByReleased = !sortByReleased
                                                    sortByReprocess = false
                                                    sortByRejected = false
                                                    sortByAvailable = false
                                                    sortByProduced = false
                                                    sortByDelivered = false
                                                "
                                                :class="{
                                                    'selected-item': sortByReleased,
                                                }"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon color="#2fbf56"
                                                        >mdi-circle</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Sort By Released
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="
                                                    sortByPending = false
                                                    sortByReleased = false
                                                    sortByReprocess = !sortByReprocess
                                                    sortByRejected = false
                                                    sortByAvailable = false
                                                    sortByProduced = false
                                                    sortByDelivered = false
                                                "
                                                :class="{
                                                    'selected-item': sortByReprocess,
                                                }"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon color="#ffa41d"
                                                        >mdi-circle</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Sort By Reprocess
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="
                                                    sortByPending = false
                                                    sortByReleased = false
                                                    sortByReprocess = false
                                                    sortByRejected = !sortByRejected
                                                    sortByAvailable = false
                                                    sortByProduced = false
                                                    sortByDelivered = false
                                                "
                                                :class="{
                                                    'selected-item': sortByRejected,
                                                }"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon color="#FF2610"
                                                        >mdi-circle</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Sort By Rejected
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.id`]="{ index }">
                                <p class="my-0">
                                    {{ index + 1 }}
                                </p>
                            </template>
                            <template v-slot:[`item.mass`]="{ item }">
                                <p class="my-0">
                                    {{ item.mass.toFixed(2) }}
                                </p>
                            </template>
                            <template v-slot:[`item.paintingArea`]="{ item }">
                                <p class="my-0">
                                    {{ item.paintingArea.toFixed(2) }}
                                </p>
                            </template>
                            <template
                                v-slot:[`item.finishAndUCCode`]="{ item }"
                            >
                                <p class="my-0">
                                    {{
                                        item.dataFinishAndUCCode &&
                                            item.dataFinishAndUCCode.name
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                                <p
                                    class="my-0"
                                    :style="{ 'max-width': '250px' }"
                                >
                                    {{ item.description }}
                                </p>
                            </template>

                            <template v-slot:[`item.notes`]="{ item }">
                                <p
                                    class="my-0"
                                    :style="{ 'max-width': '250px' }"
                                >
                                    {{ item.notes }}
                                </p>
                            </template>
                            <template v-slot:[`item.processes`]="{ item }">
                                <div
                                    v-for="(process,
                                    index) in item.productionInfo"
                                    :key="process.id"
                                >
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <p class="my-0 text-left" v-on="on">
                                                {{ process.name }}:
                                                {{ process.available }} -
                                                {{ process.produced || 0 }} -
                                                {{
                                                    index ==
                                                        item.processes.length -
                                                            1 &&
                                                    order.assemblyWorkOrder !=
                                                        undefined
                                                        ? 'NA'
                                                        : process.delivered
                                                }}
                                            </p>
                                        </template>
                                        <span>
                                            {{ process.name }}: <br />
                                            Available =
                                            {{ process.available || 0 }} ({{
                                                ((process.received || 0) *
                                                    100) /
                                                    item.quantity
                                            }}%)<br />
                                            Produced =
                                            {{ process.produced || 0 }} ({{
                                                ((process.produced || 0) *
                                                    100) /
                                                    item.quantity
                                            }}%)<br />
                                            Delivered =
                                            {{
                                                index ==
                                                item.processes.length - 1
                                                    ? 'NA'
                                                    : process.delivered
                                            }}
                                            ({{
                                                index ==
                                                item.processes.length - 1
                                                    ? 'NA'
                                                    : ((process.delivered ||
                                                          0) *
                                                          100) /
                                                      item.quantity
                                            }}%)
                                        </span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <div
                                    v-if="
                                        item.processes.includes(
                                            qualityProcess.id
                                        ) ||
                                            order.assemblyWorkOrder == undefined
                                    "
                                >
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                x-small
                                                icon
                                                class="mr-1"
                                                color="white"
                                                :style="{
                                                    'background-color':
                                                        '#2b81d6',
                                                }"
                                                v-on="on"
                                            >
                                                <p class="my-0">
                                                    {{
                                                        calculateQualityPending(
                                                            item
                                                        )
                                                    }}
                                                </p>
                                            </v-btn>
                                            <v-btn
                                                x-small
                                                icon
                                                class="mr-1"
                                                color="white"
                                                :style="{
                                                    'background-color':
                                                        '#2fbf56',
                                                }"
                                                v-on="on"
                                            >
                                                <p class="my-0">
                                                    {{
                                                        calculateReleasedQty(
                                                            item
                                                        )
                                                    }}
                                                </p>
                                            </v-btn>
                                            <v-btn
                                                x-small
                                                icon
                                                class="mr-1"
                                                color="white"
                                                :style="{
                                                    'background-color':
                                                        '#ffa41d',
                                                }"
                                                v-on="on"
                                            >
                                                <p class="my-0">
                                                    {{
                                                        calculateReprocessQty(
                                                            item
                                                        )
                                                    }}
                                                </p>
                                            </v-btn>
                                            <v-btn
                                                x-small
                                                icon
                                                class="mr-1"
                                                color="white"
                                                :style="{
                                                    'background-color':
                                                        '#FF2610',
                                                }"
                                                v-on="on"
                                            >
                                                <p class="my-0">
                                                    {{
                                                        calculateRejectedQty(
                                                            item
                                                        )
                                                    }}
                                                </p>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Pending :
                                            {{
                                                calculateQualityPending(item) ||
                                                    0
                                            }}
                                            <br />
                                            Released :
                                            {{ calculateReleasedQty(item) }}
                                            <br />
                                            Reprocess :
                                            {{ calculateReprocessQty(item) }}
                                            <br />
                                            Rejected :
                                            {{ calculateRejectedQty(item) }}
                                            <br />
                                        </span>
                                    </v-tooltip>
                                </div>
                                <span v-else>-</span>
                            </template>
                            <template v-slot:[`item.files`]="{ item }">
                                <div
                                    class="d-flex justify-center my-0"
                                    v-if="item.files != undefined"
                                >
                                    <a
                                        v-if="
                                            item.files.includes(
                                                `${item.partNumber}.pdf`
                                            )
                                        "
                                        class="mx-1"
                                        @click="loadFiles(item, 'pdf')"
                                    >
                                        PDF
                                    </a>
                                    <a
                                        v-if="
                                            item.files.includes(
                                                `${item.partNumber}.dxf`
                                            )
                                        "
                                        class="mx-1"
                                        @click="loadFiles(item, 'dxf')"
                                    >
                                        DXF
                                    </a>
                                    <a
                                        v-if="
                                            item.files.includes(
                                                `${item.partNumber}.stp`
                                            )
                                        "
                                        class="mx-1"
                                        @click="loadFiles(item, 'stp')"
                                    >
                                        STP
                                    </a>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div>
                                    <v-menu rounded offset-y>
                                        <template
                                            v-slot:activator="{ attrs, on }"
                                        >
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                depressed
                                            >
                                                <v-icon
                                                    >mdi-dots-vertical</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                @click="
                                                    registerProduction(item)
                                                "
                                                v-if="user.process"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>mdi-tools</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Register Production
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="sendToPrevProcess(item)"
                                                v-if="
                                                    user.process &&
                                                        item.processes[0] !=
                                                            user.process.id
                                                "
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        >mdi-arrow-left-circle-outline</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Return To Previous Process
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="sendToNextProcess(item)"
                                                v-if="user.process"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>mdi-send</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Send To Next Process
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="registerQuality(item)"
                                                v-if="
                                                    markQualityRegistersPermission
                                                "
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        >mdi-check-bold</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Register Quality
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="openProductionLog(item)"
                                                v-if="
                                                    item &&
                                                        item.processes.length >
                                                            0 &&
                                                        order.status !=
                                                            'inProgress'
                                                "
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        >mdi-book-cog-outline</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Production Log
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="openDeliveryLog(item)"
                                                v-if="
                                                    item &&
                                                        order.status !=
                                                            'inProgress'
                                                "
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        >mdi-book-arrow-right-outline</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Delivery Log
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click="openQualityLog(item)"
                                                v-if="
                                                    item &&
                                                        item.processes.length >
                                                            0 &&
                                                        order.status !=
                                                            'inProgress'
                                                "
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        >mdi-book-check-outline</v-icon
                                                    >
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    Quality Log
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr>
                                    <td
                                        class="font-weight-bold text-center"
                                    ></td>
                                    <td class="font-weight-bold text-center">
                                        TOTAL
                                    </td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold text-center">
                                        {{ totalMass.toFixed(2) }} (kgs)
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{ totalPaintingArea.toFixed(2) }} (m2)
                                    </td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold text-center">
                                        {{ totalQuantity }}
                                    </td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold"></td>
                                    <td
                                        class="font-weight-bold"
                                        v-if="
                                            order.status == 'approved' ||
                                                order.status == 'closed'
                                        "
                                    ></td>
                                </tr>
                            </template>
                        </v-data-table>
                        <AssemblyItems
                            v-else
                            :workOrder="order"
                            :readOnlyWO="readOnlyWO"
                            :height="height"
                            @close="closeDialog"
                            @sendToNextProcess="sendToNextProcess"
                            @sendToPrevProcess="sendToPrevProcess"
                            @registerProduction="registerProduction"
                            @openMultipleRegister="openMultipleRegister"
                            @openMultipleSend="openMultipleSend"
                            @registerQuality="registerQuality"
                            @openMultipleQuality="openMultipleQuality"
                            @openProductionLog="openProductionLog"
                            @openDeliveryLog="openDeliveryLog"
                            @openQualityLog="openQualityLog"
                            class="ma-0 pa-0"
                        />
                    </v-col>
                </v-row>
                <!--ALERT-->
                <v-alert
                    v-if="activateAlert"
                    type="success"
                    color="primary"
                    :style="{
                        position: 'absolute',
                        right: '0px',
                        bottom: '0px',
                    }"
                >
                    <v-row no-gutters>
                        <v-col cols="11">
                            <p class="my-0">{{ alertMessage }}</p>
                        </v-col>
                        <v-col cols="1">
                            <v-btn
                                small
                                icon
                                class="mt-n1"
                                @click="activateAlert = false"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
                <!--REGISTER PRODUCTION-->
                <v-dialog
                    :retain-focus="false"
                    v-model="productionDialog"
                    persistent
                    max-width="500px"
                >
                    <RegisterProduction
                        v-if="productionDialog"
                        :item="selectedItem"
                        :workOrder="order"
                        :settings="settingProcesses"
                        :user="user"
                        @closeDialog="closeRegisterProduction"
                    />
                </v-dialog>
                <!--RETURN TO THE PREV PROCESS-->
                <v-dialog
                    :retain-focus="false"
                    v-model="prevProcessDialog"
                    persistent
                    max-width="500px"
                >
                    <PrevProcess
                        v-if="prevProcessDialog"
                        :item="selectedItem"
                        :workOrder="order"
                        :user="user"
                        @closeDialog="closeSendToPrevProcess"
                    />
                </v-dialog>
                <!--MOVE TO THE NEXT PROCESS-->
                <v-dialog
                    :retain-focus="false"
                    v-model="processDialog"
                    persistent
                    :max-width="dialogMaxWidth"
                >
                    <NextProcess
                        v-if="processDialog"
                        :item="selectedItem"
                        :workOrder="order"
                        :user="user"
                        :sendOperation="sendOperation"
                        @closeDialog="closeSendToNextProcess"
                    />
                </v-dialog>
                <!--Multiple Send-->
                <v-dialog
                    :retain-focus="false"
                    v-model="multipleSendDialog"
                    persistent
                    max-width="900px"
                >
                    <MultipleSend
                        v-if="multipleSendDialog"
                        :workOrder="order"
                        :user="user"
                        :processes="settingProcesses"
                        @closeDialog="closeMultipleSend"
                    />
                </v-dialog>
            </v-container>
        </v-card-text>
        <!--Error Alert-->
        <Errors />
        <!--QUALITY PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="qualityDialog"
            persistent
            max-width="300px"
        >
            <QualityProcess
                v-if="qualityDialog"
                :item="selectedItem"
                :workOrder="order"
                :user="user"
                @closeDialog="closeQualityProcess"
            />
        </v-dialog>
        <!--MULTIPLE QUALITY PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleQualityDialog"
            persistent
            max-width="1200px"
        >
            <MultipleQualityProcess
                v-if="multipleQualityDialog"
                :workOrder="order"
                :user="user"
                @closeDialog="closeMultipleQualityProcess"
            />
        </v-dialog>
        <!--ITEM LOG-->
        <v-dialog
            :retain-focus="false"
            v-model="itemLogDialog"
            persistent
            max-width="1200px"
        >
            <ItemLog
                v-if="itemLogDialog"
                :viewType="viewType"
                :users="users"
                :selectedItem="selectedItem"
                @closeDialog="itemLogDialog = false"
            />
        </v-dialog>
        <!--Multiple Register-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleRegisterDialog"
            persistent
            max-width="900px"
        >
            <MultipleRegister
                v-if="multipleRegisterDialog"
                :workOrder="order"
                :user="user"
                :settings="settingProcesses"
                @closeDialog="closeMultipleRegister"
                @replaceItems="closeMultipleRegister"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import API from '@/services/api'
import _ from 'lodash'
import JSZip from 'jszip'
import { storage } from '@/services/firebase'
import moment from 'moment'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import qrious from 'qrious'
import { loadImage } from '@/helpers/imageHandler.js'
import { openFile } from '@/services/storage/openFile.js'

export default {
    name: 'OperatorProcessWorkOrder',
    props: {
        order: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        readOnlyWO: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        NextProcess: () => import('@/components/WorkOrders/NextProcess.vue'),
        RegisterProduction: () =>
            import('@/components/WorkOrders/RegisterProduction.vue'),
        Errors: () => import('@/components/Layout/Errors'),
        MultipleSend: () => import('@/components/WorkOrders/MultipleSend.vue'),
        PrevProcess: () => import('@/components/WorkOrders/PrevProcess.vue'),
        QualityProcess: () =>
            import('@/components/WorkOrders/QualityProcess.vue'),
        MultipleQualityProcess: () =>
            import('@/components/WorkOrders/MultipleQualityProcess.vue'),
        ItemLog: () => import('@/components/WorkOrders/ItemLog.vue'),
        MultipleRegister: () =>
            import('@/components/WorkOrders/MultipleRegister.vue'),
        AssemblyItems: () =>
            import('@/components/WorkOrders/AssemblyItems.vue'),
    },
    data: () => ({
        loading: false,
        downloadedFiles: [],
        partNumbers: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        projectManager: {},
        creator: {},
        company: {},
        numberOfFiles: 0,
        accumulated: 0,
        loadFilesDialog: false,
        originalItem: {},
        alertMessage: '',
        activateAlert: false,
        uploadFiles: false,
        selectedItem: {},
        projectManagerName: undefined,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PAINTING AREA',
                value: 'paintingArea',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PROCESSES',
                value: 'processes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FILES',
                value: 'files',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        settingProcesses: undefined,
        settingFinish: undefined,
        file: null,
        user: {},
        processDialog: false,
        productionDialog: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        productionInfo: [],
        multipleSendDialog: false,
        prevProcessDialog: false,
        qualityDialog: false,
        multipleQualityDialog: false,
        viewType: null,
        itemLogDialog: false,
        multipleRegisterDialog: false,
        users: [],
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        showProcesses: false,
        processToShow: undefined,
        processToFilter: undefined,
        partNumberToFilter: undefined,
        sortByAvailable: false,
        sortByProduced: false,
        sortByDelivered: false,
        sortByPending: false,
        sortByReleased: false,
        sortByReprocess: false,
        sortByRejected: false,
        markQualityRegistersPermission: false,
        sendOperation: false,
        dialogMaxWidth: '1000px',
    }),
    watch: {
        order: function() {
            if (this.settingProcesses) {
                this.setUpdatedData()
            }
        },
    },
    computed: {
        filterWorkOrders() {
            let conditions = []
            let items = this.order.items ? this.order.items : []
            if (this.processToFilter) {
                conditions.push(this.filterByProcess)
            }
            if (this.partNumberToFilter) {
                conditions.push(this.filterByPartNumber)
            }
            if (conditions.length > 0) {
                return items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            if (
                ((this.sortByAvailable ||
                    this.sortByProduced ||
                    this.sortByDelivered) &&
                    this.user.process) ||
                this.sortByPending ||
                this.sortByReleased ||
                this.sortByReprocess ||
                this.sortByRejected
            ) {
                const auxItems = _.cloneDeep(items)
                const sortedItems = this.sort(auxItems)
                return sortedItems
            } else if (
                !this.sortByAvailable &&
                !this.sortByProduced &&
                !this.sortByDelivered &&
                !this.sortByPending &&
                !this.sortByReleased &&
                !this.sortByReprocess &&
                !this.sortByRejected
            ) {
                return items
            }

            return items
        },
        totalMass() {
            return this.order.items
                ? this.order.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.mass * item.quantity,
                      0
                  )
                : 0
        },
        totalPaintingArea() {
            return this.order.items
                ? this.order.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.paintingArea * item.quantity,
                      0
                  )
                : 0
        },
        totalQuantity() {
            return this.order.items
                ? this.order.items.reduce(
                      (accumulator, item) => accumulator + item.quantity,
                      0
                  )
                : 0
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            // validate permission
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }

            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users

            this.markQualityRegistersPermission = this.user.permissions.includes(
                'markQualityRegisters'
            )
            // qr code
            new qrious({
                element: document.getElementById('qr'),
                value: `${process.env.VUE_APP_CLIENT_HOST}/work-orders/main-work-orders?orderId=${this.order.id}&companyId=${this.companyId}&code=${this.order.code}`,
            })
            await this.setOrderData(this.order)
            if (
                this.order.status == 'approved' ||
                this.order.status == 'closed'
            ) {
                this.headers.splice(9, 0, {
                    text: 'STATUS',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                })
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async createZip() {
            const zip = new JSZip()

            for (const file of this.downloadedFiles) {
                zip.file(file.name, file.data)
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' })
            return zipBlob
        },
        getPaths() {
            let paths = []
            if (this.order.items) {
                this.order.items.forEach(item => {
                    if (item.files) {
                        paths = [...paths, ...item.files]
                    }
                })
            }
            this.numberOfFiles = paths.length
            return paths
        },
        async downloadAllFiles() {
            for (const filePath of this.getPaths()) {
                await this.downloadFile(filePath)
                this.accumulated += 1
            }
        },
        async downloadFile(filePath) {
            try {
                const fileRef = storage().ref(
                    `${this.companyId}/workOrders/${this.order.id}/items/${filePath}`
                )
                let url
                await fileRef
                    .getDownloadURL()
                    .then(response => {
                        url = response
                    })
                    .catch(() => {
                        url = ''
                    })
                if (url) {
                    const response = await fetch(url)
                    const blob = await response.blob()
                    this.downloadedFiles.push({ name: filePath, data: blob })
                }
            } catch (error) {
                console.error(`Error al descargar ${filePath}:`, error)
            }
        },
        async downloadPDFs() {
            try {
                this.loading = true
                this.loadFilesDialog = true
                await this.downloadAllFiles()
                const zipBlob = await this.createZip()
                const zipUrl = URL.createObjectURL(zipBlob)
                const link = document.createElement('a')
                link.href = zipUrl
                link.download = `${this.order.code}.zip`
                link.click()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.numberOfFiles = 0
                this.accumulated = 0
                this.loadFilesDialog = false
            }
        },
        async print() {
            try {
                this.loading = true
                const items = await this.completeData()
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: [233, 279],
                })
                // set header and footer
                var company = this.company
                var order = this.order
                const aspectRatio = await this.getAspectRatio(
                    company.logoBase64
                )
                const header = function(data) {
                    pdf.setFontSize(16)
                    pdf.setFont('helvetica', 'bold')
                    pdf.text(
                        `${order.code} | ${order.project.reference} ${order.project.name}`,
                        data.settings.margin.left,
                        15
                    )
                    pdf.text(
                        `${moment
                            .unix(
                                order.createdOn.seconds ||
                                    order.createdOn._seconds
                            )
                            .format('YYYY-MM-DD')}`,
                        data.settings.margin.left,
                        23
                    )
                    // add company logo
                    const imgHeight = 20
                    const imgWidth = imgHeight * aspectRatio
                    const imgX =
                        pdf.internal.pageSize.width -
                        data.settings.margin.right -
                        imgWidth // Position X
                    const imgY = 10 // Position Y
                    pdf.addImage(
                        company.logoBase64,
                        'PNG',
                        imgX,
                        imgY,
                        imgWidth,
                        imgHeight
                    )
                }

                const footer = function(data) {
                    pdf.setLineWidth(0.5) // Set line thickness
                    pdf.line(
                        10,
                        pdf.internal.pageSize.height - 10,
                        pdf.internal.pageSize.width - 10,
                        pdf.internal.pageSize.height - 10
                    )

                    pdf.setFontSize(12)
                    pdf.text(
                        `${data.pageNumber}`,
                        pdf.internal.pageSize.width - 13,
                        pdf.internal.pageSize.height - 5
                    )
                }
                const fontSize = 12
                pdf.setFontSize(fontSize)
                // description text
                const descriptionText = [
                    `PRODUCTION END: ${
                        this.order.productionEnd
                            ? this.formatDate(
                                  this.order.productionEnd.seconds ||
                                      this.order.productionEnd._seconds
                              )
                            : ''
                    }`,
                    `CREATED BY: ${this.order.createdBy.name}`,
                    `PROJECT: ${this.order.project.name}`,
                    `PROJECT MANAGER: ${this.projectManager.name}`,
                    `NOTES: ${this.order.notes || ''}`,
                ]
                // Calculate the total height of the description text
                const lineHeight = fontSize * 0.5 // Line spacing
                const descriptionHeight = descriptionText.length * lineHeight
                descriptionText.forEach((line, index) => {
                    pdf.text(line, 10, 35 + (index + 1) * lineHeight)
                })
                // autotable options
                const options = {
                    margin: { top: 40, left: 10, right: 10 },
                    startY: descriptionHeight + 45,
                }
                // calculate total mass
                const totalMass = this.order.items
                    ? this.order.items
                          .reduce(
                              (accumulator, item) =>
                                  accumulator + item.mass * item.quantity,
                              0
                          )
                          .toFixed(1)
                    : 0
                // total painting area
                const totalPaintingArea = this.order.items
                    ? this.order.items
                          .reduce(
                              (accumulator, item) =>
                                  accumulator +
                                  item.paintingArea * item.quantity,
                              0
                          )
                          .toFixed(1)
                    : 0
                // total quantity
                const totalQuantity = this.order.items
                    ? this.order.items.reduce(
                          (accumulator, item) => accumulator + item.quantity,
                          0
                      )
                    : 0
                // add table
                pdf.autoTable({
                    head: [
                        [
                            'ID',
                            'PART NUMBER',
                            'DESCRIPTION',
                            'MASS',
                            'PAINTING AREA',
                            'FINISH & UC CODE',
                            'QTY',
                            'NOTES',
                            'PROCESSES',
                        ],
                    ],
                    body: [
                        ...items.map((item, index) => [
                            index + 1,
                            item.partNumber,
                            item.description,
                            item.mass,
                            item.paintingArea,
                            item.dataFinishAndUCCode
                                ? item.dataFinishAndUCCode.name
                                : '',
                            item.quantity,
                            item.notes,
                            item.dataProcesses
                                .map(process => process.name)
                                .join(', '),
                        ]),
                        [
                            '',
                            'Total',
                            '',
                            `${totalMass} (kgs)`,
                            `${totalPaintingArea} (m2)`,
                            '',
                            totalQuantity,
                            '',
                            '',
                        ],
                    ],
                    ...options,
                    didDrawPage: function(data) {
                        // Llamamos a la función de encabezado y pie de página en cada página
                        header(data)
                        footer(data)
                    },
                    columnStyles: {
                        0: { cellWidth: 8 },
                        1: { cellWidth: 25 },
                        2: { cellWidth: 30 },
                        3: { cellWidth: 25 },
                        4: { cellWidth: 25 },
                        5: { cellWidth: 25 },
                        6: { cellWidth: 15 },
                        7: { cellWidth: 30 },
                        8: { cellWidth: 30 },
                        9: { cellWidth: 30 },
                    },
                })
                const pdfDataUri = pdf.output('datauristring')
                const blob = this.dataURItoBlob(pdfDataUri)
                const blobURL = URL.createObjectURL(blob)
                const printWindow = window.open(blobURL)
                printWindow.onload = function() {
                    printWindow.print()
                    URL.revokeObjectURL(blobURL)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        dataURItoBlob(dataURI) {
            const byteString = Uint8Array.from(atob(dataURI.split(',')[1]), c =>
                c.charCodeAt(0)
            )
            const blobType = dataURI
                .split(',')[0]
                .split(':')[1]
                .split(';')[0]
            return new Blob([byteString], { type: blobType })
        },
        async completeData() {
            try {
                const {
                    data: { settings },
                } = await API.getSettings()
                this.company = settings.find(
                    s => s.name == 'Company'
                ).generalInfo
                const settingProcesses = settings.find(
                    s => s.name == 'Processes'
                )
                const settingFinish = settings.find(
                    s => s.name == 'FinishAndUCCode'
                )
                const partNumbers = await API.getPartNumbers(
                    this.order.projectId
                )
                const items = _.clone(this.order.items)
                if (items && items.length > 0) {
                    for (let item of items) {
                        item.workOrder = this.order.code
                        const partNumber = partNumbers.find(
                            pn => pn.id === item.partNumberId
                        )
                        if (partNumber) {
                            item.partNumber = partNumber.code
                        }
                        if (settingProcesses.id) {
                            if (item.processes?.length > 0) {
                                item.dataProcesses = settingProcesses.processes.filter(
                                    process =>
                                        item.processes.includes(process.id)
                                )
                            }
                        }
                        if (settingFinish.id) {
                            if (item.finishAndUCCode) {
                                item.dataFinishAndUCCode = settingFinish.finishes.find(
                                    e => e.id == item.finishAndUCCode
                                )
                            }
                        }
                    }
                }
                return items
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async downloadXLS() {
            try {
                const items = await this.completeData()
                const wsData = items.map(item => ({
                    'Work Order': item.workOrder,
                    'Part Number': item.partNumber,
                    'Production Start': this.order.productionStart
                        ? this.formatDate(
                              this.order.productionStart.seconds ||
                                  this.order.productionStart._seconds
                          )
                        : '',
                    'Production End': this.order.productionEnd
                        ? this.formatDate(
                              this.order.productionEnd.seconds ||
                                  this.order.productionEnd._seconds
                          )
                        : '',
                    Project: this.order.project.name,
                    Description: item.description,
                    Mass: item.mass,
                    'Painting Area': item.paintingArea,
                    'Finish & UC Code': item.dataFinishAndUCCode
                        ? item.dataFinishAndUCCode.name
                        : '',
                    QTY: item.quantity,
                    notes: item.notes,
                    processes: item.dataProcesses
                        .map(process => process.name)
                        .join(', '),
                }))
                const ws = XLSX.utils.json_to_sheet(wsData)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
                const wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                })
                const blob = new Blob([wbout], {
                    type: 'application/octet-stream',
                })
                const url = window.URL.createObjectURL(blob)
                const tempLink = document.createElement('a')
                tempLink.href = url
                tempLink.setAttribute('download', `${this.order.code}.xlsx`)
                tempLink.click()
                window.URL.revokeObjectURL(url)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        close() {
            this.$emit('close')
        },
        async getAspectRatio(file) {
            return new Promise(function(resolved) {
                var i = new Image()
                i.onload = function() {
                    resolved(i.width / i.height)
                }
                i.src = file
            })
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/workOrders/${this.order.id}/attachments`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadFiles(item, extension) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/workOrders/${this.order.id}/items`,
                    item.partNumber + '.' + extension
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                details: { clientHeight: detailsHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - detailsHeight + 70
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        sendToNextProcess(item) {
            this.selectedItem = _.cloneDeep(item)
            this.sendOperation = true
            this.dialogMaxWidth = '500px'
            this.processDialog = true
        },
        registerProduction(item) {
            this.selectedItem = _.cloneDeep(item)
            this.productionDialog = true
        },
        sendToPrevProcess(item) {
            this.selectedItem = _.cloneDeep(item)
            this.sendOperation = true
            this.dialogMaxWidth = '500px'
            this.prevProcessDialog = true
        },
        closeSendToPrevProcess(items) {
            this.selectedItem = _.cloneDeep({})
            this.prevProcessDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.order.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.order.items[index].deliveries = item.deliveries
                    }
                })
            }
        },
        closeRegisterProduction(items) {
            this.selectedItem = _.cloneDeep({})
            this.productionDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.order.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.order.items[index].production = item.production
                    }
                    this.order.items[
                        index
                    ].productionInfo = this.calculateFinished(item)
                })
            }
        },
        closeSendToNextProcess(items) {
            this.selectedItem = _.cloneDeep({})
            this.processDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.order.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.order.items[index].deliveries = item.deliveries
                    }
                })
            }
        },
        activateActions(item) {
            return (
                !this.loading &&
                item.processes &&
                this.user.process &&
                item.processes.includes(this.user.process.id)
            )
        },
        calculateFinished(item) {
            let productionInfo = []
            if (item.processesToShow) {
                let qualityProcess = {
                    id: this.qualityProcess.id,
                    name: this.qualityProcess.name,
                    qty: 0,
                    received: 0,
                    delivered: 0,
                    produced: 0,
                    available: 0,
                }

                let packingProcess = {
                    id: this.packingProcess.id,
                    name: this.packingProcess.name,
                    qty: 0,
                    received: 0,
                    delivered: 0,
                    produced: 0,
                    available: 0,
                }

                if (
                    item &&
                    item.processes &&
                    this.settingProcesses &&
                    this.showProcesses
                ) {
                    item.processesToShow = _.cloneDeep(item.processes)

                    if (this.processToShow) {
                        item.processesToShow = item.processesToShow.filter(
                            p => p == this.processToShow.id
                        )
                    }
                    if (item.processesToShow) {
                        item.processesToShow.forEach(process => {
                            const pIndex = item.processes.findIndex(
                                p => p == process
                            )

                            let totalProduced = 0
                            let totalDelivered = 0
                            let totalReceived = 0
                            if (item.production) {
                                let production = item.production.filter(
                                    register => register.process.id === process
                                )
                                totalProduced = production.reduce(
                                    (total, register) => total + register.qty,
                                    0
                                )
                            }
                            if (item.deliveries) {
                                let deliveries = item.deliveries.filter(
                                    register =>
                                        register.prevProcess.id === process
                                )
                                totalDelivered = deliveries.reduce(
                                    (total, register) => total + register.qty,
                                    0
                                )

                                if (pIndex > 0) {
                                    let received = item.deliveries.filter(
                                        register =>
                                            register.nextProcess.id ==
                                                process && register.receivedBy
                                    )
                                    totalReceived = received.reduce(
                                        (total, register) =>
                                            total + register.qty,
                                        0
                                    )
                                } else {
                                    totalReceived = item.quantity
                                }
                            }
                            if (pIndex == 0 && !this.order.assemblyWorkOrder) {
                                totalReceived = item.quantity
                            }

                            if (pIndex == 0 && this.order.assemblyWorkOrder) {
                                totalReceived = item.quantity
                                if (item.assemblyItems) {
                                    item.assemblyItems.forEach(ai => {
                                        let receivedQty = 0
                                        if (ai.deliveries) {
                                            receivedQty = ai.deliveries.reduce(
                                                (accumulator, delivery) =>
                                                    accumulator + delivery.qty,
                                                0
                                            )
                                        }
                                        const assemblyQty =
                                            receivedQty / ai.qtyPerAssembly
                                        if (assemblyQty < totalReceived) {
                                            totalReceived = assemblyQty
                                        }
                                    })
                                }
                            }

                            item.dataProcesses = this.settingProcesses.processes.filter(
                                process => item.processes.includes(process.id)
                            )
                            let processIndex = item.dataProcesses.findIndex(
                                p => p.id == process
                            )
                            if (process == this.qualityProcess.id) {
                                //quality process
                                qualityProcess.qty += item.quantity
                                qualityProcess.delivered = 'NA'
                                qualityProcess.produced =
                                    this.calculateRejectedQty(item) +
                                    this.calculateReleasedQty(item)
                                qualityProcess.available = this.calculateQualityPending(
                                    item
                                )

                                productionInfo.push(qualityProcess)
                            } else if (process == this.packingProcess.id) {
                                let totalReceivedNew = item.deliveries
                                    ? item.deliveries.reduce(
                                          (total, register) =>
                                              register.nextProcess.id ==
                                                  this.packingProcess.id &&
                                              register.receivedBy
                                                  ? total + register.qty
                                                  : total,
                                          0
                                      )
                                    : 0

                                let totalReceivedOld = item.deliveries
                                    ? item.deliveries.reduce(
                                          (total, register) =>
                                              register.nextProcess.id ==
                                                  this.readyForPackingProcess
                                                      .id && register.receivedBy
                                                  ? total + register.qty
                                                  : total,
                                          0
                                      )
                                    : 0

                                let totalReceived =
                                    totalReceivedOld + totalReceivedNew

                                packingProcess.qty += item.quantity
                                packingProcess.delivered = 'NA'
                                packingProcess.produced += item.packedQty
                                packingProcess.available =
                                    totalReceived -
                                    (packingProcess.produced || 0)

                                productionInfo.push(packingProcess)
                            } else {
                                productionInfo.push({
                                    name: item.dataProcesses[processIndex].name,
                                    id: process,
                                    produced: totalProduced,
                                    delivered: totalDelivered,
                                    received: totalReceived,
                                    available: totalReceived - totalProduced,
                                })
                            }
                        })
                    }
                    if (this.order.assemblyWorkOrder == undefined) {
                        //quality process
                        qualityProcess.qty += item.quantity
                        qualityProcess.delivered = 'NA'
                        qualityProcess.produced =
                            this.calculateRejectedQty(item) +
                            this.calculateReleasedQty(item)
                        qualityProcess.available = this.calculateQualityPending(
                            item
                        )

                        productionInfo.push(qualityProcess)
                        //ready for packing process
                        let totalReceivedNew = item.deliveries
                            ? item.deliveries.reduce(
                                  (total, register) =>
                                      register.nextProcess.id ==
                                          this.packingProcess.id &&
                                      register.receivedBy
                                          ? total + register.qty
                                          : total,
                                  0
                              )
                            : 0

                        let totalReceivedOld = item.deliveries
                            ? item.deliveries.reduce(
                                  (total, register) =>
                                      register.nextProcess.id ==
                                          this.readyForPackingProcess.id &&
                                      register.receivedBy
                                          ? total + register.qty
                                          : total,
                                  0
                              )
                            : 0

                        let totalReceived = totalReceivedOld + totalReceivedNew

                        packingProcess.qty += item.quantity
                        packingProcess.delivered = 'NA'
                        packingProcess.produced += item.packedQty
                        packingProcess.available =
                            totalReceived - (packingProcess.produced || 0)

                        productionInfo.push(packingProcess)
                    }
                }
            }
            return productionInfo || []
        },
        async setOrderData() {
            try {
                this.loading = true
                if (typeof this.order.project.projectManager == 'string') {
                    this.projectManager = await API.getUser(
                        this.order.project.projectManager
                    )
                } else {
                    this.projectManager = this.order.project.projectManager
                }
                this.projectManagerName = this.projectManager.name
                if (this.readOnlyWO) {
                    this.sections = [this.sections[0]]
                }
                // set partNumber
                this.partNumbers = await API.getPartNumbers(
                    this.order.projectId
                )
                // set processes and finishAndUCCode
                const {
                    data: { settings },
                } = await API.getSettings()
                this.settingProcesses = settings.find(
                    s => s.name == 'Processes'
                )
                this.settingFinish = settings.find(
                    s => s.name == 'FinishAndUCCode'
                )
                if (this.order.items && this.order.items.length > 0) {
                    for (let item of this.order.items) {
                        const partNumber = this.partNumbers.find(
                            pn => pn.id === item.partNumberId
                        )
                        if (partNumber) {
                            item.partNumber = partNumber.code
                        }
                        if (this.settingProcesses.id) {
                            if (item.processes?.length > 0) {
                                item.dataProcesses = this.settingProcesses.processes.filter(
                                    process =>
                                        item.processes.includes(process.id)
                                )
                            }
                        }
                        if (this.settingFinish.id) {
                            if (item.finishAndUCCode) {
                                item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                                    e => e.id == item.finishAndUCCode
                                )
                            }
                        }
                        item.productionInfo = this.calculateFinished(item)
                    }
                }
                this.order.items = [
                    ...(this.order.items != undefined ? this.order.items : []),
                ]
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        setUpdatedData() {
            try {
                if (this.order.items && this.order.items.length > 0) {
                    for (let item of this.order.items) {
                        const partNumber = this.partNumbers.find(
                            pn => pn.id === item.partNumberId
                        )
                        if (partNumber) {
                            item.partNumber = partNumber.code
                        }
                        if (this.settingProcesses.id) {
                            if (item.processes?.length > 0) {
                                item.dataProcesses = this.settingProcesses.processes.filter(
                                    process =>
                                        item.processes.includes(process.id)
                                )
                            }
                        }
                        if (this.settingFinish.id) {
                            if (item.finishAndUCCode) {
                                item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                                    e => e.id == item.finishAndUCCode
                                )
                            }
                        }
                        item.productionInfo = this.calculateFinished(item)
                    }
                }
                this.order.items = [
                    ...(this.order.items != undefined ? this.order.items : []),
                ]
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },

        openMultipleSend() {
            this.multipleSendDialog = true
        },
        closeMultipleSend() {
            this.multipleSendDialog = false
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.order.assemblyWorkOrder == undefined
                ) {
                    let lastProcessIndex = item.processes.length - 1
                    if (this.order.assemblyWorkOrder != undefined) {
                        lastProcessIndex =
                            item.processes.findIndex(
                                p => p == this.qualityProcess.id
                            ) - 1
                    }
                    if (lastProcessIndex > -1) {
                        const lastProductionProcess =
                            item.processes[lastProcessIndex]
                        if (item.production) {
                            const production = item.production.filter(
                                entry =>
                                    entry.process.id == lastProductionProcess
                            )
                            if (production.length > 0) {
                                pendingQty = production.reduce(
                                    (accumulator, entry) =>
                                        accumulator + entry.qty,
                                    0
                                )
                            }
                        }
                        if (item.quality) {
                            const rejected = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.rejectQty || 0),
                                0
                            )
                            const released = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.releaseQty || 0),
                                0
                            )
                            pendingQty -=
                                Number(released || 0) + Number(rejected || 0)
                        }
                    }
                } else {
                    pendingQty = -1
                }

                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.order.assemblyWorkOrder == undefined
                ) {
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        released += Number(value || 0)
                    }
                } else {
                    released = -1
                }

                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReprocessQty(item) {
            try {
                let reprocess = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.order.assemblyWorkOrder == undefined
                ) {
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.reprocessQty || 0),
                            0
                        )
                        reprocess += Number(value || 0)
                    }
                } else {
                    reprocess = -1
                }
                return reprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (
                    item.processes.includes(this.qualityProcess.id) ||
                    this.order.assemblyWorkOrder == undefined
                ) {
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        rejected += Number(value || 0)
                    }
                } else {
                    rejected = -1
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openProductionLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'production'
            this.itemLogDialog = true
        },
        openDeliveryLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'delivery'
            this.itemLogDialog = true
        },
        openQualityLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'quality'
            this.itemLogDialog = true
        },
        registerQuality(item) {
            this.selectedItem = _.cloneDeep(item)
            this.qualityDialog = true
        },
        closeQualityProcess() {
            this.selectedItem = _.cloneDeep({})
            this.qualityDialog = false
        },
        openMultipleQuality() {
            this.multipleQualityDialog = true
        },
        closeMultipleQualityProcess() {
            this.multipleQualityDialog = false
        },
        openMultipleRegister() {
            this.multipleRegisterDialog = true
        },
        closeMultipleRegister() {
            this.multipleRegisterDialog = false
        },
        filterByProcess(item) {
            let processesString = ''
            item.dataProcesses.forEach(process => {
                if (item.processesToShow.includes(process.id)) {
                    processesString += ` ${process.name}`
                }
            })
            return processesString
                .toLowerCase()
                .includes(this.processToFilter.toLowerCase())
        },
        filterByPartNumber(item) {
            return item.partNumber
                .toLowerCase()
                .includes(this.partNumberToFilter.toLowerCase())
        },
        sort(items) {
            if (this.user.process) {
                const process = this.user.process.id

                if (this.sortByAvailable) {
                    items.sort((a, b) => {
                        const productionA = a.productionInfo.find(
                            p => p.id === process
                        )
                        const productionB = b.productionInfo.find(
                            p => p.id === process
                        )
                        return (
                            (productionB?.available || 0) -
                            (productionA?.available || 0)
                        )
                    })
                } else if (this.sortByProduced) {
                    items.sort((a, b) => {
                        const productionA = a.productionInfo.find(
                            p => p.id === process
                        )
                        const productionB = b.productionInfo.find(
                            p => p.id === process
                        )
                        return (
                            (productionB?.produced || 0) -
                            (productionA?.produced || 0)
                        )
                    })
                } else if (this.sortByDelivered) {
                    items.sort((a, b) => {
                        const productionA = a.productionInfo.find(
                            p => p.id === process
                        )
                        const productionB = b.productionInfo.find(
                            p => p.id === process
                        )
                        return (
                            (productionB?.delivered || 0) -
                            (productionA?.delivered || 0)
                        )
                    })
                }
            }

            if (this.sortByPending) {
                items.sort((a, b) => {
                    return (
                        (this.calculateQualityPending(b) || 0) -
                        (this.calculateQualityPending(a) || 0)
                    )
                })
            } else if (this.sortByReleased) {
                items.sort((a, b) => {
                    return (
                        (this.calculateReleasedQty(b) || 0) -
                        (this.calculateReleasedQty(a) || 0)
                    )
                })
            } else if (this.sortByReprocess) {
                items.sort((a, b) => {
                    return (
                        (this.calculateReprocessQty(b) || 0) -
                        (this.calculateReprocessQty(a) || 0)
                    )
                })
            } else if (this.sortByRejected) {
                items.sort((a, b) => {
                    return (
                        (this.calculateRejectedQty(b) || 0) -
                        (this.calculateRejectedQty(a) || 0)
                    )
                })
            }

            return items
        },
        closeDialog() {
            this.$emit('close')
        },
        openReceiveItems() {
            this.sendOperation = false
            this.dialogMaxWidth = '1000px'
            this.processDialog = true
        },
    },
}
</script>

<style>
.selected-item {
    background-color: #dff0ff;
}
</style>
