<template>
    <div ref="container">
        <v-card :loading="loading" class="pa-2" height="100vh">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{
                        invoice.status == 'credit' || invoice.status == 'billed'
                            ? 'Credit Note Request'
                            : 'Invoice Request'
                    }}
                    <span class="font-weight-black text-uppercase">
                        {{
                            `${invoice.name}${
                                invoice.project
                                    ? ' - ' + invoice.project.name
                                    : ''
                            }`
                        }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                id="virtual-scroll-table"
                class="px-4"
                :style="{ height: `${height}px` }"
            >
                <v-row ref="details_1">
                    <v-col cols="9">
                        <v-row>
                            <v-col cols="12" xl="4" lg="4" md="4">
                                <v-text-field
                                    outline
                                    v-model="invoice.type"
                                    prepend-icon="mdi-table-column"
                                    prefix="Type: "
                                    hide-details
                                    readonly
                                />
                            </v-col>
                            <v-col cols="12" xl="4" lg="4" md="4">
                                <v-text-field
                                    outline
                                    v-model="invoice.region"
                                    prepend-icon="mdi-earth"
                                    prefix="Region: "
                                    hide-details
                                    readonly
                                />
                            </v-col>
                            <v-col cols="12" xl="4" lg="4" md="4">
                                <v-text-field
                                    outline
                                    :style="{ color: 'blue' }"
                                    v-model="invoice.invoiceNumber"
                                    prepend-icon="mdi-receipt-text-outline"
                                    prefix="Invoice No: "
                                    disabled
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-row
                                class="pa-2 pt-0"
                                v-if="invoice.status == 'credit' && !disabled"
                            >
                                <v-col cols="12" xl="4" lg="4" md="4">
                                    <div class="d-flex">
                                        <h3 class="ml-2 mt-5">Invoice:</h3>
                                        <v-chip
                                            class="mt-4 ml-1"
                                            @click="loadFile(invoice.attached)"
                                        >
                                            <v-icon class="mr-1"
                                                >mdi-paperclip</v-icon
                                            >
                                            <span
                                                class="col-12 text-truncate"
                                                >{{ invoice.attached }}</span
                                            >
                                        </v-chip>
                                    </div>
                                </v-col>

                                <v-col cols="12" xl="8" lg="8" md="8">
                                    <div class="d-flex">
                                        <v-file-input
                                            prefix="Upload Credit Note"
                                            v-model="credit"
                                            :rules="[rules.required]"
                                            required
                                            prepend-icon="mdi-upload"
                                            accept=".pdf"
                                            :style="{
                                                color: 'blue',
                                                width: '10px',
                                                'max-width': '300px',
                                            }"
                                            :clearable="false"
                                        ></v-file-input>
                                        <v-chip
                                            v-if="invoice.credit"
                                            class="mt-4 ml-1"
                                            @click="loadFile(invoice.credit)"
                                        >
                                            <v-icon class="mr-1"
                                                >mdi-paperclip</v-icon
                                            >
                                            <span
                                                class="col-12 text-truncate"
                                                >{{ invoice.credit }}</span
                                            >
                                        </v-chip>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                class="pa-2 pt-0"
                                v-else-if="invoice.status == 'closed'"
                            >
                                <v-col cols="12" xl="3" lg="3" md="3">
                                    <div class="d-flex">
                                        <h3 class="ml-2 mt-5">Invoice:</h3>
                                        <v-chip
                                            class="mt-4 ml-1"
                                            @click="loadFile(invoice.attached)"
                                        >
                                            <v-icon class="mr-1"
                                                >mdi-paperclip</v-icon
                                            >
                                            <span
                                                class="col-12 text-truncate"
                                                >{{ invoice.attached }}</span
                                            >
                                        </v-chip>
                                    </div>
                                </v-col>

                                <v-col cols="12" xl="9" lg="9" md="9">
                                    <div class="d-flex">
                                        <h3 class="mt-5">Credit notes:</h3>
                                        <v-chip
                                            class="mt-5 ml-1"
                                            v-for="(file, i) in creditFiles"
                                            :key="i"
                                            @click="loadFile(file)"
                                            :style="{ 'max-width': '200px' }"
                                        >
                                            <v-icon class="mr-1"
                                                >mdi-paperclip</v-icon
                                            >
                                            <span>{{ file }}</span>
                                        </v-chip>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-col>

                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-textarea
                            hide-details
                            rows="4"
                            prepend-icon="mdi-text"
                            class="text-body-2 pt-2"
                            label="Notes: "
                            outline
                            v-model="invoice.notes"
                            readonly
                            :style="{ 'line-height': '1 !important' }"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-3 d-flex justify-center">
                    <v-data-table
                        :headers="headers"
                        :items="proformaItems"
                        class="elevation-0"
                        :mobile-breakpoint="0"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                        :style="{ width: '100%' }"
                    >
                        <!--ITEMS-->
                        <template v-slot:[`item.index`]="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                            <p class="my-0 font-weight-bold">
                                {{ item.description }}
                            </p>
                        </template>
                        <template v-slot:[`item.pricePerUnit`]="{ item }">
                            <p class="my-0 font-weight-bold">
                                {{ formatNumber(item.pricePerUnit) }}
                            </p>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                            <p class="my-0 font-weight-bold">
                                {{ item.quantity }}
                                {{
                                    invoice.creditQty &&
                                    invoice.status != 'closed'
                                        ? `(-${invoice.creditQty})`
                                        : ''
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.creditQuantity`]="{}">
                            <div class="d-flex justify-center">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        invoice.status == 'closed'
                                            ? invoice.creditQty
                                            : invoice.creditQtyRequested
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.currentInvoice`]="{ item }">
                            <p class="my-0 font-weight-bold">
                                {{ formatNumber(item.currentInvoice) }}
                            </p>
                        </template>
                        <template v-slot:[`item.currentCredit`]="{ item }">
                            <p class="my-0 font-weight-bold">
                                {{
                                    formatNumber(
                                        invoice.status == 'closed'
                                            ? (invoice.creditQty || 0) *
                                                  item.pricePerUnit
                                            : creditTotalPrice || 0
                                    )
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <p class="my-0 font-weight-bold">
                                {{
                                    formatNumber(
                                        Number(item.currentInvoice).toFixed(0) -
                                            Number(
                                                invoice.status == 'closed'
                                                    ? (invoice.creditQty || 0) *
                                                          item.pricePerUnit
                                                    : creditTotalPrice || 0
                                            ).toFixed(0)
                                    )
                                }}
                            </p>
                        </template>
                    </v-data-table>
                </v-row>
                <v-row
                    no-gutters
                    :style="{ 'background-color': '#eeeeee' }"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black ml-4">TOTALS</p>
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="2"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black mx-4">
                            Invoiced:
                            {{ formatNumber2(invoice.proformaValue) }}
                            {{
                                invoiceTotalCredit() > 0
                                    ? `(-${formatNumber(invoiceTotalCredit())})`
                                    : ''
                            }}
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="2"
                        class="d-flex justify-space-between"
                    >
                        <v-text-field
                            v-if="
                                (invoice.status == 'credit' ||
                                    invoice.status == 'billed') &&
                                    !disabled
                            "
                            v-model="creditTotalPrice"
                            prefix="Credit: $"
                            dense
                            class="mr-4 ml-3"
                            :style="{ 'max-width': '250px' }"
                            @input="calculateCreditQty"
                            :rules="[validCredit()]"
                            type="number"
                        ></v-text-field>
                        <p class="my-2 font-weight-black mr-4 ml-2" v-else>
                            Credit:
                            {{ formatNumber2(creditNoteTotalPrice()) }}
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="2"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black mx-4">
                            Total:
                            {{
                                formatNumber(
                                    invoice.proformaValue -
                                        creditNoteTotalPrice() -
                                        invoiceTotalCredit()
                                )
                            }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-spacer />
                <v-btn
                    v-if="
                        (invoice.status == 'credit' ||
                            invoice.status == 'billed') &&
                            !disabled
                    "
                    :disabled="
                        (this.invoice.status == 'credit' &&
                            !this.invoice.credit) ||
                            !validCredit()
                    "
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="creditAction()"
                >
                    {{
                        invoice.status == 'billed'
                            ? 'REQUEST CREDIT NOTE'
                            : 'GENERATE CREDIT NOTE'
                    }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--CLOSE INVOICE-->
        <v-dialog
            v-model="openCloseInvoice"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openCloseInvoice">
                <v-card-title class="text-h5"
                    >GENERATE CREDIT NOTE</v-card-title
                >
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            When generating this credit note, it will change to
                            closed status, are you sure you want to continue?
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openCloseInvoice = false"
                    >
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateCreditNote"
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Request Credit Note-->
        <v-dialog
            v-model="creditReasonForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="creditReasonForm">
                <v-card-title class="text-h5">Reasons For Request</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you are requesting the credit
                            note.
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="creditReasonForm = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="requestCreditNote"
                        color="primary"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Error Alert-->
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { loadImage } from '@/helpers/imageHandler.js'

export default {
    name: 'CreditNoteRequest',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        inProcess: {
            type: Boolean,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
    },
    data: () => ({
        openCloseInvoice: false,
        loading: true,
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v > 0 || 'Not valid',
        },
        height: 0,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'description',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CREDIT QTY',
                value: 'creditQuantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'INVOICED',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CREDIT',
                value: 'currentCredit',
                sortable: false,
                align: 'center',
            },
            {
                text: 'TOTAL',
                value: 'total',
                sortable: false,
                align: 'center',
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        credit: null,
        rejectionTable: false,
        creditTotalPrice: 0,
        changeValue: false,
        creditReasonForm: false,
        valid: false,
        rejectedNotes: null,
        creditFiles: [],
        totalCredit: null,
        proformaItems: [],
    }),
    mounted() {
        try {
            if (this.invoice.creditPrice) {
                this.changeValue = false
                this.creditTotalPrice = this.invoice.creditPrice
                this.calculateCreditQty()
            }
            this.loading = true
            this.proformaItems = [
                {
                    description: `${this.invoice.name}${
                        this.invoice.project
                            ? ' - ' + this.invoice.project.name
                            : ''
                    }`,
                    type: 'Proforma Features',
                    pricePerUnit: this.invoice.proformaValue,
                    quantity: 1,
                    currentInvoice: this.invoice.proformaValue,
                    creditQtyRequested: 0,
                },
            ]
            this.invoice.notes = `Address: ${this.invoice.address}
Phone: ${this.invoice.phone}
P.A: ${this.invoice.tariffItem}
Description: ${this.invoice.description}`
            this.onResize()
            if (this.invoice.status == 'closed') {
                if (this.invoice.creditNotes) {
                    this.creditFiles = this.invoice.creditNotes
                        .filter(i => i.credit)
                        .map(i => i.credit)

                    this.totalCredit = this.invoice.creditNotes.reduce(
                        (accumulator, creditNote) =>
                            accumulator + creditNote.creditPrice,
                        0
                    )
                }
                if (this.invoice.credit) {
                    this.creditFiles.push(this.invoice.credit)
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        async credit() {
            try {
                this.loading = true
                await saveFile(
                    this.credit,
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`
                )
                this.invoice.credit = this.credit.name
                const invoice = await API.uploadCreditNote({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    credit: this.invoice.credit,
                })
                this.$emit('replaceInvoice', invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async generateCreditNote() {
            try {
                this.loading = true
                this.proformaItems.forEach(
                    i =>
                        (i.creditQtyRequested = i.creditQtyRequested =
                            this.creditTotalPrice / this.invoice.proformaValue)
                )
                let itemsRequested = this.proformaItems.filter(
                    item =>
                        item.creditQtyRequested && item.creditQtyRequested > 0
                )
                const params = {
                    projectId: this.invoice.proformaProject,
                    id: this.invoice.id,
                    creditPrice: this.creditTotalPrice,
                    creditRequests: itemsRequested,
                    conceptType: false,
                }
                const invoice = await API.generateCreditNote(params)
                this.$emit('replaceInvoice', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadFile(file) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`,
                    file
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        creditNoteTotalPrice() {
            let total = 0
            if (this.invoice.totalCredit && this.invoice.status == 'closed') {
                total = this.invoice.totalCredit
            } else if (this.creditTotalPrice || this.invoice.creditPrice) {
                total = this.creditTotalPrice || this.invoice.creditPrice
            } else {
                for (const cost of this.proformaItems) {
                    total +=
                        ((this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty) || 0) * cost.pricePerUnit
                }
            }
            return total
        },
        projectBilled() {
            return this.project.totalInvoiced || 0
        },
        quoteTotalInvoice(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        (cost.creditNoteQty || cost.billedQty || 0),
                0
            )
        },
        quoteTotalCredit(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        ((this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty) || 0),
                0
            )
        },
        quoteTotal(costs) {
            const invoiced = costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        (cost.creditNoteQty || cost.billedQty || 0),
                0
            )
            const credit = costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        ((this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty) || 0),
                0
            )

            return invoiced - credit
        },
        quoteTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator + (cost.creditNoteQty || cost.billedQty || 0),
                0
            )
        },
        creditTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    (Number(
                        this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty
                    ) || 0),
                0
            )
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: this.invoice.currency == 'USD' ? 2 : 0,
            }).format(number)
        },
        formatNumber2(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: this.invoice.currency == 'USD' ? 2 : 0,
            }).format(number)
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - 80
        },
        close() {
            this.$emit('close')
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        validateInvoiceItems(costs) {
            if (costs.length == 0) {
                return false
            }
            let response = false
            for (const cost of costs) {
                if (cost.creditNoteQty || cost.billedQty) {
                    response = true
                    break
                }
            }
            return response
        },
        validCredit() {
            let valid = false
            let totalCredit = 0
            for (const cost of this.proformaItems) {
                totalCredit +=
                    (cost.creditQtyRequested || 0) * cost.pricePerUnit
            }
            if (this.changeValue) {
                this.creditTotalPrice = totalCredit.toFixed(2)
            }
            valid = Number(this.creditTotalPrice) > 0

            return valid
        },

        creditAction() {
            if (this.invoice.status == 'credit') {
                this.openCloseInvoice = true
            } else if (this.invoice.status == 'billed') {
                this.creditReasonForm = true
            }
        },
        async requestCreditNote() {
            try {
                this.loading = true
                this.proformaItems.forEach(
                    i =>
                        (i.creditQtyRequested = i.creditQtyRequested =
                            this.creditTotalPrice / this.invoice.proformaValue)
                )
                let itemsRequested = this.proformaItems.filter(
                    item =>
                        item.creditQtyRequested && item.creditQtyRequested > 0
                )
                const params = {
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    notes: this.rejectedNotes,
                    creditPrice: this.creditTotalPrice,
                    creditRequests: itemsRequested,
                }
                const invoice = await API.requestCreditNote(params)
                this.$emit('replaceInvoice', invoice)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        invoiceTotalCredit() {
            let totalCredit = 0
            if (this.invoice.status != 'closed') {
                if (this.invoice.totalCredit) {
                    totalCredit = this.invoice.totalCredit
                } else {
                    for (const cost of this.proformaItems) {
                        if (cost.creditNoteQty) {
                            totalCredit +=
                                cost.creditNoteQty * cost.pricePerUnit
                        }
                    }
                }
            }

            return totalCredit
        },
        calculateCreditQty() {
            this.invoice.creditQtyRequested = (
                this.creditTotalPrice / this.invoice.proformaValue
            ).toFixed(2)
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow-y: scroll;
}
</style>
