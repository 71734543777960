<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="BOMsFiltered"
            class="elevation-0 mt-5"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            :style="{ height: `${height}px` }"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">BILL OF MATERIALS</h1>
                        <v-btn
                            v-if="workOrder.status != 'closed'"
                            rounded
                            color="primary"
                            @click="openNewBomForm"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.index`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.users`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <p
                            class="my-0 text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ item.creator.name }}
                        </p>
                    </template>
                    <span>Creator</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <p
                            class="my-0 text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ item.projectManager.name }}
                        </p>
                    </template>
                    <span>Project Manager</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <p class="my-0">{{ item.project.name }}</p>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
                <p class="my-0">{{ formatDate(item.createdOn.seconds) }}</p>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn
                        v-if="item.status == 'inProgress'"
                        small
                        icon
                        @click="openEditBomForm(item)"
                    >
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn small icon @click="openProcessBOM(item)">
                        <v-icon>
                            mdi-eye
                        </v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--BOM FORM-->
        <v-dialog v-model="bomForm" persistent max-width="80%">
            <BOMForm
                v-if="bomForm"
                :workOrder="workOrder"
                :createForm="createForm"
                :bom="selectedBom"
                :originalBom="originalBom"
                @close="closeBomForm"
            />
        </v-dialog>
        <!--PROCESS BOM-->
        <v-dialog
            v-model="processBOM"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessBOM
                v-if="processBOM"
                :bom="selectedBOM"
                :process="processName"
                @close="closeProcessBOM"
                @sendBOM="sendBOM"
                @removeBOM="closeProcessBOM"
                @rejectBOM="closeProcessBOM"
                @approve="closeProcessBOM"
            />
        </v-dialog>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { mapMutations } from 'vuex'
import { firestore } from '@/services/firebase'
import API from '@/services/api'

export default {
    name: 'BOM',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
    },
    components: {
        BOMForm: () => import('@/components/WorkOrders/BOMForm.vue'),
        ProcessBOM: () => import('@/components/WorkOrders/ProcessBOM.vue'),
    },
    data: () => ({
        alertMessage: '',
        activateAlert: false,
        processName: '',
        selectedBOM: {},
        processBOM: false,
        createForm: false,
        bomForm: false,
        selectedBom: {},
        originalBom: {},
        statusColor: {
            sent: '#FF9800',
            approved: '#27BC4F',
            production: '#FFC000',
            rejected: 'red',
            closed: 'primary',
            inProgress: '#9E9FA0',
        },
        height: 0,
        loading: false,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CODE',
                value: 'code',
                sortable: false,
                align: 'center',
                width: 170,
            },
            {
                text: 'USERS',
                value: 'users',
                sortable: false,
                align: 'center',
            },
            {
                text: 'PROJECT',
                value: 'project',
                sortable: false,
                align: 'left',
            },
            {
                text: 'DATE',
                value: 'createdOn',
                sortable: false,
                align: 'center',
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                align: 'left',
            },
            {
                text: 'STATUS',
                value: 'status',
                sortable: false,
                align: 'center',
                width: '200',
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        BOMs: [],
        search: null,
        projectToFilter: undefined,
        statusToFilter: undefined,
        listener: null,
        companyId: JSON.parse(localStorage.getItem('company')),
    }),
    computed: {
        BOMsFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterMaterial)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterForProjects)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (conditions.length > 0) {
                return this.BOMs.filter(material => {
                    return conditions.every(condition => {
                        return condition(material)
                    })
                })
            }
            return this.BOMs
        },
    },
    async mounted() {
        try {
            this.onresize()
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .doc(this.workOrder.id)
                .collection('BOMs')
                .where('projectId', '==', this.workOrder.projectId)
            this.listener = await query.onSnapshot(async docSnapshot => {
                this.loading = true
                docSnapshot.docChanges().forEach(async change => {
                    const bom = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    if (change.type != 'removed') {
                        bom.project = await API.getProject(bom.projectId)
                        bom.projectManager = await API.getUser(
                            bom.project.projectManager
                        )
                        bom.creator = await API.getUser(bom.createdBy)
                    }
                    if (change.type === 'added') {
                        this.BOMs.push(bom)
                    }
                    if (change.type === 'modified') {
                        const index = this.BOMs.findIndex(r => r.id == bom.id)
                        if (index >= 0) {
                            this.BOMs.splice(index, 1, bom)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.BOMs.findIndex(r => r.id == bom.id)
                        if (index >= 0) {
                            this.BOMs.splice(index, 1)
                        }
                    }
                })
                this.loading = false
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        sendBOM() {
            this.activateAlert = true
            this.alertMessage = 'BOM Successfully Sent for Approval'
            this.closeProcessBOM()
        },
        closeProcessBOM() {
            this.selectedBOM = {}
            this.processBOM = false
        },
        openProcessBOM(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.processName = 'Bill of Material'
            this.processBOM = true
        },
        openEditBomForm(item) {
            this.selectedBom = _.cloneDeep(item)
            this.originalBom = _.cloneDeep(item)
            this.createForm = false
            this.bomForm = true
        },
        openNewBomForm() {
            this.createForm = true
            this.bomForm = true
        },
        closeBomForm() {
            this.selectedBom = {}
            this.originalBom = {}
            this.createForm = false
            this.bomForm = false
        },
        filterMaterial(material) {
            return material.code.toLowerCase().includes(this.search)
        },
        filterForProjects(material) {
            return (
                material.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                material.project.reference
                    ?.toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterForStatus(material) {
            return material.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        onresize() {
            this.height =
                window.innerHeight -
                this.$vuetify.application.top -
                this.$vuetify.application.footer -
                67
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
    },
}
</script>

<style></style>
