<template>
    <v-app>
        <v-main>
            <v-container
                v-if="view || notFound || requestSended"
                v-resize="onResize"
                fluid
                class="overflow-y-hidden"
                ref="container"
            >
                <v-card
                    v-if="!purchaseQuotes && notFound"
                    class="d-flex justify-center align-center"
                    height="100%"
                    fluid
                    flat
                >
                    <h1 class="mr-2">
                        Error 404
                    </h1>
                    <h1>- Page not found</h1>
                </v-card>
                <v-card
                    v-if="requestSended"
                    height="100%"
                    class="d-flex justify-center align-center"
                    fluid
                    flat
                >
                    <v-card-text class="text-center">
                        <v-toolbar color="#1976D2" dark> </v-toolbar>
                        <h1 v-if="language.toLowerCase() == 'spanish'">
                            ¡Cotización enviada exitosamente!
                        </h1>
                        <h1 v-else>
                            Quote Submitted Successfully!
                        </h1>
                        <p v-if="language.toLowerCase() == 'spanish'">
                            Tu cotización ha sido enviada. ¡Gracias por tu
                            envío!
                        </p>
                        <p v-else>
                            Your quote has been sent. Thank you for your
                            submission!
                        </p>

                        <v-icon x-large color="primary"
                            >mdi-check-decagram</v-icon
                        >
                    </v-card-text>
                </v-card>

                <div
                    v-if="!notFound && view"
                    :style="{
                        height: `${
                            $vuetify.breakpoint.smAndDown
                                ? '100%'
                                : detailsHeight + 'px'
                        }`,
                        widht: `${
                            $vuetify.breakpoint.smAndDown
                                ? '100%'
                                : detailsHeight + 'px'
                        }`,
                    }"
                    overflow="visible"
                >
                    <v-card v-show="!notFound && view" fluid height="100%">
                        <v-toolbar
                            v-show="view && purchaseQuotes"
                            color="#1976D2"
                            dark
                        >
                            <v-toolbar-title
                                v-if="!$vuetify.breakpoint.smAndDown"
                                class="uppercase no-break"
                                >{{
                                    language.toLowerCase() == 'spanish'
                                        ? 'Recepción de cotización para la empresa'
                                        : 'Quote reception for company'
                                }}
                                {{
                                    purchaseQuotes &&
                                    purchaseQuotes[0] &&
                                    purchaseQuotes[0].companyName
                                        ? purchaseQuotes[0].companyName
                                        : ''
                                }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <div>
                                <v-btn
                                    @click="sendAlert"
                                    rounded
                                    :color="form ? 'white' : 'white '"
                                    class="blue--text bold"
                                    :loading="loading"
                                    :disabled="form"
                                >
                                    {{
                                        language.toLowerCase() == 'spanish'
                                            ? 'ENVAR COTIZACIÓN'
                                            : 'SEND QUOTE'
                                    }}
                                </v-btn>
                            </div>
                        </v-toolbar>
                        <div
                            id="virtual-scroll-table"
                            :style="{ height: `${details2Height}px` }"
                        >
                            <v-card-title
                                v-if="view"
                                class="mt-0"
                                ref="instructionsRef"
                            >
                                <v-col cols="12">
                                    <v-row>
                                        <h2 class="no-break custom-font">
                                            {{
                                                language.toLowerCase() ==
                                                'spanish'
                                                    ? 'Has sido invitado a enviar una cotización de los siguientes artículos'
                                                    : 'You have been invited to submit a quote for the following items'
                                            }}
                                            {{
                                                +$vuetify.breakpoint.smAndDown
                                                    ? purchaseQuotes &&
                                                      purchaseQuotes[0] &&
                                                      purchaseQuotes[0]
                                                          .companyName
                                                        ? language.toLowerCase() ==
                                                          'spanish'
                                                            ? ', para la compañía ' +
                                                              purchaseQuotes[0]
                                                                  .companyName
                                                            : ', by company ' +
                                                              purchaseQuotes[0]
                                                                  .companyName
                                                        : ''
                                                    : ''
                                            }}
                                        </h2>
                                    </v-row>
                                </v-col>

                                <v-col v-if="notes" cols="12" class="mt-2">
                                    <v-row>
                                        <h4>
                                            {{
                                                language.toLowerCase() ==
                                                'spanish'
                                                    ? 'Notas: '
                                                    : 'Notes: '
                                            }}
                                        </h4>

                                        <h4 class="notes no-break ml-1">
                                            {{ notes }}
                                        </h4>
                                    </v-row>
                                </v-col>

                                <v-col cols="12" class="mt-2">
                                    <v-row>
                                        <h4>
                                            {{
                                                language.toLowerCase() ==
                                                'spanish'
                                                    ? 'Moneda: '
                                                    : 'Currency: '
                                            }}
                                        </h4>

                                        <div class="ml-5 mt-n4">
                                            <v-autocomplete
                                                v-model="currency"
                                                :items="currencies"
                                                :placeholder="
                                                    language.toLowerCase() ==
                                                    'spanish'
                                                        ? 'Click aquí para seleccionar la moneda'
                                                        : 'Click here to select a currency'
                                                "
                                                :rules="[rules.required]"
                                                required
                                                :style="{
                                                    width: currency
                                                        ? '85px'
                                                        : '300px',
                                                }"
                                            />
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-card-title v-else-if="requestSended">
                                <div>
                                    <h2 class="no-break">
                                        Quote form was successfully sent.
                                    </h2>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row
                                    no-gutters
                                    class="scrollable-div"
                                    justify="center"
                                >
                                    <v-card
                                        width="100%"
                                        class="d-flex justify-center align-center"
                                    >
                                        <v-data-table
                                            id="virtual-scroll-table"
                                            :headers="headers"
                                            :items="purchaseQuotes"
                                            class="elevation-0"
                                            :mobile-breakpoint="0"
                                            :loading="loading"
                                            disable-pagination
                                            hide-default-footer
                                            :style="{ height: `${height}px` }"
                                        >
                                            <template v-slot:top>
                                                <v-row
                                                    no-gutter
                                                    class="mx-0"
                                                    :style="{
                                                        'background-color':
                                                            '#eeeeee',
                                                    }"
                                                >
                                                    <v-col
                                                        cols="3"
                                                        class="d-flex"
                                                    >
                                                        <h1>
                                                            {{
                                                                language.toLowerCase() ==
                                                                'spanish'
                                                                    ? 'ARTICULOS'
                                                                    : 'ITEMS'
                                                            }}
                                                        </h1>
                                                    </v-col>
                                                </v-row>
                                            </template>

                                            <!--HEADERS-->
                                            <!-- ITEMS -->
                                            <template
                                                v-slot:[`item.index`]="{
                                                    index,
                                                }"
                                            >
                                                {{ index + 1 }}
                                            </template>
                                            <template
                                                v-slot:[`item.picture`]="{
                                                    item,
                                                }"
                                            >
                                                <v-btn
                                                    color="transparent"
                                                    elevation="0"
                                                    small
                                                    class="text-capitalize text--darken-2 "
                                                    @click="
                                                        openFileFromURL(file)
                                                    "
                                                    v-for="(file,
                                                    i) in item.picture"
                                                    :key="i"
                                                >
                                                    <v-icon
                                                        class="mr-1"
                                                        color="primary"
                                                        :style="{
                                                            'font-size': '17px',
                                                        }"
                                                    >
                                                        mdi-paperclip
                                                    </v-icon>
                                                    <span
                                                        :style="{
                                                            'font-size': '11px',
                                                            color: '#2b81d6',
                                                            'text-decoration':
                                                                'underline',
                                                            cursor: 'pointer',
                                                        }"
                                                        >{{
                                                            item && item.picture
                                                                ? file
                                                                : ''
                                                        }}</span
                                                    >
                                                </v-btn>
                                            </template>
                                            <template
                                                v-slot:[`item.quotedQty`]="{
                                                    item,
                                                }"
                                            >
                                                <v-row justify="end">
                                                    <div>
                                                        <v-text-field
                                                            :readonly="
                                                                requestSended
                                                            "
                                                            v-model="
                                                                item.quotes
                                                                    .quotedQty
                                                            "
                                                            dense
                                                            class="pt-2"
                                                            :rules="[
                                                                rules.required,
                                                                rules.number,
                                                            ]"
                                                            :style="{
                                                                width: '60px',
                                                            }"
                                                        />
                                                    </div>
                                                </v-row>
                                            </template>
                                            <template
                                                v-slot:[`item.unitPriceNoTaxBeforeTRM`]="{
                                                    item,
                                                }"
                                            >
                                                <v-row justify="end">
                                                    <div>
                                                        <v-text-field
                                                            :readonly="
                                                                requestSended
                                                            "
                                                            v-model="
                                                                item.quotes
                                                                    .unitPriceNoTaxBeforeTRM
                                                            "
                                                            dense
                                                            class="pt-2 text-right"
                                                            :rules="[
                                                                rules.required,
                                                                rules.number,
                                                            ]"
                                                            :style="{
                                                                width: '60px',
                                                                align: 'right',
                                                                justify: 'end',
                                                            }"
                                                        />
                                                    </div>
                                                </v-row>
                                            </template>
                                            <template
                                                v-slot:[`item.taxValueBeforeTRM`]="{
                                                    item,
                                                }"
                                            >
                                                <v-row justify="end">
                                                    <div>
                                                        <v-text-field
                                                            :readonly="
                                                                requestSended
                                                            "
                                                            v-model="
                                                                item.quotes
                                                                    .taxValueBeforeTRM
                                                            "
                                                            dense
                                                            class="pt-2 text-right"
                                                            :rules="[
                                                                rules.required,
                                                                rules.number,
                                                            ]"
                                                            :style="{
                                                                width: '60px',
                                                                align: 'right',
                                                                justify: 'end',
                                                            }"
                                                        />
                                                    </div>
                                                </v-row>
                                            </template>
                                            <template
                                                v-slot:[`item.priceBeforeTRM`]="{
                                                    item,
                                                }"
                                            >
                                                <v-row justify="end">
                                                    <div>
                                                        <span
                                                            v-if="
                                                                item.quotes
                                                                    .unitPriceNoTaxBeforeTRM &&
                                                                    item.quotes
                                                                        .taxValueBeforeTRM
                                                            "
                                                            >{{
                                                                Number(
                                                                    item.quotes
                                                                        .taxValueBeforeTRM
                                                                ) +
                                                                    Number(
                                                                        item
                                                                            .quotes
                                                                            .unitPriceNoTaxBeforeTRM
                                                                    )
                                                            }}</span
                                                        >
                                                    </div>
                                                </v-row>
                                            </template>
                                            <template
                                                v-slot:[`item.bidderItemNotes`]="{
                                                    item,
                                                }"
                                            >
                                                <v-row justify="center">
                                                    <div>
                                                        <v-text-field
                                                            :readonly="
                                                                requestSended
                                                            "
                                                            v-model="
                                                                item.quotes
                                                                    .bidderItemNotes
                                                            "
                                                            dense
                                                            class="pt-2"
                                                            :style="{
                                                                width: '160px',
                                                            }"
                                                        />
                                                    </div>
                                                </v-row>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-row>
                                <v-row ref="attach" no-gutter class="mt-0 pt-4">
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                        <li>
                                            <v-row>
                                                <div
                                                    v-if="file"
                                                    class="ml-8 mt-n2"
                                                >
                                                    <strong
                                                        >{{
                                                            language.toLowerCase() ==
                                                            'spanish'
                                                                ? 'Archivo de Cotización'
                                                                : 'Quote File:'
                                                        }}
                                                    </strong>
                                                </div>
                                                <div class="mt-2">
                                                    <v-btn
                                                        v-if="file"
                                                        color="transparent"
                                                        elevation="0"
                                                        small
                                                        class="text-capitalize text--darken-2 mt-n9 ml-20"
                                                    >
                                                        <v-icon
                                                            class="mr-1"
                                                            color="primary"
                                                            :style="{
                                                                'font-size':
                                                                    '17px',
                                                            }"
                                                        >
                                                            mdi-paperclip
                                                        </v-icon>
                                                        <span
                                                            @click.stop="
                                                                openFile(file)
                                                            "
                                                            :style="{
                                                                'font-size':
                                                                    '11px',
                                                                color:
                                                                    '#2b81d6',
                                                                'text-decoration':
                                                                    'underline',
                                                                cursor:
                                                                    'pointer',
                                                            }"
                                                            >{{
                                                                file &&
                                                                file.name
                                                                    ? file.name
                                                                    : ''
                                                            }}</span
                                                        >
                                                        <v-icon
                                                            @click.stop="
                                                                deleteFile()
                                                            "
                                                            class="ml-1"
                                                            color="grey darken-2"
                                                            small
                                                        >
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="
                                                            !file &&
                                                                !requestSended
                                                        "
                                                        @click.stop="
                                                            onButtonClick
                                                        "
                                                        color="primary"
                                                        class="ml-6 mt-n9"
                                                    >
                                                        <v-icon
                                                            class="mr-1"
                                                            color="white"
                                                            :style="{
                                                                'font-size':
                                                                    '17px',
                                                            }"
                                                        >
                                                            mdi-paperclip
                                                        </v-icon>

                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '11px',
                                                                color: 'white',
                                                                cursor:
                                                                    'pointer',
                                                            }"
                                                            >{{
                                                                language.toLowerCase() ==
                                                                'spanish'
                                                                    ? 'Click aquí para subir archivo'
                                                                    : 'Click here to submit the quote file'
                                                            }}</span
                                                        >
                                                    </v-btn>
                                                    <input
                                                        ref="quotesUpload"
                                                        class="d-none"
                                                        type="file"
                                                        name="quotes"
                                                        @change="onFileChanged"
                                                    />
                                                </div>
                                            </v-row>
                                        </li>
                                    </v-col>
                                    <v-spacer> </v-spacer>
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                        <li>
                                            <v-row>
                                                <div class="ml-6 mt-n2">
                                                    <strong v-if="promiseDate"
                                                        >{{
                                                            language.toLowerCase() ==
                                                            'spanish'
                                                                ? 'Fecha de entrega:'
                                                                : 'Delivery Date:'
                                                        }}
                                                    </strong>
                                                </div>
                                                <div
                                                    :class="
                                                        promiseDate
                                                            ? 'ml-2 mt-n8'
                                                            : 'ml-0 mt-n8'
                                                    "
                                                >
                                                    <v-menu
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :nudge-left="50"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                        v-model="menu2"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs,
                                                            }"
                                                        >
                                                            <v-text-field
                                                                v-model="
                                                                    promiseDate
                                                                "
                                                                :placeholder="
                                                                    language.toLowerCase() ==
                                                                    'spanish'
                                                                        ? 'Selecciona aquí fecha de entrega'
                                                                        : 'Click here to select Promise Date'
                                                                "
                                                                prepend-icon="mdi-calendar"
                                                                :readonly="
                                                                    requestSended
                                                                "
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                required
                                                                :rules="[
                                                                    rules.required,
                                                                ]"
                                                                :style="{
                                                                    width:
                                                                        promiseDate ==
                                                                        ''
                                                                            ? '290px'
                                                                            : '135px',
                                                                }"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="
                                                                promiseDate
                                                            "
                                                            @input="
                                                                menu2 = false
                                                            "
                                                            :min="minDate"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </div>
                                            </v-row>
                                        </li>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                        <li>
                                            <v-textarea
                                                class="custom-margin mt-n8"
                                                v-model="bidderGeneralNotes"
                                                :placeholder="
                                                    language.toLowerCase() ==
                                                    'spanish'
                                                        ? 'Escriba aquí sus notas si lo requiere.'
                                                        : 'Write general notes here if needed.'
                                                "
                                                rows="1"
                                                :readonly="requestSended"
                                                no-resize
                                            >
                                                <template v-slot:details>
                                                    <span>Send Notes</span>
                                                </template>
                                            </v-textarea>
                                        </li>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </div>
                        <!--Error Alert-->
                        <Errors />
                    </v-card>
                </div>
                <v-dialog
                    v-model="sendWarning"
                    persistent
                    max-width="400px"
                    :retain-focus="false"
                >
                    <v-card :loading="loading" v-if="sendWarning">
                        <v-card-title class="text-h5">Send Quote</v-card-title>
                        <v-card-text>
                            {{
                                this.language.toLowerCase() == 'spanish'
                                    ? '¿Está seguro de querer enviar la cotización?'
                                    : 'Are you sure you want to send this quote?'
                            }}
                        </v-card-text>

                        <v-card-actions>
                            <v-btn text color="error" @click="closeAlert">
                                {{
                                    this.language.toLowerCase() == 'spanish'
                                        ? 'Cancelar'
                                        : 'Cancel'
                                }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="uploadPurchaseQuote"
                                text
                                color="primary"
                                :loading="loading"
                            >
                                {{
                                    this.language.toLowerCase() == 'spanish'
                                        ? 'Enviar'
                                        : 'Send'
                                }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import Errors from '@/components/Layout/Errors'
import { mapMutations } from 'vuex'
import { auth } from '@/services/firebase'
import axios from 'axios'
import { openFile } from '@/services/storage/openFile.js'

export default {
    name: 'PurchasingQuotes',
    components: {
        Errors,
    },
    data: () => ({
        height: 0,
        heightContainer: window.innerHeight,
        loading: false,
        purchaseQuotes: undefined,
        headers: [],
        rules: {
            required: v => !!v || 'Required',
            number2: v => v > 0 || 'Error',
            number: v => v >= 0 || 'Error',
        },
        selectedItem: {},
        title: '',
        currentDate: '',
        menu2: false,
        createdOn: '',
        bidderGeneralNotes: '',
        notes: '',
        promiseDate: '',
        route1: '/purchaseQuotes/uploadPurchaseQuote',
        minDate: '',
        originalPurchaseQuotes: [],
        file: undefined,
        companyId: '',
        quoteId: '',
        requestId: '',
        view: false,
        notFound: false,
        requestSended: false,
        alertMessage: '',
        activateAlert: false,
        detailsHeight: undefined,
        details2Height: undefined,
        sendWarning: false,
        language: '',
        user: '',
        currencies: ['USD', 'COP', 'EUR'],
        currency: undefined,
        dataTRM: undefined,
        TRM: undefined,
    }),
    computed: {
        form() {
            const keysToCheck = [
                'taxValueBeforeTRM',
                'unitPriceNoTaxBeforeTRM',
                'quotedQty',
            ]
            if (this.purchaseQuotes && this.purchaseQuotes.length > 0) {
                return !this.purchaseQuotes.every(
                    item =>
                        keysToCheck.every(
                            key =>
                                item.quotes[key] !== null &&
                                item.quotes[key] !== undefined &&
                                item.quotes[key] !== false &&
                                Number(item.quotes[key]) >= 0
                        ) &&
                        this.promiseDate &&
                        this.file &&
                        !this.requestSended &&
                        this.currency
                )
            } else {
                return false
            }
        },
    },

    watch: {
        currency() {
            this.TRM =
                this.currency == 'EUR'
                    ? this.dataTRM.eur
                    : this.currency == 'USD'
                    ? this.dataTRM.usd
                    : 1
        },
    },
    created() {
        this.getTRM()
        auth().onAuthStateChanged(user => {
            if (!user) {
                auth()
                    .signInAnonymously()
                    .then(userCredential => {
                        this.user = userCredential
                    })
                    .catch(error => {
                        console.error('Error in authentication:', error.message)
                    })
            } else {
                this.getPurchaseQuotes()
            }
        })
    },
    async mounted() {
        try {
            this.increasePurchaseQuoteCounter()
            this.currentDate = moment().format('MMM/DD/YYYY')
            this.minDate = this.minDate = new Date().toISOString()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            if (this.view) {
                const {
                    instructionsRef: { clientHeight: intructionsHeight },
                    attach: { clientHeight: attachHeight },
                    container: { clientHeight: containerHeight },
                } = this.$refs

                this.detailsHeight = window.innerHeight - 24
                this.details2Height = window.innerHeight - 20
                this.height =
                    this.purchaseQuotes && this.purchaseQuotes.length > 0
                        ? 48 * this.purchaseQuotes.length
                        : window.innerHeight -
                          containerHeight -
                          attachHeight -
                          intructionsHeight -
                          510
                if (this.$vuetify.breakpoint.smAndDown) {
                    this.height = 250
                }
                if (this.$vuetify.breakpoint.md) {
                    this.height = 270
                }
                if (this.$vuetify.breakpoint.lg) {
                    this.height = 300
                }
                if (this.$vuetify.breakpoint.xl) {
                    this.height = 500
                }
                if (this.height < 250) {
                    this.height = 250
                }
            }
        },
        async getPurchaseQuotes() {
            try {
                const data = await API.getPurchaseQuotes(
                    this.$router.currentRoute.query.text
                )
                this.language = data[data.length - 1]
                this.companyId = data[data.length - 2]
                this.requestId = data[data.length - 3]
                data.splice(data.length - 3, 3)
                const purchaseQuotes = data
                this.notes =
                    purchaseQuotes[0] && purchaseQuotes[0].quotes?.notes
                        ? purchaseQuotes[0].quotes.notes
                        : ''
                this.purchaseQuotes = purchaseQuotes
                this.headers = [
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Indice'
                                : 'Index',
                        value: 'index',
                        align: 'center',
                        sortable: false,
                        width: 80,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Descripcin'
                                : 'Description',
                        value: 'description',
                        align: 'left',
                        sortable: false,
                        width: 50,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Imagen'
                                : 'Picture',
                        value: 'picture',
                        align: 'center',
                        sortable: false,
                        width: 30,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Cantidad requerida'
                                : 'Request quantity',
                        value: 'quantity',
                        align: 'right',
                        sortable: false,
                        width: 30,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Cantidad Cotizada'
                                : 'Quoted Quantity',
                        value: 'quotedQty',
                        align: 'right',
                        sortable: false,
                        width: 30,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Precio Unitario'
                                : 'Unit price',
                        value: 'unitPriceNoTaxBeforeTRM',
                        align: 'right',
                        sortable: false,
                        width: 30,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Valor de impuesto'
                                : 'Tax Value',
                        value: 'taxValueBeforeTRM',
                        align: 'right',
                        sortable: false,
                        width: 30,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Precio total unitario'
                                : 'Total unit price',
                        value: 'priceBeforeTRM',
                        align: 'right',
                        sortable: false,
                        width: 90,
                    },
                    {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Notas'
                                : 'Notes',
                        value: 'bidderItemNotes',
                        align: 'center',
                        sortable: false,
                        width: 100,
                    },
                ]
                if (
                    purchaseQuotes.some(item => item.notes && item.notes != '')
                ) {
                    this.headers.splice(2, 0, {
                        text:
                            this.language.toLowerCase() == 'spanish'
                                ? 'Notas de Item'
                                : 'Item Notes',
                        value: 'notes',
                        align: 'left',
                        sortable: false,
                        width: 60,
                    })
                }
                if (this.purchaseQuotes) {
                    this.view = true
                } else {
                    this.notFound = true
                }
            } catch (error) {
                this.view = false
                this.notFound = true
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getTRM() {
            try {
                axios
                    .get(
                        'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/cop.json'
                    )
                    .then(response => {
                        this.dataTRM = response.data.cop
                    })
                    .catch(error => {
                        console.error('Error al hacer la solicitud:', error)
                    })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async increasePurchaseQuoteCounter() {
            try {
                API.increasePurchaseQuoteCounter(
                    this.$router.currentRoute.query.text
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('MMM/DD/YYYY')}`
        },
        onButtonClick() {
            this.$refs.quotesUpload.click()
        },
        async onFileChanged(e) {
            if (e.target.files.length > 0) {
                const file = e.target.files[0]
                this.file = file
            }
        },
        deleteFile() {
            this.file = undefined
        },
        sendAlert() {
            this.sendWarning = true
        },
        closeAlert() {
            this.selectedItem = {}
            this.sendWarning = false
        },
        async uploadPurchaseQuote() {
            try {
                this.loading = true

                await this.saveFile(this.file)

                this.purchaseQuotes.forEach(item => {
                    item.quotes.TRM =
                        this.currency == 'EUR'
                            ? this.dataTRM.eur
                            : this.currency == 'USD'
                            ? this.dataTRM.usd
                            : 1

                    item.quotes.unitPriceNoTaxBeforeTRM = Number(
                        item.quotes.unitPriceNoTaxBeforeTRM
                    )
                    item.quotes.taxValueBeforeTRM = Number(
                        item.quotes.taxValueBeforeTRM
                    )
                    item.quotes.quotedQty = Number(item.quotes.quotedQty)
                    item.quotes.unitPriceNoTax =
                        item.quotes.unitPriceNoTaxBeforeTRM / item.quotes.TRM
                    item
                    item.quotes.taxValue =
                        item.quotes.taxValueBeforeTRM / item.quotes.TRM

                    item.quotes.priceBeforeTRM =
                        item.quotes.unitPriceNoTaxBeforeTRM +
                        item.quotes.taxValueBeforeTRM

                    item.quotes.price =
                        item.quotes.priceBeforeTRM / item.quotes.TRM

                    item.quotes.bidderGeneralNotes = this.bidderGeneralNotes
                    item.quotes.currency = this.currency
                    item.quotes.promiseDate = this.promiseDate
                    item.quotes.file = [this.file.name]
                    delete item.companyName
                    delete item.quotes.contacts
                    delete item.quotes.createdOn
                    delete item.quotes.supplier
                    delete item.quotes.notes
                    delete item.quotes.id
                })
                const quote = await API.uploadPurchaseQuote({
                    params: this.$router.currentRoute.query.text,
                    paramsToUpdate: this.purchaseQuotes,
                    quotes: [this.file.name],
                })
                if (quote.dueDate) {
                    quote.dueDate = quote.dueDate.substring(0, 10)
                }

                this.requestSended = true
                this.activateAlert = true
                this.alertMessage = 'Quote form was successfully sent.'
                this.sendWarning = false
                this.view = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile(file) {
            try {
                this.loading = true
                //Create an instance of FormData
                const formData = new FormData()
                formData.append('picture', file)
                await API.uploadPurchaseQuoteFile(
                    this.$router.currentRoute.query.text,
                    formData
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openFile() {
            if (this.file) {
                // Create a temporal URL for the file
                const pdfUrl = URL.createObjectURL(this.file)
                // Open the file
                window.open(pdfUrl, '_blank')
            } else {
                alert('Please select a file first.')
            }
        },
        async openFileFromURL(file) {
            try {
                if (file) {
                    // Open the file
                    await openFile(
                        file,
                        `${this.companyId}/purchases/${this.requestId}/images`
                    )
                } else {
                    alert('There is not file to be open.')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}

.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.uppercase {
    text-transform: uppercase;
    font-size: 23px;
    font-weight: bold;
    white-space: normal; /* Permite que el texto se envuelva */
    overflow-wrap: break-word; /* Asegura que las palabras largas se dividan correctamente */
    width: 100%; /* Asegura que el título use todo el ancho disponible */
}
.no-break {
    overflow-wrap: normal; /* Asegura que las palabras no se dividan */
    word-break: keep-all; /* Mantiene las palabras completas en lugar de dividirlas */
}
.custom-font {
    font-size: 22px; /* Cambia a tu tamaño deseado */
}
h1 {
    margin-top: 20px;
    font-size: 24px;
    color: #333;
}
h2 p {
    color: #666;
    font-size: 18px;
}
.notes {
    font-weight: normal;
}

.custom-margin {
    margin: 20px;
}
.scrollable-div {
    overflow-x: auto; /* Solo desplazamiento horizontal */
    white-space: nowrap; /* Hace que el contenido no haga salto de línea automáticamente */
}
</style>
