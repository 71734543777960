<template>
    <v-card>
        <div v-if="!isDeleteOption">
            <v-card-title>
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    Edit BOM Item
                </h2>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text>
                <div>
                    <v-data-table
                        id="virtual-scroll-table"
                        :headers="headers"
                        :items="reservesFiltered"
                        class="elevation-0 mt-5"
                        :mobile-breakpoint="0"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                        :style="{ height: `${height}px` }"
                    >
                        <template v-slot:top>
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                :style="{ 'background-color': '#eeeeee' }"
                            >
                                <v-col cols="12">
                                    <h2 class="mx-4 my-n3">RESERVED STOCK</h2>
                                </v-col>
                            </v-row>
                        </template>
                        <!--ITEMS-->
                        <template v-slot:[`item.name`]="{ item }">
                            <p class="my-0">
                                {{
                                    `${item.material} ${item.short_material_text}`
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                            <div v-if="loading">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    size="24"
                                ></v-progress-circular>
                            </div>
                            <div v-else>
                                <p class="my-0">{{ item.free_use }}</p>
                            </div>
                        </template>
                        <template v-slot:[`item.qty`]="{ item }">
                            <v-form ref="form" v-model="valid">
                                <v-text-field
                                    type="number"
                                    v-model="item.bomQuantity"
                                    :rules="[
                                        () =>
                                            rules.maxValue(
                                                item.bomQuantity,
                                                item
                                            ),

                                        () => rules.checkRequired(item),
                                    ]"
                                    required
                            /></v-form>
                        </template>
                        <template v-slot:[`item.notes`]="{ item }">
                            <v-text-field v-model="item.notes" />
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    :loading="loading"
                    color="primary"
                    rounded
                    class="mt-n2 mb-3 mx-2"
                    :disabled="!valid"
                    @click="saveBOM"
                >
                    {{ 'EDIT BILL OF MATERIAL ITEM' }}
                </v-btn>
            </v-card-actions>
        </div>
        <div v-else>
            <v-card-title class="text-h5">Delete BOM Item</v-card-title>
            <v-card-text>
                Are you sure you want to delete this BOM Item?
            </v-card-text>
            <v-card-actions>
                <v-btn text color="secondary" @click="close">
                    Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    @click="deleteBomItem"
                    text
                    color="error"
                    :loading="loading"
                >
                    Delete
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import { storage } from '@/services/firebase'
import moment from 'moment'

export default {
    name: 'BOMItemForm',
    props: {
        bom: {
            type: Object,
            default: () => {},
            required: true,
        },
        itemToFilter: {
            type: Object,
            default: () => {},
            required: true,
        },
        isDeleteOption: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data: () => ({
        aux_reserves: [],
        reserves: [],
        loading: false,
        headers: [
            {
                text: 'ITEM',
                value: 'name',
                sortable: false,
                align: 'left',
            },
            {
                text: 'AVAILABLE',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'STORE',
                value: 'storage',
                sortable: false,
                align: 'left',
            },
            {
                text: 'QTY',
                value: 'qty',
                sortable: false,
                align: 'left',
                width: '100',
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                align: 'left',
            },
        ],
        height: 0,
        valid: false,
        rules: {
            checkRequired: item => {
                if (item.prevBomQuantity && item.bomQuantity < 0) {
                    return 'Value must be a positive number'
                }
                return true
            },
            maxValue: (v, item) => {
                //let itemQty = item.prevBomQuantity ? item.prevBomQuantity : 0
                const maxValue = Number(item.free_use)
                return (
                    v <= maxValue ||
                    `The value cannot be greater than ${maxValue}.`
                )
            },
        },
        companyId: JSON.parse(localStorage.getItem('company')),
    }),
    computed: {
        reservesFiltered() {
            let conditions = []

            if (this.itemToFilter) {
                conditions.push(this.filterItem)
            }
            return this.reserves.filter(reserve => {
                return conditions.every(condition => {
                    return condition(reserve)
                })
            })
        },
    },
    async mounted() {
        try {
            this.onresize()
            this.loading = true
            this.prevQty = this.itemToFilter.quantity
            await this.getReserves()
            await this.calculateStock()
            if (this.bom.id) {
                this.bom.items.forEach(request => {
                    const reserveIndex = this.reserves.findIndex(
                        reserve =>
                            request.description
                                .toLowerCase()
                                .includes(
                                    `${reserve.material}`.toLowerCase()
                                ) &&
                            request.store == reserve.storage &&
                            request.order ==
                                (reserve.order || reserve.orderNumber)
                    )
                    if (reserveIndex > -1) {
                        this.reserves.splice(reserveIndex, 1, {
                            ...this.reserves[reserveIndex],
                            bomQuantity: request.quantity,
                            notes: request.notes,
                            id: request.id,
                        })
                    }
                })
            }
            this.reserves.forEach(reserve => {
                reserve.prevBomQuantity = reserve.bomQuantity
                    ? reserve.bomQuantity
                    : 0
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getReserves() {
            try {
                this.loading = true
                const reserves = await API.getReservedStock(this.bom.projectId)
                for (const reserve of reserves) {
                    const aux_reserve_index = this.aux_reserves.findIndex(
                        r =>
                            r.material == reserve.material &&
                            r.storage == reserve.storage
                    )
                    if (aux_reserve_index > -1) {
                        const newFreeUse =
                            Number(
                                this.aux_reserves[aux_reserve_index].free_use ||
                                    0
                            ) + Number(reserve.free_use || 0)
                        this.aux_reserves.splice(aux_reserve_index, 1, {
                            ...this.aux_reserves[aux_reserve_index],
                            free_use: newFreeUse,
                        })
                    } else {
                        this.aux_reserves.push(reserve)
                    }
                }
                this.reserves = _.cloneDeep(this.aux_reserves)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async calculateStock() {
            try {
                this.loading = true

                let lastStockUpdate = null
                const storageRef = storage()
                    .ref(`${this.companyId}/stock`)
                    .child('stock.json')
                await storageRef.getMetadata().then(metadata => {
                    lastStockUpdate = metadata.updated
                })

                const queryDate = moment(
                    lastStockUpdate.substring(0, 10)
                ).format('YYYY-MM-DD')
                //const stockDate = new Date(lastStockUpdate)
                const boms = await API.getBOMsChangelogForStock({
                    projectId: this.bom.projectId,
                    status: ['inProgress', 'approved'],
                    startDate: queryDate,
                })

                let activeBoms = boms.filter(bom => {
                    return bom.id != this.bom.id
                })

                const activeItems = activeBoms.flatMap(bom => bom.items)
                activeItems.forEach(
                    item => (item.material = item.description.split(' ')[0])
                )

                this.reserves.forEach(reserve => {
                    const usedReserves = activeItems.filter(
                        i =>
                            i.material == reserve.material &&
                            i.store == reserve.storage &&
                            i.order == (reserve.order || reserve.orderNumber)
                    )
                    const usedQuantity = usedReserves.reduce(
                        (total, item) => total + item.quantity,
                        0
                    )
                    reserve.free_use = reserve.free_use - usedQuantity
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveBOM() {
            await this.editBOM()
        },
        async editBOM() {
            try {
                this.loading = true
                const requests = this.reserves.filter(reserve => {
                    delete reserve.prevBomQuantity
                    if (
                        reserve.bomQuantity != undefined &&
                        reserve.bomQuantity != ''
                    ) {
                        reserve.bomQuantity = Number.parseInt(
                            reserve.bomQuantity
                        )
                    }
                    if (
                        Number.isInteger(reserve.bomQuantity) ||
                        reserve.notes
                    ) {
                        if (reserve.id) {
                            const bomItem = this.bom.items.find(
                                item => item.id == reserve.id
                            )
                            if (
                                bomItem &&
                                bomItem.quantity == reserve.bomQuantity &&
                                bomItem.notes == reserve.notes
                            ) {
                                return false
                            }
                            return true
                        }
                        return true
                    }
                })
                const bomUpdated = await API.updateBOM({
                    workOrderId: this.bom.workOrderId,
                    bomId: this.bom.id,
                    notificationId: 'FAbZ7a7PCL3KccuFMrc6',
                    requests,
                })
                this.$emit('close', bomUpdated.items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close', this.bom.items)
        },
        filterItem(reserve) {
            return (
                (reserve.material + ' ' + reserve.short_material_text)
                    .toLowerCase()
                    .includes(this.itemToFilter.description.toLowerCase()) &&
                reserve.storage == this.itemToFilter.store
            )
        },
        onresize() {
            this.height = 300
        },
        async deleteBomItem() {
            this.loading = true
            const reserves = this.reserves.filter(
                reserve => reserve.id === this.itemToFilter.id
            )
            reserves.forEach(reserve => (reserve.bomQuantity = 0))
            const bomUpdated = await API.updateBOM({
                workOrderId: this.bom.workOrderId,
                bomId: this.bom.id,
                notificationId: 'FAbZ7a7PCL3KccuFMrc6',
                requests: reserves,
            })
            this.loading = false
            this.$emit('close', bomUpdated.items)
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
