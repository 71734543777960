<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'historyGeneralInfo',
                    params: { quote: newQuote, history: true },
                }"
            >
                General Information
            </v-tab>

            <v-tab
                :to="{
                    name: 'historyCommercialTerms',
                    params: { quote: newQuote, history: true },
                }"
            >
                Commercial Terms
            </v-tab>
            <v-tab
                :to="{
                    name: 'historyCosts',
                    params: {
                        quote: newQuote,
                        costs: costs,
                        history: true,
                        settings,
                    },
                }"
            >
                Costs
            </v-tab>
            <v-tab
                :to="{
                    name: 'historyVersions',
                    params: {
                        quote: newQuote,
                        costs: costs,
                        costVersions: costVersions,
                        settings,
                    },
                }"
            >
                Versions
            </v-tab>
            <v-tab
                :to="{
                    name: 'tasks-by-quote',
                    params: {
                        quoteId: id,
                    },
                }"
            >
                Tasks
            </v-tab>
            <v-tab
                :to="{
                    name: 'historyFollowUp',
                    params: {
                        id: newQuote.id,
                        quote: newQuote,
                        settings,
                    },
                }"
            >
                Follow Up
            </v-tab>
            <v-tab
                :to="{
                    name: 'historyQuoteLogs',
                    params: {
                        id: newQuote.id,
                        quote: newQuote,
                        settings,
                    },
                }"
            >
                Logs
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title class="py-3" ref="title">
                <v-btn
                    small
                    fab
                    depressed
                    @click="show"
                    icon
                    class="mr-1"
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon>
                        mdi-arrow-left-bold-circle-outline
                    </v-icon>
                </v-btn>
                <span v-if="quote">{{ quote.name }}</span>
                <v-spacer />
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="UpdateAwardedQuotes"
                            @click.stop="restartDialog = true"
                        >
                            <v-dialog
                                v-model="restartDialog"
                                persistent
                                max-width="400px"
                                :retain-focus="false"
                            >
                                <v-card :loading="loading">
                                    <v-card-title class="text-h5"
                                        >Restore Quote</v-card-title
                                    >
                                    <v-card-text>
                                        Are you sure you want to restore this
                                        quote?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            text
                                            color="secondary"
                                            @click="restartDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="restartQuote"
                                            text
                                            color="error"
                                            :loading="loading"
                                        >
                                            Restore
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-list-item-title>
                                Restore Quote
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="deleteQuotes"
                            @click.stop="deleteDialog = true"
                        >
                            <v-dialog
                                v-model="deleteDialog"
                                persistent
                                max-width="400px"
                                :retain-focus="false"
                            >
                                <v-card :loading="loading">
                                    <v-card-title class="text-h5"
                                        >Delete Quote</v-card-title
                                    >
                                    <v-card-text>
                                        Are you sure you want to delete this
                                        quote?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            text
                                            color="secondary"
                                            @click="deleteDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="deleteQuote"
                                            text
                                            color="error"
                                            :loading="loading"
                                        >
                                            Delete
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-list-item-title>
                                Delete Quote
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>
            <v-card-text
                class="overflow-y-auto"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { storage } from '@/services/firebase'
import API from '@/services/api'
export default {
    name: 'QuoteHistory',
    props: {
        id: String,
        quote: Object,
        history: {
            type: Boolean,
            default: true,
        },
        settings: Array,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            deleteError: false,
            restartDialog: false,
            deleteDialog: false,
            costs: null,
            costVersions: null,
            newQuote: this.quote,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            UpdateAwardedQuotes: false,
            deleteQuotes: false,
        }
    },
    provide() {
        return {
            replaceQuote: undefined,
        }
    },
    beforeUpdate() {
        const quote = this.historyQuotes.find(i => i.id == this.id)
        this.newQuote = quote
    },
    watch: {
        id: async function(newId) {
            if (newId) {
                await this.getCosts()
                await this.getHistoryCosts()
            }
        },
    },
    updated() {
        this.onResize()
    },
    computed: {
        ...mapState([
            'saveBtn',
            'historyQuotes',
            'quotes',
            'archivedCosts',
            'archivedVersions',
        ]),
    },
    created() {
        if (this.id) {
            this.getCosts()
            if (this.archivedVersions.length > 0) {
                const costVersions = this.archivedVersions.find(
                    x => x.idQuote == this.id
                )
                if (costVersions) {
                    this.costVersions = costVersions
                } else {
                    this.getHistoryCosts()
                }
            } else {
                this.getHistoryCosts()
            }
        }
    },
    async mounted() {
        const {
            data: { user },
        } = await API.getMyInfo()
        const UpdateAwardedQuotes = user.permissions.find(
            x => x == 'updateawarded'
        )
        const deleteQuotes = user.permissions.find(x => x == 'deleteQuotes')
        if (UpdateAwardedQuotes) {
            this.UpdateAwardedQuotes = true
        }
        if (deleteQuotes) {
            this.deleteQuotes = true
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        ...mapActions([
            'removeHistoryQuoteAction',
            'removeQuoteAction',
            'addArchivedCostsAction',
            'addArchivedVersionsAction',
        ]),
        ...mapMutations(['setAlternatives', 'setErrorItems']),
        restartQuote: async function() {
            try {
                this.loading = true
                this.errorMsg = null
                const { id } = this.quote
                const {
                    data: { restoreQuote },
                } = await API.restartQuote(id, '8V32jkdzXcIndJx0SUjc')
                this.removeHistoryQuoteAction(id)
                restoreQuote.createdOn = moment
                    .unix(restoreQuote.createdOn._seconds)
                    .format('MMM/DD/YYYY')
                if (restoreQuote.updatedOn) {
                    restoreQuote.updatedOn = moment
                        .unix(restoreQuote.updatedOn._seconds)
                        .format('MMM/DD/YYYY')
                }
                this.quotes.push(restoreQuote)
                this.$router.push({ name: 'main board' })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.restartDialog = false
            }
        },
        deleteDocument: async function(doc) {
            const path = `${this.companyId}/history/${this.quote.id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete()
            return true
        },
        deleteQuote: async function() {
            try {
                this.loading = true
                this.deleteError = false
                this.errorMsg = null
                const { id } = this.quote
                if (this.quote.files.length > 0) {
                    this.quote.files.forEach(doc => {
                        this.deleteDocument(doc.file)
                    })
                }
                await API.deleteQuoteHistory(id)
                this.removeHistoryQuoteAction(id)
                this.deleteDialog = false
                this.$router.push({ name: 'noHistory' })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getCosts: async function() {
            try {
                this.setAlternatives([])
                this.loading = true
                this.error = false
                this.errorMsg = null
                let costs = await API.getCosts(this.id)
                costs = costs.filter(cost => cost.type)
                this.costs = costs.filter(cost => cost.type == 'cost')
                this.setAlternatives(
                    costs.filter(cost => cost.type == 'alternate')
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getHistoryCosts: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { historyCosts },
                } = await API.getHistoryCosts(this.id)
                if (historyCosts.length > 0) {
                    historyCosts.forEach(cost => {
                        cost.createdOn = moment
                            .unix(cost.createdOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    })
                    this.addArchivedVersionsAction({
                        idQuote: this.id,
                        historyCosts: historyCosts,
                    })
                    const versions = this.archivedVersions.find(
                        x => x.idQuote == this.id
                    )
                    this.costVersions = versions
                } else {
                    this.costVersions = {}
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
