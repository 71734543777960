<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
            :height="$vuetify.breakpoint.mdAndDown ? '69vh' : '64vh'"
            multiple-expand
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">WORK ORDERS BALANCE</h1>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.projectManager`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.projectManager.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.woQty`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getTotalWOQty(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.releasedQty`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getTotalReleased(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.receivedQty`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getTotalReceived(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.pendingQty`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getTotalPending(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.shippedQty`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getTotalShipped(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.balanceQty`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getTotalBalance(item) }}
                    </p>
                </div>
            </template>
            <template
                v-slot:expanded-item="{
                    headers,
                    item: item,
                }"
            >
                <td
                    :colspan="headers.length"
                    class="pa-4 ma-0 outlined"
                    :style="{ background: 'white' }"
                >
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-data-table
                                :headers="subheaders"
                                :items="item.items"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                :loading="loading"
                                disable-pagination
                                hide-default-footer
                            >
                                <!--ITEMS-->
                                <template v-slot:[`item.qty`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.quantity }}
                                        </p>
                                    </div>
                                </template>
                                <template
                                    v-slot:[`item.releasedQty`]="{ item }"
                                >
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.released }}
                                        </p>
                                    </div>
                                </template>
                                <template
                                    v-slot:[`item.receivedQty`]="{ item }"
                                >
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.received }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.shippedQty`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.shipped }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.balanceQty`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.balance }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.pendingQty`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{
                                                (item.released || 0) -
                                                    (item.received || 0)
                                            }}
                                        </p>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'PackingBalance',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {},
    data: () => ({
        workOrder: false,
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'PROJECT MANAGER',
                value: 'projectManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
                width: 240,
            },
            {
                text: 'WO QTY',
                value: 'woQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RELEASED',
                value: 'releasedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RECEIVED',
                value: 'receivedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PENDING',
                value: 'pendingQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SHIPPED',
                value: 'shippedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'BALANCE',
                value: 'balanceQty',
                align: 'center',
                sortable: false,
            },
        ],
        subheaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RELEASED',
                value: 'releasedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RECEIVED',
                value: 'receivedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PENDING',
                value: 'pendingQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SHIPPED',
                value: 'shippedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'BALANCE',
                value: 'balanceQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ORIGIN PROCESS',
                value: 'originProcess',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        settingProcesses: undefined,
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        qualityProcess: {
            id: 'quality',
            name: 'quality|',
        },
        user: {},
        packingLists: [],
        startDate: 0,
        queryStartDate: 0,
        structuredWorkOrders: [],
        expanded: [],
    }),
    computed: {
        ...mapState(['notificationResource']),
        workOrdersFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.structuredWorkOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.structuredWorkOrders
        },
    },
    watch: {
        startDate: async function(newStartDate) {
            try {
                if (newStartDate > 0 && newStartDate != this.queryStartDate) {
                    this.queryStartDate = newStartDate
                    this.loading = true
                    this.packingLists = await API.getAllPackingLists({
                        startDate: moment
                            .unix(newStartDate)
                            .format('YYYY-MM-DD'),
                    })
                    this.structureData()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            const {
                data: { users },
            } = await API.getLiteUsers()

            const projects = await API.getLiteProjects({ all: true })
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .where('status', 'in', ['approved', 'finished'])

            this.listener = await query.onSnapshot(async docSnapshot => {
                docSnapshot.docChanges().forEach(async change => {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    const userIndex = users.findIndex(
                        u => u.id == workOrder.projectManager
                    )
                    if (userIndex >= 0) {
                        workOrder.projectManager = users[userIndex]
                    } else {
                        workOrder.projectManager = {
                            id: workOrder.projectManager,
                        }
                        workOrder.projectManager.name = ''
                    }
                    if (change.type === 'added') {
                        const projectIndex = projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            projects.push(workOrder.project)
                        }
                        this.workOrders.splice(0, 0, workOrder)
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                projects.push(workOrder.project)
                            }
                            this.workOrders.splice(index, 1, workOrder)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }
                })
                this.getStartDate()
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        getStartDate() {
            let startDate = Date.now() / 1000
            this.workOrders.forEach(wo => {
                if (wo.items) {
                    wo.items.forEach(item => {
                        if (item.deliveries) {
                            item.deliveries.forEach(delivery => {
                                if (
                                    delivery.nextProcess &&
                                    (delivery.nextProcess.id ==
                                        this.readyForPackingProcess.id ||
                                        delivery.nextProcess.id ==
                                            this.packingProcess.id)
                                ) {
                                    if (
                                        delivery.deliveredOn.seconds < startDate
                                    ) {
                                        startDate = delivery.deliveredOn.seconds
                                    }
                                }
                            })
                        }
                    })
                }
            })
            this.startDate = startDate
        },
        structureData() {
            let structuredWorkOrders = _.cloneDeep(this.workOrders)
            structuredWorkOrders.forEach(workOrder => {
                //filter packing using this work order
                const filteredPackingLists = this.packingLists.filter(
                    packingList =>
                        packingList.selectedWorkOrders &&
                        packingList.selectedWorkOrders.some(
                            wo => wo.code == workOrder.code
                        ) &&
                        ['shipped', 'completed'].includes(packingList.status)
                )

                workOrder.items.forEach(item => {
                    let itemEntries = []
                    filteredPackingLists.forEach(pl => {
                        pl.packages.forEach(pk => {
                            const entries = pk.items.filter(
                                i =>
                                    i.partNumber == item.partNumber &&
                                    i.workOrder == workOrder.code
                            )
                            itemEntries.push(...entries)
                        })
                    })
                    //shipped QTY
                    const shippedQty = itemEntries.reduce(
                        (accum, entry) => accum + Number(entry.packingQty || 0),
                        0
                    )
                    item.shipped = shippedQty
                    item.received = 0
                    item.released = 0
                    item.sent = 0
                    item.originProcess = ''

                    if (item.deliveries) {
                        //sent QTY
                        const deliveries = item.deliveries.filter(
                            register =>
                                register.nextProcess.id ==
                                    this.packingProcess.id ||
                                register.nextProcess.id ==
                                    this.readyForPackingProcess.id
                        )

                        item.sent = deliveries.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                        //received QTY
                        const received = deliveries.filter(
                            register => register.receivedBy
                        )

                        item.received = received.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }

                    //balanceQTY
                    item.balance =
                        Number(item.quantity || 0) - Number(item.shipped || 0)

                    if (item.quality && item.processes) {
                        const qualityIndex = item.processes.findIndex(
                            p => p == this.qualityProcess.id
                        )
                        if (
                            qualityIndex > -1 &&
                            item.processes.length - 2 == qualityIndex &&
                            (item.processes[qualityIndex + 1] ==
                                this.packingProcess.id ||
                                item.processes[qualityIndex + 1] ==
                                    this.readyForPackingProcess.id)
                        ) {
                            if (qualityIndex - 1 > -1) {
                                const process = this.settingProcesses.processes.find(
                                    pr =>
                                        pr.id ==
                                        item.processes[qualityIndex - 1]
                                )
                                if (process) {
                                    item.originProcess = process.name
                                }
                            }

                            item.released = item.quality.reduce(
                                (accum, q) => accum + Number(q.releaseQty || 0),
                                0
                            )
                        }
                    }
                })

                this.structuredWorkOrders = structuredWorkOrders
            })
        },
        getTotalReleased(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.released,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalReceived(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.received,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalShipped(wo) {
            if (wo.items) {
                return Number(
                    wo.items.reduce((accum, item) => accum + item.shipped, 0)
                )
            } else {
                return 0
            }
        },
        getTotalBalance(wo) {
            if (wo.items) {
                return wo.items.reduce((accum, item) => accum + item.balance, 0)
            } else {
                return 0
            }
        },
        getTotalWOQty(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.quantity,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalPending(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) =>
                        accum + ((item.released || 0) - (item.received || 0)),
                    0
                )
            } else {
                return 0
            }
        },
        sort() {
            this.structuredWorkOrders.sort(
                (a, b) => this.getTotalPending(b) - this.getTotalPending(a)
            )
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
