<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="itemsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">CATEGORIES</h1>
                        <v-btn
                            rounded
                            color="primary"
                            @click="openCreateItem"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.name`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByName"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.name`]="{ item }">
                <div class="d-flex justify-left px-8">
                    <p class="my-0 text-capitalize">
                        {{ item.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.description`]="{ item }">
                <div class="d-flex justify-left">
                    <p class="my-0 text-capitalize">
                        {{ item.description }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openEditItem(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small icon @click="openDeleteItem(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!---ITEM FORM-->
        <v-dialog
            v-model="openItemForm"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <ItemForm
                v-if="openItemForm"
                :title="'category'"
                :createForm="createForm"
                :item="selectedItem"
                :originalItem="originalItem"
                :invoiceSettings="setting"
                @addItem="addItem"
                @replaceItem="replaceItem"
                @close="closeEditItem"
            />
        </v-dialog>
        <!--Delete Item-->
        <v-dialog
            v-model="openDeleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openDeleteWarning">
                <v-card-title class="text-h5">Delete category</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this invoice category?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteItem">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteItem"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'InvoiceCategories',
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        ItemForm: () => import('@/views/Settings/SettingItemForm.vue'),
    },
    data: () => ({
        loading: false,
        user: {},
        items: [],
        selectedItem: {},
        originalItem: {},
        createForm: true,
        openItemForm: false,
        openDeleteWarning: false,
        headers: [
            {
                text: 'NAME',
                value: 'name',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        searchByName: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: {
                    invoiceSettings: { categories },
                },
            } = await API.getInvoiceSettings(this.setting.id)
            this.items = categories
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        itemsFiltered() {
            const conditions = []
            if (this.items && this.items.length > 0) {
                if (this.searchByName) {
                    conditions.push(this.filterByName)
                }
            }

            if (conditions.length > 0) {
                return this.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return this.items
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByName(item) {
            return item.name
                .toLowerCase()
                .includes(this.searchByName.toLowerCase())
        },
        replaceItem(itemToUpdate) {
            const index = this.items.findIndex(
                item => item.id == itemToUpdate.id
            )
            if (index >= 0) {
                this.items.splice(index, 1, {
                    ...this.items[index],
                    ...itemToUpdate,
                })
            }
        },
        addItem(item) {
            if (this.items && this.items.length > 0) {
                this.items = [...this.items, item]
            } else {
                this.items = [item]
            }
        },
        removeItem() {
            const index = this.items.findIndex(
                item => item.id == this.selectedItem.id
            )
            if (index >= 0) {
                this.items.splice(index, 1)
            }
        },
        async deleteItem() {
            try {
                this.loading = true
                await API.deleteInvoiceSettingItem({
                    settingId: this.setting.id,
                    itemId: this.selectedItem.id,
                    itemType: 'category',
                })
                this.removeItem()
                this.closeDeleteItem()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteItem() {
            this.selectedItem = {}
            this.openDeleteWarning = false
        },
        openDeleteItem(item) {
            this.selectedItem = _.cloneDeep(item)
            this.openDeleteWarning = true
        },
        closeEditItem() {
            this.createForm = true
            this.selectedItem = {}
            this.originalItem = {}
            this.openItemForm = false
        },
        openEditItem(item) {
            this.createForm = false
            this.selectedItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
            this.openItemForm = true
        },
        openCreateItem() {
            this.createForm = true
            this.openItemForm = true
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
    max-height: 60vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px; 
}
.v-data-table__wrapper thead tr {
    position: sticky; 
    top: 0;
    z-index: 10;
}
</style>
