<template>
    <v-card>
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div>
                <div>
                    <v-toolbar
                        flat
                        :loading="loading"
                        color="#eeeeee"
                        :style="{ border: '#e0e0e0 1px solid' }"
                    >
                        <v-btn
                            outlined
                            class="mr-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            Today
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-select
                            v-model="type"
                            :items="types"
                            hide-details
                            solo
                            outline
                            flat
                            placeholder="Status"
                            :style="{ 'max-width': '150px' }"
                            class="mx-4"
                        />
                    </v-toolbar>
                </div>
            </div>
            <div>
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-overlap-mode="'stack'"
                    :event-overlap-threshold="0"
                    :event-more="false"
                    type="month"
                    :event-height="-1"
                    @click:event="showEvent"
                    @change="setEvents"
                >
                    <template slot="dayMonth" slot-scope="{ day }">
                        <div class="day">dayHeader slot {{ day }}</div>
                    </template>
                    <template #event="{event}">
                        <div class="pa-1">
                            <p class="my-0 text-wrap text-body-1">
                                {{ event.quote.client.name }}
                            </p>
                            <p class="my-0 text-wrap text-body-2">
                                {{ event.name }}
                            </p>
                        </div>
                        <div class="d-flex justify-end pt-0 px-1 pb-0">
                            <p class="text-body-1">
                                {{ formatNumber(event.quote) }}
                            </p>
                        </div>
                    </template>
                </v-calendar>
            </div>
        </v-card-text>
        <!--QUOTE-->
        <v-dialog :retain-focus="false" v-model="openQuoteDialog" fullscreen>
            <v-card v-if="openQuoteDialog" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <Quote
                        :id="selectedQuote.id"
                        :quote="selectedQuote"
                        :settings="settings"
                        :history="!!selectedQuote.archive"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'QuoteSchedule',
    props: {
        quotes: {
            type: Array,
            required: true,
        },
        settings: {
            type: Array,
            required: true,
        },
    },
    components: {
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data: () => ({
        selectedQuote: {},
        openQuoteDialog: false,
        focus: '',
        events: [],
        types: ['ALL', 'BIDING', 'APPROVAL'],
        type: 'ALL',
        loading: false,
        filterQuotes: [],
        colors: {
            open: '#1976d2',
            approval: '#ff9800',
        },
        heightPopUp: 0,
    }),
    watch: {
        type() {
            try {
                this.filterQuotes = this.quotes.filter(quote => {
                    if (this.type == 'ALL') {
                        return true
                    } else if (
                        this.type == 'BIDING' &&
                        quote.status == 'OPEN'
                    ) {
                        return true
                    } else if (this.type == quote.status) {
                        return true
                    } else return false
                })
                this.setEvents()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
    computed: {},
    mounted() {
        try {
            this.filterQuotes = _.cloneDeep(this.quotes)
            this.setEvents()
            this.heightPopUp = window.innerHeight - 62
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        formatNumber(quote) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: quote.currency,
                maximumFractionDigits: 2,
            }).format(quote.totalCosts ? quote.totalCosts : 0)
        },
        setEvents() {
            let events = []
            for (const quote of this.filterQuotes) {
                if (
                    quote.promiseDate &&
                    (quote.status == 'OPEN' || quote.status == 'APPROVAL')
                ) {
                    const date = moment
                        .unix(
                            quote.promiseDate._seconds ||
                                quote.promiseDate.seconds
                        )
                        .format('YYYY-MM-DD')
                    events.push({
                        name: `(${quote.number})` + ' ' + quote.name,
                        start: date,
                        end: date,
                        color: this.colors[quote.status.toLowerCase()],
                        timed: true,
                        quote,
                    })
                }
            }
            this.events = events
        },
        closeDialog() {
            this.selectedQuote = {}
            this.openQuoteDialog = false
        },
        async showEvent({ event }) {
            try {
                this.selectedQuote = _.cloneDeep(event.quote)
                this.openQuoteDialog = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
