var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"fill-height",attrs:{"color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":_vm.user.tasks,"options":{
            threshold: 0.5,
        }}},[_c('v-card',{staticClass:"ma-0 pa-0",style:({
                'background-color': '#fafafa',
                'border-radius': '10px',
            }),attrs:{"flat":""}},[_c('v-row',{staticClass:"d-flex ma-0 pa-0"},[_c('v-avatar',{staticClass:"ml-3 mt-3 pa-0",attrs:{"size":"28"}},[(_vm.profilePicUrl)?_c('v-img',{attrs:{"src":_vm.profilePicUrl,"alt":_vm.user.name}}):_c('v-icon',{attrs:{"x-large":"","color":"#707070"}},[_vm._v("mdi-account-circle")])],1),_c('div',{staticClass:"ma-0 pa-2 pb-1"},[_c('p',{staticClass:"ma-0 pa-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('p',{staticClass:"ma-0 pa-0 mt-n1"},[_vm._v(" "+_vm._s(_vm.user.role)+" ")])])],1),_c('v-row',{staticClass:"flex-nowrap ma-0 pa-0"},[_c('draggable',{staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({ height: `${_vm.height - 68}px`, width: '100%' }),attrs:{"group":"tasks"},on:{"change":_vm.draggableLog},model:{value:(_vm.user.tasks),callback:function ($$v) {_vm.$set(_vm.user, "tasks", $$v)},expression:"user.tasks"}},_vm._l((_vm.user.tasks),function(task,index){return _c('div',{key:index,staticClass:"list-group-item py-0 mx-0 my-3 px-1"},[(task)?_c('v-sheet',{staticClass:"fill-height",attrs:{"min-height":"100","color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":task,"options":{
                                    threshold: 0.5,
                                }}},[_c('TaskCard',{attrs:{"task":task,"users":_vm.users},on:{"openTaskDetails":_vm.openTaskDetails,"enableLoadAnimation":_vm.enableLoadAnimation}})],1)],1):_vm._e()],1)}),0)],1)],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"640px"},model:{value:(_vm.viewTaskDetails),callback:function ($$v) {_vm.viewTaskDetails=$$v},expression:"viewTaskDetails"}},[(_vm.viewTaskDetails)?_c('TaskDetails',{attrs:{"task":_vm.selectedTask,"users":_vm.users},on:{"closeDialog":_vm.closeTaskDetails,"replaceTask":_vm.replaceTask,"deleteTask":_vm.deleteTask}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }