<template>
    <v-app>
        <v-card v-if="order.id" :loading="loading" height="100%">
            <v-card-title>
                <h2 class="font-weight-regular">
                    Process work order
                    <span class="font-weight-black"
                        >{{ order.code }} | {{ order.project.reference }}
                        {{ order.project.name }}</span
                    >
                </h2>
                <v-spacer />
                <!-- <div class="d-flex">
                    <v-btn small icon color="primary" @click="downloadPDFs">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn small icon color="primary" @click="downloadXLS">
                        <v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-btn small icon color="primary" @click="print">
                        <v-icon>mdi-printer-pos</v-icon>
                    </v-btn>
                </div> -->
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="px-4 mt-5">
                <v-container fluid ref="container" class="pb-0">
                    <v-row no-gutters ref="details">
                        <v-col cols="12" xl="4" lg="4" md="4">
                            <div>
                                <p>
                                    <strong>ID: </strong>
                                    {{ order.code.split('-')[1] }}
                                </p>

                                <p>
                                    <strong>CREATION DATE: </strong
                                    >{{
                                        ` ${formatDate(
                                            order.createdOn.seconds ||
                                                order.createdOn._seconds
                                        )}`
                                    }}
                                </p>
                                <p
                                    v-if="
                                        order.productionEnd &&
                                            (order.status == 'finished' ||
                                                order.status == 'closed')
                                    "
                                >
                                    <strong>PRODUCTION END: </strong
                                    >{{
                                        ` ${formatDate(
                                            order.productionEnd.seconds ||
                                                order.productionEnd._seconds
                                        )}`
                                    }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>CREATED BY: </strong>
                                    {{
                                        ` ${
                                            order.createdBy &&
                                            order.createdBy.name
                                                ? order.createdBy.name
                                                : order.createdBy
                                        }`
                                    }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>PROJECT MANAGER:</strong
                                    >{{
                                        ` ${order.project.projectManager.name}`
                                    }}
                                </p>
                                <div class="d-flex">
                                    <div class="d-flex align-center">
                                        <strong>ATTACHED: </strong>
                                    </div>
                                    <div class="d-flex align-center flex-wrap">
                                        <v-btn
                                            color="grey lighten-3"
                                            elevation="0"
                                            x-small
                                            class="ma-1 text-capitalize grey--text text--darken-1  "
                                            v-for="(file,
                                            i) in order.attachments"
                                            :key="i"
                                        >
                                            <span
                                                @click="openFileAttached(file)"
                                                >{{ file }}</span
                                            >
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            class="pl-xl-2 pl-lg-2 pl-md-2"
                        >
                            <v-textarea
                                v-model="order.notes"
                                prepend-icon="mdi-text"
                                prefix="Notes: "
                                hide-details
                                rows="1"
                                :disabled="true"
                            >
                            </v-textarea>
                        </v-col>
                        <v-spacer />
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            class="d-flex justify-end"
                        >
                            <canvas id="qr"></canvas>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="12">
                            <v-data-table
                                id="virtual-scroll-table"
                                :headers="headers"
                                :items="filterWorkOrders"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                :loading="loading"
                                disable-pagination
                                hide-default-footer
                                :style="{ height: `${height}` }"
                            >
                                <template v-slot:top>
                                    <v-row
                                        no-gutter
                                        :style="{
                                            'background-color': '#eeeeee',
                                        }"
                                        class="mt-0 mx-0"
                                    >
                                        <v-col cols="4" class="d-flex">
                                            <h2 class="mx-4 mt-1">ITEMS</h2>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                    </v-row>
                                </template>
                                <!-- HEADERS -->
                                <template
                                    v-slot:[`header.partNumber`]="{ header }"
                                >
                                    <v-text-field
                                        class="py-2 mt-2"
                                        :label="header.text"
                                        v-model="partNumberToFilter"
                                        dense
                                        :style="{ width: '150px' }"
                                    />
                                </template>
                                <!--ITEMS-->
                                <template v-slot:[`item.id`]="{ index }">
                                    <p class="my-0">
                                        {{ index + 1 }}
                                    </p>
                                </template>
                                <template v-slot:[`item.mass`]="{ item }">
                                    <p class="my-0">
                                        {{ item.mass.toFixed(2) }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.paintingArea`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{ item.paintingArea.toFixed(2) }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.finishAndUCCode`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{
                                            item.dataFinishAndUCCode &&
                                                item.dataFinishAndUCCode.name
                                        }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.description`]="{ item }"
                                >
                                    <p
                                        class="my-0"
                                        :style="{ 'max-width': '250px' }"
                                    >
                                        {{ item.description }}
                                    </p>
                                </template>

                                <template v-slot:[`item.notes`]="{ item }">
                                    <p
                                        class="my-0"
                                        :style="{ 'max-width': '250px' }"
                                    >
                                        {{ item.notes }}
                                    </p>
                                </template>
                                <!-- <template v-slot:[`item.files`]="{ item }">
                                    <div
                                        class="d-flex justify-center my-0"
                                        v-if="item.files != undefined"
                                    >
                                        <a
                                            v-if="
                                                item.files.includes(
                                                    `${item.partNumber}.pdf`
                                                )
                                            "
                                            class="mx-1"
                                            @click="loadFiles(item, 'pdf')"
                                        >
                                            PDF
                                        </a>
                                        <a
                                            v-if="
                                                item.files.includes(
                                                    `${item.partNumber}.dxf`
                                                )
                                            "
                                            class="mx-1"
                                            @click="loadFiles(item, 'dxf')"
                                        >
                                            DXF
                                        </a>
                                        <a
                                            v-if="
                                                item.files.includes(
                                                    `${item.partNumber}.stp`
                                                )
                                            "
                                            class="mx-1"
                                            @click="loadFiles(item, 'stp')"
                                        >
                                            STP
                                        </a>
                                    </div>
                                -->
                                <!--FOOTER-->
                                <template v-slot:[`body.append`]>
                                    <tr>
                                        <td
                                            class="font-weight-bold text-center"
                                        ></td>
                                        <td
                                            class="font-weight-bold text-center"
                                        >
                                            TOTAL
                                        </td>
                                        <td class="font-weight-bold"></td>
                                        <td
                                            class="font-weight-bold text-center"
                                        >
                                            {{ totalMass.toFixed(2) }} (kgs)
                                        </td>
                                        <td
                                            class="font-weight-bold text-center"
                                        >
                                            {{ totalPaintingArea.toFixed(2) }}
                                            (m2)
                                        </td>
                                        <td class="font-weight-bold"></td>
                                        <td
                                            class="font-weight-bold text-center"
                                        >
                                            {{ totalQuantity }}
                                        </td>
                                        <td class="font-weight-bold"></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <!--ALERT-->
                    <v-alert
                        v-if="activateAlert"
                        type="success"
                        color="primary"
                        :style="{
                            position: 'absolute',
                            right: '0px',
                            bottom: '0px',
                        }"
                    >
                        <v-row no-gutters>
                            <v-col cols="11">
                                <p class="my-0">{{ alertMessage }}</p>
                            </v-col>
                            <v-col cols="1">
                                <v-btn
                                    small
                                    icon
                                    class="mt-n1"
                                    @click="activateAlert = false"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-container>
            </v-card-text>
            <!--Error Alert-->
            <Errors />
        </v-card>
    </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import qrious from 'qrious'
import { openFile } from '@/services/storage/openFile.js'

export default {
    name: 'PublicWorkOrder',
    props: {
        orderId: {
            type: String,
            required: true,
        },
        code: {
            type: String,
            required: true,
        },
        companyId: {
            type: String,
            required: true,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
    },
    data: () => ({
        order: {},
        loading: false,
        alertMessage: '',
        activateAlert: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'left',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PAINTING AREA',
                value: 'paintingArea',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
                width: 200,
            },
            // {
            //     text: 'FILES',
            //     value: 'files',
            //     align: 'center',
            //     sortable: false,
            // },
        ],
        height: 0,
        rules: {
            required: v => !!v || 'The value is required',
        },
        partNumberToFilter: undefined,
    }),
    computed: {
        filterWorkOrders() {
            let conditions = []
            let items = this.order.items ? this.order.items : []
            if (this.partNumberToFilter) {
                conditions.push(this.filterByPartNumber)
            }
            if (conditions.length > 0) {
                return items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return items
        },
        totalMass() {
            return this.order.items
                ? this.order.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.mass * item.quantity,
                      0
                  )
                : 0
        },
        totalPaintingArea() {
            return this.order.items
                ? this.order.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.paintingArea * item.quantity,
                      0
                  )
                : 0
        },
        totalQuantity() {
            return this.order.items
                ? this.order.items.reduce(
                      (accumulator, item) => accumulator + item.quantity,
                      0
                  )
                : 0
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.order = await API.findWorkOrderByCode(
                this.companyId,
                undefined,
                this.code
            )
            // qr code
            new qrious({
                element: document.getElementById('qr'),
                value: `${process.env.VUE_APP_CLIENT_HOST}/work-orders/main-work-orders?orderId=${this.order.id}&companyId=${this.companyId}&code=${this.order.code}`,
            })
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/workOrders/${this.order.id}/attachments`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            this.height = '60vh'
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        filterByPartNumber(item) {
            return item.partNumber
                .toLowerCase()
                .includes(this.partNumberToFilter.toLowerCase())
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.selected-item {
    background-color: #dff0ff;
}
</style>
