<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Set Processes
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text>
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :style="{
                        'max-height': `${height}px`,
                        'overflow-y': 'auto',
                    }"
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            :style="{ 'background-color': '#eeeeee' }"
                        >
                            <v-col cols="6" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                            <v-col cols="6" class="d-flex justify-end"
                                ><v-btn
                                    :loading="loading"
                                    color="primary"
                                    rounded
                                    class="mb-3 mr-3"
                                    @click="openProcessesDialog"
                                >
                                    SELECT PROCESSES
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <!--HEADERS-->
                    <template v-slot:[`header.selected`]="{ header }">
                        <div class="d-flex align-center justify-center">
                            <p class="ma-0 pr-2">{{ header.text }}</p>
                            <v-simple-checkbox
                                color="primary"
                                v-model="selectAll"
                                class="d-flex align-center justify-center"
                                @click="selectAllItems"
                            ></v-simple-checkbox>
                        </div>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                            @input="verifySelected"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.productionInfo`]="{ item }">
                        <div
                            v-for="process in item.productionInfo"
                            :key="process.id"
                        >
                            <p class="my-0 text-left">
                                {{ process.name }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.newProcesses`]="{ item }">
                        <div
                            v-for="process in itemsProcesses"
                            :key="process.id"
                        >
                            <p class="my-0 text-left" v-if="item.selected">
                                {{ process.name }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.selected`]="{ item }">
                        <div class="d-flex justify-center">
                            <v-simple-checkbox
                                color="primary"
                                v-model="item.selected"
                                @click="verifySelected"
                                :disabled="processesValidation(item)"
                            ></v-simple-checkbox>
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton() || !itemsProcesses.length > 0"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>

        <!--ARRANGE PROCESSES-->
        <v-dialog
            :retain-focus="false"
            v-model="processesDialog"
            persistent
            max-width="400px"
        >
            <ArrangeProcesses
                v-if="processesDialog"
                :settingsProcesses="processes"
                :multipleItemSelect="true"
                :itemsProcesses="itemsProcesses"
                @closeDialog="closeProcessesDialog"
                @saveProcesses="saveProcesses"
            />
        </v-dialog>

        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Items Selected
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="workOrder.items.filter(i => i.selected)"
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    :style="{ 'background-color': '#eeeeee' }"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.itemsProcesses`]="{}">
                                <div
                                    v-for="process in itemsProcesses"
                                    :key="process.id"
                                >
                                    <p class="my-0 text-left">
                                        {{ process.name }}
                                    </p>
                                </div>
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.qtyToSend }}</p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        rounded
                        :disabled="!valid"
                        :loading="loading"
                        @click="save"
                        class="mr-2"
                    >
                        SAVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'MultipleSend',
    components: {
        ArrangeProcesses: () =>
            import('@/components/WorkOrders/ArrangeProcesses.vue'),
    },
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        processes: { type: Object, required: true },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'OLD PROCESSES',
                value: 'productionInfo',
                align: 'left',
                sortable: false,
            },
            {
                text: 'NEW PROCESSES',
                value: 'newProcesses',
                align: 'left',
                sortable: false,
            },
            {
                text: 'SELECTED',
                value: 'selected',
                align: 'center',
                sortable: false,
            },
        ],
        confirmHeaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PROCESSES',
                value: 'itemsProcesses',
                align: 'left',
                sortable: false,
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },
        confirmDialog: false,
        processesDialog: false,
        itemsProcesses: [],
        height: 0,
        selectAll: false,
        partNumberToFilter: undefined,
    }),
    computed: {
        filteredItems() {
            let conditions = []

            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }

            if (conditions.length > 0) {
                return this.workOrder.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.workOrder.items
        },
    },
    mounted() {
        try {
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async save() {
            try {
                this.loading = true
                const itemsToUpdate = this.workOrder.items
                    .filter(i => i.selected)
                    .map(i => ({ id: i.id }))
                await API.setBatchProcesses({
                    workOrderId: this.workOrder.id,
                    items: itemsToUpdate,
                    processes: this.itemsProcesses.map(process => process.id),
                    deliveryOptions: this.itemsProcesses.map(process => ({
                        id: process.id,
                        cancelSend: process.cancelSend || false,
                    })),
                    settingProcessesId: this.processes.id,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        openProcessesDialog() {
            this.processesDialog = true
        },

        closeProcessesDialog() {
            this.processesDialog = false
        },

        saveProcesses(processes) {
            this.itemsProcesses = processes
            if (this.workOrder.assemblyWorkOrder) {
                this.workOrder.items.forEach(item => {
                    if (item.processes[0] != this.itemsProcesses[0].id) {
                        item.selected = false
                    }
                })
            }

            this.closeProcessesDialog()
        },

        close() {
            this.workOrder.items.forEach(item => {
                delete item.qtyToSend
            })
            this.$emit('closeDialog')
        },

        disableButton() {
            return !this.workOrder.items.find(item => item.selected)
        },
        onResize() {
            this.height = window.innerHeight - 260
        },
        verifySelected() {
            if (this.filteredItems.length > 0) {
                this.selectAll = !this.filteredItems.find(
                    i => i.selected != true
                )
            } else {
                this.selectAll = false
            }
        },
        selectAllItems() {
            const items = this.filteredItems.map(i => i.id)
            this.workOrder.items.forEach(i => {
                if (items.includes(i.id)) {
                    if (this.workOrder.assemblyWorkOrder == true) {
                        if (
                            i.assemblyWorkOrders &&
                            i.assemblyWorkOrders.length > 0
                        ) {
                            if (
                                this.itemsProcesses &&
                                this.itemsProcesses.length > 0 &&
                                i.processes[i.processes.length - 1] ==
                                    this.itemsProcesses[this.itemsProcesses - 1]
                                        .id
                            ) {
                                i.selected = this.selectAll
                            }
                        } else {
                            i.selected = this.selectAll
                        }
                    } else if (this.workOrder.assemblyWorkOrder == false) {
                        if (i.assemblyWorkOrders) {
                            if (
                                this.itemsProcesses &&
                                this.itemsProcesses.length > 0 &&
                                i.processes[i.processes.length - 1] ==
                                    this.itemsProcesses[
                                        this.itemsProcesses.length - 1
                                    ].id
                            ) {
                                i.selected = this.selectAll
                            }
                        } else {
                            i.selected = this.selectAll
                        }
                    } else {
                        i.selected = this.selectAll
                    }
                }
            })
            this.workOrder.items = _.cloneDeep(this.workOrder.items)
        },
        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },
        processesValidation(item) {
            if (this.workOrder.assemblyWorkOrder == true) {
                return (
                    (this.itemsProcesses &&
                        this.itemsProcesses.length > 0 &&
                        item.processes[0] != this.itemsProcesses[0].id) ||
                    this.itemsProcesses.length == 0
                )
            } else if (this.workOrder.assemblyWorkOrder == false) {
                if (
                    item.assemblyWorkOrders &&
                    item.assemblyWorkOrders.length > 0
                ) {
                    return (
                        (this.itemsProcesses &&
                            this.itemsProcesses.length > 0 &&
                            item.processes[item.processes.length - 1] !=
                                this.itemsProcesses[
                                    this.itemsProcesses.length - 1
                                ].id) ||
                        this.itemsProcesses.length == 0
                    )
                } else {
                    return !this.itemsProcesses.length > 0
                }
            } else {
                return !this.itemsProcesses.length > 0
            }
        },
    },
}
</script>

<style></style>
