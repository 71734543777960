<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab
                :to="{
                    name: 'stock',
                }"
            >
                STOCK
            </v-tab>
            <v-tab
                :to="{
                    name: 'request',
                }"
            >
                CODE REQUEST
            </v-tab>
            <v-tab
                :to="{
                    name: 'reserves',
                }"
            >
                RESERVES
            </v-tab>
            <v-tab
                :to="{
                    name: 'boms-delivered',
                }"
            >
                OPEN BOM
            </v-tab>
            <v-tab
                :to="{
                    name: 'requested-boms',
                }"
            >
                OPEN ORDERS
            </v-tab>
            <v-tab
                :to="{
                    name: 'closed-boms',
                }"
            >
                CLOSED BOMS
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'StockRoom',
    data: () => ({
        loading: true,
        height: 0,
    }),
    mounted() {
        this.onResize()
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = window.innerHeight - containerHeight - tabsHeight - 25
        },
    },
}
</script>

<style></style>
