<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-0"
        ref="container"
        :style="
            `height:${
                this.$router.currentRoute.name == 'assign' ? '89vh' : '86vh'
            };`
        "
    >
        <v-row ref="followUpHeader" class="d-flex">
            <v-col class="ml-5 my-0 pa-0 d-flex align-center">
                <v-btn
                    v-if="!history"
                    rounded
                    depressed
                    color="primary"
                    class="white--text"
                    @click="openTaskFormDialog"
                    hide-details
                >
                    <v-icon left>mdi-plus</v-icon>
                    <span>New</span>
                </v-btn>
                <v-card-title :style="{ 'max-width': '220px' }">
                    <v-combobox
                        v-model="filteredUsers"
                        :items="users"
                        item-text="name"
                        item-value="id"
                        label="Users"
                        flat
                        solo
                        multiple
                        text
                        hide-details
                    >
                        <template v-slot:selection="{ index }">
                            <div v-if="index === 0" class="mr-6">
                                <span>Users</span>
                            </div>
                            <v-badge
                                v-if="index === 0"
                                :content="filteredUsers.length"
                                :value="users"
                                color="primary"
                                overlap
                                class="mt-n2"
                            >
                            </v-badge>
                        </template>
                    </v-combobox>
                </v-card-title>
                <v-card-title :style="{ 'max-width': '220px' }">
                    <v-combobox
                        v-model="filteredRoles"
                        :items="roles"
                        label="Roles"
                        flat
                        solo
                        multiple
                        text
                        hide-details
                    >
                        <template v-slot:selection="{ index }">
                            <div v-if="index === 0" class="mr-6">
                                <span>Roles</span>
                            </div>
                            <v-badge
                                v-if="index === 0"
                                :content="filteredRoles.length"
                                :value="roles"
                                color="primary"
                                overlap
                                class="mt-n2"
                            >
                            </v-badge>
                        </template>
                    </v-combobox>
                </v-card-title>
            </v-col>
        </v-row>
        <v-progress-linear v-if="loading" color="primary" indeterminate />
        <v-divider class="mb-2"></v-divider>
        <v-card
            flat
            :class="{
                'overflow-x-auto': $vuetify.breakpoint.smAndDown,
                '': $vuetify.breakpoint.mdAndUp,
            }"
        >
            <div>
                <v-row class="flex-nowrap ma-0">
                    <v-col
                        class="pl-2 pr-0 py-0 ma-0"
                        v-for="user in filtered"
                        :key="user.id"
                        cols="8"
                        xl="3"
                        lg="3"
                        md="5"
                    >
                        <UserDraggable
                            :height="height"
                            :user="user"
                            :users="users"
                            @deleteTask="deleteTask"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- task  -->
        <v-dialog
            :retain-focus="false"
            v-model="openTaskForm"
            persistent
            max-width="640px"
        >
            <TaskForm
                v-if="openTaskForm"
                :task="selectedTask"
                :originalTask="selectedTask"
                :createForm="createForm"
                :projectId="projectId"
                :quoteId="quoteId"
                @closeDialog="closeTaskFormDialog"
                @replaceTask="replaceTask"
                @addTask="addTask"
                @openEditDialog="openEditDialog"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/services/api'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import UserDraggable from '@/components/Tasks/UserDraggable.vue'
import TaskForm from '@/components/Tasks/TaskForm.vue'

export default {
    name: 'MainBoard',
    components: {
        UserDraggable,
        TaskForm,
    },
    props: {
        quoteId: String,
        projectId: String,
        projectCollaborators: {
            type: Array,
            default: () => [],
        },
        avoidEmptyUsers: Boolean,
        history: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            errorMsg: null,
            error: null,
            dialogQuote: false,
            createDialog: false,
            value: null,
            users: [],
            roles: [],
            filteredRoles: [],
            filteredUsers: [],
            folderLogo: 'company_logo',
            height: 0,
            heightPopUp: 0,
            openTaskForm: false,
            tasks: [],
            viewTaskDetails: false,
            selectedTask: {},
            createForm: false,
            user: JSON.parse(localStorage.getItem('userId')),
            creatingFlag: true,
            status: [],
        }
    },
    provide() {
        return {
            enableLoadAnimation: this.enableLoadAnimation,
        }
    },
    computed: {
        ...mapGetters(['getTaskInfo']),
        filtered: function() {
            let firstFilter =
                this.filteredUsers.length > 0 ? this.filteredUsers : this.users
            if (this.avoidEmptyUsers)
                firstFilter = firstFilter.filter(user => {
                    return user.tasks.length > 0
                })
            const secondFilter =
                this.filteredRoles.length > 0
                    ? firstFilter.filter(user => {
                          return this.filteredRoles.includes(user.role)
                      })
                    : firstFilter
            return secondFilter.sort((a, b) => {
                return b.tasks.length - a.tasks.length
            })
        },
    },
    watch: {
        getTaskInfo(ArchiveTask) {
            if (ArchiveTask) {
                this.deleteTask(ArchiveTask.userId, ArchiveTask.taskId)
            }
        },
    },
    methods: {
        ...mapActions(['setFromMainBoardAction']),
        ...mapMutations(['setErrorItems']),
        deleteTask(userId, taskId) {
            const index = this.users.findIndex(user => user.id == userId)
            if (index >= 0) {
                const updatedTasks = this.users[index].tasks.filter(
                    task => task.id != taskId
                )
                this.users.splice(index, 1, {
                    ...this.users[index],
                    tasks: updatedTasks,
                })
            }
            this.users = [...this.users]
        },
        enableLoadAnimation(state) {
            this.loading = state
        },
        replaceTask(task) {
            this.selectedTask = { ...this.selectedTask, ...task }
            let date = new Date(this.selectedTask.startDate)
            this.selectedTask.startDate = {
                seconds: date.getTime() / 1000,
            }
            date = new Date(this.selectedTask.expectedDate)
            this.selectedTask.expectedDate = {
                seconds: date.getTime() / 1000,
            }
            const userIndex = this.users.findIndex(
                user => user.id === this.selectedTask.assignedTo
            )
            if (userIndex >= 0) {
                const taskIndex = this.users[userIndex].tasks.findIndex(
                    task => task.id === this.selectedTask.id
                )
                if (taskIndex >= 0) {
                    this.users[userIndex].tasks.splice(taskIndex, 1, {
                        ...this.users[userIndex].tasks[taskIndex],
                        ...this.selectedTask,
                    })
                    this.users.splice(userIndex, 1, this.users[userIndex])
                }
            }
        },
        addTask(task) {
            let date = new Date(task.startDate)
            task.startDate = {
                seconds: date.getTime() / 1000,
            }
            date = new Date(task.expectedDate)
            task.expectedDate = {
                seconds: date.getTime() / 1000,
            }
            const userIndex = this.users.findIndex(
                user => user.id === task.assignedTo
            )
            if (userIndex >= 0) {
                this.users.splice(userIndex, 1, {
                    ...this.users[userIndex],
                    tasks: [...this.users[userIndex].tasks, task],
                })
            }
        },
        openEditDialog() {
            this.createForm = false
            this.openTaskForm = true
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                followUpHeader: { clientHeight: headerHeight },
            } = this.$refs
            this.height = containerHeight - headerHeight + 19
            this.heightPopUp = window.innerHeight - 62
        },
        closeDialog() {
            this.dialogQuote = false
        },
        openTaskFormDialog: function() {
            this.createForm = true
            this.openTaskForm = true
        },
        closeTaskFormDialog: function() {
            this.createForm = false
            this.openTaskForm = false
        },
    },
    async created() {
        try {
            this.loading = true
            // get users
            let {
                data: { users },
            } = await API.getUsers()
            // filter user
            if (this.projectCollaborators.length > 0) {
                for (const colaborator of this.projectCollaborators) {
                    const user = users.find(u => u.id == colaborator)
                    if (user) {
                        this.users.push(user)
                    }
                }
            } else {
                this.users = users
            }
            const tasks = await API.getAssignedTasks(
                this.projectId,
                this.quoteId
            )
            tasks.forEach(task => {
                const index = this.users.findIndex(user => {
                    return user.id == task.assignedTo
                })
                if (index > -1) {
                    this.users[index].tasks = this.users[index].tasks
                        ? this.users[index].tasks
                        : []
                    this.users[index].tasks.push(task)
                }
            })
            let rolesAux = {}
            this.users.forEach((user, i) => {
                rolesAux[user.role] = user
                if (user.tasks) {
                    this.users[i].tasks.sort(function(a, b) {
                        return a.index - b.index
                    })
                } else {
                    user.tasks = []
                }
            })
            this.roles = Object.keys(rolesAux)
            this.tasks = tasks
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
}
</script>

<style scoped>
.borderRounded {
    border-radius: 10px;
}
.banner >>> .v-banner__wrapper {
    border-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
</style>
