<template>
    <v-card>
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div>
                <div>
                    <v-toolbar
                        flat
                        :loading="loading"
                        color="#eeeeee"
                        :style="{ border: '#e0e0e0 1px solid' }"
                        class="d-flex flex-wrap"
                    >
                        <v-btn
                            outlined
                            class="mr-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            Today
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-select
                            v-model="type"
                            :items="types"
                            hide-details
                            solo
                            outline
                            flat
                            placeholder="Status"
                            :style="{ 'max-width': '150px' }"
                            class="mx-4"
                        />
                        <v-text-field
                            v-model="project"
                            hide-details
                            placeholder="Project"
                            outline
                            flat
                            solo
                        />
                        <v-combobox
                            label="Users"
                            v-model="selectedUsers"
                            :items="users"
                            item-text="name"
                            chips
                            multiple
                            outline
                            flat
                            solo
                            class="mt-7 mx-4"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                    <span>{{ item.name }}</span>
                                    <v-btn small icon @click="removeUser(item)"
                                        ><v-icon
                                            >mdi-close-circle</v-icon
                                        ></v-btn
                                    >
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="text-grey text-caption align-self-center"
                                >
                                    (+{{ `${numberOfUsers() - 1}` }}
                                    other/s)
                                </span>
                            </template>
                        </v-combobox>
                        <v-text-field
                            v-model="purchaseNumber"
                            hide-details
                            label="Number"
                            outline
                            flat
                            solo
                        />
                        <v-combobox
                            label="Suppliers"
                            v-model="selectedSuppliers"
                            :items="suppliers"
                            item-text="name"
                            chips
                            multiple
                            outline
                            flat
                            solo
                            class="mt-7 mx-4"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                    <span>{{ item.name }}</span>
                                    <v-btn
                                        small
                                        icon
                                        @click="removeSupplier(item)"
                                        ><v-icon
                                            >mdi-close-circle</v-icon
                                        ></v-btn
                                    >
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="text-grey text-caption align-self-center"
                                >
                                    (+{{ `${numberOfSuppliers() - 1}` }}
                                    other/s)
                                </span>
                            </template>
                        </v-combobox>
                    </v-toolbar>
                </div>
            </div>
            <div>
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-overlap-mode="'stack'"
                    :event-overlap-threshold="0"
                    :event-more="false"
                    type="month"
                    :event-height="-1"
                    @click:event="showEvent"
                    @change="setEvents"
                >
                    <template slot="dayMonth" slot-scope="{ day }">
                        <div class="day">dayHeader slot {{ day }}</div>
                    </template>
                    <template #event="{event}">
                        <div class="pa-2 mt-2">
                            <p class="my-0 text-wrap text-body-1">
                                {{ event.name }}
                            </p>
                            <p class="my-0 text-wrap text-body-2">
                                Project:
                                {{ event.purchase.project.reference }} -
                                {{ event.purchase.project.name }}
                            </p>
                            <p
                                v-for="(item, i) in event.items"
                                :key="i"
                                class="my-0 text-wrap text-body-3"
                            >
                                -
                                {{ `(${item.quantity}) ` }}
                                {{
                                    event.purchase.type == 'material'
                                        ? `${item.code} `
                                        : ''
                                }}

                                {{ item.description }}
                            </p>
                        </div>
                        <div class="d-flex justify-end pt-0 px-1 pb-1 mb-n2">
                            <p class="pt-5 text-body-1">
                                {{
                                    formatNumber(calculateTotal(event.purchase))
                                }}
                            </p>
                        </div>
                    </template>
                </v-calendar>
            </div>
        </v-card-text>
        <!--Request Form-->
        <v-dialog persistent fullscreen v-model="openForm">
            <PurchaseRequest
                v-if="openForm"
                :request="selectedRequest"
                :originalRequest="originalRequest"
                :createForm="false"
                @closeDialog="closeRequestForm"
                @replaceRequest="replaceRequest"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'

export default {
    name: 'PurchaseSchedule',
    components: {
        PurchaseRequest: () =>
            import('@/components/Purchasing/PurchaseRequest.vue'),
    },
    props: {
        purchases: {
            type: Array,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        suppliers: [],
        selectedSuppliers: [],
        purchaseNumber: '',
        selectedUsers: [],
        selectedRequest: {},
        originalRequest: {},
        openForm: false,
        project: '',
        focus: '',
        events: [],
        types: [
            'ALL',
            'inProgress',
            'onHold',
            'pricing',
            'preApproval',
            'approval',
            'purchasing',
            'inTransit',
            'received',
            'rejected',
        ],
        type: 'ALL',
        loading: false,
        colors: Object.freeze({
            inProgress: 'primary',
            onHold: 'green',
            pricing: '#3293e6',
            preApproval: '#FF9800',
            approval: '#27BC4F',
            purchasing: 'primary',
            inTransit: '#ff7800',
            received: '#00a135',
            rejected: 'red',
        }),
    }),
    watch: {
        filterPurchases() {
            this.setEvents()
        },
    },
    computed: {
        filterPurchases() {
            let conditions = []
            if (this.type) {
                conditions.push(this.filterByType)
            }
            if (this.project) {
                conditions.push(this.filterByProject)
            }
            if (this.selectedUsers.length > 0) {
                conditions.push(this.filterByUsers)
            }
            if (this.purchaseNumber) {
                conditions.push(this.filterByNumber)
            }
            if (this.selectedSuppliers.length > 0) {
                conditions.push(this.filterBySuppliers)
            }
            if (conditions.length > 0) {
                return this.purchases.filter(purchase => {
                    return conditions.every(condition => {
                        return condition(purchase)
                    })
                })
            }
            return this.purchases
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.suppliers = await API.getSuppliers()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        numberOfSuppliers() {
            var quantity = 0
            this.selectedSuppliers.forEach(selectedSupplier => {
                if (
                    this.suppliers.find(
                        supplier =>
                            supplier.id ==
                            (typeof selectedSupplier === 'string'
                                ? selectedSupplier
                                : selectedSupplier.id)
                    )
                ) {
                    quantity += 1
                }
            })
            return quantity
        },
        filterBySuppliers(purchase) {
            return !!this.selectedSuppliers.find(supplier => {
                for (let item of purchase.items) {
                    const quote = item.quotes.find(quote => quote.able)
                    if (quote.supplier.id == supplier.id) {
                        return true
                    }
                }
                return false
            })
        },
        filterByNumber(purchase) {
            return purchase.number.toString().includes(this.purchaseNumber)
        },
        removeUser(selectedUser) {
            this.selectedUsers = this.selectedUsers.filter(
                user => user.id != selectedUser.id
            )
        },
        numberOfUsers() {
            var quantity = 0
            this.selectedUsers.forEach(selectedUser => {
                if (
                    this.users.find(
                        user =>
                            user.id ==
                            (typeof selectedUser === 'string'
                                ? selectedUser
                                : selectedUser.id)
                    )
                ) {
                    quantity += 1
                }
            })
            return quantity
        },
        filterByUsers(purchase) {
            return !!this.selectedUsers.find(user => {
                return (
                    user.id == purchase.buyer ||
                    user.id == purchase.createdBy.id ||
                    user.id == purchase.projectManager.id
                )
            })
        },
        replaceRequest(request) {
            this.$emit('replace', request)
        },
        closeRequestForm() {
            this.openForm = false
            this.selectedRequest = {}
            this.originalRequest = {}
        },
        filterByType(purchase) {
            if (this.type == 'ALL') {
                return true
            } else return this.type == purchase.status
        },
        filterByProject(purchase) {
            let projectName =
                `${purchase.project.reference}`.toLowerCase() +
                ' ' +
                purchase.project.name.toLocaleLowerCase()
            return projectName.includes(this.project.toLowerCase())
        },
        calculateTotal(request) {
            let calculatedTotal = 0
            request.items = request.items ? request.items : []
            request.items.forEach(i => {
                i.quotes = i.quotes ? i.quotes : []
                i.quotes.forEach(q => {
                    if (q.able) {
                        calculatedTotal += i.quantity * q.price
                    }
                })
            })
            return calculatedTotal
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE').format(value)
        },
        setEvents() {
            let events = []
            for (const purchase of this.filterPurchases) {
                if (purchase.items && purchase.items.length > 0) {
                    for (const item of purchase.items) {
                        if (item.purchaseOrder) {
                            const date =
                                typeof item.purchaseOrder.dueDate == 'string'
                                    ? item.purchaseOrder.dueDate.slice(0, 10)
                                    : moment
                                          .unix(
                                              item.purchaseOrder.dueDate
                                                  ._seconds ||
                                                  item.purchaseOrder.dueDate
                                                      .seconds
                                          )
                                          .format('YYYY-MM-DD')
                            const event = events[events.length - 1]
                            if (
                                event &&
                                event.start == date &&
                                event.purchase.id == purchase.id
                            ) {
                                event.items.push(item)
                            } else {
                                events.push({
                                    name:
                                        `(${purchase.number}) ` + purchase.type,
                                    start: date,
                                    end: date,
                                    color: this.colors[purchase.status],
                                    timed: true,
                                    purchase,
                                    purchaseItem: item,
                                    items: [item],
                                })
                            }
                        }
                    }
                }
            }
            this.events = events
        },
        async showEvent({ event }) {
            try {
                this.selectedRequest = _.cloneDeep(event.purchase)
                this.originalRequest = _.cloneDeep(event.purchase)
                this.openForm = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
