var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary","rounded":"","loading":_vm.loading,"disabled":!_vm.valid || Object.keys(_vm.milestoneDiff).length < 2},on:{"click":_vm.saveChanges}},[_vm._v(" SAVE ")])],1),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('v-card-text',{class:{
            'height-sm': _vm.$vuetify.breakpoint.smAndDown,
            'height-md': !_vm.$vuetify.breakpoint.smAndDown,
        },style:({ 'overflow-y': 'auto' })},[_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-form',{staticClass:"mt-2 mb-0",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","cols":"12"}},[_c('v-col',{staticClass:"mx-0 mt-3",attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-0",attrs:{"label":"Milestone Date*","prepend-icon":"mdi-calendar","readonly":"","required":"","hide-details":"","rules":[_vm.rules.required]},model:{value:(_vm.milestone.date),callback:function ($$v) {_vm.$set(_vm.milestone, "date", $$v)},expression:"milestone.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.milestone.date),callback:function ($$v) {_vm.$set(_vm.milestone, "date", $$v)},expression:"milestone.date"}})],1)],1),_c('v-col',{staticClass:"ma-0 d-flex align-end",attrs:{"cols":"12","sm":"9"}},[_c('v-slider',{staticClass:"ma-0 ml-3",class:{
                                    'mt-12': _vm.$vuetify.breakpoint.smAndDown,
                                    'mt-0': !_vm.$vuetify.breakpoint.smAndDown,
                                },attrs:{"step":"5","show-ticks":"always","thick-size":"4","min":0,"max":100,"color":_vm.componentColor(_vm.milestone.percentage),"thumb-label":"always","hide-details":"","label":"Percentage","thumb-size":28},model:{value:(_vm.milestone.percentage),callback:function ($$v) {_vm.$set(_vm.milestone, "percentage", $$v)},expression:"milestone.percentage"}})],1)],1),_c('v-row',{staticClass:"d-flex flex-wrap mt-5 mx-1 align-center"},[_c('h4',{staticClass:"mt-2"},[_vm._v(" Files: ")]),(
                                _vm.milestone.files &&
                                    _vm.milestone.files.length > 0
                            )?_c('div',_vm._l((_vm.localFiles.map(
                                    localFile => localFile.file.name
                                ) || []),function(item,i){return _c('v-btn',{key:i,staticClass:"ma-1 text-capitalize text--darken-2 pa-2",attrs:{"color":"transparent","elevation":"0","small":""}},[_c('span',{staticClass:"col-12 text-truncate",style:({
                                        'font-size': '11px',
                                        color: '#2b81d6',
                                        'text-decoration': 'underline',
                                        cursor: 'pointer',
                                        'max-width': '65vw',
                                        overflow: 'hidden',
                                        'text-overflow': 'ellipsis',
                                        'white-space': 'nowrap',
                                    }),on:{"click":function($event){$event.stopPropagation();return _vm.openFile(item)}}},[_vm._v(_vm._s(item))]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(item)}}},[_vm._v(" mdi-close-circle ")])],1)}),1):_vm._e(),_c('v-btn',{staticClass:"mx-4",style:({ width: '20px', height: '20px' }),attrs:{"depressed":"","fab":"","x-small":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.onButtonClick}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('input',{ref:"milestoneFiles",staticClass:"d-none",attrs:{"type":"file","name":"milestoneFiles"},on:{"change":_vm.onFileChanged}})],1),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","cols":"12"}},[_c('v-textarea',{staticClass:"mx-0",attrs:{"hide-details":"","prepend-icon":"mdi-text","label":"Notes *","rules":[_vm.rules.required],"required":"","rows":"2"},model:{value:(_vm.milestone.notes),callback:function ($$v) {_vm.$set(_vm.milestone, "notes", $$v)},expression:"milestone.notes"}})],1)],1)],1),_c('v-col',{staticClass:"mt-3",class:{
                    'timeline-sm': _vm.$vuetify.breakpoint.smAndDown,
                    'timeline-md': !_vm.$vuetify.breakpoint.smAndDown,
                },style:({ 'overflow-y': 'auto' }),attrs:{"cols":"12","md":"6"}},[_c('v-timeline',{staticClass:"pr-6",attrs:{"dense":"","align-top":""}},_vm._l((_vm.milestone.timeline || []),function(item,index){return _c('div',{key:index},[_c('v-timeline-item',{attrs:{"small":"","color":_vm.componentColor(item.percentage)}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"pa-0 mt-6 ml-n6"})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"text-capitalize font-weight-bold"},[_vm._v(" "+_vm._s(item.percentage)+" % ")]),_c('p',{staticClass:"my-0 py-0"},[_vm._v(" "+_vm._s(_vm.getUserName(item.updatedBy))+" ")]),_c('p',{staticClass:"my-0 py-0"},[_vm._v(" "+_vm._s(_vm.formatDate( item.updatedOn._seconds || item.updatedOn.seconds ))+" ")])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"8"}},[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Notes: ")]),_vm._v(_vm._s(item.notes)+" ")]),_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Milestone Date: ")]),_vm._v(" "+_vm._s(_vm.formatDate2( item.date._seconds || item.date.seconds ))+" ")]),(
                                            _vm.milestone.files &&
                                                _vm.milestone.files.length > 0
                                        )?_c('div',_vm._l((_vm.timelineFiles(
                                                _vm.milestone.timeline,
                                                index
                                            ) || []),function(file,i){return _c('v-btn',{key:i,staticClass:"ma-1 text-capitalize text--darken-2 py-2 px-0",attrs:{"color":"transparent","elevation":"0","small":""}},[_c('span',{staticClass:"col-12 text-truncate px-0 pt-0",style:({
                                                    'font-size': '11px',
                                                    color: '#2b81d6',
                                                    'text-decoration':
                                                        'underline',
                                                    cursor: 'pointer',
                                                    'max-width': '20vw',
                                                    overflow: 'hidden',
                                                    'text-overflow':
                                                        'ellipsis',
                                                    'white-space': 'nowrap',
                                                }),on:{"click":function($event){$event.stopPropagation();return _vm.openFile(file)}}},[_vm._v(_vm._s(file))])])}),1):_vm._e()])],1)],1)],1)}),0)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }