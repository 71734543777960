var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-0",style:({ height: `${_vm.itemsHeight}px` }),attrs:{"id":"virtual-scroll-table","headers":_vm.headers,"items":_vm.bom.items || [],"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","multiple-expand":"","show-expand":_vm.bom.items != undefined && _vm.bom.status == 'approved',"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4",style:({ 'background-color': '#eeeeee' }),attrs:{"no-gutter":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mx-4 my-n3"},[_vm._v("ITEMS")]),_c('v-spacer'),(
                            _vm.BOMSelected.length > 0 &&
                                _vm.validateDeliver &&
                                _vm.deliveryBOMPermission &&
                                _vm.enableOptions &&
                                _vm.partialDelivery
                        )?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","color":"primary"},on:{"click":_vm.openAddNewDelivery}},[_vm._v(" DELIVER ")]):_vm._e()],1)],1)]},proxy:true},{key:`item.index`,fn:function({ index }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:`item.code`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.code))])])]}},{key:`item.description`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.description))])])]}},{key:`item.total`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.quantity)+" ")])])]}},{key:`item.partial`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.requests)+" ")])])]}},{key:`item.store`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.store)+" ")])])]}},{key:`item.notes`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.notes)+" ")])])]}},{key:`item.deliver`,fn:function({ item }){return [(_vm.enableOptions && item.requests > 0 && _vm.partialDelivery)?_c('div',{staticClass:"mb-5"},[_c('v-text-field',{attrs:{"hide-details":"","type":"number","rules":[_vm.rules.number, _vm.validateQuantity(item)]},model:{value:(item.quantityToDeliver),callback:function ($$v) {_vm.$set(item, "quantityToDeliver", $$v)},expression:"item.quantityToDeliver"}})],1):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [(
                    _vm.bom.status == 'inProgress' || _vm.bom.status == 'rejected'
                )?_c('div',{staticClass:"d-flex justify-center"},[(!_vm.readBOMOnly)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openBomItemForm(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(!_vm.readBOMOnly)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openDeleteBomItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1):_vm._e(),_c('div',[(item.deliveryRejectionLog)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.openRejectedLog(item)}}},[_c('v-icon',[_vm._v(" mdi-book-cancel-outline ")])],1):_vm._e()],1)]}},(_vm.bom.status == 'approved' || _vm.bom.status == 'closed')?{key:"expanded-item",fn:function({ headers, item: item }){return [_c('td',{staticClass:"pa-4 ma-0 outlined",style:({ background: 'white' }),attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.deliveryLogHeaders,"items":item.deliveries,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.deliveredBy`,fn:function({
                                    item,
                                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.findUserName(item.deliveredBy))+" ")])]}},{key:`item.deliveredTo`,fn:function({
                                    item,
                                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.operator.name)+" ")])]}},{key:`item.date`,fn:function({
                                    item,
                                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.formatDate( item.date._seconds || item.date.seconds ))+" ")])]}},{key:`item.qty`,fn:function({
                                    item,
                                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.qty)+" ")])]}},{key:`item.sap`,fn:function({
                                    item,
                                }){return [(item.id || _vm.bom.status == 'closed')?_c('v-simple-checkbox',{attrs:{"disabled":!_vm.user.permissions.includes(
                                            'closePartial'
                                        ) || _vm.bom.status == 'closed',"color":"success"},on:{"click":function($event){return _vm.checkSAP(item)}},model:{value:(item.sap),callback:function ($$v) {_vm.$set(item, "sap", $$v)},expression:"item.sap"}}):_vm._e()]}},{key:`item.notes`,fn:function({
                                    item,
                                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.notes)+" ")])]}}],null,true)})],1)],1)],1)]}}:null],null,true)}),(_vm.activateAlert)?_c('v-alert',{style:({ position: 'absolute', right: '0px', bottom: '0px' }),attrs:{"type":"success","color":"primary"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.alertMessage))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-n1",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.activateAlert = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","retain-focus":false,"max-width":"600"},model:{value:(_vm.addNewDelivery),callback:function ($$v) {_vm.addNewDelivery=$$v},expression:"addNewDelivery"}},[(_vm.addNewDelivery)?_c('AddNewDelivery',{attrs:{"bom":_vm.bom,"items":_vm.BOMSelected,"requestData":_vm.requestData},on:{"close":_vm.closeAddNewDelivery}}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.bomItemForm),callback:function ($$v) {_vm.bomItemForm=$$v},expression:"bomItemForm"}},[(_vm.bomItemForm)?_c('BOMItemForm',{attrs:{"bom":_vm.bom,"itemToFilter":_vm.selectedItem,"isDeleteOption":_vm.isDeleteOption},on:{"close":_vm.closeBomItemForm}}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.rejectionTable),callback:function ($$v) {_vm.rejectionTable=$$v},expression:"rejectionTable"}},[(_vm.rejectionTable)?_c('RejectionStockItemsTable',{attrs:{"items":_vm.rejectionItems,"users":_vm.users},on:{"closeDialog":_vm.closeRejectedLog}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }