import { render, staticRenderFns } from "./UserGeneralInfo.vue?vue&type=template&id=426f8ae8&scoped=true&"
import script from "./UserGeneralInfo.vue?vue&type=script&lang=js&"
export * from "./UserGeneralInfo.vue?vue&type=script&lang=js&"
import style0 from "./UserGeneralInfo.vue?vue&type=style&index=0&id=426f8ae8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "426f8ae8",
  null
  
)

export default component.exports