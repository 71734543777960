var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s('Reprocess For ' + _vm.workOrder.code))]),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('v-card-text',[_c('v-form',{staticClass:"mb-2 mx-1 mt-3",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"id":"virtual-scroll-table","disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredItems,"mobile-breakpoint":0,"fixed-header":"","height":_vm.$vuetify.breakpoint.mdAndDown ? '50vh' : '35vh',"item-key":"index","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4 align-center",staticStyle:{"background-color":"#eeeeee"},attrs:{"no-gutter":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('h2',{staticClass:"ml-4"},[_vm._v("WORK ORDER ITEMS")]),_c('v-spacer')],1)],1)]},proxy:true},{key:`header.partNumber`,fn:function({ header }){return [_c('v-text-field',{staticClass:"py-2 mt-2 custom-label",attrs:{"label":header.text,"dense":""},model:{value:(_vm.partNumberToFilter),callback:function ($$v) {_vm.partNumberToFilter=$$v},expression:"partNumberToFilter"}})]}},{key:`item.selectedQuantity`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticClass:"my-0",attrs:{"type":"number","rules":[
                                    () =>
                                        _vm.rules.maxValue(
                                            item.selectedQuantity,
                                            item
                                        ),
                                ]},model:{value:(item.selectedQuantity),callback:function ($$v) {_vm.$set(item, "selectedQuantity", $$v)},expression:"item.selectedQuantity"}})],1)]}},{key:`item.releasedQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.quality ? item.quality.reduce( (accum, entry) => accum + (entry.releaseQty || 0), 0 ) : 0)+" ")])])]}},{key:`item.reprocessQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.quality ? item.quality.reduce( (accum, entry) => accum + (entry.reprocessQty || 0), 0 ) : 0)+" ")])])]}},{key:`item.rejectedQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.quality ? item.quality.reduce( (accum, entry) => accum + (entry.rejectQty || 0), 0 ) : 0)+" ")])])]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"pb-3 pt-0 mx-6"},[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.buttonConditions(),"loading":_vm.loading},on:{"click":_vm.action}},[_vm._v(" Create ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }