<template>
    <div>
        <v-menu offset-y ref="notificationMenu">
            <template v-slot:activator="{ on }">
                <v-btn icon class="ms-0 pa-0" v-on="on">
                    <v-icon large color="#707070">mdi-bell-outline</v-icon>
                    <v-badge
                        v-if="unreadNotifications.length > 0"
                        :content="unreadNotifications.length"
                        color="red"
                        overlap
                        :style="{
                            position: 'absolute',
                            top: '5px',
                            right: '12px',
                        }"
                    ></v-badge>
                </v-btn>
            </template>

            <v-list class="pb-0">
                <v-list-item>
                    <v-list-item-content class="py-0 my-0">
                        <v-list-item-title class="font-weight-bold"
                            >Notifications</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    v-if="
                        unreadNotifications.length > 0 &&
                            unreadNotifications.length != notifications.length
                    "
                    class="text-center"
                >
                    <v-btn-toggle :style="{ width: '100%' }">
                        <v-btn
                            @click.stop="notificationsToShow = 'all'"
                            rounded
                            small
                            :style="{ width: '33%' }"
                            >All</v-btn
                        >
                        <v-btn
                            @click.stop="notificationsToShow = 'unread'"
                            rounded
                            small
                            :style="{ width: '33%' }"
                            >Unread</v-btn
                        >
                        <v-btn
                            @click.stop="notificationsToShow = 'read'"
                            rounded
                            small
                            :style="{ width: '33%' }"
                            >Read</v-btn
                        >
                    </v-btn-toggle>
                </v-list-item>

                <v-list-item-group
                    :style="{ 'max-height': '300px', 'overflow-y': 'auto' }"
                    v-if="notifications.length > 0"
                >
                    <div
                        v-for="notification in notificationsFiltered"
                        :key="notification.id"
                    >
                        <NotificationCard
                            @openQuote="openQuote"
                            :notification="notification"
                            :notificationDocs="notificationDocs"
                        />
                    </div>
                </v-list-item-group>

                <v-list-item v-else>
                    <v-list-item-content
                        >No notifications to show.</v-list-item-content
                    >
                </v-list-item>
            </v-list>
        </v-menu>
        <!--QUOTE-->
        <v-dialog :retain-focus="false" v-model="openQuoteDialog" fullscreen>
            <v-card v-if="openQuoteDialog" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeQuote">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <Quote
                        :id="selectedQuote.id"
                        :quote="selectedQuote"
                        :settings="settings"
                        :history="!!selectedQuote.archive"
                        :followUp="true"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations, mapState } from 'vuex'

export default {
    name: 'NotificationsBell',
    components: {
        NotificationCard: () =>
            import('@/components/Notifications/NotificationCard.vue'),
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data() {
        return {
            selectedQuote: {},
            heightPopUp: 0,
            openQuoteDialog: false,
            notifications: [],
            unreadNotifications: [],
            notificationsToShow: 'all',
            openDeleteDialog: false,
            openReadDialog: false,
            loading: false,
            companyId: JSON.parse(localStorage.getItem('company')),
            notificationDocs: {},
        }
    },
    computed: {
        ...mapState(['settings']),
        notificationsFiltered() {
            let conditions = []
            if (this.notificationsToShow != 'all') {
                conditions.push(this.filterByStatus)
            }

            if (conditions.length > 0) {
                return this.notifications.filter(notification => {
                    return conditions.every(condition => {
                        return condition(notification)
                    })
                })
            }
            this.sort()
            return this.notifications
        },
    },

    async mounted() {
        try {
            const {
                data: { user },
            } = await API.getMyInfo()

            const currentDate = new Date()
            const dateLimit = new Date()
            dateLimit.setDate(currentDate.getDate() - 2.5)

            let NotificationsQuery = firestore
                .collection('users')
                .doc(user.id)
                .collection('notifications')

            this.listener = await NotificationsQuery.onSnapshot(
                async docSnapshot => {
                    docSnapshot.docChanges().forEach(async change => {
                        const notification = Object.assign(change.doc.data(), {
                            id: change.doc.id,
                        })
                        if (!notification.logs) {
                            return null
                        }
                        const userNotifications = notification.logs
                        this.notificationDocs[
                            notification.id
                        ] = userNotifications?.map(item => {
                            return {
                                ...item,
                                notificationName: notification.name,
                            }
                        })
                        if (change.type === 'added') {
                            userNotifications?.forEach(log => {
                                if (
                                    !this.notifications.find(
                                        n => n.id == log.id
                                    )
                                ) {
                                    this.notifications.push(log)
                                }
                            })
                        }
                        if (change.type === 'modified') {
                            let newNotifications = []
                            Object.keys(this.notificationDocs).forEach(key => {
                                newNotifications = [
                                    ...newNotifications,
                                    ...this.notificationDocs[key],
                                ]
                            })
                            this.notifications = newNotifications
                        }
                    })
                    this.verifyResourceExistence()
                    if (this.notifications.length < 1) {
                        this.closeMenu()
                    } else if (this.notifications.length == 1) {
                        this.notificationsToShow = 'all'
                    }
                    this.unreadNotifications = this.notifications.filter(
                        n => !n.read
                    )
                }
            )
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeQuote() {
            this.selectedQuote = {}
            this.openQuoteDialog = false
        },
        openQuote(quote) {
            this.selectedQuote = quote
            this.heightPopUp = window.innerHeight - 62
            this.openQuoteDialog = true
        },
        filterByStatus(notification) {
            return this.notificationsToShow === 'read'
                ? notification.read
                : !notification.read
        },
        sort() {
            this.notifications.sort(
                (a, b) => b.createdOn.seconds - a.createdOn.seconds
            )
        },
        verifyResourceExistence() {
            this.notifications.forEach((notification, index) => {
                const currentResourceId = notification.resourceId
                const currentCreatedOn = notification.createdOn
                const resourceNotifications = this.notifications.filter(
                    n => n.resourceId === currentResourceId
                )

                const newerNotifications = resourceNotifications.filter(
                    n => n.createdOn.seconds > currentCreatedOn.seconds
                )

                if (newerNotifications.length > 0) {
                    this.notifications[index].alert = true
                }
            })
            this.notifications = _.cloneDeep(this.notifications)
        },
        closeMenu() {
            this.notificationsToShow = 'all'
            this.$refs.notificationMenu.isActive = false
        },
    },
}
</script>
