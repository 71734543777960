var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"overflow-y-hidden pa-0",style:({ height: '100%' }),attrs:{"max-width":"1200"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","color":"primary","icon":"","rounded":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('h3',{staticClass:"ml-2"},[_vm._v(" Processes "+_vm._s(_vm.item ? '(' + _vm.item.partNumber + ')' : '')+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"rounded":"","color":"primary","loading":_vm.loading,"disabled":_vm.usedProcesses.length <= 0 || _vm.processesValidation()},on:{"click":_vm.saveProcesses}},[_vm._v(" SAVE ")])],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"ml-2"},[[_c('v-row',{staticClass:"d-flex justify-start",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-sheet',{staticClass:"fill-height"},[_c('h4',{staticClass:"primary--text my-2"},[_vm._v("Use")]),_c('draggable',_vm._b({staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({
                                    height: `${_vm.height}px`,
                                    width: '100%',
                                }),model:{value:(_vm.usedProcesses),callback:function ($$v) {_vm.usedProcesses=$$v},expression:"usedProcesses"}},'draggable',_vm.dragOptions,false),[_vm._l((_vm.usedProcesses),function(process,index){return _c('div',{key:index,staticClass:"mx-0 my-0 d-flex align-center",attrs:{"draggable":""}},[_c('v-chip',{staticClass:"full-width-chip mb-3"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(index + 1 + ')'))]),_vm._v(" "+_vm._s(process.name)+" ")]),(
                                                index <
                                                    _vm.usedProcesses.length - 1
                                            )?_c('v-btn',{staticClass:"ml-3 mt-n3",attrs:{"depressed":"","color":process.cancelSend
                                                    ? 'red'
                                                    : 'primary',"icon":"","rounded":""},on:{"click":function($event){return _vm.changeCancelSend(index)}}},[(process.cancelSend)?_c('v-icon',[_vm._v(" mdi-download-off-outline ")]):_c('v-icon',[_vm._v(" mdi-download ")])],1):_vm._e()],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('div',[_c('h4',{staticClass:"secondary--text my-2"},[_vm._v(" Processes ")]),_c('draggable',_vm._b({attrs:{"tag":'tbody'},model:{value:(_vm.unusedProcesses),callback:function ($$v) {_vm.unusedProcesses=$$v},expression:"unusedProcesses"}},'draggable',_vm.dragOptions,false),[_vm._l((_vm.unusedProcesses),function(process,index){return _c('div',{key:index,staticClass:"mx-0 my-0 d-flex align-center",attrs:{"draggable":""}},[_c('v-chip',{staticClass:"full-width-chip mb-3"},[_vm._v(" "+_vm._s(process.name)+" ")])],1)})],2)],1)])],1)]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }