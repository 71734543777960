import { storage } from '../services/firebase'

export const loadImage = async (path, picture) => {
    const storageRef = storage().ref(path)
    const profilePicRef = storageRef.child(picture)
    var profilePicURL = ''
    await profilePicRef
        .getDownloadURL()
        .then(url => {
            profilePicURL = url
        })
        .catch(() => {
            profilePicURL = ''
        })
    return profilePicURL
}

export const deleteImage = async (path, picture) => {
    const storageRef = storage().ref(path)
    const profilePicRef = storageRef.child(picture)
    await profilePicRef.delete().catch(error => {
        console.log('error deleting image')
        error && true
    })
}
