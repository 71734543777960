<template>
    <div class="mx-0 px-0 py-0 mt-3">
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="updatedHeaders"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    @click:row="openProject"
                    :item-class="itemRowBackground"
                    :height="$vuetify.breakpoint.mdAndDown ? '75vh' : '63.4vh'"
                    fixed-header
                >
                    <template v-slot:top>
                        <div>
                            <v-row
                                no-gutter
                                :style="{ 'background-color': '#eeeeee' }"
                                :class="
                                    $vuetify.breakpoint.xs
                                        ? 'd-flex justify-center py-4 mx-0'
                                        : 'd-flex justify-start py-4 mx-0'
                                "
                            >
                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[0].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[0].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[0]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{ filters.length }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex" v-if="totals.b2bCOP != 0">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[1].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[1].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[1]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        ({{ totals.qtyCop }})
                                                        {{
                                                            formatNumber(
                                                                totals.b2bCOP,
                                                                'COP'
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex" v-if="totals.b2bUSD != 0">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[2].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[2].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[2]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        ({{ totals.qtyUsd }})
                                                        {{
                                                            formatNumber(
                                                                totals.b2bUSD,
                                                                'USD'
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <v-spacer v-if="!$vuetify.breakpoint.xs" />
                                <div
                                    class="pt-5 pr-3"
                                    v-if="uploadBacklogPermission"
                                >
                                    <v-btn
                                        rounded
                                        color="primary"
                                        @click="openUploadBacklog"
                                        :disabled="loading"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        {{
                                            !$vuetify.breakpoint.xs
                                                ? 'Backlog'
                                                : ''
                                        }}
                                    </v-btn>
                                </div>
                                <div class="pt-5 pr-3">
                                    <v-btn
                                        v-if="generateInformPermission"
                                        rounded
                                        color="primary"
                                        @click="openGenerateXLS"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        {{
                                            !$vuetify.breakpoint.xs
                                                ? 'INFORM'
                                                : ''
                                        }}
                                    </v-btn>
                                </div>
                                <div class="pt-5 pr-3">
                                    <v-btn
                                        depressed
                                        color="primary"
                                        @click="openProjectForm"
                                        rounded
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        {{
                                            !$vuetify.breakpoint.xs ? 'New' : ''
                                        }}
                                    </v-btn>
                                </div>
                            </v-row>
                        </div>
                    </template>
                    <!-- Headers -->

                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectReference"
                        />
                    </template>

                    <template v-slot:[`header.sapCode`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectSapCode"
                            class="d-flex mb-n4"
                        />
                    </template>

                    <template v-slot:[`header.client`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectClient"
                        />
                    </template>

                    <template v-slot:[`header.name`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectName"
                        />
                    </template>

                    <template v-slot:[`header.accountManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectAManager"
                        />
                    </template>

                    <template v-slot:[`header.projectManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectPManager"
                        />
                    </template>

                    <template v-slot:[`header.inv`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="stockBySapCode"
                        />
                    </template>

                    <template v-slot:[`header.collaborators`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectCollaborator"
                        />
                    </template>

                    <template v-slot:[`header.price`]="{ header }">
                        <v-text-field
                            class="mt-5"
                            :label="header.text"
                            v-model="projectValue"
                        />
                    </template>

                    <template v-slot:[`header.invoiced`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="filterBilled"
                        />
                    </template>

                    <!-- Items -->

                    <template v-slot:[`item.accountManager`]="{ item }">
                        <div class="my-4">
                            {{ item.accountManagerName }}
                        </div>
                    </template>
                    <template v-slot:[`item.projectManager`]="{ item }">
                        <div class="my-4">
                            {{ item.projectManagerName }}
                        </div>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                        <div class="my-4">
                            {{ item.clientName }}
                        </div>
                    </template>
                    <template v-slot:[`item.collaborators`]="{ item }">
                        <div v-if="showTooltip(item)">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-for="(collaborator,
                                        index) of item.dataCollaborators.filter(
                                            c =>
                                                c != item.projectManagerName &&
                                                c != item.accountManagerName
                                        )"
                                        :key="index"
                                        class="my-4"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <p v-if="index == 0">
                                            {{ collaborator }}
                                        </p>
                                        <p
                                            v-else-if="
                                                index == 1 &&
                                                    item.dataCollaborators.filter(
                                                        c =>
                                                            c !=
                                                                item.projectManagerName &&
                                                            c !=
                                                                item.accountManagerName
                                                    ).length < 3
                                            "
                                        >
                                            {{ collaborator }}
                                        </p>
                                        <p v-else-if="index === 2">
                                            +
                                            {{
                                                item.dataCollaborators.filter(
                                                    c =>
                                                        c !=
                                                            item.projectManagerName &&
                                                        c !=
                                                            item.accountManagerName
                                                ).length - 1
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <div>
                                    <template>
                                        <div
                                            v-for="(collaborator,
                                            index) of item.dataCollaborators.filter(
                                                c =>
                                                    c !=
                                                        item.projectManagerName &&
                                                    c != item.accountManagerName
                                            )"
                                            :key="index"
                                            class="my-4"
                                        >
                                            <p>
                                                {{ collaborator }}
                                            </p>
                                        </div>
                                    </template>
                                </div>
                            </v-tooltip>
                        </div>
                        <div
                            v-for="(collaborator,
                            index) of item.dataCollaborators.filter(
                                c =>
                                    c != item.projectManagerName &&
                                    c != item.accountManagerName
                            )"
                            :key="index"
                            class="my-4"
                            v-else
                        >
                            {{ collaborator }}
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <div class="my-4" :style="{ 'text-align': 'end' }">
                            <div class="my-4">
                                {{ formatCurrency(item) }} {{ item.currency }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.invoiced`]="{ item }">
                        <div
                            class="my-4 font-weight-bold"
                            @click.stop="openInvoiceDetails(item)"
                            :style="{
                                cursor: item.totalInvoiced
                                    ? 'pointer'
                                    : 'default',

                                textAlign: 'end',
                            }"
                        >
                            {{
                                item.totalInvoiced
                                    ? formatInvoiced(item)
                                    : ' 0 (0%)'
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.b2b`]="{ item }">
                        <div class="my-4" :style="{ 'text-align': 'end' }">
                            {{ formatB2B(item.b2b) }}
                        </div>
                    </template>
                    <template v-slot:[`item.stockBySapCode`]="{ item }">
                        <div class="my-4" :style="{ 'text-align': 'end' }">
                            {{ formatB2B(item.stockBySapCode) }}
                        </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <div class="d-flex justify-center my-4">
                            <v-btn
                                icon
                                small
                                @click.stop="editProject(item)"
                                :disabled="loading"
                            >
                                <v-icon small :color="iconColor(item)"
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click.stop="openProjectClosingProcess(item)"
                                v-if="
                                    isProjectManager(item) ||
                                        checkProjectStatusPermission
                                "
                            >
                                <v-icon small :color="iconColor(item)"
                                    >mdi-archive-lock-open-outline</v-icon
                                >
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- project form -->
        <v-dialog
            :retain-focus="false"
            v-model="projectDialog"
            persistent
            max-width="640px"
        >
            <ProjectForm
                v-if="projectDialog"
                :quotes="awardedQuotes"
                :users="users"
                :clients="clients"
                :project="selectedProject"
                :originalProject="originalProject"
                :createForm="createProject"
                :selectedQuote="selectedQuote"
                @closeDialog="closeDialog"
                @addProject="addProject"
                @replaceProject="replaceProject"
                @removeProject="removeProject"
            />
        </v-dialog>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToProjects">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- project  -->
        <v-dialog
            :retain-focus="false"
            v-model="viewProject"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card class="fill-height" v-if="viewProject">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToProjects">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ selectedProject.name }}
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- invoice Details  -->
        <v-dialog :retain-focus="false" v-model="invoiceDetails" persistent>
            <ProjectInvoiceDetails
                v-if="invoiceDetails"
                :project="selectedProject"
                @close="closeInvoiceDetails"
            />
        </v-dialog>
        <!-- INFORM -->
        <v-dialog
            v-model="openInform"
            :retain-focus="false"
            persistent
            max-width="400"
        >
            <v-card :loading="loading" v-if="openInform">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeGenerateXLS"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        INFORM
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateXLS"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        GENERATE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- UPLOAD BACKLOG -->
        <v-dialog
            v-model="backlogDialog"
            :retain-focus="false"
            persistent
            max-width="400"
        >
            <v-card :loading="loading" v-if="backlogDialog">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeUploadBacklog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>UPLOAD BACKLOG</h3>
                </v-card-title>
                <v-form v-model="formValid">
                    <v-card-text class="mt-3">
                        <v-menu
                            ref="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="cutOffDate"
                                    label="Select Cut Off Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    prepend-icon="mdi-calendar"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="cutOffDate"
                                @input="saveCutDate"
                            ></v-date-picker>
                        </v-menu>
                        <v-btn
                            color="primary"
                            block
                            class="mt-2"
                            @click="uploadBacklog"
                            :disabled="invalidDate"
                        >
                            Select File
                        </v-btn>
                        <input
                            ref="backlogFile"
                            class="d-none"
                            type="file"
                            name="backlogFile"
                            accept=".xls,.xlsx"
                            @change="onBacklogChanged"
                        />
                        <div
                            v-if="selectedFileName || backlog"
                            class="mt-4 mb-n3"
                        >
                            <p v-if="selectedFileName">
                                <strong>Selected File:</strong>
                                {{ selectedFileName }}
                            </p>
                            <p v-if="backlog && backlog.file">
                                <strong>Current Backlog:</strong>
                                <a @click="openBacklogFile(backlog.file)">{{
                                    backlog.file
                                }}</a>
                            </p>
                            <p v-if="backlog && backlog.cutOffDate">
                                <strong>Current Cut Off Date:</strong>
                                {{ backlog.cutOffDate }}
                            </p>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <v-btn
                            @click="openDeleteBacklogDialog"
                            color="error"
                            :loading="loading"
                            rounded
                            :disabled="
                                !backlog || !backlog.file || !backlog.cutOffDate
                            "
                        >
                            Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="
                                !backlog || !backlog.file || !backlog.cutOffDate
                            "
                            @click="sendBacklogForm"
                            color="primary"
                            :loading="loading"
                            rounded
                            :disabled="invalidDate || invalidNewBacklogFile"
                        >
                            Upload
                        </v-btn>
                        <v-btn
                            v-if="backlog && backlog.file && backlog.cutOffDate"
                            @click="sendBacklogForm"
                            color="primary"
                            :loading="loading"
                            rounded
                            :disabled="invalidDate || invalidNewBacklogFile"
                        >
                            Update
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- CONFIRM DELETE BACKLOG -->
        <v-dialog
            v-model="deleteBacklogDialog"
            :retain-focus="false"
            persistent
            max-width="400"
        >
            <v-card :loading="loading" v-if="deleteBacklogDialog">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeDeleteBacklogDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        DELETE BACKLOG
                    </h3>
                </v-card-title>
                <v-card-text v-if="deleteBacklogDialog">
                    Are you sure you want to delete this backlog?
                </v-card-text>
                <v-divider class="ml-4 mr-5" />
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteBacklog"
                        color="error"
                        :loading="loading"
                        rounded
                    >
                        DELETE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- CLOSING PROJECT PROCESS -->
        <v-dialog
            v-model="closingProcessDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <ProjectClosingProcess
                v-if="closingProcessDialog"
                :project="selectedProject"
                :checkProjectStatusPermission="checkProjectStatusPermission"
                @close="closeProjectClosingProcess"
                @remove="removeProject"
            />
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations, mapGetters } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import moment from 'moment'
import { saveFile } from '@/services/storage/saveFile.js'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'
import { storage } from '@/services/firebase'

export default {
    name: 'Projects',
    components: {
        ProjectForm: () => import('@/components/Projects/ProjectForm.vue'),
        ProjectInvoiceDetails: () =>
            import('@/components/Projects/ProjectInvoiceDetails.vue'),
        ProjectClosingProcess: () =>
            import('@/components/Projects/ProjectClosingProcess.vue'),
    },
    data: () => ({
        filterBilled: null,
        headers: [
            {
                text: 'Reference',
                value: 'reference',
                align: 'center',
                sortable: false,
                width: 80,
            },
            {
                text: 'SAP Code',
                value: 'sapCode',
                align: 'center',
                width: 80,
            },
            {
                text: 'Name',
                value: 'name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Client',
                value: 'client',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Account M.',
                value: 'accountManager',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Project M.',
                value: 'projectManager',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Collaborators',
                value: 'collaborators',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Price',
                value: 'price',
                align: 'right',
                sorteable: true,
            },
            {
                text: 'Billed',
                value: 'invoiced',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Balance to Bill',
                value: 'b2b',
                sortable: true,
                align: 'right',
            },
            {
                text: 'Inv',
                value: 'stockBySapCode',
                align: 'center',
                sortable: true,
            },
            {
                text: 'Action',
                value: 'action',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        projects: [],
        loading: false,
        users: [],
        clients: [],
        projectDialog: false,
        createProject: false,
        selectedProject: {},
        heightPopUp: 0,
        viewProject: false,
        dialogQuote: false,
        costs: undefined,
        settings: undefined,
        awardedQuotes: [],
        projectClient: undefined,
        projectName: undefined,
        projectPManager: undefined,
        projectAManager: undefined,
        projectReference: undefined,
        projectSapCode: undefined,
        projectCity: undefined,
        projectCollaborator: undefined,
        projectQuote: undefined,
        selectedQuote: undefined,
        projectValue: undefined,
        stockBySapCode: undefined,
        openTaskForm: false,
        originalProject: {},
        invoiceDetails: false,
        generateInformPermission: false,
        checkProjectStatusPermission: false,
        uploadBacklogPermission: false,
        openInform: false,
        valid: false,
        insights: [
            {
                color: '#3498DB',
                icon: 'mdi-clipboard-multiple',
                iconColor: '#1A5276',
                title: 'PROJECTS',
            },
            {
                color: '#ffa414',
                icon: 'mdi-cash-check',
                iconColor: '#1A5276',
                currency: 'COP',
                title: 'Balance to Bill (COP)',
            },
            {
                color: '#20844c',
                icon: 'mdi-cash-check',
                iconColor: '#2874A6',
                currency: 'USD',
                title: 'Balance to Bill (USD)',
            },
        ],
        authUser: undefined,
        closingProcessDialog: false,
        companyId: '',
        backlog: [],
        backlogDialog: false,
        selectedFileName: null,
        invalidDate: true,
        invalidNewBacklogFile: true,
        cutOffDate: null,
        formValid: null,
        dynamicHeaders: [],
        deleteBacklogDialog: false,
        tempItem: [],
    }),
    async mounted() {
        try {
            this.loading = true
            // retrive project
            this.projects = await API.getProjects({})
            this.projects = this.projects.filter(project => !project.generic)
            // set auth user
            const {
                data: { user },
            } = await API.getMyInfo()
            this.authUser = user
            // set company id
            this.companyId = this.authUser.companyId
            // retrieve permission
            this.generateInformPermission = user.permissions.includes(
                'generateProjectsInform'
            )
            // Check Project Status Permission
            this.checkProjectStatusPermission = user.permissions.includes(
                'checkProjectStatus'
            )
            // Upload Backlog Permission
            this.uploadBacklogPermission = user.permissions.includes(
                'uploadBacklog'
            )
            // set users
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // set clients
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
            // set settings
            const {
                data: { settings },
            } = await API.getSettings()
            let orderSettings = settings.filter(x => x.name == 'Company')
            orderSettings[1] = settings.find(x => x.name == 'Exchange')
            orderSettings[2] = settings.find(x => x.name == 'Permissions')
            orderSettings[3] = settings.find(x => x.name == 'Quotes')
            this.settings = orderSettings
            // complete project data
            this.completeProjectData()
            // open new project creation form if a won quotation is sent from another route
            const quoteId = this.$router.currentRoute.params.quoteId
            if (quoteId) {
                this.awardedQuotes = await API.getProjectQuotes()
                this.selectedQuote = this.awardedQuotes.find(
                    quote => quote.id === quoteId
                )
                this.openProjectForm()
            }
            // calculate project b2b
            this.projects.forEach(project => {
                project.stockBySapCode = 0
                project.b2b = project.price - (project.totalInvoiced || 0)
            })
            // get inv
            const stocks = await API.getRawStock()
            stocks.forEach(stock => {
                this.projects.forEach(project => {
                    if (project.sapCode) {
                        if (
                            (stock.order || stock.orderNumber) ==
                            project.sapCode
                        ) {
                            project.stockBySapCode += Number(
                                stock.usable_free_value
                            )
                        }
                    }
                })
            })
            // get existent backlog
            this.backlog = await this.getBacklogInfo()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
            if (this.getProjectId) {
                const project = this.projects.find(
                    p => p.id == this.getProjectId
                )
                if (project) {
                    this.openProject(project)
                }
            }
            this.setProjectId(undefined)
        }
    },
    updated() {
        this.onResize()
    },
    provide() {
        return {
            openQuoteFromProjects: this.openQuoteFromProjects,
        }
    },
    computed: {
        ...mapGetters(['getProjectId']),
        updatedHeaders() {
            const actionHeader = this.headers.find(
                header => header.value === 'action'
            )
            const filteredHeaders = this.headers.filter(
                header => header.value !== 'action'
            )
            return [...filteredHeaders, ...this.dynamicHeaders, actionHeader]
        },
        filters() {
            let conditions = []

            if (this.projectClient) {
                conditions.push(this.filterClient)
            }

            if (this.projectName) {
                conditions.push(this.filterName)
            }

            if (this.projectAManager) {
                conditions.push(this.filterAManager)
            }

            if (this.projectPManager) {
                conditions.push(this.filterPManager)
            }

            if (this.projectReference) {
                conditions.push(this.filterReference)
            }

            if (this.projectSapCode) {
                conditions.push(this.filterSapCode)
            }

            if (this.projectCollaborator) {
                conditions.push(this.filterCollaborators)
            }

            if (this.projectValue) {
                conditions.push(this.filterValue)
            }

            if (this.filterBilled) {
                conditions.push(this.filterByBilled)
            }

            if (conditions.length > 0) {
                const result = this.projects.filter(project => {
                    return conditions.every(condition => {
                        return condition(project)
                    })
                })
                return result
            }
            this.sortByReference()
            return this.projects
        },
        totals() {
            let totals = {
                totalUsd: 0,
                totalCop: 0,
                qtyUsd: 0,
                qtyCop: 0,
                b2bUSD: 0,
                b2bCOP: 0,
            }

            this.filters.forEach(project => {
                if (project.price != null) {
                    if (project.currency == 'COP') {
                        totals.totalCop += parseFloat(project.price)
                        totals.b2bCOP +=
                            project.price - (project.totalInvoiced || 0)
                        totals.qtyCop += 1
                    } else {
                        totals.totalUsd += parseFloat(project.price)
                        totals.b2bUSD +=
                            project.price - (project.totalInvoiced || 0)
                        totals.qtyUsd += 1
                    }
                }
            })

            return totals
        },
    },
    methods: {
        ...mapMutations(['setErrorItems', 'setProjectId']),
        // returns the color that an icon should take when a project is 100% invoiced
        iconColor(item) {
            return this.formatInvoiced(item).includes('100%')
                ? 'white'
                : 'black'
        },
        // paints the background of a row for a one hundred percent billable project
        itemRowBackground(item) {
            return this.formatInvoiced(item).includes('100%')
                ? 'item_class'
                : ''
        },
        // filter by billed column
        filterByBilled(item) {
            return this.formatInvoiced(item).includes(this.filterBilled)
        },
        // close the dialogue to start with the process of closing a project.
        closeProjectClosingProcess() {
            this.selectedProject = {}
            this.closingProcessDialog = false
        },
        // opens the dialogue to start with the process of closing a project.
        openProjectClosingProcess(project) {
            this.selectedProject = _.cloneDeep(project)
            this.closingProcessDialog = true
        },
        // validate if the authenticated user is the project manager of a project
        isProjectManager(project) {
            return !project ? false : project.projectManager == this.authUser.id
        },
        sortByReference() {
            this.projects.sort((a, b) => {
                return b.reference - a.reference
            })
        },
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                maximumFractionDigits: 0,
            }).format(value)
        },
        filterValue(item) {
            return (
                item.price &&
                item.currency &&
                (item.currency
                    .toLowerCase()
                    .includes(this.projectValue.toLowerCase()) ||
                    item.price
                        .toString()
                        .toLowerCase()
                        .includes(this.projectValue.toLowerCase()))
            )
        },
        filterCollaborators(item) {
            let res = false
            for (let i = 0; i < item.dataCollaborators.length; i++) {
                let collaborator = item.dataCollaborators[i]
                if (collaborator) {
                    res = collaborator
                        .toLowerCase()
                        .includes(this.projectCollaborator.toLowerCase())
                }
                if (res) break
            }
            return res
        },

        filterReference(item) {
            return (
                item.reference &&
                item.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectReference.toLowerCase())
            )
        },
        filterSapCode(item) {
            return (
                item.sapCode &&
                item.sapCode
                    .toString()
                    .toLowerCase()
                    .includes(this.projectSapCode.toLowerCase())
            )
        },
        filterPManager(item) {
            return (
                item.projectManagerName &&
                item.projectManagerName
                    .toLowerCase()
                    .includes(this.projectPManager.toLowerCase())
            )
        },
        filterAManager(item) {
            return (
                item.accountManagerName &&
                item.accountManagerName
                    .toLowerCase()
                    .includes(this.projectAManager.toLowerCase())
            )
        },
        filterName(item) {
            if (item.name) {
                return (
                    item.name &&
                    item.name
                        .toLowerCase()
                        .includes(this.projectName.toLowerCase())
                )
            } else {
                return false
            }
        },
        filterClient(item) {
            return (
                item.clientName &&
                item.clientName
                    .toLowerCase()
                    .includes(this.projectClient.toLowerCase())
            )
        },
        completeProjectData() {
            for (let project of this.projects) {
                project.clientName = this.getClientName(project.client)
                project.accountManagerName = this.getUserName(
                    project.accountManager
                )
                project.projectManagerName = this.getUserName(
                    project.projectManager
                )
                project.dataCollaborators = project.collaborators.map(
                    collaborator => {
                        return this.getUserName(collaborator)
                    }
                )
                project.dataQuotes = project.quotes.map(quote => {
                    return this.getQuoteName(quote)
                })
            }
        },
        async openQuoteFromProjects(quoteId, costs) {
            this.backToProjects()
            const quote = this.awardedQuotes.find(quote => quote.id === quoteId)
            await this.openQuote(quote, costs)
        },
        async openQuote(quote, costs) {
            this.dialogQuote = true
            this.costs = costs
            this.$router.replace({
                name:
                    quote.status !== 'SENT'
                        ? 'quoteGIProject'
                        : 'quoteFollowUpProject',
                params: {
                    id: quote.id,
                    quote,
                    settings: this.settings,
                },
            })
        },
        backToProjects() {
            this.dialogQuote = false
            this.viewProject = false
            this.$router
                .push({
                    name: 'projects',
                })
                .catch(error => error && null)
        },
        async openProject(project) {
            if (!this.loading) {
                this.viewProject = true
                this.selectedProject = _.cloneDeep(project)
                // const quotes = await API.getProjectQuotes(project.id)
                this.$router.push({
                    name: 'project',
                    params: {
                        id: project.id,
                        project,
                        clients: this.clients,
                        users: this.users,
                    },
                })
            }
        },
        editProject(project) {
            this.selectedProject = _.cloneDeep(project)
            this.originalProject = _.cloneDeep(project)
            this.createProject = false
            this.projectDialog = true
        },
        getQuoteName(id) {
            const quoteId = id.split('/')[0]
            if (this.awardedQuotes.length > 0) {
                return this.awardedQuotes.find(quote => quote.id == quoteId)
                    ?.name
            } else {
                return ''
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        openProjectForm() {
            this.selectedProject = undefined
            this.projectDialog = true
            this.createProject = true
        },
        closeDialog() {
            this.selectedQuote = undefined
            this.originalProject = undefined
            this.projectDialog = false
        },
        addProject(project) {
            this.projects.push(project)
            this.completeProjectData()
        },
        replaceProject(id, changes) {
            const index = this.projects.findIndex(p => p.id === id)
            if (index >= 0) {
                this.projects.splice(
                    index,
                    1,
                    Object.assign(this.projects[index], changes)
                )
            }
            this.completeProjectData()
        },
        removeProject(id) {
            this.projects = this.projects.filter(p => p.id != id)
        },
        formatCurrency(item) {
            return new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(item.price)
        },
        formatInvoiced(item) {
            const totalAmount = item.totalInvoiced
            const percentage = (totalAmount / item.price) * 100
            const formattedInvoiced = new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(totalAmount)
            return `${formattedInvoiced} (${percentage.toFixed(0)}%)`
        },
        formatB2B(item) {
            item = Math.floor(item)
            const formattedB2B = new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(item)
            return formattedB2B
        },
        openInvoiceDetails(item) {
            if (item.totalInvoiced && item.totalInvoiced > 0) {
                this.invoiceDetails = true
                this.selectedProject = _.cloneDeep(item)
            }
        },
        closeInvoiceDetails() {
            this.invoiceDetails = false
            this.selectedProject = _.cloneDeep({})
        },
        async generateXLS() {
            try {
                this.loading = true
                const allProjects = await API.getInformProjects()
                const data = []
                for (const inListProject of allProjects) {
                    let row = {}
                    const {
                        reference,
                        sapCode,
                        name,
                        client,
                        accountManager,
                        collaborators,
                        createdOn,
                        currency,
                        projectManager,
                        price,
                        offer,
                        generic,
                        country,
                        city,
                        state,
                        address,
                        totalInvoiced,
                        quotes,
                        archive,
                    } = inListProject

                    let quotesString = ''
                    for (const index in quotes) {
                        quotesString += quotes[index].split('/')[1]
                        if (index < quotes.length - 1) {
                            quotesString += ', '
                        }
                    }

                    let collaboratorsString = ''
                    if (collaborators) {
                        let filtered = collaborators.filter(
                            c => c != projectManager
                        )
                        for (const index in filtered) {
                            const collaboratorData = this.users.find(
                                u => u.id == collaborators[index]
                            )
                            if (collaboratorData) {
                                collaboratorsString += collaboratorData.name
                                if (index < filtered.length - 1) {
                                    collaboratorsString += ', '
                                }
                            }
                        }
                    }

                    let projectManagerName = ''
                    let accountManagerName = ''
                    let clientName = ''
                    if (projectManager) {
                        const data = this.users.find(
                            u => u.id == projectManager
                        )
                        if (data) {
                            projectManagerName = data.name
                        }
                    }
                    if (accountManager) {
                        const data = this.users.find(
                            u => u.id == accountManager
                        )
                        if (data) {
                            accountManagerName = data.name
                        }
                    }
                    if (client) {
                        const data = this.clients.find(c => c.id == client)
                        if (data) {
                            clientName = data.name
                        }
                    }
                    const dataFormatted = moment
                        .unix(createdOn._seconds)
                        .format('YYYY-MM-DD')
                    row.reference = reference ? reference.toString() : ''
                    row.sapCode = sapCode ? sapCode : ''
                    row.offer = offer ? offer : ''
                    row.name = name
                    row.client = clientName
                    row.price = price
                    row.totalInvoiced = totalInvoiced
                    row.B2B =
                        Number(price ? price.toFixed(0) : 0) -
                        Number(totalInvoiced ? totalInvoiced.toFixed(0) : 0)
                    row.currency = currency
                    row.accountManager = accountManagerName
                    row.projectManager = projectManagerName
                    row.collaborators = collaboratorsString
                    row.quoteCode = quotesString
                    row.generic = generic ? 'generic' : ''
                    row.archived = archive ? 'archived' : ''
                    row.createdOn = dataFormatted
                    row.country = country
                    row.state = state
                    row.city = city
                    row.address = address
                    data.push(row)
                }

                // only array possible
                const reportWS = XLSX.utils.json_to_sheet(data)

                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, reportWS, 'projects') // sheetAName is name of Worksheet
                // export Excel file
                XLSX.writeFile(wb, `projects.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        // get backlog of storage
        async getBacklogFile() {
            try {
                this.loading = true

                const { backlogFile } = await API.getBacklogFile()
                if (backlogFile) {
                    await this.filterBacklogData(this.projects, backlogFile)
                    return backlogFile
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        // get backlog of firestore
        async getBacklogInfo() {
            try {
                this.loading = true
                const { backlog } = await API.getBacklog()
                if (backlog) {
                    await this.getBacklogFile()
                    return backlog
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async uploadBacklogFile(file, backlogFile, companyId) {
            try {
                this.loading = true
                if (file && backlogFile && companyId) {
                    const storageRef = storage().ref(
                        `${this.companyId}/Backlog`
                    )

                    if (
                        backlogFile.length > 0 &&
                        typeof backlogFile[0] === 'object'
                    ) {
                        backlogFile = backlogFile[0]
                    }

                    await saveFile(file, `${this.companyId}/Backlog`)
                    await storageRef
                        .child('backlog.json')
                        .putString(JSON.stringify(backlogFile))
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async onBacklogChanged() {
            let file = this.$refs.backlogFile.files[0]
            if (file) {
                this.selectedFileName = file.name
                const validExtensions = ['.xls', '.xlsx']
                const validMimeTypes = [
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]
                const fileName = file.name.toLowerCase()
                const hasValidExtension = validExtensions.some(ext =>
                    fileName.endsWith(ext)
                )
                const hasValidMimeType = validMimeTypes.includes(file.type)

                if (hasValidExtension && hasValidMimeType) {
                    this.invalidNewBacklogFile = false
                    if (this.sendBacklog) {
                        this.saveBacklog(file)
                    }
                }
            }
        },
        // save backlog information in firestore.
        async saveBacklog(file) {
            try {
                this.loading = true
                if (file && this.isValidDate(this.cutOffDate)) {
                    // delete old backlog data in storage.
                    let backlogFile = await this.readFile(file, true)
                    if (backlogFile) {
                        await this.deleteCurrentBacklog()

                        const backlog = {
                            file: file.name,
                            creation_date: new Date(),
                            userId: this.authUser.id,
                            cutOffDate: this.cutOffDate,
                        }

                        // save in firestore.
                        await API.addBacklog({
                            backlog,
                        })

                        // save files in storage.
                        this.uploadBacklogFile(
                            file,
                            backlogFile,
                            this.companyId
                        )
                    }

                    this.cutOffDate = null
                    this.selectedFileName = null
                    this.invalidNewBacklogFile = true
                    this.dynamicHeaders = []
                    this.backlog = await this.getBacklogInfo()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteBacklog() {
            try {
                if (this.backlog) {
                    await this.deleteCurrentBacklog()
                    await API.deleteBacklog(this.backlog.id)
                    this.dynamicHeaders = []
                    this.backlog = await this.getBacklogInfo()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeDeleteBacklogDialog()
                this.closeUploadBacklog()
            }
        },
        // open backlog file of storage
        async openBacklogFile(fileName) {
            try {
                if (fileName) {
                    const backlogFile = await openFile(
                        fileName,
                        `${this.companyId}/Backlog`
                    )
                    return backlogFile
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        // delete backlog of storage
        async deleteBacklogFile(fileName) {
            if (fileName) {
                await deleteFile(fileName, `${this.companyId}/Backlog`)
            }
        },
        saveCutDate(date) {
            if (this.isValidDate(date)) {
                this.invalidDate = false
                this.cutOffDate = date
                this.closeDataPicker()
            } else {
                this.invalidDate = true
            }
        },
        isValidDate(date) {
            const d = new Date(date)
            return !isNaN(d.getTime())
        },
        closeDataPicker() {
            if (this.$refs.menu) {
                this.$refs.menu.save()
            }
        },
        filterBacklogData(projects, backlogData) {
            if (projects && backlogData) {
                for (let p of projects) {
                    for (let backlog of backlogData) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                backlog,
                                'Order'
                            )
                        ) {
                            backlog.order = backlog.Order
                            delete backlog.Order
                        }

                        if (
                            p.sapCode &&
                            backlog.order &&
                            p.sapCode.toLowerCase() == backlog.order
                        ) {
                            const keys = Object.keys(backlog)

                            for (let key of keys) {
                                if (key === 'order') {
                                    delete backlog[key]
                                } else {
                                    p[key] = backlog[key] || 0
                                }
                            }
                        }
                    }
                }
                this.generateDynamicHeaders(backlogData)
            }
        },
        generateDynamicHeaders(backlogData) {
            if (backlogData && backlogData.length > 0) {
                for (let item of backlogData) {
                    delete item.Order
                }

                const allKeys = new Set()

                for (let item of backlogData) {
                    Object.keys(item).forEach(key => allKeys.add(key))
                }

                const uniqueKeys = Array.from(allKeys)

                this.dynamicHeaders = uniqueKeys.map(key => ({
                    text: key,
                    value: key,
                    align: 'center',
                    sortable: true,
                }))
            }
        },
        closeGenerateXLS() {
            this.openInform = false
        },
        openGenerateXLS() {
            this.openInform = true
        },
        closeUploadBacklog() {
            this.backlogDialog = false
            this.invalidDate = true
            this.sendBacklog = false
        },
        async openUploadBacklog() {
            this.backlog = await this.getBacklogInfo()
            this.backlogDialog = true
        },
        uploadBacklog() {
            this.invalidNewBacklogFile = true
            this.$refs.backlogFile.click()
        },
        closeDeleteBacklogDialog() {
            this.deleteBacklogDialog = false
        },
        openDeleteBacklogDialog() {
            this.deleteBacklogDialog = true
        },
        sendBacklogForm() {
            this.sendBacklog = true
            this.onBacklogChanged()
            this.closeUploadBacklog()
        },
        showTooltip(item) {
            if (item.dataCollaborators) {
                return (
                    item.dataCollaborators.filter(
                        c =>
                            c != item.projectManagerName &&
                            c != item.accountManagerName
                    ).length > 2
                )
            }
            return false
        },

        async deleteCurrentBacklog() {
            try {
                this.loading = true
                const backlogInfo = await this.getBacklogInfo()
                const backlogFile = await this.getBacklogFile()

                if (!backlogInfo || !backlogFile) {
                    return
                }

                if (backlogInfo.file && backlogInfo != []) {
                    const fileName = backlogInfo.file
                    await this.deleteBacklogFile(fileName)
                }

                if (backlogFile.length >= 0 && backlogFile != []) {
                    await this.deleteBacklogFile('backlog.json')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async readFile(file, checkRequired = false) {
            try {
                this.tempItems = []
                this.loading = true
                const data = await file.arrayBuffer()
                /* data is an ArrayBuffer */
                const workbook = XLSX.read(data)
                var first_sheet_name = workbook.SheetNames[0]
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name]

                const range = worksheet['!ref']
                    ? XLSX.utils.decode_range(worksheet['!ref'])
                    : undefined

                if (!range) {
                    throw new Error(
                        'Invalid document. Please ensure the file is not empty and contains the required data.'
                    )
                }

                for (let col = range.s.c; col <= range.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({
                        r: range.s.r,
                        c: col,
                    })
                    const cell = worksheet[cellAddress]

                    if (cell) {
                        if (cell.v)
                            worksheet[cellAddress].v = cell.v.toString().trim()
                        if (cell.h)
                            worksheet[cellAddress].h = cell.h.toString().trim()
                        if (cell.w)
                            worksheet[cellAddress].w = cell.w.toString().trim()
                    }
                }

                if (checkRequired) {
                    // get headers
                    const headers = []

                    for (let col = range.s.c; col <= range.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({
                            r: range.s.r,
                            c: col,
                        })
                        const cell = worksheet[cellAddress]
                        headers.push(cell ? cell.v.trim() : undefined)
                    }

                    // requiredHeaders
                    const requiredColumns = ['order']
                    const missingColumns = requiredColumns.filter(
                        col =>
                            !headers.some(
                                header =>
                                    header.toLowerCase() === col.toLowerCase()
                            )
                    )

                    if (missingColumns.length > 0) {
                        throw new Error(
                            `The following columns are missing from the file: ${missingColumns.join(
                                ', '
                            )}`
                        )
                    }
                }

                this.tempItems.push(
                    XLSX.utils.sheet_to_json(worksheet, {
                        raw: true,
                    })
                )
                return this.tempItems
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.item_class {
    background-color: #3385d7 !important;
    color: white !important;
}
</style>
