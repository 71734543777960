<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            ref="menu"
            cols="12"
            md="3"
            sm="12"
            lg="3"
            class="border-right d-flex flex-column flex-nowrap"
            :style="{ height: `${height}px` }"
        >
            <v-row
                no-gutters
                class="border-bottom font-weight-bold flex-grow-0"
            >
                <v-col
                    v-if="$vuetify.breakpoint.smAndDown"
                    cols="9"
                    class="pl-4 mr-0 mt-1"
                >
                    <v-row>
                        <p class="font-weight-bold mx-2 mt-2">
                            CALCULATIONS
                        </p>
                    </v-row>
                </v-col>
                <v-col cols="2" v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn
                        fab
                        elevation="0"
                        small
                        class="primary--text mt-1"
                        color="primary"
                        @click="openNewCalculation = true"
                    >
                        <v-icon color="white">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-col>
                <!-- Search -->
                <v-col
                    cols="12"
                    md="10"
                    lg="10"
                    :class="
                        $vuetify.breakpoint.smAndDown
                            ? 'pb-2 px-2 mt-n1 pt-0'
                            : 'py-2 px-2'
                    "
                >
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
                <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn
                        fab
                        elevation="0"
                        small
                        class="primary--text ml-1 mt-2"
                        color="primary"
                        @click="openNewCalculation = true"
                    >
                        <v-icon color="white">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- calculations list -->
            <v-list v-else class="overflow-y-auto pa-0">
                <template v-if="filteredCalculations.length > 0">
                    <v-list-item-group>
                        <v-list-item
                            v-for="(calculation, i) in filteredCalculations"
                            :key="calculation.id"
                            color="primary"
                            three-line
                            link
                            @click="hide"
                            :class="{
                                'border-bottom':
                                    i < filteredCalculations.length - 1,
                            }"
                            :to="{
                                name: 'calculation',
                                params: {
                                    id: calculation.id,
                                    calculation: calculation,
                                    settings,
                                },
                            }"
                        >
                            <v-sheet
                                min-height="70"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="calculation"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                    class="fill-height"
                                >
                                    <v-list-item-content class="mb-0">
                                        <v-list-item-title
                                            class="mt-1 mb-2 py-0 font-weight-regular"
                                            >{{
                                                calculation.title
                                            }}</v-list-item-title
                                        >
                                        <v-row
                                            class="d-flex flex-wrap font-weight-regular"
                                        >
                                            <v-col cols="12" class="my-0 py-0">
                                                <v-list-item-subtitle
                                                    class="mt-0"
                                                    >{{
                                                        calculation.updatedOn ||
                                                            calculation.createdOn
                                                    }}</v-list-item-subtitle
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-lazy>
                            </v-sheet>
                        </v-list-item>
                    </v-list-item-group>
                </template>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            No calculations
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col
            ref="content"
            cols="12"
            sm="0"
            md="9"
            lg="9"
            :style="{ height: `${height}px` }"
        >
            <router-view @show="show" />
        </v-col>
        <v-dialog
            :retain-focus="false"
            v-model="openNewCalculation"
            persistent
            max-width="640px"
        >
            <v-card>
                <v-card-title class="text-h5">New Calculation</v-card-title>
                <v-card-text>
                    <v-form ref="newCalcForm">
                        <div v-if="baseCalculation">
                            <v-row no-gutters class="mb-0 pb-0 mt-3">
                                <p
                                    :style="{
                                        'font-size': '16px',
                                        color: '#000000',
                                    }"
                                >
                                    General params
                                </p>
                            </v-row>
                            <v-row no-gutters class="mt-n1">
                                <v-col cols="12" class="d-flex align-center">
                                    <v-text-field
                                        v-model="baseCalculation.title"
                                        :rules="[rules.required]"
                                        class="py-0 my-0"
                                        prepend-icon="mdi-package-variant-closed"
                                        label="Name *"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col
                                    cols="12"
                                    xl="6"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.materialPrice"
                                        :rules="[rules.required]"
                                        class="py-0 my-0"
                                        prepend-icon="mdi-package-variant-closed"
                                        label="Material price (usd/lb) *"
                                        required
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="6"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.paintPrice"
                                        :rules="[rules.required]"
                                        class="py-0 my-0"
                                        prepend-icon="mdi-format-color-fill"
                                        label="Paint price (usd/lb) *"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-1">
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.waste"
                                        :rules="[rules.required]"
                                        class="py-0 my-0"
                                        prepend-icon="mdi-grain"
                                        label="Waste (0-100%) *"
                                        required
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.exchange"
                                        :rules="[rules.required]"
                                        class="py-0 my-0"
                                        prepend-icon="mdi-cash"
                                        label="Exchange *"
                                        required
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.margin"
                                        :rules="[rules.required]"
                                        class="py-0 my-0"
                                        prepend-icon="mdi-percent"
                                        label="Margin *"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-0 pb-0 mt-3">
                                <p
                                    :style="{
                                        'font-size': '16px',
                                        color: '#000000',
                                    }"
                                >
                                    Area sizes (sqft) *
                                </p>
                            </v-row>
                            <v-row
                                v-if="baseCalculation.areas"
                                no-gutters
                                class="mt-n1"
                            >
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.areas.small"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-ruler"
                                        label="Small"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.areas.medium"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="Medium"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.areas.large"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="Large"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-0 pb-0 mt-3">
                                <p
                                    :style="{
                                        'font-size': '16px',
                                        color: '#000000',
                                    }"
                                >
                                    Weight per Area (lbs/sqft) *
                                </p>
                            </v-row>
                            <v-row
                                v-if="baseCalculation.weights"
                                no-gutters
                                class="mt-n1"
                            >
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.weights.small"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-weight-pound"
                                        label="Small"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.weights.medium"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="Medium"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.weights.large"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="Large"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-0 pb-0 mt-3">
                                <p
                                    :style="{
                                        'font-size': '16px',
                                        color: '#000000',
                                    }"
                                >
                                    Material costs per quantity *
                                </p>
                            </v-row>
                            <v-row
                                v-if="baseCalculation.materials"
                                no-gutters
                                class="mt-n1"
                            >
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="
                                            baseCalculation.materials.small
                                        "
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-package-variant-closed-plus"
                                        label="> 1"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="
                                            baseCalculation.materials.medium
                                        "
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="> 10,000"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="
                                            baseCalculation.materials.large
                                        "
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="> 20,000"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-0 pb-0 mt-3">
                                <p
                                    :style="{
                                        'font-size': '16px',
                                        color: '#000000',
                                    }"
                                >
                                    Assembly costs per quantity *
                                </p>
                            </v-row>
                            <v-row
                                v-if="baseCalculation.assambly"
                                no-gutters
                                class="mt-n1"
                            >
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.assambly.small"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-package-variant-closed-plus"
                                        label="> 1"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="
                                            baseCalculation.assambly.medium
                                        "
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="> 10,000"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.assambly.large"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="> 20,000"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-0 pb-0 mt-3">
                                <p
                                    :style="{
                                        'font-size': '16px',
                                        color: '#000000',
                                    }"
                                >
                                    Package costs per quantity *
                                </p>
                            </v-row>
                            <v-row
                                v-if="baseCalculation.packages"
                                no-gutters
                                class="mt-n1"
                            >
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.packages.small"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-package-variant-closed-plus"
                                        label="> 1"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="
                                            baseCalculation.packages.medium
                                        "
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="> 10,000"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    xl="4"
                                    lg="4"
                                    md="4"
                                    sm="12"
                                    class="d-flex align-center"
                                >
                                    <v-text-field
                                        v-model="baseCalculation.packages.large"
                                        :rules="[rules.required]"
                                        prepend-icon="mdi-arrow-split-vertical"
                                        label="> 20,000"
                                        required
                                        class="py-0 my-0"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openNewCalculation = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="newLoading"
                        @click="newCalculation"
                        rounded
                        color="primary"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'Calculations',
    data() {
        return {
            loading: false,
            newLoading: false,
            openNewCalculation: false,
            height: 0,
            search: null,
            error: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            calculations: [],
            settingsCalculation: null,
            baseCalculation: {
                title: '',
                currency: 'USD',
                materialPrice: 2.1,
                paintPrice: 2.25,
                waste: 8,
                exchange: 4900,
                margin: 40,
                areas: { small: 3.2, medium: 15.1, large: 15.1 },
                weights: { small: 6.34, medium: 5.72, large: 5.42 },
                materials: { small: 0.6, medium: 0.52, large: 0.45 },
                assambly: { small: 0.7, medium: 0.61, large: 0.53 },
                packages: { small: 0.16, medium: 0.14, large: 0.12 },
            },
            rules: {
                required: v => !!v || 'Required',
            },
        }
    },
    computed: {
        filteredCalculations: function() {
            if (!this.search) {
                return this.calculations
            } else {
                return this.calculations.filter(calculation => {
                    if (calculation.client)
                        return calculation.title
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    else
                        return calculation.title
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                })
            }
        },
        ...mapState(['users', 'contacts', 'settings']),
    },
    provide() {
        return {
            replaceCalculation: this.replaceCalculation,
            updatecalculation: this.updatecalculation,
        }
    },
    watch: {
        calculations: function(newCalculations) {
            const params = this.$router.currentRoute.params
            if (params.id) {
                if (!params.calculation) {
                    const calculation = newCalculations.find(
                        i => i.id == params.id
                    )
                    this.$router.currentRoute.params.calculation = calculation
                }
            }
        },
        openNewCalculation: function(state) {
            if (state) {
                const params = !this.settingsCalculation
                    ? this.settings.find(s => s.name == 'Calculations')
                    : this.settingsCalculation
                if (params) {
                    this.baseCalculation = params
                }
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async updatecalculation(calculation) {
            const index = this.calculations.findIndex(
                item => item.id === calculation.id
            )
            if (index >= 0) this.calculations[index] = calculation
        },
        async openCalculation(calculation) {
            this.cardCalculation = _.cloneDeep(calculation)
            this.$router.replace({
                name: 'calculation',
                params: {
                    id: this.cardCalculation.id,
                    calculation: this.cardCalculation,
                    settings: this.settings,
                },
            })
        },
        onResize() {
            this.height =
                window.innerHeight -
                this.$vuetify.application.top -
                this.$vuetify.application.footer
            this.heightPopUp = window.innerHeight - 62
        },
        hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        ...mapActions([
            'addUsersAction',
            'addSettingsAction',
            'removeCalculationAction',
        ]),
        getCalculations: async function() {
            try {
                this.error = null
                this.loading = true
                this.calculations = []
                const {
                    data: { calculations },
                } = await API.getCalculations()
                for (const calculation of calculations) {
                    if (!calculation.number) {
                        calculation.shortId = calculation.createdOn._seconds
                            .toString(16)
                            .toUpperCase()
                    }
                    calculation.seconds = calculation.updatedOn
                        ? calculation.updatedOn._seconds
                        : calculation.createdOn._seconds
                    calculation.date = moment
                        .unix(calculation.createdOn._seconds)
                        .format('MMM DD YYYY ')
                    calculation.createdOn = moment
                        .unix(calculation.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                    if (calculation.updatedOn) {
                        calculation.updatedOn = moment
                            .unix(calculation.updatedOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    }
                    this.calculations.push(calculation)
                }
                this.calculations.sort((a, b) => b.seconds - a.seconds)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getSettings() {
            try {
                this.loading = true
                this.error = null
                const {
                    data: { settings },
                } = await API.getSettings()
                let orderSettings = settings.filter(x => x.name == 'Company')
                orderSettings[1] = settings.find(x => x.name == 'Exchange')
                orderSettings[2] = settings.find(x => x.name == 'Permissions')
                orderSettings[3] = settings.find(x => x.name == 'Quotes')
                const auxCalculation = settings.find(
                    x => x.name == 'Calculations'
                )
                orderSettings[4] = auxCalculation
                if (auxCalculation) {
                    this.settingsCalculation = auxCalculation
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async newCalculation() {
            try {
                this.newLoading = true
                const {
                    data: { calculation },
                } = await API.createCalculation(this.baseCalculation)
                calculation.createdOn = moment
                    .unix(calculation.createdOn._seconds)
                    .format('MMM/DD/YYYY h:mm a')
                this.calculations.unshift(calculation)
                this.newLoading = false
                this.openNewCalculation = false
                this.$router.push({
                    name: 'calculation',
                    params: {
                        id: calculation.id,
                        calculation: calculation,
                        settings: this.settings,
                    },
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
    async mounted() {
        try {
            this.$root.$on('replaceCalculation', calculation => {
                this.updatecalculation(calculation)
            })
            this.loading = true
            this.error = null
            if (this.settings.length == 0) {
                await this.getSettings()
            }
            await this.getCalculations()
            if (this.calculations.length > 0) {
                const calculation = this.calculations[0]
                this.$router.push({
                    name: 'calculation',
                    params: {
                        id: calculation.id,
                        calculation: calculation,
                        settings: this.settings,
                    },
                })
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.underline-span {
    border-bottom: 1px solid;
    color: #1976d2;
}
</style>
