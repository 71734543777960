<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab
                :to="{
                    name: 'leads-inprogress',
                    params: { ruta: 'inProgress' },
                }"
            >
                NEW
            </v-tab>
            <v-tab
                :to="{
                    name: 'leads-onhold',
                    params: { ruta: 'onHold' },
                }"
            >
                ON HOLD
            </v-tab>
            <v-tab
                :to="{
                    name: 'leads-approved',
                    params: { ruta: 'approved' },
                }"
            >
                APPROVED
            </v-tab>
            <v-tab
                :to="{
                    name: 'leads-rejected',
                    params: { ruta: 'rejected' },
                }"
            >
                REJECTED
            </v-tab>
            <v-tab
                :to="{
                    name: 'leads-modifiedQuote',
                    params: { ruta: 'modified' },
                }"
            >
                MODIFICATION REQUESTED
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="{ height: `${height}px` }"
            >
                <router-view :key="$route.fullPath" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'Leading',
    data: () => ({
        loading: true,
        height: 0,
    }),
    async mounted() {
        try {
            this.loading = true
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = window.innerHeight - containerHeight - tabsHeight - 25
        },
    },
}
</script>

<style></style>
