<template>
    <div>
        <v-card id="details" :loading="loading" height="100vh">
            <v-card-title ref="title">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>
                    {{
                        windowTitle +
                            this.status.charAt(0).toUpperCase() +
                            this.status.slice(1)
                    }}
                </h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="mt-n5">
                <v-row no-gutters>
                    <v-data-table
                        :headers="showDelayInfo ? delayHeaders : headers"
                        :items="filterRequests"
                        class="elevation-0"
                        :mobile-breakpoint="0"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                        @click:row="openRequest"
                        fixed-header
                    >
                        <!--TOP-->
                        <template v-slot:top>
                            <v-row
                                no-gutter
                                class="py-4 mx-0"
                                :style="{ 'background-color': '#eeeeee' }"
                            >
                                <v-col cols="6" class="d-flex">
                                    <p class="font-weight-medium my-2">
                                        PURCHASES 
                                    </p>
                                </v-col>
                                <v-col cols="6" class="d-flex justify-end">
                                    <v-btn
                                        rounded
                                        color="primary"
                                        @click="generateXLS"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        DOWNLOAD
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>

                        <!--HEADERS-->
                        <template v-slot:[`header.id`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="idToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.date`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="dateToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.dueDate`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="dueDateToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.delayDays`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="delayDaysToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.type`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="typeToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.createdBy`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="usersToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.projectManager`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="projectManagerToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.project`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="projectToFilter"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <template v-slot:[`header.daysInStatus`]="{ header }">
                            <v-text-field
                                :label="header.text"
                                v-model="daysInStatus"
                                dense
                                class="pt-2"
                            />
                        </template>
                        <!--ITEMS-->
                        <template v-slot:[`item.id`]="{ item }">
                            <p>
                                {{ item.number }}
                            </p>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            <p class="my-0">
                                {{ item.formattedDate }}
                            </p>
                        </template>
                        <template v-slot:[`item.dueDate`]="{ item }">
                            <p class="my-0">
                                {{ item.dueDateFormatted }}
                            </p>
                        </template>
                        <template v-slot:[`item.delayDays`]="{ item }">
                            <p class="my-0">
                                {{ item.delayDays }}
                            </p>
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <p class="my-0 text-capitalize">
                                {{ item.type }}
                            </p>
                        </template>
                        <template v-slot:[`item.createdBy`]="{ item }">
                            <p class="my-0 text-capitalize">
                                {{ item.createdBy.name }}
                            </p>
                        </template>
                        <template v-slot:[`item.projectManager`]="{ item }">
                            <p class="my-0 text-capitalize">
                                {{ item.projectManager.name }}
                            </p>
                        </template>
                        <template v-slot:[`item.project`]="{ item }">
                            <p class="my-0 text-capitalize">
                                {{ item.project.name }}
                            </p>
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
        </v-card>

        <!--Error Alert-->
        <Errors />
        <!--Request Form-->
        <v-dialog persistent fullscreen v-model="openForm">
            <PurchaseRequest
                v-if="openForm"
                :request="selectedRequest"
                :originalRequest="originalRequest"
                :createForm="createForm"
                @closeDialog="closeRequestForm"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Errors from '@/components/Layout/Errors'
import { auth } from '@/services/firebase'
import moment from 'moment'
import PurchaseRequest from '@/components/Purchasing/PurchaseRequest.vue'
import API from '@/services/api'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'PurchasesDelayed',
    props: {
        delayed: {
            type: Array,
            default: () => [],
        },
        status: {
            type: String,
            default: () => '',
        },
        showDelayInfo: {
            type: Boolean,
            default: () => true,
        },
        windowTitle: {
            type: String,
            default: () => '',
        },
    },
    components: {
        Errors,
        PurchaseRequest,
    },
    data: () => ({
        companyId: JSON.parse(localStorage.getItem('company')),
        loading: false,
        openForm: false,
        selectedRequest: {},
        originalRequest: {},
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'P. MANAGER',
                value: 'projectManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DAYS IN STATUS',
                value: 'daysInStatus',
                align: 'center',
                sortable: false,
            },
        ],
        delayHeaders: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DUE DATE',
                value: 'dueDate',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELAY DAYS',
                value: 'delayDays',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'P. MANAGER',
                value: 'projectManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DAYS IN STATUS',
                value: 'daysInStatus',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        valid: false,
        user: {},
        createForm: false,
        idToFilter: '',
        dateToFilter: '',
        typeToFilter: '',
        usersToFilter: '',
        projectManagerToFilter: '',
        projectToFilter: '',
        daysInStatus: '',
        dueDateToFilter: '',
        delayDaysToFilter: '',
    }),
    computed: {
        filterRequests() {
            let conditions = []
            if (this.idToFilter) {
                conditions.push(this.filterForId)
            }
            if (this.dateToFilter) {
                conditions.push(this.filterForDate)
            }
            if (this.typeToFilter) {
                conditions.push(this.filterForType)
            }
            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }
            if (this.projectManagerToFilter) {
                conditions.push(this.filterForPrjectM)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterForProject)
            }
            if (this.daysInStatus) {
                conditions.push(this.filterForDaysInStatus)
            }
            if (this.dueDateToFilter) {
                conditions.push(this.filterForDueDate)
            }
            if (this.delayDaysToFilter) {
                conditions.push(this.filterForDelayDays)
            }

            if (conditions.length > 0) {
                return this.delayed.filter(request => {
                    return conditions.every(condition => {
                        return condition(request)
                    })
                })
            }
            this.sort()
            return this.delayed
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const now = new Date()
            this.delayed.sort((a, b) => b.number - a.number)
            this.delayed.forEach(purchase => {
                purchase.formattedDate = this.formatDate(
                    purchase.createdOn.seconds
                )
                const dueDateSeconds = purchase.dueDate.seconds
                const dueDateMilliseconds = dueDateSeconds * 1000
                const dueDate = new Date(dueDateMilliseconds)

                const timeDifference = now.getTime() - dueDate.getTime()
                const daysDifference = Math.ceil(
                    timeDifference / (1000 * 3600 * 24)
                )
                purchase.delayDays = daysDifference
                purchase.dueDateFormatted = this.formatDate(
                    purchase.dueDate.seconds
                )
            })

            this.userId = auth().currentUser.uid
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        filterForId(item) {
            return item.number.toString().includes(this.idToFilter)
        },
        filterForDate(item) {
            const date = this.formatDate(
                item.createdOn.seconds
                    ? item.createdOn.seconds
                    : item.createdOn._seconds
            )
            return date.includes(this.dateToFilter)
        },
        filterForType(item) {
            return item.type
                .toLowerCase()
                .includes(this.typeToFilter.toLowerCase())
        },
        filterForUsers(item) {
            return (
                item.createdBy &&
                item.createdBy.name
                    .toLowerCase()
                    .includes(this.usersToFilter.toLowerCase())
            )
        },
        filterForPrjectM(item) {
            return (
                item.projectManager &&
                item.projectManager.name
                    .toLowerCase()
                    .includes(this.projectManagerToFilter.toLowerCase())
            )
        },
        filterForProject(item) {
            return item.project.name
                .toLowerCase()
                .includes(this.projectToFilter.toLowerCase())
        },
        filterForDaysInStatus(item) {
            return item.daysInStatus
                .toLowerCase()
                .includes(this.daysInStatus.toLowerCase())
        },
        filterForDueDate(item) {
            const date = this.formatDate(
                item.dueDate.seconds
                    ? item.dueDate.seconds
                    : item.dueDate._seconds
            )
            return date.includes(this.dueDateToFilter)
        },
        filterForDelayDays(item) {
            return item.delayDays
                .toString()
                .toLowerCase()
                .includes(this.delayDaysToFilter.toLowerCase())
        },
        sort() {
            this.delayed.sort(
                (a, b) => b.createdOn.seconds - a.createdOn.seconds
            )
        },
        openRequest(request) {
            const status = this.delayed[0].status
            const user = this.user
            if (
                (status == 'pricing' &&
                    !user.permissions.includes('pricing')) ||
                (status == 'inTransit' &&
                    !user.permissions.includes('inTransitPurchase')) ||
                (status == 'received' &&
                    !user.permissions.includes('receivedPurchase'))
            ) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: 'cannot perform this action.',
                })
            } else {
                this.selectedRequest = { ...request }
                this.originalRequest = { ...request }
                this.openForm = true
            }
        },
        closeRequestForm() {
            this.openForm = false
            this.selectedRequest = {}
            this.originalRequest = {}
        },
        close() {
            this.$emit('closeDialog')
        },
        async generateXLS() {
            try {
                this.loading = true
                const reportData = []
                for (const purchase of this.delayed) {
                    let row = {}

                    const {
                        number,
                        formattedDate,
                        dueDateFormatted,
                        delayDays,
                        type,
                        createdBy,
                        projectManager,
                        project,
                        daysInStatus,
                    } = purchase

                    row.id = number
                    row.date = formattedDate
                    row.dueDate = dueDateFormatted
                    if (this.showDelayInfo) {
                        row.delayDays = delayDays
                    }
                    row.type = type
                    row.createdBy = createdBy.name
                    row.projectManager = projectManager.name
                    row.project = project.name
                    row.daysInStatus = daysInStatus
                    reportData.push(row)
                    row = {}
                }

                const reportWS = XLSX.utils.json_to_sheet(reportData)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, reportWS, 'purchases')
                const date = new Date()
                const year = date.getFullYear()
                const month = date.getMonth() + 1
                const day = date.getDate()

                const documentName = `purchases ${this.status} (${year}-${month}-${day}).xlsx`
                XLSX.writeFile(wb, documentName)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
::v-deep(.v-data-table-header th) {
    background-color: #eeeeee !important
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-data-table__wrapper {
  max-height: 75vh;
  overflow-y: auto;
}
.v-data-table__row {
  height: 64px; 
}
.v-data-table__wrapper thead tr {
  position: sticky; 
  top: 0;
  z-index: 10;
}
</style>
