<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            cols="12"
            md="3"
            lg="3"
            sm="12"
            ref="menu"
            class="border-right d-flex flex-column flex-nowrap"
            :style="{ height: `${height}px` }"
        >
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Search -->
                <v-col cols="12" class="pa-2 pb-0">
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
                <!-- Sort -->
                <v-col
                    cols="7"
                    class="px-2 py-2 d-flex align-center justify-start"
                >
                    <v-select
                        v-model="sortBy"
                        flat
                        :items="sortOptions"
                        hide-details="auto"
                        solo
                        :append-icon="null"
                        :append-outer-icon="
                            desc ? 'mdi-chevron-down' : 'mdi-chevron-up'
                        "
                        @click:append-outer="desc = !desc"
                    />
                </v-col>
                <!-- New -->
                <v-col
                    cols="5"
                    class="px-2 py-2 d-flex align-center justify-end"
                >
                    <v-btn
                        depressed
                        color="primary"
                        rounded
                        @click="openCreateDialog"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        <span>New</span>
                    </v-btn>
                    <v-dialog
                        :retain-focus="false"
                        v-model="createDialog"
                        persistent
                        max-width="600px"
                    >
                        <UserForm
                            v-if="createDialog"
                            title="New User"
                            :create-form="true"
                            @closeDialog="closeCreateDialog"
                            @addUser="addUser"
                        />
                    </v-dialog>
                </v-col>
            </v-row>
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Users list -->
            <v-list v-else class="overflow-y-auto pa-0">
                <template v-if="filteredUsers.length > 0">
                    <div v-for="user in filteredUsers" :key="user.id">
                        <UserCard :user="user" @userDetail="userDetail" />
                    </div>
                </template>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            No users
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            cols="12"
            md="9"
            lg="9"
            :style="{ height: `${height}px` }"
        >
            <router-view @show="show" />
        </v-col>
        <v-dialog
            v-else
            :retain-focus="false"
            v-model="userDialog"
            persistent
            fullscreen
        >
            <div v-if="userDialog" :style="{ height: '100%' }">
                <router-view @show="show" />
            </div>
        </v-dialog>
    </v-row>
</template>

<script>
import UserForm from '@/components/Users/UserForm'
import UserCard from '@/components/Users/UserCard'
import { storage } from '@/services/firebase'
import { mapState, mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
export default {
    name: 'Users',
    components: {
        UserForm,
        UserCard,
    },
    data() {
        return {
            users: [],
            folderPictures: 'users_pictures',
            loading: false,
            height: 0,
            search: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderLogo: 'company_logo',
            createDialog: false,
            loadingError: false,
            errorMsg: null,
            sortOptions: [
                {
                    text: 'Role',
                    value: 'role',
                },
                {
                    text: 'Name',
                    value: 'name',
                },
            ],
            sortBy: 'role',
            desc: true,
            selectedUser: null,
            categories: [],
            userDialog: false,
        }
    },
    provide() {
        return {
            closeDetail: this.closeDetail,
        }
    },
    watch: {
        users: function(newUsers) {
            const params = this.$router.currentRoute.params
            if (params.id) {
                if (!params.user) {
                    const user = newUsers.find(i => i.id == params.id)
                    this.$router.currentRoute.params.user = user
                }
            }
        },
    },
    computed: {
        filteredUsers() {
            function compare(key, desc) {
                return function innerSort(a, b) {
                    if (
                        !Object.prototype.hasOwnProperty.call(a, key) ||
                        !Object.prototype.hasOwnProperty.call(b, key)
                    ) {
                        return 0
                    }

                    const varA =
                        typeof a[key] === 'string'
                            ? a[key].toUpperCase()
                            : a[key]
                    const varB =
                        typeof b[key] === 'string'
                            ? b[key].toUpperCase()
                            : b[key]

                    let comparison = 0
                    if (varA > varB) {
                        comparison = 1
                    } else if (varA < varB) {
                        comparison = -1
                    }

                    return desc ? comparison * -1 : comparison
                }
            }
            return this.users
                .filter(q => {
                    if (this.search)
                        return q.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    return true
                })
                .sort(compare(this.sortBy, this.desc))
        },
        ...mapState([
            'companyRoles',
            'usersPermissions',
            'settings',
            'userRef',
        ]),
    },
    async created() {
        try {
            this.loadingError = false
            this.errorMsg = null
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            const {
                data: { users },
            } = await API.getUsers(user)
            for (const user of users) {
                user.createdOn = moment
                    .unix(user.createdOn._seconds)
                    .format('MMM/DD/YYYY HH:mm')
            }
            this.users = users
            if (this.settings.length == 0) {
                this.getSettings()
            } else {
                const settingsRoles = this.settings.filter(
                    x => x.name == 'Permissions'
                )
                const usersPermissions = []
                this.categories = Object.keys(settingsRoles[0].permissions)
                this.categories.forEach(key => {
                    for (let item of settingsRoles[0].permissions[key] || []) {
                        usersPermissions.push({
                            text: item.name,
                            value: item.permission,
                            description: item.description,
                        })
                    }
                })
                this.addRolesAction(settingsRoles[0].roles)
                this.addPermissionsAction(usersPermissions)
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            this.height =
                window.innerHeight - this.$vuetify.application.top - 40
        },
        closeDetail(value) {
            this.userDialog = value
        },
        hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        userDetail(user) {
            this.userDialog = true
            this.$router.push({
                name: 'user',
                params: { id: user.id, user: user },
            })
        },
        ...mapActions([
            'addSettingsAction',
            'addRolesAction',
            'addPermissionsAction',
        ]),
        openCreateDialog: function() {
            this.createDialog = true
        },
        closeCreateDialog: function() {
            this.createDialog = false
        },
        addUser: function(user) {
            this.users.push(user)
            this.$router.push({
                name: 'user',
                params: { id: user.id, user: user },
            })
        },
        getSettings: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { settings },
                } = await API.getSettings()
                const indexLogo = settings.findIndex(x => x.name == 'Company')
                if (settings[indexLogo].generalInfo.image.file) {
                    const path = `${this.companyId}/${this.folderLogo}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(
                        settings[indexLogo].generalInfo.image.file
                    )
                    settings[
                        indexLogo
                    ].generalInfo.image.imageURL = await profilePicRef.getDownloadURL()
                }
                let orderSettings = settings.filter(x => x.name == 'Company')
                orderSettings[1] = settings.find(x => x.name == 'Exchange')
                orderSettings[2] = settings.find(x => x.name == 'Permissions')
                orderSettings[3] = settings.find(x => x.name == 'Quotes')

                const calculations = this.userRef.permissions.find(
                    x => x == 'calculations'
                )

                if (calculations) {
                    const item = settings.find(x => x.name == 'Calculations')
                    if (item) {
                        orderSettings[4] = item
                    }
                }
                const finish = settings.find(x => x.name == 'FinishAndUCCode')
                if (finish) {
                    orderSettings.push(finish)
                }
                const processes = settings.find(x => x.name == 'Processes')
                const products = settings.find(x => x.name == 'Products')
                const invoicing = settings.find(x => x.name == 'Invoicing')
                if (invoicing) {
                    orderSettings.push(invoicing)
                }

                if (processes) {
                    const machines = {
                        name: 'Machines',
                        nameRoute: 'machinesSettings',
                        id: processes.id,
                    }
                    orderSettings.push(machines)
                }

                const workOrderTab = {
                    name: 'WorkOrders',
                    nameRoute: 'workOrderSettings',
                    processes: processes || {},
                    products: products || {},
                }
                orderSettings.push(workOrderTab)
                const operatorsTab = {
                    name: 'Operators',
                    nameRoute: 'operators',
                }
                orderSettings.push(operatorsTab)
                this.addSettingsAction(orderSettings)
                const settingsRoles = this.settings.filter(
                    x => x.name == 'Permissions'
                )
                this.categories = Object.keys(settingsRoles[0].permissions)
                const usersPermissions = []
                this.categories.forEach(key => {
                    for (let item of settingsRoles[0].permissions[key]) {
                        usersPermissions.push({
                            text: item.name,
                            value: item.permission,
                            description: item.description,
                        })
                    }
                })
                this.addRolesAction(settingsRoles[0].roles)
                this.addPermissionsAction(usersPermissions)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
