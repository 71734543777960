var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],style:({
        display: 'flex',
        'background-color': '#FAFAFA',
        height: `${_vm.height}px`,
    }),attrs:{"fluid":""}},[_c('v-row',{style:({
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
        }),attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"mb-2",style:({ 'margin-left': 'auto', 'margin-right': 'auto' }),attrs:{"contain":"","src":require("../assets/PROJECT_AGENDA.png"),"max-height":"320px","max-width":"320px"}}),_c('div',{style:({ display: 'flex', 'justify-content': 'center' })},[_c('v-progress-circular',{staticClass:"progress_circular",attrs:{"dark":"","indeterminate":"","size":"44"}})],1)],1),_c('v-spacer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }