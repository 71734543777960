var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading,"width":"100%"}},[_c('v-card-title',{staticClass:"text-h5"},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","color":"primary","icon":"","rounded":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),(!_vm.createForm)?_c('v-btn',{attrs:{"depressed":"","color":"primary","rounded":"","icon":""},on:{"click":_vm.toUpdate}},[_c('v-icon',[_vm._v("mdi-lead-pencil")])],1):_vm._e()],1),_c('v-card-text',[_c('v-form',{ref:"costForm",staticClass:"we",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{staticClass:"align-center",style:({ 'row-gap': '10px' }),attrs:{"no-gutters":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"text-body-2 pt-3",attrs:{"dense":"","hide-details":"","required":"","rules":[_vm.rules.required],"placeholder":"Detailed name of the scope","prepend-icon":"mdi-file-document-edit","label":"Reference*","disabled":_vm.lead.status == 'rejected' || !_vm.isEditing},model:{value:(_vm.cost.reference),callback:function ($$v) {_vm.$set(_vm.cost, "reference", $$v)},expression:"cost.reference"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"text-body-2 mt-0 pt-0",attrs:{"flat":"","hide-details":"","required":"","placeholder":"Reviewer By","items":_vm.users,"item-text":"name","item-value":"id","prepend-icon":"mdi-account","label":"Scope Reviewer","disabled":(_vm.lead && _vm.lead.status == 'rejected') ||
                                    !_vm.isEditing},model:{value:(_vm.cost.reviewer),callback:function ($$v) {_vm.$set(_vm.cost, "reviewer", $$v)},expression:"cost.reviewer"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-0",attrs:{"disabled":(_vm.lead &&
                                            _vm.lead.status == 'rejected') ||
                                            !_vm.isEditing,"label":"Engineering Date","prepend-icon":"mdi-calendar","readonly":"","required":"","hide-details":""},model:{value:(_vm.cost.engineeringDate),callback:function ($$v) {_vm.$set(_vm.cost, "engineeringDate", $$v)},expression:"cost.engineeringDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minDate,"disabled":(_vm.lead && _vm.lead.status == 'rejected') ||
                                        !_vm.isEditing},model:{value:(_vm.cost.engineeringDate),callback:function ($$v) {_vm.$set(_vm.cost, "engineeringDate", $$v)},expression:"cost.engineeringDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"text-body-2 pt-0 mt-0",attrs:{"flat":"","hide-details":"","required":"","placeholder":"Cost category for invoicing","rules":[_vm.rules.required],"items":_vm.categories,"item-text":"name","item-value":"name","prepend-icon":"mdi-text-box-search-outline","label":"Category *","disabled":(_vm.lead && _vm.lead.status == 'rejected') ||
                                    !_vm.isEditing},model:{value:(_vm.cost.category),callback:function ($$v) {_vm.$set(_vm.cost, "category", $$v)},expression:"cost.category"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"text-body-2 pt-0 mt-0",attrs:{"flat":"","hide-details":"","required":"","value":"ft2","rules":[_vm.rules.required],"items":_vm.units,"prepend-icon":"mdi-tape-measure","disabled":(_vm.lead && _vm.lead.status == 'rejected') ||
                                    !_vm.isEditing,"label":"Measure*"},model:{value:(_vm.cost.measure),callback:function ($$v) {_vm.$set(_vm.cost, "measure", $$v)},expression:"cost.measure"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"text-body-2 pt-0 mt-0",attrs:{"dense":"","type":"number","hide-details":"","required":"","rules":[_vm.rules.number2],"placeholder":"Item units","prepend-icon":"mdi-chart-box-plus-outline","disabled":_vm.lead.status == 'rejected' || !_vm.isEditing,"label":"Units*"},model:{value:(_vm.cost.quantity),callback:function ($$v) {_vm.$set(_vm.cost, "quantity", $$v)},expression:"cost.quantity"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-textarea',{staticClass:"text-body-2 pt-0 mt-0 pb-0",style:({ 'min-height': '100px' }),attrs:{"hide-details":"","rows":"3","placeholder":"Detailed description of the scope","flat":"","prepend-icon":"mdi-pencil-box-outline","disabled":_vm.lead.status == 'rejected' || !_vm.isEditing,"label":"Description"},model:{value:(_vm.cost.description),callback:function ($$v) {_vm.$set(_vm.cost, "description", $$v)},expression:"cost.description"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mr-3 ml-5 mt-0 pb-5 pt-0"},[_c('small',[_vm._v("* indicates required field")]),_c('v-spacer'),(_vm.createForm || _vm.isEditing)?_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.saveCost}},[_vm._v(" Save ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }