<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            ref="menu"
            cols="12"
            md="3"
            sm="12"
            lg="3"
            class="border-right d-flex flex-column flex-nowrap"
            :style="{ height: `${height}px` }"
        >
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Search -->
                <v-col cols="12" class="pa-2 pb-0">
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
                <!-- Sort -->
                <v-col
                    cols="7"
                    class="py-2 px-2 d-flex align-center justify-start"
                >
                    <v-select
                        v-model="sortBy"
                        flat
                        :items="sortOptions"
                        hide-details="auto"
                        solo
                        :append-icon="null"
                        :append-outer-icon="
                            desc ? 'mdi-chevron-down' : 'mdi-chevron-up'
                        "
                        @click:append-outer="desc = !desc"
                    />
                </v-col>
                <!-- New -->
                <v-col
                    cols="5"
                    class="px-2 py-2 d-flex align-center justify-end"
                >
                    <v-btn
                        depressed
                        color="primary"
                        rounded
                        @click="openCreateDialog"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        <span>New</span>
                    </v-btn>

                    <ClientForm
                        v-model="createDialog"
                        title="New Client"
                        :create-form="true"
                        @closeDialog="closeCreateDialog"
                        @addClient="addClient"
                    />
                </v-col>
            </v-row>
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Quotes list -->
            <v-list
                v-if="!loading"
                class="overflow-y-auto pa-0"
                id="scroll-target"
                v-scroll:#scroll-target="onScroll"
            >
                <template v-if="filteredClients.length > 0">
                    <v-list-item
                        v-for="(client, i) in filteredClients2"
                        :key="client.id"
                        class="pa-0"
                    >
                        <CardClient
                            @click="hide"
                            :class="{
                                'border-bottom': i < filteredClients.length - 1,
                            }"
                            :client="client"
                            @clientDetail="clientDetail"
                        >
                        </CardClient>
                    </v-list-item>
                </template>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            No clients
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            ref="content"
            cols="12"
            sm="0"
            md="9"
            lg="9"
            :style="{ height: `${height}px` }"
        >
            <router-view @show="show" />
        </v-col>
        <v-dialog
            v-else
            :retain-focus="false"
            v-model="clientDialog"
            persistent
            fullscreen
        >
            <div v-if="clientDialog" :style="{ height: '100%' }">
                <router-view @show="show" />
            </div>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="contactDialog"
            persistent
            max-width="600px"
        >
            <ContactClientForm
                v-if="contactDialog"
                title="New Contact"
                :create-form="true"
                @closeDialog="closeContactDialog"
                @addContact="addContact"
                :contactRoles="client.companyRoles"
                :clientId="client.id"
            />
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import ClientForm from '@/components/Clients/ClientForm'
import CardClient from '@/components/Clients/CardClient'
import ContactClientForm from '@/components/Clients/ContactForm'
export default {
    name: 'Clients',
    components: {
        ClientForm,
        CardClient,
        ContactClientForm,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            folderLogo: 'company_logo',
            folderImages: 'photo_galery',
            loading: false,
            height: 0,
            clientQuotes: [],
            search: null,
            createDialog: false,
            loadingError: false,
            errorMsg: null,
            sortOptions: [
                {
                    text: 'Updated',
                    value: 'updatedOn',
                },
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'City',
                    value: 'city',
                },
            ],
            sortBy: 'name',
            desc: false,
            numberClients: 15,
            completed: true,
            roles: [],
            contactDialog: false,
            client: {},
            clientDialog: false,
        }
    },

    provide() {
        return {
            closeDetail: this.closeDetail,
        }
    },
    watch: {
        clients: function(newClients) {
            const params = this.$router.currentRoute.params
            if (params.id) {
                if (!params.client) {
                    const client = newClients.find(i => i.id == params.id)
                    this.$router.currentRoute.params.client = client
                }
            }
        },
    },
    computed: {
        filteredClients() {
            function compare(key, desc) {
                return function innerSort(a, b) {
                    if (
                        !Object.prototype.hasOwnProperty.call(a, key) ||
                        !Object.prototype.hasOwnProperty.call(b, key)
                    ) {
                        return 0
                    }

                    const varA =
                        typeof a[key] === 'string'
                            ? a[key].toUpperCase()
                            : a[key]
                    const varB =
                        typeof b[key] === 'string'
                            ? b[key].toUpperCase()
                            : b[key]

                    let comparison = 0
                    if (varA._seconds) {
                        if (varA._seconds < varB._seconds) {
                            comparison = 1
                        } else if (varA._seconds > varB._seconds) {
                            comparison = -1
                        }
                    } else {
                        if (varA > varB) {
                            comparison = 1
                        } else if (varA < varB) {
                            comparison = -1
                        }
                    }

                    return desc ? comparison * -1 : comparison
                }
            }
            return this.clients
                .filter(q => {
                    if (this.search)
                        return q.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    return true
                })
                .map(q => {
                    if (!q.updatedOn) {
                        q.updatedOn = q.createdOn
                    }
                    return q
                })
                .sort(compare(this.sortBy, this.desc))
        },
        filteredClients2: function() {
            return (
                this.completed &&
                this.filteredClients.slice(0, this.numberClients)
            )
        },
        ...mapState(['clients', 'quotes', 'contacts', 'userRef']),
    },
    async created() {
        try {
            this.loading = true
            this.resetClientsAction()
            await this.getClients()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onScroll: async function(e) {
            let bottomOfWindow =
                e.target.scrollHeight - Math.abs(e.target.scrollTop) ===
                e.target.clientHeight
            if (bottomOfWindow && this.completed) {
                this.completed = false
                this.numberClients += 15
                this.completed = true
            }
        },
        closeDetail(value) {
            this.clientDialog = value
        },
        onResize() {
            this.height =
                window.innerHeight - this.$vuetify.application.top - 40
        },
        getClients: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { clients },
                } = await API.getClients({ companyId: this.companyId })
                const {
                    data: { roles },
                } = await API.getRoles(this.companyId)
                this.roles = roles.roles
                for (const client of clients) {
                    client.companyRoles = roles.roles
                }
                this.addClientsAction(clients)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
        },
        clientDetail() {
            this.clientDialog = true
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        ...mapActions([
            'addClientsAction',
            'addContactsAction',
            'addQuotesAction',
            'addSettingsAction',
            'resetClientsAction',
        ]),
        openCreateDialog: function() {
            this.createDialog = true
        },
        closeCreateDialog: function() {
            this.createDialog = false
        },
        addClient: function(client) {
            client.companyRoles = this.roles
            this.clients.push(client)
            this.client = client
            this.contactDialog = true
        },
        closeContactDialog() {
            this.contactDialog = false
        },
        addContact(contact) {
            this.client.contacts = [contact]
        },
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
