<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-card :loading="loading" flat>
            <v-card-title class="py-3" ref="title">
                <v-row class="my-1">
                    <v-col
                        v-if="$vuetify.breakpoint.smAndDown"
                        class="ml-n4 mr-1 mt-n1"
                        cols="1"
                    >
                        <v-btn small fab depressed @click="show" icon>
                            <v-icon>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <span
                        :class="{
                            'ml-3 mt-3': $vuetify.breakpoint.smAndDown,
                            'ml-1': !$vuetify.breakpoint.smAndDown,
                        }"
                        >Calculations</span
                    >
                    <v-spacer />
                    <v-btn
                        :loading="loading"
                        @click="updateCalculation"
                        :disabled="saveState"
                        class="mx-1"
                        depressed
                        rounded
                        color="success"
                        >Save</v-btn
                    >
                </v-row>
            </v-card-title>
            <v-divider />
            <v-card-subtitle ref="subtitle" class="mt-0">
                <div>
                    <v-row no-gutters class="mb-0 pb-0 mt-3 mb-3">
                        <p :style="{ 'font-size': '16px', color: '#000000' }">
                            General params
                        </p>
                    </v-row>
                    <v-row no-gutters class="mt-n1 mb-3">
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.materialPrice"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                class="py-0 my-0"
                                prepend-icon="mdi-package-variant-closed"
                                label="Material price (usd/lb)"
                                required
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.paintPrice"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                class="py-0 my-0"
                                prepend-icon="mdi-format-color-fill"
                                label="Paint price (usd/lb)"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-1">
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.waste"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                class="py-0 my-0"
                                prepend-icon="mdi-grain"
                                label="Waste (0-100%)"
                                required
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.exchange"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                class="py-0 my-0"
                                prepend-icon="mdi-cash"
                                label="Exchange"
                                required
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.margin"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                class="py-0 my-0"
                                prepend-icon="mdi-percent"
                                label="Margin"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-0 pb-0 mt-3 mb-3">
                        <p :style="{ 'font-size': '16px', color: '#000000' }">
                            Area sizes (sqft)
                        </p>
                    </v-row>
                    <v-row v-if="setting.areas" no-gutters class="mt-n1">
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.areas.small"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-ruler"
                                label="Small"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.areas.medium"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="Medium"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.areas.large"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="Large"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-0 pb-0 mt-3 mb-3">
                        <p :style="{ 'font-size': '16px', color: '#000000' }">
                            Weight per Area (lbs/sqft)
                        </p>
                    </v-row>
                    <v-row v-if="setting.weights" no-gutters class="mt-n1">
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.weights.small"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-weight-pound"
                                label="Small"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.weights.medium"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="Medium"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.weights.large"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="Large"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-0 pb-0 mt-3 mb-3">
                        <p :style="{ 'font-size': '16px', color: '#000000' }">
                            Material costs per quantity
                        </p>
                    </v-row>
                    <v-row v-if="setting.materials" no-gutters class="mt-n1">
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.materials.small"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-package-variant-closed-plus"
                                label="> 1"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.materials.medium"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="> 10,000"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.materials.large"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="> 20,000"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-0 pb-0 mt-3 mb-3">
                        <p :style="{ 'font-size': '16px', color: '#000000' }">
                            Assembly costs per quantity
                        </p>
                    </v-row>
                    <v-row v-if="setting.assambly" no-gutters class="mt-n1">
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.assambly.small"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-package-variant-closed-plus"
                                label="> 1"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.assambly.medium"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="> 10,000"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.assambly.large"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="> 20,000"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-0 pb-0 mt-3 mb-3">
                        <p :style="{ 'font-size': '16px', color: '#000000' }">
                            Package costs per quantity
                        </p>
                    </v-row>
                    <v-row v-if="setting.packages" no-gutters class="mt-n1">
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.packages.small"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-package-variant-closed-plus"
                                label="> 1"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.packages.medium"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="> 10,000"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="4"
                            lg="4"
                            md="4"
                            sm="12"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="setting.packages.large"
                                type="number"
                                :rules="[rules.required, rules.number]"
                                prepend-icon="mdi-arrow-split-vertical"
                                label="> 20,000"
                                required
                                class="py-0 my-0"
                            />
                        </v-col>
                    </v-row>
                </div>
            </v-card-subtitle>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
export default {
    name: 'CalculationSettings',
    components: {},
    props: {
        setting: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            saveState: true,
            height: 0,
            updateTitleLoading: false,
            localCalculation: null,
            loading: false,
            error: null,
            rules: {
                required: v => !!v || 'Required',
                number: v => v > 0 || 'The number must be greater than zero',
            },
        }
    },
    mounted() {
        this.onResize()
        this.localCalculation = _.cloneDeep(this.setting)
    },
    watch: {
        'setting.materialPrice': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.paintPrice': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.waste': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.exchange': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.margin': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.areas.small': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.areas.medium': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.areas.large': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.weights.small': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.weights.medium': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.weights.large': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.materials.small': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.materials.medium': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.materials.large': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.assambly.small': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.assambly.medium': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.assambly.large': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.packages.small': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.packages.medium': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
        'setting.packages.large': async function(current, previous) {
            if (previous && Number(previous) !== Number(current)) {
                this.emitChanges()
            }
        },
    },
    computed: {
        ...mapState(['saveBtn']),
        ...mapGetters(['getAlternatives']),
    },
    methods: {
        emitChanges() {
            this.saveState = false
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height = containerHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        ...mapActions(['replaceCalculationAction']),
        updateCalculation: async function() {
            try {
                this.loading = true
                this.error = null
                let changes = {}
                Object.keys(this.setting).forEach(key => {
                    if (
                        (this.localCalculation[key] === undefined &&
                            this.setting[key] !== undefined) ||
                        (this.setting[key] !== undefined &&
                            JSON.stringify(this.setting[key]) !==
                                JSON.stringify(this.localCalculation[key]))
                    ) {
                        changes[key] = this.setting[key]
                    }
                })
                await API.updateCalculationInSettings(this.setting.id, changes)
                this.localCalculation = _.cloneDeep(this.setting)
                this.$root.$emit('replaceCalculation', this.setting)
                this.loading = false
            } catch (error) {
                this.error = error.message
            } finally {
                this.loading = false
            }
        },
        replaceCalculation(calculation) {
            this.saveState = true
            this.setting = calculation
        },
    },
}
</script>
