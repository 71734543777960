<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Assembly Items
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text :style="`max-height: ${height}px; overflow-y:auto;`">
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="6" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                        </v-row>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                            @input="verifyCheckBox"
                        />
                    </template>
                    <template v-slot:[`header.workOrder`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="workOrderToFilter"
                            dense
                            class="pt-2"
                            @input="verifyCheckBox"
                        />
                    </template>
                    <template v-slot:[`header.type`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="typeToFilter"
                            dense
                            class="pt-2"
                            @input="verifyCheckBox"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.workOrder`]="{ item }">
                        <p class="my-0">
                            {{ item.workOrder }}
                        </p>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.available`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.available }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.qtyPerAssembly`]="{ item }">
                        <div class="mb-2">
                            <v-text-field
                                v-model="item.qtyPerAssembly"
                                type="number"
                                :rules="[() => maxValue(item)]"
                                @input="verifyCheckBox"
                            />
                        </div>
                    </template>
                    <template v-slot:[`item.totalQty`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{
                                    (Number(item.qtyPerAssembly) || 0) *
                                        (Number(parentItem.quantity) || 0)
                                }}
                            </p>
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-row
                v-if="
                    !partNumberToFilter && !workOrderToFilter && !typeToFilter
                "
                class="ml-1 d-flex justify-left align-center"
            >
                <v-simple-checkbox
                    color="primary"
                    v-model="setAvailable"
                    @click="setAllAvailable"
                    class="ml-2 d-flex justify-center align center"
                >
                </v-simple-checkbox>
                <small>Select All Available Quantities</small>
            </v-row>
            <v-row v-else class="ml-1 d-flex justify-left align-center">
                <v-simple-checkbox
                    color="primary"
                    v-model="setAvailableInFiltered"
                    @click="setAllAvailableInFiltered"
                    class="ml-2 d-flex justify-center align center"
                >
                </v-simple-checkbox>
                <small>Set Available Quantities In Filtered Items</small>
            </v-row>

            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton()"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Assembly Items
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="
                                items.filter(
                                    i =>
                                        i.qtyPerAssembly && i.qtyPerAssembly > 0
                                )
                            "
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="`max-height: ${height}px; overflow-y:auto;`"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    style="background-color: #eeeeee"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                <p class="my-0">
                                    {{ index + 1 }}
                                </p>
                            </template>
                            <template v-slot:[`item.totalQty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">
                                        {{
                                            (Number(item.qtyPerAssembly) || 0) *
                                                (Number(parentItem.quantity) ||
                                                    0)
                                        }}
                                    </p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-row class="ma-2">
                        <v-spacer />
                        <v-col cols="3" class="d-flex justify-end pa-0 ">
                            <v-btn
                                color="primary"
                                rounded
                                :loading="loading"
                                @click="save"
                            >
                                SAVE
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'AssemblyForm',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        parentItem: {
            type: Object,
            required: true,
        },
        originalItems: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'WORK ORDER',
                value: 'workOrder',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'availableQty',
                align: 'center',
                sortable: false,
                width: '130',
            },
            {
                text: 'QTY PER ASSEMBLY',
                value: 'qtyPerAssembly',
                align: 'center',
                sortable: false,
                width: 150,
            },
            {
                text: 'TOTAL QTY',
                value: 'totalQty',
                align: 'center',
                sortable: false,
                width: 130,
            },
        ],
        confirmHeaders: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WORK ORDER',
                value: 'workOrder',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUANTITY PER ASSEMBLY',
                value: 'qtyPerAssembly',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL QUANTITY',
                value: 'totalQty',
                align: 'center',
                sortable: false,
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },

        confirmDialog: false,
        height: 0,
        partNumberToFilter: undefined,
        workOrderToFilter: undefined,
        typeToFilter: undefined,
        setAvailable: false,
        setAvailableInFiltered: false,
        items: [],
    }),
    computed: {
        filteredItems() {
            //filter available
            let conditions = []
            conditions.push(this.filterByAvailable)
            conditions.push(this.filterByLastProcess)
            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }
            if (this.typeToFilter) {
                conditions.push(this.filterByType)
            }
            if (this.workOrderToFilter) {
                conditions.push(this.filterByWorkOrder)
            }
            if (conditions.length > 0) {
                return this.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return this.items
        },
    },
    mounted() {
        this.items = _.cloneDeep(this.originalItems)
        if (this.parentItem.assemblyItems) {
            this.parentItem.assemblyItems.forEach(ai => {
                if (ai.items) {
                    ai.items.forEach(individualItem => {
                        const itemIndex = this.items.findIndex(
                            i => i.id == individualItem.itemId
                        )
                        if (itemIndex > -1) {
                            this.items[itemIndex].availableQty +=
                                (individualItem.qtyPerAssembly || 0) *
                                this.parentItem.quantity
                            this.items[itemIndex].qtyPerAssembly =
                                individualItem.qtyPerAssembly
                        }
                    })
                }
            })
        }
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async save() {
            try {
                this.loading = true
                let filteredItems = this.items.filter(
                    i => i.qtyPerAssembly && i.qtyPerAssembly > 0
                )

                let AssemblyItems = filteredItems.map(fi => ({
                    itemId: fi.id,
                    workOrderId: fi.workOrderId,
                    qtyPerAssembly: fi.qtyPerAssembly,
                }))

                await API.saveAssemblyItems({
                    items: AssemblyItems,
                    workOrderId: this.workOrder.id,
                    itemId: this.parentItem.id,
                })

                this.$emit('closeDialog')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        close() {
            this.$emit('closeDialog')
        },

        disableButton() {
            const selectedItems = this.items.filter(
                item => item.qtyPerAssembly > 0
            )
            const completeForm = selectedItems.every(
                i =>
                    i.qtyPerAssembly * this.parentItem.quantity <=
                    i.availableQty
            )
            return selectedItems.length == 0 || !completeForm
        },

        onResize() {
            this.height = window.innerHeight - 260
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },

        filterByWorkOrder(item) {
            return (
                item.workOrder &&
                item.workOrder
                    .toLowerCase()
                    .includes(this.workOrderToFilter.toLowerCase())
            )
        },

        filterByType(item) {
            return (
                item.type &&
                item.type
                    .toLowerCase()
                    .includes(this.typeToFilter.toLowerCase())
            )
        },
        maxValue(item) {
            const totalValue =
                Number(item.qtyPerAssembly) * Number(this.parentItem.quantity)

            if (totalValue > item.availableQty) {
                return `The quantity selected cannot be greater than ${Math.floor(
                    Number(item.availableQty) / Number(this.parentItem.quantity)
                )}`
            } else if (item.qtyPerAssembly < 0) {
                return 'The value must be positive'
            } else {
                return true
            }
        },
        setAllAvailable() {
            this.items.forEach(item => {
                if (
                    this.setAvailable &&
                    item.availableQty &&
                    item.availableQty > 0
                ) {
                    item.qtyPerAssembly = Math.floor(
                        Number(item.availableQty / this.parentItem.quantity)
                    )
                } else {
                    delete item.qtyPerAssembly
                }
            })
        },
        verifyCheckBox() {
            this.setAvailable = !this.items.some(
                item =>
                    (item.qtyPerAssembly || 0) <
                    Math.floor(
                        Number(item.availableQty / this.parentItem.quantity)
                    )
            )
            this.setAvailableInFiltered = !this.filteredItems.some(
                item =>
                    (item.qtyPerAssembly || 0) <
                    Math.floor(
                        Number(item.availableQty / this.parentItem.quantity)
                    )
            )
        },
        setAllAvailableInFiltered() {
            this.items.forEach(item => {
                if (
                    this.setAvailableInFiltered &&
                    item.availableQty &&
                    item.availableQty > 0 &&
                    this.filteredItems.includes(item)
                ) {
                    item.qtyPerAssembly = Math.floor(
                        Number(item.availableQty / this.parentItem.quantity)
                    )
                } else if (
                    !this.setAvailableInFiltered &&
                    this.filteredItems.includes(item)
                ) {
                    delete item.qtyPerAssembly
                }
            })
        },
        filterByAvailable(item) {
            return item.availableQty > 0
        },
        filterByLastProcess(item) {
            return (
                item.processes &&
                this.parentItem.processes &&
                item.processes[item.processes.length - 1] ==
                    this.parentItem.processes[0]
            )
        },
    },
}
</script>

<style></style>
