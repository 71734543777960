<template>
    <div ref="container">
        <v-card :loading="loading" class="pa-2">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    Invoice Details /
                    <span class="font-weight-black text-uppercase">
                        {{
                            `${invoice.name}${
                                invoice.project
                                    ? ' - ' + invoice.project.name
                                    : ''
                            }`
                        }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="px-4">
                <v-row ref="details_1">
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-text-field
                            outline
                            v-model="invoice.type"
                            prepend-icon="mdi-table-column"
                            prefix="Type: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-text-field
                            outline
                            v-model="invoice.region"
                            prepend-icon="mdi-earth"
                            prefix="Region: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-textarea
                            hide-details
                            rows="4"
                            prepend-icon="mdi-text"
                            class="text-body-2 pt-2"
                            label="Notes: "
                            outline
                            v-model="invoice.notes"
                            readonly
                            :style="{ 'line-height': '1 !important' }"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row ref="details_2" class="ma-0 pa-0">
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <div class="d-flex my-2">
                            <div class="d-flex align-center">
                                <strong>ATTACHMENTS: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="grey lighten-3"
                                    elevation="0"
                                    x-small
                                    class="ma-1 text-capitalize grey--text text--darken-1  "
                                    v-for="(file, i) in invoice.attachments"
                                    :key="i"
                                >
                                    <span @click="openFileAttached(file)">{{
                                        file
                                    }}</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        v-if="
                            invoice.attached &&
                                (invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed' ||
                                    invoice.status == 'credit')
                        "
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                    >
                        <v-text-field
                            outline
                            :style="{ color: 'blue' }"
                            v-model="invoice.invoiceNumber"
                            prepend-icon="mdi-receipt-text-outline"
                            prefix="Invoice No: "
                            :disabled="
                                invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed' ||
                                    invoice.status == 'credit'
                            "
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col
                        v-if="
                            invoice.attached &&
                                (invoice.status == 'billed' ||
                                    invoice.status == 'approved')
                        "
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                    >
                        <v-chip
                            class="mt-4 ml-1"
                            @click="loadFile(invoice.attached)"
                        >
                            <v-icon class="mr-1">mdi-paperclip</v-icon>
                            <span>{{ invoice.attached }}</span>
                        </v-chip>
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'closed'"
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="d-flex flex-wrap"
                    >
                        <div class="d-flex mr-2">
                            <h3 class="mt-5">Invoice:</h3>
                            <v-chip
                                v-if="invoice.attached"
                                class="mt-4 ml-1 pr-10"
                                :style="{ 'max-width': '200px' }"
                                @click="loadFile(invoice.attached)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.attached
                                }}</span>
                            </v-chip>
                        </div>
                        <div class="d-flex">
                            <h3 class="mt-5">Credit note:</h3>
                            <v-chip
                                v-if="invoice.credit"
                                class="mt-4 ml-1 pr-10"
                                :style="{ 'max-width': '200px' }"
                                @click="loadFile(invoice.credit)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.credit
                                }}</span>
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    class="quote_tables mt-8"
                    :style="{ height: `${height}px` }"
                >
                    <v-col cols="12" class="mb-6">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        `${item.name}${
                                            item.project
                                                ? '-' + item.project.name
                                                : ''
                                        }`
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.type`]="{}">
                                <p class="my-0 font-weight-bold">
                                    Proforma Features
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ numberFormat(item.proformaValue) }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{}">
                                <p class="my-0 font-weight-bold">
                                    {{ 1 }}
                                </p>
                            </template>
                            <template v-slot:[`item.currentInvoice`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ numberFormat(item.proformaValue) }}
                                </p>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    :style="{ 'background-color': '#eeeeee' }"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black ml-4">TOTALS:</p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT PRICE:
                            {{ numberFormat(invoice.proformaValue) }}
                        </p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT BILLED:
                            {{ numberFormat(invoice.totalInvoiced || 0) }}
                        </p>
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black">Qty: {{ 1 }}</p>
                        <p class="my-2 font-weight-black mr-4">
                            Current Invoice:
                            {{ numberFormat(invoice.proformaValue) }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-btn
                    v-if="invoice.status == 'international'"
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="openRejectForm"
                >
                    REJECT
                </v-btn>
                <v-btn
                    v-if="
                        invoice.status == 'billed' &&
                            ((invoice.project && userId == projectManager) ||
                                (!invoice.project &&
                                    userId ==
                                        (typeof invoice.createdBy == 'string'
                                            ? invoice.createdBy
                                            : invoice.createdBy.id)))
                    "
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="creditNoteProcess = true"
                >
                    REQUEST CREDIT NOTE
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="
                        invoice.region == 'international' &&
                            invoice.status == 'rejected'
                    "
                    :loading="loading"
                    rounded
                    color="#EDC43B"
                    class="mb-4 mr-2 white--text"
                    @click="resetInvoice"
                >
                    SEND
                </v-btn>
                <v-btn
                    v-if="
                        invoice.region == 'international' &&
                            invoice.status == 'international'
                    "
                    :loading="loading"
                    rounded
                    color="#27BC4F"
                    class="mb-4 mr-2 white--text"
                    @click="sentToGenerate"
                >
                    SENT TO GENERATE
                </v-btn>
                <v-btn
                    v-if="invoice.status == 'billed'"
                    :loading="loading"
                    rounded
                    color="primary"
                    class="mb-4 mr-2 white--text"
                    @click="openApproveDialog = true"
                >
                    APPROVE
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Reject Invoice-->
        <v-dialog
            v-model="rejectForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this
                            invoice
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectForm">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectInvoice"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Error Alert-->
        <Errors />
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Request Credit Note-->
        <v-dialog
            v-model="creditNote"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="creditNote">
                <v-card-title class="text-h5">Reasons For Request</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you are requesting the credit
                            note.
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeCreditNote">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="requestCreditNote"
                        color="primary"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- APPROVE INVOICE -->
        <v-dialog
            v-model="openApproveDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeApproveDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        Approve Invoice
                        {{ invoice ? invoice.number : '' }}
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    Are you sure you want to approve it?
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="approveInvoice"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        APPROVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Credit request-->
        <v-dialog
            v-model="creditNoteProcess"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <CreditNoteRequestProforma
                v-if="creditNoteProcess"
                :invoice="invoice"
                :project="project"
                @close="closeCreditNoteProcess"
                @replaceInvoice="replaceInvoice"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { loadImage } from '@/helpers/imageHandler.js'
import { auth } from '@/services/firebase'

export default {
    name: 'ProformaFeatures',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        inProcess: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
        CreditNoteRequestProforma: () =>
            import('@/components/Invoicing/CreditNoteRequestProforma.vue'),
    },
    data: () => ({
        items: [],
        openApproveDialog: false,
        rejectionTable: false,
        rejectedNotes: null,
        valid: false,
        loading: true,
        rules: {
            required: v => !!v || 'The value is required',
        },
        regions: ['international', 'local'],
        height: 0,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'name',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CURRENT INVOICE',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
        ],
        rejectForm: false,
        companyId: JSON.parse(localStorage.getItem('company')),
        selectedFile: null,
        invoices: [],
        dataProject: {},
        userId: '',
        projectManager: '',
        creditNote: false,
        creditNoteProcess: false,
    }),
    inject: {
        addInvoice: {
            from: 'addInvoice',
            default() {
                return null
            },
        },
        updateInvoice: {
            from: 'updateInvoice',
            default() {
                return null
            },
        },
        confirmSend: {
            from: 'confirmSend',
            default() {
                return null
            },
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.userId = auth().currentUser.uid
            if (this.invoice.project) {
                this.projectManager = this.invoice.project.projectManager
            }
            this.items = [this.invoice]
            this.invoice.notes = `Address: ${this.invoice.address}
Phone: ${this.invoice.phone}
P.A: ${this.invoice.tariffItem}
Description: ${this.invoice.description}
            `
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeApproveDialog() {
            this.openApproveDialog = false
        },
        async approveInvoice() {
            try {
                this.loading = false
                await this.$emit('approveInvoice')
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeCreditNote() {
            this.creditNote = false
        },
        async requestCreditNote() {
            try {
                this.loading = true
                const invoice = await API.requestCreditNote({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    notes: this.rejectedNotes,
                })
                this.$emit('replaceInvoice', invoice)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadFile(file) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`,
                    file
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.invoice.proformaProject}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        numberFormat(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: 0,
            }).format(number)
        },
        async sentToGenerate() {
            try {
                this.loading = true
                const invoice = await API.sentToGenerate({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    notificationId: 'WNzjPtOPmiwPEuv0vRc5',
                })
                this.updateInvoice(invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async rejectInvoice() {
            try {
                this.loading = true
                const invoice = await API.rejectInvoice({
                    id: this.invoice.id,
                    projectId: this.invoice.proformaProject,
                    notes: this.rejectedNotes,
                    notificationId: 'bgOJPzzceetopCQj5kxs',
                })
                this.rejectForm = false
                this.$emit('replaceInvoice', invoice)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeRejectForm() {
            this.rejectForm = false
        },
        openRejectForm() {
            this.rejectForm = true
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
                details_2: { clientHeight, offsetTop },
                actions: { clientHeight: actionsHeight },
            } = this.$refs

            this.height =
                window.innerHeight -
                (offsetTop + clientHeight + titleHeight + actionsHeight + 52)
        },
        close() {
            this.$emit('close')
        },
        async resetInvoice() {
            try {
                this.loading = true
                if (this.invoice.status == 'rejected') {
                    const invoice = await API.updateProformaFeature(
                        this.invoice.id,
                        { status: 'international' }
                    )
                    this.updateInvoice(invoice)
                }
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeCreditNoteProcess() {
            this.creditNoteProcess = false
        },
        replaceInvoice(invoice) {
            this.$emit('replaceInvoice', invoice)
            this.$emit('close')
        },
    },
}
</script>

<style>
.quote_tables {
    width: 100%;
    overflow: scroll;
}
</style>
