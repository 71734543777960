var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"fill-height",attrs:{"color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":_vm.user.purchases,"options":{
            threshold: 0.5,
        }}},[_c('v-card',{staticClass:"ma-0 pa-0",style:({
                'background-color': '#fafafa',
                'border-radius': '10px',
            }),attrs:{"flat":""}},[(_vm.user.name == 'On Hold')?_c('p',{staticClass:"ma-0 pa-3 pb-1 d-flex font-weight-bold",style:({ color: '#EDC43B' })},[_vm._v(" ON HOLD ")]):_c('v-row',{staticClass:"d-flex ma-0 pa-0"},[_c('v-avatar',{staticClass:"ml-3 mt-3 pa-0",attrs:{"size":"28"}},[(_vm.profilePicUrl)?_c('v-img',{attrs:{"src":_vm.profilePicUrl,"alt":_vm.user.name}}):_c('v-icon',{attrs:{"x-large":"","color":"#707070"}},[_vm._v("mdi-account-circle")])],1),_c('div',{staticClass:"ma-0 pa-2 pb-1"},[_c('p',{staticClass:"ma-0 pa-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('p',{staticClass:"ma-0 pa-0 mt-n1"},[_vm._v(" "+_vm._s(_vm.user.role)+" ")])])],1),_c('v-row',{staticClass:"flex-nowrap ma-0 pa-0"},[_c('draggable',{staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({ height: `${_vm.height - 68}px`, width: '100%' }),attrs:{"group":"purchases"},on:{"change":_vm.draggableLog},model:{value:(_vm.user.purchases),callback:function ($$v) {_vm.$set(_vm.user, "purchases", $$v)},expression:"user.purchases"}},_vm._l((_vm.userPurchases),function(request,index){return _c('div',{key:index,staticClass:"list-group-item py-0 mx-0 my-3 px-1"},[(request)?_c('v-sheet',{staticClass:"fill-height",attrs:{"min-height":"94","color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":request,"options":{
                                    threshold: 0.5,
                                }}},[_c('PurchasesTask',{attrs:{"request":request},on:{"openAssignBuyer":_vm.openAssignBuyer}})],1)],1):_vm._e()],1)}),0)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.selectBuyer),callback:function ($$v) {_vm.selectBuyer=$$v},expression:"selectBuyer"}},[(_vm.selectBuyer)?_c('AssignBuyer',{attrs:{"requestId":_vm.selectedRequest,"users":_vm.users},on:{"close":_vm.closeAssignBuyer}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }