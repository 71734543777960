<template>
    <v-card class="fill-height elevation-0" :loading="loading">
        <v-card-title v-if="viewDetails">
            <h3>Template</h3>
            <v-spacer />
            <v-btn
                color="primary"
                dark
                rounded
                @click="saveTemplate"
                :loading="loading"
                >SAVE</v-btn
            >
        </v-card-title>
        <v-card-text>
            <div>
                <vue-editor
                    :editor-toolbar="customToolbar"
                    v-model="leadSetting.template"
                    :style="{
                        height: '75%',
                    }"
                />
            </div>
            <div class="my-4" v-if="viewDetails">
                <v-row>
                    <v-col cols="12" class="py-1">
                        <p>
                            The autocomplete data with the information of each
                            lead will be indicated with special names inside
                            ${}.
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="my-0 py-0"
                        v-for="keyWord of leadSetting.keyWords"
                        :key="keyWord.word"
                    >
                        <p>
                            <strong>{{ keyWord.word }}:</strong>
                            {{ keyWord.description }}
                        </p>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'LeadTemplate',
    props: {
        setting: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: () => 'reject',
        },
    },
    components: {
        VueEditor,
    },
    data: () => ({
        leadSetting: {},
        templateHeight: 0,
        loading: false,
        template: '',
        customToolbar: [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ align: '' }, { align: 'center' }, { align: 'right' }],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
        viewDetails: true,
    }),
    mounted() {
        if (!this.$router.currentRoute.name.includes('lead-notifications')) {
            this.viewDetails = false
        }
        // set setting
        this.leadSetting = this.setting.leads.find(
            lead => lead.title == this.type
        )
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveTemplate() {
            try {
                this.loading = true
                await API.updateNotificationTemplate({
                    id: this.setting.id,
                    leads: this.leadSetting,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
