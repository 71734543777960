<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            ref="menu"
            cols="12"
            md="3"
            sm="12"
            lg="3"
            class="border-right d-flex flex-column flex-nowrap"
            :style="{ height: `${height}px` }"
        >
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Search -->
                <v-col cols="12" class="pa-2 pb-2">
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Sort -->
                <v-col
                    cols="12"
                    class="py-2 px-2  d-flex align-center justify-start"
                >
                    <v-select
                        v-model="state"
                        flat
                        :items="states"
                        hide-details="auto"
                        solo
                    />
                </v-col>
            </v-row>
            <!-- Loader -->
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Quotes list -->
            <v-list
                v-else
                class="overflow-y-auto pa-0"
                id="scroll-target"
                v-scroll:#scroll-target="onScroll"
            >
                <template v-if="filteredChats.length > 0">
                    <div v-for="(chat, i) in filteredChats2" :key="chat.id">
                        <v-sheet
                            min-height="100"
                            class="fill-height"
                            color="transparent"
                        >
                            <v-lazy
                                :v-model="chat"
                                :options="{
                                    threshold: 0.5,
                                }"
                                class="fill-height"
                            >
                                <v-list-item
                                    color="primary"
                                    three-line
                                    link
                                    @click="open(chat)"
                                    :class="{
                                        'border-bottom':
                                            i < filteredChats.length - 1,
                                    }"
                                >
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="mb-2">{{
                                            formatDate(
                                                chat.updatedOn || chat.createdOn
                                            )
                                        }}</v-list-item-subtitle>
                                        <v-list-item-title class="mb-2">{{
                                            `(${chat.number}) ${chat.name}`
                                        }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-chip
                                                dark
                                                :color="getColor(chat.status)"
                                            >
                                                {{ getStatus(chat.status) }}
                                            </v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-lazy>
                        </v-sheet>
                    </div>
                </template>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            No chats
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col
            ref="content"
            cols="12"
            sm="0"
            md="9"
            lg="9"
            :style="{ height: `${height}px` }"
        >
            <div class="pa-2">
                <v-progress-linear indeterminate v-if="loadingQuote" />
                <router-view :key="$route.fullPath" @show="show" />
            </div>
        </v-col>
        <!--ERRORS-->
        <Errors />
    </v-row>
</template>

<script>
//
import { mapMutations, mapGetters } from 'vuex'
import API from '@/services/api'
import moment from 'moment'

export default {
    name: 'ProjectChats',
    components: {
        Errors: () => import('@/components/Layout/Errors.vue'),
    },
    data: () => ({
        loadingQuote: false,
        numberChats: 15,
        completed: true,
        loading: false,
        height: 0,
        search: '',
        states: [
            {
                text: 'Awarded',
                value: 'AWARDED',
            },
        ],
        state: 'AWARDED',
        chats: [],
        chat: {},
    }),
    watch: {
        chats: function(newChats) {
            const params = this.$router.currentRoute.params
            if (params.id) {
                if (!params.quote) {
                    const chat = newChats.find(i => i.id == params.id)
                    this.chat = chat
                    this.$router.currentRoute.params.quote = chat
                }
            }
        },
        chatId(newChatId) {
            if (newChatId !== null) {
                const chat = this.chats.find(chat => chat.id === newChatId)
                if (chat) {
                    const index = this.chats.indexOf(chat)
                    const now = new Date()
                    chat.updatedOn = {
                        _seconds: Math.floor(now.getTime() / 1000),
                        _nanoseconds: (now.getTime() % 1000) * 1000000,
                    }
                    this.chats.splice(index, 1)
                    this.chats.unshift(chat)
                }
            }
        },
    },
    computed: {
        ...mapGetters(['getChatId']),
        chatId() {
            return this.getChatId
        },
        filteredChats() {
            const conditions = []
            if (this.search) {
                conditions.push(this.filterBySearch)
            }
            if (this.state) {
                conditions.push(this.filterByStatus)
            }
            if (conditions.length > 0) {
                return this.chats.filter(chat => {
                    return conditions.every(condition => condition(chat))
                })
            }
            return this.chats
        },
        chatName() {
            return this.chat.name
        },
        filteredChats2: function() {
            return (
                this.completed && this.filteredChats.slice(0, this.numberChats)
            )
        },
    },
    async mounted() {
        try {
            this.loading = true
            // get data
            this.chats = await API.getChats({ status: 'AWARDED' })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onScroll: async function(e) {
            let bottomOfWindow =
                e.target.scrollHeight - Math.abs(e.target.scrollTop) ===
                e.target.clientHeight
            if (bottomOfWindow && this.completed) {
                this.completed = false
                this.numberChats += 15
                this.completed = true
            }
        },
        filterBySearch(item) {
            return (
                item.name.toLowerCase().includes(this.search.toLowerCase()) ||
                `${item.number}`
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            )
        },
        filterByStatus(item) {
            if (this.state == 'ALL') {
                return true
            } else {
                return item.status.toLowerCase() == this.state.toLowerCase()
            }
        },
        formatDate(date) {
            return moment.unix(date._seconds).format('YYYY-MM-DD HH:mm:ss')
        },
        getStatus(status) {
            if (status == 'OPEN') return 'Bidding'
            else if (status == 'AWARDED') return 'Awarded'
            else if (status == 'LOST') return 'Lost'
            else if (status == 'SENT') return 'Follow Up'
            else return 'Approval'
        },
        getColor(status) {
            if (status == 'OPEN') return 'primary'
            else if (status == 'AWARDED') return 'success'
            else if (status == 'LOST') return 'error'
            else if (status == 'SENT') return 'warning'
            else return 'orange'
        },
        async open(chat) {
            try {
                this.loadingQuote = true
                if (this.$vuetify.breakpoint.smAndDown) {
                    this.$refs.menu.classList.remove('d-flex')
                    this.$refs.menu.classList.add('d-none')
                }
                const {
                    data: { quotes: quote },
                } = await API.getQuote(chat.id)
                this.$router.replace({
                    name: 'project-chat',
                    params: {
                        id: chat.id,
                        quote: quote,
                    },
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingQuote = false
            }
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        onResize() {
            this.height =
                window.innerHeight -
                this.$vuetify.application.top -
                this.$vuetify.application.footer
        },
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
