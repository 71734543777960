<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="invoicesFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">CLOSED</h1>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateRangeText"
                                    label="Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="{ 'max-width': '250px' }"
                                    class="pt-1"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    :loading="loading"
                                    @click="loadData"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-btn
                            color="primary"
                            rounded
                            class="mx-2"
                            @click="openFindInvoice"
                        >
                            <v-icon class="mx-1">mdi-database-search</v-icon>
                            FIND
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="codeToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.closedBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="closedByToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.invoiceNumber`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="invoiceToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.region`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="regionToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatDate(item.createdOn._seconds) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.createdBy" class="my-0 text-capitalize">
                        {{ item.createdBy.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.closedOn`]="{ item }">
                <div v-if="item.closedOn" class="d-flex justify-center">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <p class="my-0" v-bind="attrs" v-on="on">
                                {{ formatDate(item.closedOn._seconds) }}
                            </p>
                        </template>
                        <span>{{ item.closedBy.name }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.billedOn`]="{ item }">
                <div v-if="item.billedOn" class="d-flex justify-center">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <p class="my-0" v-bind="attrs" v-on="on">
                                {{ formatDate(item.billedOn._seconds) }}
                            </p>
                        </template>
                        <span>{{ item.billedBy.name }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.approvedOn`]="{ item }">
                <div v-if="item.approvedOn" class="d-flex justify-center">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <p class="my-0" v-bind="attrs" v-on="on">
                                {{ formatDate(item.approvedOn._seconds) }}
                            </p>
                        </template>
                        <span>{{ item.approvedBy.name }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.project" class="my-0">
                        {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.client`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.client" class="my-0">
                        {{ item.client.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.currency }}</p>
                </div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <div class="d-flex justify-end align-right">
                    <p class="my-0">
                        {{ numberFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.billed`]="{ item }">
                <div class="d-flex justify-end align-right">
                    <p class="my-0">
                        {{ billedFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.credit`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ creditFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ totalFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.region`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="text-capitalize my-0">
                        {{ item.region }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openInvoiceProcess(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="restoreInvoice"
                        small
                        icon
                        @click="openRestoreInvoice(item)"
                    >
                        <v-icon>mdi-autorenew</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--INVOICE DETAILS-->
        <v-dialog
            v-model="invoiceProcess"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <GenerateInvoice
                v-if="invoiceProcess"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :setting="invoiceSettings"
                @close="closeInvoiceProcess"
            />
        </v-dialog>
        <!--INVOICE DETAILS-->
        <v-dialog
            v-model="creditNoteProcess"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <CreditNoteRequest
                v-if="creditNoteProcess && selectedInvoice.type != 'proforma'"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :setting="invoiceSettings"
                @close="closeCreditNoteProcess"
                @removeInvoice="removeInvoice"
            />

            <CreditNoteRequestProforma
                v-if="creditNoteProcess && selectedInvoice.type == 'proforma'"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                @close="closeCreditNoteProcess"
                @removeInvoice="removeInvoice"
            />
        </v-dialog>
        <!--PROFORMA DETAILS-->
        <v-dialog
            v-model="proformaDialog"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProformaFeaturesGenerating
                v-if="proformaDialog"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project || {}"
                @close="closeInvoiceProcess"
            />
        </v-dialog>
        <!-- FIND INVOICE -->
        <v-dialog
            v-model="findInvoice"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindInvoice
                v-if="findInvoice"
                @close="closeFindInvoice"
                @openInvoice="openInvoice"
            />
        </v-dialog>
        <!-- RESTORE INVOICE -->
        <v-dialog
            v-model="openRestore"
            :retain-focus="false"
            persistent
            max-width="400"
        >
            <RestoreInvoice
                v-if="openRestore"
                :invoice="selectedInvoice"
                @close="closeRestoreInvoice"
                @removeInvoice="removeInvoice"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'Closed',
    components: {
        GenerateInvoice: () =>
            import('@/components/Invoicing/GenerateInvoice.vue'),
        FindInvoice: () => import('@/components/Invoicing/FindInvoice.vue'),
        ProformaFeaturesGenerating: () =>
            import('@/components/Invoicing/ProformaFeaturesGenerating.vue'),
        RestoreInvoice: () =>
            import('@/components/Invoicing/RestoreInvoice.vue'),
        CreditNoteRequest: () =>
            import('@/components/Invoicing/CreditNoteRequest.vue'),
        CreditNoteRequestProforma: () =>
            import('@/components/Invoicing/CreditNoteRequestProforma.vue'),
    },
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        proformaDialog: false,
        selectedInvoice: undefined,
        invoiceProcess: false,
        codeToFilter: undefined,
        userToFilter: undefined,
        projectToFilter: undefined,
        regionToFilter: undefined,
        invoiceToFilter: undefined,
        closedByToFilter: undefined,
        loading: false,
        invoices: [],
        activateAlert: false,
        alertMessage: undefined,
        headers: [
            {
                text: 'ID',
                value: 'number',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'BILLED',
                value: 'billedOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'APPROVED',
                value: 'approvedOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLOSED',
                value: 'closedOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLIENT',
                value: 'client',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CURRENCY',
                value: 'currency',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRICE',
                value: 'amount',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AMOUNT',
                value: 'billed',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREDIT',
                value: 'credit',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL',
                value: 'total',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'INVOICE',
                value: 'invoiceNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REGION',
                value: 'region',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        projects: [],
        users: [],
        clients: [],
        invoiceSettings: {},
        findInvoice: false,
        restoreInvoice: false,
        openRestore: false,
        creditNoteProcess: false,
        menu: false,
        dates: [],
    }),
    computed: {
        ...mapState(['invoiceUpdates']),
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        invoicesFiltered() {
            let conditions = []
            if (this.codeToFilter) {
                conditions.push(this.filterCode)
            }
            if (this.userToFilter) {
                conditions.push(this.filterUser)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.regionToFilter) {
                conditions.push(this.filterRegion)
            }
            if (this.closedByToFilter) {
                conditions.push(this.filterClosedBy)
            }
            if (this.invoiceToFilter) {
                conditions.push(this.filterInvoice)
            }
            if (conditions.length > 0) {
                return this.invoices.filter(invoice => {
                    return conditions.every(condition => {
                        return condition(invoice)
                    })
                })
            }
            this.sort()
            return this.invoices
        },
    },
    watch: {
        invoiceUpdates: function(data) {
            if (data) {
                this.UpdateInvoice(data)
                this.setInvoiceUpdates(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            // set permissions
            const {
                data: { user },
            } = await API.getMyInfo()
            this.restoreInvoice = user.permissions.includes('restoreInvoice')
            //set initial dates
            let currentDate = new Date()
            let date1 = new Date(currentDate)
            date1.setMonth(date1.getMonth() - 1)
            date1.setHours(0, 0, 0, 0)
            this.dates[0] = date1.toISOString().split('T')[0]
            this.dates[1] = currentDate.toISOString().split('T')[0]
            // get data
            await this.getSetting()
            await this.loadData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setInvoiceUpdates']),
        ...mapGetters(['getInvoiceUpdates']),
        closeRestoreInvoice() {
            this.selectedInvoice = {}
            this.openRestore = false
        },
        openRestoreInvoice(invoice) {
            this.selectedInvoice = _.cloneDeep(invoice)
            this.openRestore = true
        },
        removeInvoice() {
            this.invoices = this.invoices.filter(
                invoice => invoice.id != this.selectedInvoice.id
            )
        },
        openInvoice(invoice) {
            try {
                this.loading = true
                //set client
                invoice.client = this.clients.find(
                    client => client.id == invoice.clientId
                )
                //set createdBy
                let userIndex = this.users.findIndex(
                    user => user.id == invoice.createdBy
                )
                if (userIndex > -1) {
                    invoice.createdBy = this.users[userIndex]
                }
                //set closedBy
                userIndex = this.users.findIndex(
                    user => user.id == invoice.closedBy
                )
                if (userIndex > -1) {
                    invoice.closedBy = this.users[userIndex]
                }
                //set approvedBy
                userIndex = this.users.findIndex(
                    user => user.id == invoice.approvedBy
                )
                if (userIndex > -1) {
                    invoice.approvedBy = this.users[userIndex]
                }
                //set billedBy
                userIndex = this.users.findIndex(
                    user => user.id == invoice.billedBy
                )
                if (userIndex > -1) {
                    invoice.billedBy = this.users[userIndex]
                }
                //set Project
                const projectIndex = this.projects.findIndex(
                    project => project.id == invoice.projectId
                )
                if (projectIndex > -1) {
                    invoice.project = this.projects[projectIndex]
                }
                this.selectedInvoice = _.cloneDeep(invoice)
                this.invoiceProcess = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeFindInvoice() {
            this.findInvoice = false
        },
        openFindInvoice() {
            this.findInvoice = true
        },
        async getSetting() {
            try {
                this.loading = true
                if (Object.keys(this.setting).length > 0) {
                    this.invoiceSettings = this.setting
                } else {
                    const {
                        data: { settings },
                    } = await API.getSettings()
                    const setting = settings.find(
                        setting => setting.name.toLowerCase() == 'invoicing'
                    )
                    if (setting) {
                        this.invoiceSettings = setting
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadData() {
            try {
                this.loading = true
                this.menu = false
                this.invoices = []
                let date1 = new Date(this.dates[0]).getTime()
                let date2 = new Date(this.dates[1]).getTime()
                if (date1 > date2) {
                    date1 = this.dates[1]
                    date2 = this.dates[0]
                } else {
                    date1 = this.dates[0]
                    date2 = this.dates[1]
                }
                const invoices = await API.getInvoices({
                    status: 'closed',
                    startDate: date1,
                    endDate: date2,
                })
                this.projects = await API.getLiteProjects({ all: true })
                const {
                    data: { users },
                } = await API.getLiteUsers()
                const {
                    data: { clients },
                } = await API.getLiteClients()
                this.clients = clients
                this.users = users
                for (let invoice of invoices) {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    let userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                    //set closedBy
                    userIndex = this.users.findIndex(
                        user => user.id == invoice.closedBy
                    )
                    if (userIndex > -1) {
                        invoice.closedBy = this.users[userIndex]
                    }
                    //set approvedBy
                    userIndex = this.users.findIndex(
                        user => user.id == invoice.approvedBy
                    )
                    if (userIndex > -1) {
                        invoice.approvedBy = this.users[userIndex]
                    }
                    //set billedBy
                    userIndex = this.users.findIndex(
                        user => user.id == invoice.billedBy
                    )
                    if (userIndex > -1) {
                        invoice.billedBy = this.users[userIndex]
                    }
                    //set Project
                    const projectIndex = this.projects.findIndex(
                        project => project.id == invoice.projectId
                    )
                    if (projectIndex > -1) {
                        invoice.project = this.projects[projectIndex]
                    }
                    this.invoices.push(invoice)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openInvoiceProcess(item) {
            try {
                this.selectedInvoice = _.cloneDeep(item)
                if (
                    this.selectedInvoice.type == 'proforma' &&
                    !this.selectedInvoice.credit &&
                    this.selectedInvoice.creditPrice == undefined
                ) {
                    this.proformaDialog = true
                } else if (
                    this.selectedInvoice.credit ||
                    this.selectedInvoice.creditPrice != undefined
                ) {
                    if (this.selectedInvoice.project) {
                        this.selectedInvoice.project.quotes = await API.getProjectQuotes(
                            this.selectedInvoice.project.id
                        )
                    }
                    this.creditNoteProcess = true
                } else {
                    if (this.selectedInvoice.project) {
                        this.selectedInvoice.project.quotes = await API.getProjectQuotes(
                            this.selectedInvoice.project.id
                        )
                    }
                    this.invoiceProcess = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeInvoiceProcess() {
            this.selectedInvoice = {}
            this.invoiceProcess = false
            this.proformaDialog = false
        },
        closeCreditNoteProcess() {
            this.selectedInvoice = {}
            this.creditNoteProcess = false
            this.proformaDialog = false
        },
        filterCode(invoice) {
            let number = invoice.number
            if (typeof invoice.number == 'number') {
                number = invoice.number.toString()
            }
            return number
                .toLowerCase()
                .includes(this.codeToFilter.toLowerCase())
        },
        filterUser(invoice) {
            if (invoice.createdBy.name) {
                return invoice.createdBy.name
                    .toLowerCase()
                    .includes(this.userToFilter.toLowerCase())
            }
        },
        filterProject(invoice) {
            if (invoice.project) {
                return (
                    invoice.project.name
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase()) ||
                    invoice.project.reference
                        .toString()
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase())
                )
            }
        },
        filterRegion(invoice) {
            return invoice.region
                .toLowerCase()
                .includes(this.regionToFilter.toLowerCase())
        },
        filterInvoice(invoice) {
            return invoice.invoiceNumber.includes(this.invoiceToFilter)
        },
        filterClosedBy(invoice) {
            return invoice.closedBy.name
                .toLowerCase()
                .includes(this.closedByToFilter.toLowerCase())
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        numberFormat(invoice) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? invoice.amount
                    : Math.ceil(invoice.amount)
            )
        },
        billedFormat(invoice) {
            let invoiceAmount = 0
            for (let key in invoice.items) {
                for (let cost of invoice.items[key]) {
                    if (cost.currentInvoice) {
                        invoiceAmount += cost.currentInvoice
                    }
                }
            }
            if (isEmpty(invoice.items)) {
                invoiceAmount = invoice.amount
            }
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? invoiceAmount
                    : Math.ceil(invoiceAmount)
            )
        },
        creditFormat(invoice) {
            let creditAmount = 0
            if (invoice.totalCredit) {
                creditAmount = invoice.totalCredit
            } else {
                for (let key in invoice.items) {
                    for (let cost of invoice.items[key]) {
                        if (cost.creditNoteQty) {
                            creditAmount +=
                                cost.creditNoteQty * cost.pricePerUnit
                        }
                    }
                }
            }

            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? creditAmount
                    : Math.ceil(creditAmount)
            )
        },
        totalFormat(invoice) {
            let totalAmount = 0
            for (let key in invoice.items) {
                for (let cost of invoice.items[key]) {
                    if (cost.currentInvoice) {
                        totalAmount += cost.currentInvoice
                    }
                    if (cost.creditNoteQty && !invoice.totalCredit) {
                        totalAmount -= cost.creditNoteQty * cost.pricePerUnit
                    }
                }
            }
            if (isEmpty(invoice.items)) {
                totalAmount = invoice.amount
            }
            if (invoice.totalCredit) {
                totalAmount -= invoice.totalCredit
            }

            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD' ? totalAmount : Math.ceil(totalAmount)
            )
        },
        sort() {
            this.invoices.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        async UpdateInvoice(invoice) {
            try {
                const index = this.invoices.findIndex(i => i.id == invoice.id)
                if (index >= 0) {
                    if (invoice.status == 'closed') {
                        const updatedInvoice = {
                            ...this.invoices[index],
                            ...invoice,
                        }
                        this.invoices.splice(index, 1, updatedInvoice)
                    } else {
                        this.invoices = this.invoices.filter(
                            i => i.id != invoice.id
                        )
                    }
                } else if (invoice.status == 'closed') {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    const userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                    //set Project
                    const projectIndex = this.projects.findIndex(
                        project => project.id == invoice.projectId
                    )
                    if (projectIndex > -1) {
                        invoice.project = this.projects[projectIndex]
                    }
                    this.invoices.push(invoice)
                }
            } catch (error) {
                console.error(error)
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
  max-height: 72vh;
  overflow-y: auto;
}
.v-data-table__row {
  height: 64px; 
}
.v-data-table__wrapper thead tr {
  position: sticky; 
  top: 0;
  z-index: 10;
}
</style>
