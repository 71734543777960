var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"id":"invoices","to":{
                name: 'invoices',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" INVOICES ")]),(_vm.approvalInternational)?_c('v-tab',{attrs:{"to":{
                name: 'international',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" INTERNATIONAL ")]):_vm._e(),(_vm.generateInvoce)?_c('v-tab',{attrs:{"to":{
                name: 'generating',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" GENERATING ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: 'invoicing-closed',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" CLOSED ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:({ height: `${_vm.height}px` })},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }