var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: 'users-register',
            }}},[_vm._v(" Users ")]),_c('v-tab',{attrs:{"to":{
                name: 'users-permissions',
            }}},[_vm._v(" Permissions ")]),(_vm.user.permissions.includes('readUsers'))?_c('v-tab',{attrs:{"to":{
                name: 'users-logs',
            }}},[_vm._v(" Logs ")]):_vm._e()],1),_c('v-card',[_c('v-card-text',{staticClass:"overflow-y-auto pt-0",style:({ height: `${_vm.height}px` })},[_c('router-view')],1)],1),_c('Errors')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }