var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[(!!_vm.localSetting)?_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'lead-notifications-reject',
                params: {
                    setting: _vm.localSetting,
                    type: 'reject',
                },
            }}},[_vm._v(" REJECT ")]),_c('v-tab',{attrs:{"to":{
                name: 'lead-notifications-bidding',
                params: {
                    setting: _vm.localSetting,
                    type: 'bidding',
                },
            }}},[_vm._v(" BIDDING ")])],1):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:(`height: ${_vm.height}px`)},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }