<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="informs"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            :style="{ 'background-color': '#eeeeee' }"
                        >
                            <v-col cols="12" class="d-flex mb-n3">
                                <h1 class="mr-4">REPORTS</h1>
                                <v-spacer />
                                <v-btn
                                    color="primary"
                                    rounded
                                    @click="openInformDialog"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                    NEW
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.emails`]="{ item }">
                        <div>
                            <p
                                class="my-0"
                                v-for="email of item.emails"
                                :key="email"
                            >
                                {{ email }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.report`]="{ item }">
                        <div>
                            <p class="my-0">
                                {{ item.report || 'general' }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                        <div>
                            <p class="my-0">
                                {{
                                    `${item.day ? getDay(item.day) : ''} ${
                                        item.date
                                    }`
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-center">
                            <v-btn small icon @click="updateInformDialog(item)">
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn small icon @click="deleteInform(item)">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!--INFORM DIALOG-->
        <v-dialog
            :retain-focus="false"
            v-model="informDialog"
            persistent
            max-width="640px"
        >
            <Informs
                v-if="informDialog"
                :inform="selectedInform"
                :originalInform="originalInform"
                :createForm="createForm"
                :settingId="setting.id"
                @addInform="addInform"
                @replaceInform="replaceInform"
                @close="closeInformDialog"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
    name: 'SettingsInform',
    props: {
        setting: {
            type: Object,
            required: true,
        },
    },
    components: {
        Informs: () => import('@/components/Settings/Informs.vue'),
    },
    data: () => ({
        loading: false,
        headers: [
            {
                text: 'TYPE',
                value: 'type',
                sortable: false,
                width: '100',
            },
            {
                text: 'EMAILS',
                value: 'emails',
                sortable: false,
            },
            {
                text: 'REPORT',
                value: 'report',
                sortable: false,
            },
            {
                text: 'DATE AND TIME',
                value: 'date',
                sortable: false,
                width: '200',
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: '100',
            },
        ],
        informDialog: false,
        createForm: false,
        selectedInform: {},
        originalInform: {},
        informs: [],
    }),
    async mounted() {
        try {
            this.loading = true
            this.informs = await API.getInformDetails(this.setting.id)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getDay(index) {
            const days = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ]
            return days[index]
        },
        async deleteInform(item) {
            try {
                this.loading = true
                await API.deleteInform(this.setting.id, item.id)
                this.informs = this.informs.filter(
                    inform => inform.id != item.id
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openInformDialog() {
            this.createForm = true
            this.informDialog = true
        },
        closeInformDialog() {
            this.createForm = false
            this.selectedInform = {}
            this.originalInform = {}
            this.informDialog = false
        },
        updateInformDialog(inform) {
            this.createForm = false
            this.selectedInform = _.cloneDeep(inform)
            this.originalInform = _.cloneDeep(inform)
            this.informDialog = true
        },
        addInform(inform) {
            this.informs.push(inform)
        },
        replaceInform(inform) {
            const index = this.informs.findIndex(item => item.id == inform.id)
            if (index >= 0) {
                this.informs.splice(index, 1, {
                    ...this.informs[index],
                    ...inform,
                })
            }
        },
    },
}
</script>

<style scoped>
.v-data-table {
    border: 1px solid #eeeeee;
}
.v-data-table >>> thead {
    background-color: #eeeeee;
}
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
    max-height: 60vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px; 
}
.v-data-table__wrapper thead tr {
    position: sticky; 
    top: 0;
    z-index: 10;
}
</style>
