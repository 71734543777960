var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'stock',
            }}},[_vm._v(" STOCK ")]),_c('v-tab',{attrs:{"to":{
                name: 'request',
            }}},[_vm._v(" CODE REQUEST ")]),_c('v-tab',{attrs:{"to":{
                name: 'reserves',
            }}},[_vm._v(" RESERVES ")]),_c('v-tab',{attrs:{"to":{
                name: 'boms-delivered',
            }}},[_vm._v(" OPEN BOM ")]),_c('v-tab',{attrs:{"to":{
                name: 'requested-boms',
            }}},[_vm._v(" OPEN ORDERS ")]),_c('v-tab',{attrs:{"to":{
                name: 'closed-boms',
            }}},[_vm._v(" CLOSED BOMS ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:({ height: `${_vm.height}px` })},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }