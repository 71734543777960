import { render, staticRenderFns } from "./NCcodes.vue?vue&type=template&id=c6b8193c&"
import script from "./NCcodes.vue?vue&type=script&lang=js&"
export * from "./NCcodes.vue?vue&type=script&lang=js&"
import style0 from "./NCcodes.vue?vue&type=style&index=0&id=c6b8193c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports