<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Upload File
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-4">
                <v-row no-gutters>
                    <v-text-field
                        v-model="trm"
                        type="number"
                        label="TRM *"
                        class="my-2"
                        prepend-icon="mdi-currency-usd"
                        :rules="[rules.required]"
                        required
                    >
                    </v-text-field>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="cutOffDate"
                                    label="Cut-off Date *"
                                    prepend-icon="mdi-calendar"
                                    :rules="[rules.required]"
                                    readonly
                                    v-bind="attrs"
                                    class="my-2"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="cutOffDate"
                                @input="menu = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-file-input
                        truncate-length="15"
                        v-model="file"
                        placeholder="File *"
                        accept=".xlsx"
                        hide-details
                        required
                        class="my-2"
                        :rules="[rules.required]"
                    ></v-file-input>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                rounded
                :loading="loading"
                class="mb-3"
                @click="readFile"
                :disabled="!valid"
            >
                IMPORT
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import API from '@/services/api'
//

export default {
    name: 'UploadPOC',
    data: () => ({
        loading: false,
        valid: false,
        file: null,
        rules: {
            required: v => !!v || 'invalid value',
        },
        pocData: [],
        pocs: [],
        trm: 1,
        estimatedMargin: 0,
        selectedYear: '',
        selectedMonth: '',
        defaultEstimatedMargin: null,
        cutOffDate: null,
        menu: false,
    }),
    mounted() {
        const options = { timeZone: 'America/Bogota', timeZoneName: 'short' }
        const formatter = new Intl.DateTimeFormat('en-US', options)
        const currentDate = formatter.format(new Date())
        this.selectedYear = currentDate.slice(6, 10)
        this.selectedMonth = currentDate.slice(0, 2)
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async createPOC(items, projects) {
            try {
                this.loading = true
                const newItems = []
                for (const item of items) {
                    const project = projects.find(
                        p => '' + p.sapCode == '' + item['CO']
                    )
                    if (!project) {
                        continue
                    }
                    const poc = this.pocs.find(q => q.orden_CO == item['CO'])
                    if (poc) {
                        this.defaultEstimatedMargin = poc.estimated_margin
                    } else {
                        this.defaultEstimatedMargin = 0.25
                    }
                    newItems.push(
                        this.createData(
                            project,
                            item,
                            this.defaultEstimatedMargin
                        )
                    )
                }
                const response = await API.createPOC({ items: newItems })
                this.$emit('addPOC', response)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getProjects(items) {
            try {
                this.loading = true
                const projects = await API.getProjectsBySapCode({
                    codes: items.map(item => item['CO']),
                })
                return projects
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async readFile() {
            try {
                this.loading = true
                const data = await this.file.arrayBuffer()
                /* data is an ArrayBuffer */
                const workbook = XLSX.read(data)
                var first_sheet_name = workbook.SheetNames[0]
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name]
                const items = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                })
                const projects = await this.getProjects(items)
                this.pocs = await API.getAllPOC()
                this.createPOC(items, projects)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        createData(project, item, estimatedMargin) {
            if (project.currency == 'USD') {
                project.b2b = project.b2b * this.trm
                project.price = project.price * this.trm
            }
            const estimated_revenues =
                (item['Billed to Date'] || 0) + project.b2b
            const estimated_gross_profit = estimated_revenues * estimatedMargin
            const estimated_cost = Math.abs(
                estimated_revenues - estimated_gross_profit
            )
            const cost_of_revenues =
                (item['Costs'] || 0) + (item['Nómina'] || 0)
            const percentage_completed = cost_of_revenues / estimated_cost
            const revenues_earned = percentage_completed * estimated_revenues
            const gross_profit = revenues_earned - cost_of_revenues

            let data = {
                job: project.name,
                client: project.clientData
                    ? project.clientData.name
                    : project.client,
                project_id: project.reference,
                orden_CO: item['CO'],
                currency: project.currency,
                total_amount_before_taxes: project.price,
                balance_to_bill: project.b2b,
                costs: item['Costs'] || 0,
                nomina: item['Nómina'] || 0,
                billed_to_date: item['Billed to Date'] || 0,
                estimated_revenues,
                estimated_margin: estimatedMargin,
                estimated_gross_profit,
                estimated_cost,
                percentage_completed,
                revenues_earned,
                gross_profit,
                [`cost_of_revenues_(COP)`]: cost_of_revenues,
                [`revenues_earned_(COP)`]:
                    estimated_revenues * percentage_completed,
                [`gross_profit_(COP)`]:
                    estimated_revenues * percentage_completed -
                    cost_of_revenues,
                ['billed_to_date_for_this_month']: project.price - project.b2b,
                ['estimated_cost_to_complete_(COP)']:
                    estimated_cost - cost_of_revenues,
                year: this.selectedYear,
                month: this.selectedMonth,
                trm_used: this.trm,
                cutOffDate: this.cutOffDate,
            }
            return data
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
