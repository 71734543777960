var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.costsFiltered,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4",style:({ 'background-color': '#eeeeee' }),attrs:{"no-gutter":""}},[_c('div',{staticClass:"d-flex"},[[_c('v-card',{staticClass:"pt-3 pl-2 pr-5 mx-3",attrs:{"min-width":"180px","height":"75px"}},[_c('v-card-text',{staticClass:"d-flex my-0 py-0 mx-0 px-0",attrs:{"width":"100%"}},[_c('v-chip',{staticClass:"mb-1 mt-0 mx-2 pt-0 white--text",style:({ height: '50px' }),attrs:{"color":_vm.insights[0].color,"label":""}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.insights[0].icon)+" ")])],1),_c('div',{staticClass:"mb-1 mt-0 pt-0"},[_c('div',{staticClass:"d-flex mr-0 pr-0 text-capitalize"},[_c('span',{style:({
                                                'font-size': '14px',
                                                'font-weight': 'bold',
                                            })},[_vm._v(_vm._s(_vm.insights[0].title))])]),_c('p',{staticClass:"text-h6 black--text"},[_vm._v(" "+_vm._s(_vm.costsFiltered.length)+" ")])])],1)],1)]],2),_c('div',{staticClass:"d-flex"},[[_c('v-card',{staticClass:"pt-3 pl-2 pr-5 mx-3",attrs:{"min-width":"180px","height":"75px"}},[_c('v-card-text',{staticClass:"d-flex my-0 py-0 mx-0 px-0",attrs:{"width":"100%"}},[_c('v-chip',{staticClass:"mb-1 mt-0 mx-2 pt-0 white--text",style:({ height: '50px' }),attrs:{"color":_vm.insights[1].color,"label":""}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.insights[1].icon)+" ")])],1),_c('div',{staticClass:"mb-1 mt-0 pt-0"},[_c('div',{staticClass:"d-flex mr-0 pr-0 text-capitalize"},[_c('span',{style:({
                                                'font-size': '14px',
                                                'font-weight': 'bold',
                                            })},[_vm._v(_vm._s(_vm.insights[1].title))])]),_c('p',{staticClass:"text-h6 black--text"},[_vm._v(" "+_vm._s(_vm.milestonesInfo().active)+" ")])])],1)],1)]],2),_c('div',{staticClass:"d-flex"},[[_c('v-card',{staticClass:"pt-3 pl-2 pr-5 mx-3",attrs:{"min-width":"180px","height":"75px"}},[_c('v-card-text',{staticClass:"d-flex my-0 py-0 mx-0 px-0",attrs:{"width":"100%"}},[_c('v-chip',{staticClass:"mb-1 mt-0 mx-2 pt-0 white--text",style:({ height: '50px' }),attrs:{"color":_vm.insights[2].color,"label":""}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.insights[2].icon)+" ")])],1),_c('div',{staticClass:"mb-1 mt-0 pt-0"},[_c('div',{staticClass:"d-flex mr-0 pr-0 text-capitalize"},[_c('span',{style:({
                                                'font-size': '14px',
                                                'font-weight': 'bold',
                                            })},[_vm._v(_vm._s(_vm.insights[2].title))])]),_c('p',{staticClass:"text-h6 black--text"},[_vm._v(" "+_vm._s(_vm.milestonesInfo().completed)+" ")])])],1)],1)]],2),_c('div',{staticClass:"d-flex"},[[_c('v-card',{staticClass:"pt-3 pl-2 pr-5 mx-3",attrs:{"min-width":"180px","height":"75px"}},[_c('v-card-text',{staticClass:"d-flex my-0 py-0 mx-0 px-0",attrs:{"width":"100%"}},[_c('v-chip',{staticClass:"mb-1 mt-0 mx-2 pt-0 white--text",style:({ height: '50px' }),attrs:{"color":_vm.insights[3].color,"label":""}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.insights[3].icon)+" ")])],1),_c('div',{staticClass:"mb-1 mt-0 pt-0"},[_c('div',{staticClass:"d-flex mr-0 pr-0 text-capitalize"},[_c('span',{style:({
                                                'font-size': '14px',
                                                'font-weight': 'bold',
                                            })},[_vm._v(_vm._s(_vm.insights[3].title))])]),_c('p',{staticClass:"text-h6 black--text"},[_vm._v(" "+_vm._s(_vm.milestonesInfo().percentage.toFixed( 1 ))+" % ")])])],1)],1)]],2)])]},proxy:true},{key:`header.description`,fn:function({ header }){return [_c('v-text-field',{staticClass:"pb-2 pt-3 mt-2",attrs:{"label":header.text,"dense":""},model:{value:(_vm.searchByDescription),callback:function ($$v) {_vm.searchByDescription=$$v},expression:"searchByDescription"}})]}},{key:`header.type`,fn:function({ header }){return [_c('v-text-field',{staticClass:"pb-2 pt-3 mt-2",attrs:{"label":header.text,"dense":""},model:{value:(_vm.searchByType),callback:function ($$v) {_vm.searchByType=$$v},expression:"searchByType"}})]}},{key:`header.quoteNumber`,fn:function({ header }){return [_c('v-text-field',{staticClass:"pb-2 pt-3 mt-2",attrs:{"label":header.text,"dense":""},model:{value:(_vm.searchByQuote),callback:function ($$v) {_vm.searchByQuote=$$v},expression:"searchByQuote"}})]}},{key:`item.description`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v(" "+_vm._s(item.reference)+" ")])])]}},{key:`item.type`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v(" "+_vm._s(item.type)+" ")])])]}},{key:`item.milestone`,fn:function({ item, header }){return [_c('div',{staticClass:"d-flex justify-center align-center"},[(
                        item.formatMilestones &&
                            item.formatMilestones[header.id]
                    )?_c('p',{class:{
                        'text-gray':
                            _vm.dateDiff(
                                item.formatMilestones[header.id].date
                            ) > 20 &&
                            item.formatMilestones[header.id].percentage <
                                100,
                        'text-orange':
                            _vm.dateDiff(
                                item.formatMilestones[header.id].date
                            ) > 6 &&
                            _vm.dateDiff(
                                item.formatMilestones[header.id].date
                            ) < 20 &&
                            item.formatMilestones[header.id].percentage <
                                100,
                        'text-red':
                            _vm.dateDiff(
                                item.formatMilestones[header.id].date
                            ) < 7 &&
                            item.formatMilestones[header.id].percentage <
                                100,
                        'text-green':
                            item.formatMilestones[header.id].percentage ==
                            100,
                    },style:({ margin: 0 })},[_vm._v(" "+_vm._s(_vm.formatDate( item.formatMilestones[header.id].date ._seconds || item.formatMilestones[header.id].date .seconds ))+" ")]):_vm._e(),_c('v-btn',{staticClass:"d-flex justify-center mt-0 ml-1",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openMilestoneDialog(item, header.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.formatMilestones[header.id] ? item.formatMilestones[header.id] .percentage == 100 ? 'mdi-eye' : 'mdi-pencil' : 'mdi-plus'))])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","width":"1300px"},model:{value:(_vm.milestoneDialog),callback:function ($$v) {_vm.milestoneDialog=$$v},expression:"milestoneDialog"}},[(_vm.milestoneDialog)?_c('MilestoneDetails',{attrs:{"selectedMilestone":_vm.selectedMilestone,"originalCost":_vm.selectedCost,"milestones":_vm.milestones,"settingId":_vm.settingId,"users":_vm.users},on:{"replaceMilestones":_vm.replaceMilestones,"close":_vm.closeMilestoneDialog}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }