<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="invoicesFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">INVOICES</h1>
                        <v-btn
                            v-if="readAllInvoices"
                            color="primary"
                            rounded
                            class="mx-2"
                            @click="openFindInvoice"
                        >
                            <v-icon class="mx-1">mdi-database-search</v-icon>
                            FIND
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            v-if="readAllInvoices"
                            rounded
                            color="primary"
                            @click="openGenerateXLS"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                            INFORM
                        </v-btn>
                        <v-btn
                            v-if="createInvoice"
                            rounded
                            color="primary"
                            @click="openCreateInvoce"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="codeToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.region`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="regionToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatDate(item.createdOn._seconds) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.createdBy" class="my-0 text-capitalize">
                        {{ item.createdBy.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.project" class="my-0">
                        {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.client`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.client" class="my-0">
                        {{ item.client.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.currency" class="my-0">
                        {{ item.currency }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <div class="d-flex justify-end align-right">
                    <p class="my-0">
                        {{ numberFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.billed`]="{ item }">
                <div class="d-flex justify-end align-right">
                    <p class="my-0">
                        {{
                            item.type == 'proforma'
                                ? numberFormat(item)
                                : billedFormat(item)
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.region`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="text-capitalize my-0">
                        {{ item.region }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn
                        small
                        icon
                        @click="openInvoiceRequest(item)"
                        v-if="
                            item.status == 'rejected' &&
                                item.createdBy.id == userId
                        "
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-simple-checkbox
                        color="primary"
                        v-model="item.able"
                        v-if="item.status === 'billed' && approvalInvoice"
                        @click="onCheckboxClicked(item)"
                    ></v-simple-checkbox>
                    <v-btn small icon @click="openInvoiceProcess(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                        small
                        icon
                        @click="openSendDialog(item)"
                        v-if="item.status === 'approved' && generateInvoce"
                    >
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                    <v-btn
                        small
                        icon
                        @click="openDeleteDialog(item)"
                        v-if="
                            item.status === 'rejected' &&
                                (deleteInvoicePermission ||
                                    item.createdBy.id == user.id ||
                                    item.isProjectManager)
                        "
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--ALERT-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--INVOCE FORM-->
        <v-dialog
            v-model="createInvoce"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <CreateInvoce
                v-if="createInvoce"
                :users="users"
                @close="closeCreateInvoce"
                @selectProject="selectProject"
            />
        </v-dialog>
        <!-- INVOICE REQUEST -->
        <v-dialog
            v-model="invoiceRequest"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <InvoiceRequest
                v-if="invoiceRequest"
                :project="selectedProject"
                :invoice="selectedInvoice"
                :setting="invoiceSettings"
                @close="closeInvoiceRequest"
            />
        </v-dialog>
        <!--INVOICE DETAILS-->
        <v-dialog
            fullscreen
            v-model="invoiceProcess"
            :retain-focus="false"
            persistent
        >
            <InvoiceDetails
                v-if="invoiceProcess"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :setting="invoiceSettings"
                @replaceInvoice="replaceInvoice"
                @close="closeInvoiceProcess"
                @approveInvoice="approve"
            />
        </v-dialog>
        <!--CREDIT DETAILS-->
        <v-dialog
            v-model="creditNoteProcess"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <CreditNoteRequest
                v-if="creditNoteProcess && selectedInvoice.type != 'proforma'"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :setting="invoiceSettings"
                :disabled="!creditActions"
                @close="closeCreditNoteProcess"
            />
            <CreditNoteRequestProforma
                v-if="creditNoteProcess && selectedInvoice.type == 'proforma'"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :disabled="!creditActions"
                @close="closeCreditNoteProcess"
            />
        </v-dialog>
        <!--PROFORMA DETAILS-->
        <v-dialog
            fullscreen
            v-model="proformaDetails"
            :retain-focus="false"
            persistent
        >
            <ProformaFeatures
                v-if="proformaDetails"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project || {}"
                @replaceInvoice="replaceInvoice"
                @close="closeInvoiceProcess"
                @approveInvoice="approve"
            />
        </v-dialog>
        <!-- APPROVE INVOICE -->
        <v-dialog
            v-model="approveInvoice"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeApproveDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        Approve Invoice
                        {{ selectedInvoice ? selectedInvoice.number : '' }}
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    Are you sure you want to approve it?
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="approve"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        APPROVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- SEND INVOICE -->
        <v-dialog
            v-model="sendInvoice"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeSendDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        Send Invoice
                        {{ selectedInvoice ? selectedInvoice.number : '' }}
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    Did you send the invoice to the client?
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-btn
                        @click="closeSendDialog"
                        color="error"
                        :loading="loading"
                        rounded
                    >
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="sendToClient"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- INFORM -->
        <v-dialog
            v-model="openInform"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading" v-if="openInform">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeGenerateXLS"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        INFORM
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    <v-form v-model="valid">
                        <v-row no-gutters>
                            <v-col cols="12" class="pt-4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-combobox
                                            class="py-0 my-0"
                                            v-model="dateRangeText"
                                            color="primary"
                                            label="Date range"
                                            readonly
                                            flat
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                            prepend-icon="mdi-calendar-blank-outline"
                                        ></v-combobox>
                                    </template>
                                    <v-date-picker
                                        v-model="dates"
                                        range
                                        scrollable
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateXLS"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        GENERATE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- FIND INVOICE -->
        <v-dialog
            v-model="findInvoice"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindInvoice
                v-if="findInvoice"
                @close="closeFindInvoice"
                @openInvoice="openInvoice"
            />
        </v-dialog>
        <!-- DELETE DIALOG -->
        <v-dialog
            v-model="deleteDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeDeleteDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        Delete Invoice
                        {{ selectedInvoice ? selectedInvoice.number : '' }}
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    Are you sure you want to delete this invoice?
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-btn
                        @click="closeDeleteDialog"
                        color="primary"
                        :loading="loading"
                        text
                    >
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteInvoice"
                        color="error"
                        :loading="loading"
                        rounded
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- proforma form -->
        <v-dialog
            :retain-focus="false"
            v-model="proformaFormDialog"
            persistent
            max-width="640px"
        >
            <ProformaFeaturesForm
                v-if="proformaFormDialog"
                :project="selectedInvoice.project"
                :projects="projects"
                :createForm="false"
                :invoice="selectedInvoice"
                :originalInvoice="originalInvoice"
                @close="closeProformaFeatureForm"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'Invoices',
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        CreateInvoce: () => import('@/components/Invoicing/CreateInvoce.vue'),
        InvoiceRequest: () =>
            import('@/components/Invoicing/InvoiceRequest.vue'),
        InvoiceDetails: () =>
            import('@/components/Invoicing/InvoiceDetails.vue'),
        FindInvoice: () => import('@/components/Invoicing/FindInvoice.vue'),
        ProformaFeatures: () =>
            import('@/components/Invoicing/ProformaFeatures.vue'),
        ProformaFeaturesForm: () =>
            import('@/components/Invoicing/ProformaFeaturesForm.vue'),
        CreditNoteRequest: () =>
            import('@/components/Invoicing/CreditNoteRequest.vue'),
        CreditNoteRequestProforma: () =>
            import('@/components/Invoicing/CreditNoteRequestProforma.vue'),
    },
    provide() {
        return {
            addInvoice: this.addInvoice,
            confirmSend: this.confirmSend,
            updateInvoice: this.updateInvoice,
            loadProformaFeature: this.loadProformaFeature,
            replaceProformaFeature: this.replaceProformaFeature,
        }
    },
    data: () => ({
        proformaDetails: false,
        deleteDialog: false,
        findInvoice: false,
        menu: false,
        invoiceSettings: {},
        invoiceDetails: false,
        createInvoce: false,
        selectedProject: {},
        invoiceRequest: false,
        selectedInvoice: {},
        invoiceProcess: false,
        sendInvoice: false,
        approveInvoice: false,
        codeToFilter: undefined,
        userToFilter: undefined,
        projectToFilter: undefined,
        regionToFilter: undefined,
        statusToFilter: undefined,
        loading: false,
        invoices: [],
        activateAlert: false,
        alertMessage: undefined,
        headers: [
            {
                text: 'ID',
                value: 'number',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLIENT',
                value: 'client',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CURRENCY',
                value: 'currency',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRICE',
                value: 'amount',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AMOUNT',
                value: 'billed',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REGION',
                value: 'region',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            approved: '#1976d2',
            billed: '#27bc4f',
            generating: '#edc43b',
            international: '#edc43b',
            closed: '#000000',
            rejected: 'red',
            credit: '#eda73b',
        },
        projects: [],
        users: [],
        clients: [],
        createInvoice: false,
        approvalInvoice: false,
        generateInvoce: false,
        userId: JSON.parse(localStorage.getItem('userId')),
        resourceId: undefined,
        readAllInvoices: false,
        deleteInvoicePermission: false,
        openInform: false,
        valid: false,
        dates: [],
        startDate: '',
        endDate: '',
        proformaFormDialog: false,
        originalInvoice: {},
        creditNoteProcess: false,
        creditActions: false,
        user: [],
    }),
    computed: {
        ...mapState(['notificationResource']),
        ...mapState(['invoiceUpdates']),
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        invoicesFiltered() {
            this.openResource()
            let conditions = []
            if (this.codeToFilter) {
                conditions.push(this.filterCode)
            }
            if (this.userToFilter) {
                conditions.push(this.filterUser)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.regionToFilter) {
                conditions.push(this.filterRegion)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterStatus)
            }
            if (conditions.length > 0) {
                return this.invoices.filter(invoice => {
                    return conditions.every(condition => {
                        return condition(invoice)
                    })
                })
            }
            this.sort()
            return this.invoices
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
        invoiceUpdates: function(data) {
            if (data) {
                this.UpdateInvoice(data)
                this.setInvoiceUpdates(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            if (Object.keys(this.setting).length > 0) {
                this.invoiceSettings = this.setting
            } else {
                const {
                    data: { settings },
                } = await API.getSettings()
                const setting = settings.find(
                    setting => setting.name.toLowerCase() == 'invoicing'
                )
                if (setting) {
                    this.invoiceSettings = setting
                }
            }
            await this.loadData()
            // set permissions
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.deleteInvoiceConditions()
            this.createInvoice = user.permissions.includes('createInvoice')
            this.approvalInvoice = user.permissions.includes('approvalInvoice')
            this.generateInvoce = user.permissions.includes('generateInvoice')
            this.readAllInvoices = user.permissions.includes('readAllInvoices')
            this.deleteInvoicePermission = user.permissions.includes(
                'deleteInvoice'
            )
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        ...mapActions(['setInvoiceUpdates']),
        ...mapGetters(['getInvoiceUpdates']),
        closeProformaFeatureForm() {
            this.proformaFormDialog = false
            this.originalInvoice = {}
            this.selectedInvoice = {}
        },
        async deleteInvoice() {
            try {
                this.loading = true
                await API.deleteInvoice({
                    id: this.selectedInvoice.id,
                    projectId:
                        this.selectedInvoice.type == 'proforma'
                            ? this.selectedInvoice.proformaProject
                            : this.selectedInvoice.projectId,
                })
                this.invoices = this.invoices.filter(
                    invoice => invoice.id != this.selectedInvoice.id
                )
                this.closeDeleteDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteDialog() {
            this.selectedInvoice = {}
            this.deleteDialog = false
        },
        openDeleteDialog(item) {
            this.selectedInvoice = item
            this.deleteDialog = true
        },
        openInvoice(invoice) {
            try {
                this.loading = true
                //set client
                invoice.client = this.clients.find(
                    client => client.id == invoice.clientId
                )
                //set createdBy
                const userIndex = this.users.findIndex(
                    user => user.id == invoice.createdBy
                )
                if (userIndex > -1) {
                    invoice.createdBy = this.users[userIndex]
                }
                //set Project
                const projectIndex = this.projects.findIndex(
                    project => project.id == invoice.projectId
                )
                if (projectIndex > -1) {
                    invoice.project = this.projects[projectIndex]
                }
                this.selectedInvoice = _.cloneDeep(invoice)
                this.invoiceProcess = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeFindInvoice() {
            this.findInvoice = false
        },
        openFindInvoice() {
            this.findInvoice = true
        },
        setDate() {
            if (this.dates[0] > this.dates[1]) {
                this.startDate = this.dates[1]
                this.endDate = this.dates[0]
            } else {
                this.startDate = this.dates[0]
                this.endDate = this.dates[1]
            }
            this.menu = false
        },
        closeGenerateXLS() {
            this.openInform = false
        },
        openGenerateXLS() {
            this.openInform = true
        },
        async generateXLS() {
            try {
                this.loading = true
                this.setDate()
                const informInvoices = await API.getInformInvoices({
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                const reportData = []
                for (const invoice of informInvoices) {
                    let row = {}
                    const {
                        createdBy,
                        createdOn,
                        approvedBy,
                        approvedOn,
                        closedBy,
                        closedOn,
                        project,
                        client,
                        billedBy,
                        billedOn,
                        concept,
                        amount,
                        number,
                        item_quantity_billed,
                        invoice_amount,
                        status,
                        region,
                        currency,
                        ...params
                    } = invoice

                    row.id = number
                    row.createdBy = createdBy.name
                    row.createdOn = createdOn
                    row.project = project ? project.name : ''
                    row.projectSapCode = project ? project.sapCode : ''
                    row.projectTotal = amount
                    row.client = client ? client.name : ''
                    row.concept = concept ? concept.name : ''
                    row.status = status
                    row.region = region
                    row.item_quantity_billed = item_quantity_billed
                    row.invoice_amount = invoice_amount
                    row.currency = currency
                    row.billedBy = billedBy ? billedBy.name : ''
                    row.billedOn = billedOn
                    row.approvedBy = approvedBy ? approvedBy.name : ''
                    row.approvedOn = approvedOn
                    row.closedBy = closedBy ? closedBy.name : ''
                    row.closedOn = closedOn
                    row = {
                        ...row,
                        ...params,
                    }
                    reportData.push(row)
                }

                // only array possible
                const reportWS = XLSX.utils.json_to_sheet(reportData)

                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, reportWS, 'report') // sheetAName is name of Worksheet
                // export Excel file
                XLSX.writeFile(wb, `invoicesReport.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openInvoiceRequest(item) {
            const invoice = _.cloneDeep(item)
            this.selectedProject = _.cloneDeep(invoice.project)
            invoice.createdBy = item.createdBy.id
            this.selectedInvoice = invoice
            if (this.selectedInvoice.type == 'proforma') {
                this.proformaFormDialog = true
                this.selectedInvoice = {
                    id: this.selectedInvoice.id,
                    name: this.selectedInvoice.name,
                    projectId: this.selectedInvoice.projectId,
                    project: this.selectedInvoice.project || null,
                    address: this.selectedInvoice.address,
                    phone: this.selectedInvoice.phone,
                    proformaValue: this.selectedInvoice.proformaValue,
                    tariffItem: this.selectedInvoice.tariffItem,
                    description: this.selectedInvoice.description,
                }
                this.originalInvoice = _.cloneDeep(this.selectedInvoice)
            } else {
                this.invoiceRequest = true
            }
        },
        replaceInvoice(invoice) {
            const index = this.invoices.findIndex(i => i.id == invoice.id)
            if (index >= 0) {
                this.invoices.splice(index, 1, {
                    ...this.invoices[index],
                    ...invoice,
                })
            }
        },
        loadProformaFeature(invoice) {
            //set createdBy
            const userIndex = this.users.findIndex(
                user => user.id == invoice.createdBy
            )
            if (userIndex > -1) {
                invoice.createdBy = this.users[userIndex]
            }
            //set Project
            const projectIndex = this.projects.findIndex(
                project => project.id == invoice.projectId
            )
            if (projectIndex > -1) {
                invoice.project = this.projects[projectIndex]
            }
            this.invoices.splice(0, 0, invoice)
            this.alertMessage = 'Invoice Request was successfully created.'
            this.activateAlert = true
        },
        loadInvoice(invoice) {
            //set client
            invoice.client = this.clients.find(
                client => client.id == invoice.clientId
            )
            //set createdBy
            const userIndex = this.users.findIndex(
                user => user.id == invoice.createdBy
            )
            if (userIndex > -1) {
                invoice.createdBy = this.users[userIndex]
            }
            //set Project
            const projectIndex = this.projects.findIndex(
                project => project.id == invoice.projectId
            )
            if (projectIndex > -1) {
                invoice.project = this.projects[projectIndex]
            }
            this.invoices.splice(0, 0, invoice)
        },
        confirmSend(invoice) {
            this.closeInvoiceRequest()
            this.loadInvoice(invoice)
            this.alertMessage = 'Invoice Request was successfully created.'
            this.activateAlert = true
        },
        async sendToClient() {
            try {
                this.loading = true
                const invoice = await API.sendInvoiceToClient({
                    id: this.selectedInvoice.id,
                    projectId:
                        this.selectedInvoice.type != 'proforma'
                            ? this.selectedInvoice.projectId
                            : this.selectedInvoice.proformaProject,
                })
                this.replaceInvoice(invoice)
                this.sendInvoice = false
                this.activateAlert = true
                this.alertMessage = 'Invoice Request was successfully Closed.'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async approve() {
            try {
                this.loading = true
                const invoice = await API.approvalInvoice({
                    id: this.selectedInvoice.id,
                    projectId:
                        this.selectedInvoice.type == 'proforma'
                            ? this.selectedInvoice.proformaProject
                            : this.selectedInvoice.projectId,
                    notificationId: 'z4yw1pMEbJAt5r0oz4FZ',
                    settingId: this.invoiceSettings.id,
                })
                this.replaceInvoice(invoice)
                this.approveInvoice = false
                this.activateAlert = true
                this.alertMessage = 'Invoice Request was successfully Approved.'
                this.selectedInvoice = {}
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadData() {
            try {
                this.loading = true
                const invoices = await API.getInvoices({})
                this.projects = await API.getLiteProjects({
                    all: true,
                    closing: true,
                })
                const {
                    data: { users },
                } = await API.getLiteUsers()
                const {
                    data: { clients },
                } = await API.getLiteClients()
                this.clients = clients
                this.users = users
                for (let invoice of invoices) {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    const userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                    //set Project
                    const projectIndex = this.projects.findIndex(
                        project => project.id == invoice.projectId
                    )
                    if (projectIndex > -1) {
                        invoice.project = this.projects[projectIndex]
                    }
                    this.invoices.push(invoice)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        addInvoice(invoice) {
            this.loadInvoice(invoice)
            this.closeInvoiceRequest()
            this.alertMessage = 'Invoice Request was successfully created.'
            this.activateAlert = true
        },
        replaceProformaFeature(invoice) {
            //set Project
            if (invoice.projectId) {
                const projectIndex = this.projects.findIndex(
                    project => project.id == invoice.projectId
                )
                if (projectIndex > -1) {
                    invoice.project = this.projects[projectIndex]
                }
            }
            this.replaceInvoice(invoice)
            this.alertMessage = 'Invoice Request was successfully updated.'
            this.activateAlert = true
        },
        updateInvoice(invoice) {
            //set client
            invoice.client = this.clients.find(
                client => client.id == invoice.clientId
            )
            //set createdBy
            const userIndex = this.users.findIndex(
                user => user.id == invoice.createdBy
            )
            if (userIndex > -1) {
                invoice.createdBy = this.users[userIndex]
            }
            //set Project
            const projectIndex = this.projects.findIndex(
                project => project.id == invoice.projectId
            )
            if (projectIndex > -1) {
                invoice.project = this.projects[projectIndex]
            }
            this.replaceInvoice(invoice)
            this.closeInvoiceRequest()
            this.alertMessage = 'Invoice Request was successfully updated.'
            this.activateAlert = true
        },
        closeInvoiceRequest() {
            this.invoiceRequest = false
        },
        closeCreateInvoce() {
            this.createInvoce = false
        },
        async selectProject(project, invoice) {
            try {
                this.loading = true
                this.selectedProject = project
                this.selectedInvoice = invoice
                this.invoiceRequest = true
                this.closeCreateInvoce()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openCreateInvoce() {
            this.createInvoce = true
        },
        async openInvoiceProcess(item) {
            try {
                this.loading = true
                this.selectedInvoice = _.cloneDeep(item)
                if (
                    this.selectedInvoice.type == 'proforma' &&
                    this.selectedInvoice.status != 'credit'
                ) {
                    this.proformaDetails = true
                } else if (
                    this.selectedInvoice.status == 'credit' ||
                    (this.selectedInvoice.type == 'proforma' &&
                        this.selectedInvoice.status == 'credit')
                ) {
                    if (this.selectedInvoice.project) {
                        this.selectedInvoice.project.quotes = await API.getProjectQuotes(
                            this.selectedInvoice.project.id
                        )
                    }
                    this.creditNoteProcess = true
                    this.creditActions = false
                } else {
                    this.invoiceProcess = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeInvoiceProcess() {
            this.selectedInvoice = {}
            this.invoiceProcess = false
            this.proformaDetails = false
        },
        openSendDialog(item) {
            this.selectedInvoice = item
            this.sendInvoice = true
        },
        closeSendDialog() {
            this.selectedInvoice = {}
            this.sendInvoice = false
        },
        filterCode(invoice) {
            let number = invoice.number
            if (typeof invoice.number == 'number') {
                number = invoice.number.toString()
            }
            return number
                .toLowerCase()
                .includes(this.codeToFilter.toLowerCase())
        },
        filterUser(invoice) {
            if (invoice.createdBy.name) {
                return invoice.createdBy.name
                    .toLowerCase()
                    .includes(this.userToFilter.toLowerCase())
            }
        },
        filterProject(invoice) {
            if (invoice.project) {
                return (
                    invoice.project.name
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase()) ||
                    invoice.project.reference
                        .toString()
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase())
                )
            }
        },
        filterRegion(invoice) {
            return invoice.region
                .toLowerCase()
                .includes(this.regionToFilter.toLowerCase())
        },
        filterStatus(invoice) {
            return invoice.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('L LT')
        },
        numberFormat(invoice) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? invoice.amount
                    : Math.ceil(invoice.amount)
            )
        },
        billedFormat(invoice) {
            let invoiceAmount = 0
            for (let key in invoice.items) {
                for (let cost of invoice.items[key]) {
                    if (cost.currentInvoice) {
                        invoiceAmount += cost.currentInvoice
                    }
                }
            }
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? invoiceAmount
                    : Math.ceil(invoiceAmount)
            )
        },
        sort() {
            this.invoices.sort(
                (a, b) => b.createdOn._seconds - a.createdOn._seconds
            )
        },
        onCheckboxClicked(item) {
            if (item.able) {
                this.selectedInvoice = _.cloneDeep(item)
                this.approveInvoice = true
            }
        },
        closeApproveDialog() {
            this.selectedInvoice = {}
            this.approveInvoice = false
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.invoices.find(
                    i => i.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openInvoiceProcess(resource)
                }
            }
        },
        async UpdateInvoice(invoice) {
            try {
                const index = this.invoices.findIndex(i => i.id == invoice.id)
                if (index >= 0) {
                    if (invoice.status != 'closed') {
                        const updatedInvoice = {
                            ...this.invoices[index],
                            ...invoice,
                        }
                        this.invoices.splice(index, 1, updatedInvoice)
                    } else {
                        this.invoices = this.invoices.filter(
                            i => i.id != invoice.id
                        )
                    }
                    const updatedInvoice = {
                        ...this.invoices[index],
                        ...invoice,
                    }
                    this.invoices.splice(index, 1, updatedInvoice)
                } else if (invoice.status != 'closed') {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    const userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                    //set Project
                    const projectIndex = this.projects.findIndex(
                        project => project.id == invoice.projectId
                    )
                    if (projectIndex > -1) {
                        invoice.project = this.projects[projectIndex]
                    }
                    this.invoices.push(invoice)
                }
            } catch (error) {
                console.error(error)
            }
        },
        closeCreditNoteProcess() {
            this.selectedInvoice = {}
            this.creditNoteProcess = false
        },
        deleteInvoiceConditions() {
            for (let project of this.projects) {
                for (let invoice of this.invoices) {
                    if (
                        project.projectManager == this.user.id &&
                        invoice.projectId == project.id
                    ) {
                        // get invoice index
                        const index = this.invoices.findIndex(
                            i => i.id == invoice.id
                        )
                        this.invoices[index].isProjectManager = true
                    }
                }
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
  max-height: 72vh;
  overflow-y: auto;
}

.v-data-table__row {
  height: 64px; 
}

.v-data-table__wrapper thead tr {
  position: sticky; 
  top: 0;
  z-index: 10;
}
</style>
