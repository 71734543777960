var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:{
        'd-flex justify-end my-3': _vm.user.id === _vm.userId,
        'd-flex justify-start my-3': _vm.user.id !== _vm.userId,
    }},[_c('div',[_c('div',{staticClass:"d-flex ml-3 pl-3"},[_c('div',{staticClass:"d-flex flex-column pa-2 user-post"},[_c('div',{staticClass:"d-flex flex-row"},[(_vm.user)?_c('div',{class:'d-flex justify-left mb-1'},[_c('v-avatar',{attrs:{"size":"36"}},[(_vm.pictureURL)?_c('v-img',{attrs:{"src":_vm.pictureURL,"alt":_vm.user.name}}):_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('span',{staticClass:"text-capitalize text-subtitle-2 mx-2 mt-2"},[_vm._v(_vm._s(_vm.user.name))])],1):_vm._e(),_c('v-spacer'),_c('div',[(_vm.date)?_c('p',{staticClass:"my-0 py-0 body-2"},[_vm._v(" "+_vm._s(_vm.date.format('MMM DD, HH:mm'))+" ")]):_vm._e()])],1),(!_vm.post.file)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.safeHTML(_vm.post.message))}}):(
                        _vm.post.file.extension.toLowerCase() == 'pdf' ||
                            _vm.post.file.extension.toLowerCase() == 'PDF'
                    )?_c('div',{style:({ 'max-width': '300px' })},[(_vm.fileURL)?_c('PdfThumbnail',{attrs:{"pdfFile":_vm.fileURL}}):_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{style:({ 'max-width': '200px !important' })},[_c('p',{staticClass:"text-truncate pt-2"},[_vm._v(" "+_vm._s(_vm.fileName)+" ")])])],1):(
                        _vm.post.file.extension.toLowerCase() != 'jpg' &&
                            _vm.post.file.extension.toLowerCase() != 'jpeg' &&
                            _vm.post.file.extension.toLowerCase() != 'png' &&
                            _vm.post.file.extension.toLowerCase() != 'webp'
                    )?_c('div',{staticClass:"my-2"},[_c('v-chip',{attrs:{"label":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-paperclip ")]),_c('a',{staticClass:"my-0",style:({ 'text-decoration': 'none' }),attrs:{"target":"_blank","href":_vm.fileURL}},[_vm._v(_vm._s(_vm.fileName)+" ")])],1)],1):_c('div',{staticClass:"my-2"},[(_vm.fileURL)?_c('a',{attrs:{"href":_vm.fileURL,"target":"_blank"}},[_c('v-img',{attrs:{"src":_vm.fileURL,"alt":_vm.fileName,"max-width":"300","max-height":"388"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]),(_vm.post.event && _vm.post.event.title)?_c('div',{staticClass:"mt-1 d-flex justify-left"},[_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"color":"blue","outlined":""},on:{"click":_vm.openEvent}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.post.event.title.length > 32 ? _vm.post.event.title.substring(0, 32) + '...' : _vm.post.event.title)+" ")],1)],1):_vm._e(),(_vm.post && _vm.post.files)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.post.files),function(file,i){return _c('v-btn',{key:i,staticClass:"mt-1 justify-left mr-1 text-capitalize grey--text text--darken-1",attrs:{"color":"grey lighten-3","elevation":"0"}},[_c('span',{on:{"click":function($event){return _vm.openDoc(file)}}},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(_vm._s(file))],1)])}),1):_vm._e()]),_c('div',{staticClass:"degradedRightSide"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }