<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Mark Ready For Packing
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text>
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            :style="{ 'background-color': '#eeeeee' }"
                        >
                            <v-col cols="6" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                        </v-row>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.available`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.available }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.qtyToMark"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                            />
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton() || !valid"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Mark Ready For Packing
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="workOrder.items.filter(i => i.qtyToMark)"
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="{
                                'max-height': `${height}px`,
                                'overflow-y': 'auto',
                            }"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    :style="{ 'background-color': '#eeeeee' }"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.code`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.code }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.qtyToMark }}</p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-row class="ma-2">
                        <v-col cols="3" class="d-flex justify-end pa-0 ">
                            <v-btn
                                color="primary"
                                rounded
                                :disabled="!valid"
                                :loading="loading"
                                @click="markItems"
                            >
                                CONFIRM
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'MultiplePacking',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'available',
                align: 'center',
                sortable: false,
                width: '130',
            },
            {
                text: 'QTY TO MARK',
                value: 'qty',
                align: 'center',
                sortable: false,
                width: '130',
            },
        ],
        confirmHeaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUANTITY TO MARK',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },
        selectedProcess: { id: 'readyForPacking', name: 'Ready for packing' },
        selectedMachine: {
            id: 'readyForPacking',
            name: 'Ready for Packing',
            process: 'readyForPacking',
        },
        confirmDialog: false,
        height: 0,
        partNumberToFilter: '',
    }),
    computed: {
        filteredItems() {
            let conditions = []
            conditions.push(this.filterAvailable)

            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }

            if (conditions.length > 0) {
                return this.workOrder.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.workOrder.items
        },
    },
    mounted() {
        this.workOrder.items.forEach(item => {
            this.calculateValues(item)
            item.qtyToMark = Number(item.available)
            this.onResize()
        })
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async markItems() {
            try {
                this.loading = true
                await API.markBatchReadyForPacking({
                    items: this.workOrder.items.filter(i => i.qtyToMark),
                    workOrderId: this.workOrder.id,
                    process: this.selectedProcess,
                    machine: this.selectedMachine,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        calculateValues(item) {
            try {
                item.available = 0

                const lastProcessId = item.processes[item.processes.length - 1]

                if (item.production) {
                    item.production.forEach(production => {
                        if (production.process.id == lastProcessId) {
                            item.available += Number(production.qty)
                        } else if (
                            production.process.id == this.selectedProcess.id
                        ) {
                            item.available -= Number(production.qty)
                        }
                    })
                }
            } catch (error) {
                console.error(error)
            }
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        close() {
            this.workOrder.items.forEach(item => {
                delete item.qtyToMark
            })
            this.$emit('closeDialog')
        },

        disableButton() {
            return !this.workOrder.items.find(item => item.qtyToMark > 0)
        },

        onResize() {
            this.height = window.innerHeight - 260
        },

        filterAvailable(item) {
            return item.available && item.available > 0
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },

        maxValue(item) {
            return item.qtyToMark > item.available || item.qtyToMark <= 0
                ? 'The quantity to be marked cannot be greater than the quantity available'
                : true
        },
    },
}
</script>

<style></style>
