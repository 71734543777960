<template>
    <div class="mx-0 px-0 py-0 mt-3">
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    :height="height"
                    fixed-header
                >
                    <template v-slot:top>
                        <div ref="tableHeader">
                            <v-row
                                no-gutter
                                :style="{ 'background-color': '#eeeeee' }"
                                :class="
                                    $vuetify.breakpoint.xs
                                        ? 'd-flex justify-center py-4 mx-0'
                                        : 'd-flex justify-start py-2 mx-0'
                                "
                            >
                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[0].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[0].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[0]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{ filters.length }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>
                            </v-row>
                        </div>
                    </template>
                    <!-- Headers -->

                    <template v-slot:[`header.reference`]="{ header }">
                        <div class="d-flex">
                            <v-text-field
                                :label="header.text"
                                v-model="projectReference"
                            />
                        </div>
                    </template>

                    <template v-slot:[`header.sapCode`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectSapCode"
                        />
                    </template>

                    <template v-slot:[`header.name`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectName"
                        />
                    </template>

                    <template v-slot:[`header.client`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectClient"
                        />
                    </template>

                    <template v-slot:[`header.accountManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectAManager"
                        />
                    </template>

                    <template v-slot:[`header.projectManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectPManager"
                        />
                    </template>

                    <template v-slot:[`header.price`]="{ header }">
                        <h3>{{ header.text }}</h3>
                    </template>

                    <template v-slot:[`header.exports`]="{ header }">
                        <v-select
                            :label="header.text"
                            v-model="filterByExports"
                            :items="filterByFiles"
                        />
                    </template>

                    <template v-slot:[`header.clientPortfolio`]="{ header }">
                        <v-select
                            :label="header.text"
                            v-model="filterByClientPortfolio"
                            :items="filterByFiles"
                        />
                    </template>

                    <template v-slot:[`header.closingInSAP`]="{ header }">
                        <v-select
                            :label="header.text"
                            v-model="filterByClosingInSAP"
                            :items="filterByFiles"
                        />
                    </template>

                    <!-- Items -->

                    <template v-slot:[`item.client`]="{ item }">
                        <div class="my-4">
                            {{ item.clientName }}
                        </div>
                    </template>

                    <template v-slot:[`item.accountManager`]="{ item }">
                        <div class="my-4">
                            {{ item.accountManagerName }}
                        </div>
                    </template>

                    <template v-slot:[`item.projectManager`]="{ item }">
                        <div class="my-4">
                            {{ item.projectManagerName }}
                        </div>
                    </template>

                    <template v-slot:[`item.price`]="{ item }">
                        <div class="my-4" :style="{ 'text-align': 'end' }">
                            <div class="my-4">
                                {{ formatCurrency(item) }}
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.exports`]="{ item }">
                        <div
                            class="d-flex justify-center"
                            v-if="comexEvidencePermission"
                        >
                            <div class="d-flex">
                                <v-checkbox
                                    class="pt-1"
                                    color="primary"
                                    v-model="item.comexCheck"
                                    @click="checkParam('comexCheck', item)"
                                />
                            </div>
                            <div class="d-flex pt-4">
                                <span
                                    class="pt-1"
                                    :style="{
                                        'font-size': '14px',
                                        color: '#2b81d6',
                                        'text-decoration': 'underline',
                                        cursor: 'pointer',
                                    }"
                                    @click="openFile(item, item.exports)"
                                    >{{ item.exports }}</span
                                >
                                <v-btn
                                    @click="onButtonClick(item, 'exports')"
                                    depressed
                                    fab
                                    x-small
                                    color="primary"
                                    class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                    :loading="loading"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <input
                                    ref="exports"
                                    class="d-none"
                                    type="file"
                                    name="exports"
                                    @change="onFileChanged"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.clientPortfolio`]="{ item }">
                        <div
                            class="d-flex justify-center"
                            v-if="portfolioEvidencePermission"
                        >
                            <div class="d-flex">
                                <v-checkbox
                                    class="pt-1"
                                    color="primary"
                                    v-model="item.portfolioCheck"
                                    @click="checkParam('portfolioCheck', item)"
                                />
                            </div>
                            <div class="d-flex pt-4">
                                <span
                                    class="py-0 mt-1"
                                    :style="{
                                        'font-size': '14px',
                                        color: '#2b81d6',
                                        'text-decoration': 'underline',
                                        cursor: 'pointer',
                                    }"
                                    @click="
                                        openFile(item, item.clientPortfolio)
                                    "
                                    >{{ item.clientPortfolio }}</span
                                >
                                <v-btn
                                    @click="
                                        onButtonClick(item, 'clientPortfolio')
                                    "
                                    depressed
                                    fab
                                    x-small
                                    color="primary"
                                    class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                    :loading="loading"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <input
                                    ref="clientPortfolio"
                                    class="d-none"
                                    type="file"
                                    name="clientPortfolio"
                                    @change="onFileChanged"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.closingInSAP`]="{ item }">
                        <div
                            class="d-flex justify-center"
                            v-if="sapEvidencePermission"
                        >
                            <div class="d-flex">
                                <v-checkbox
                                    class="pt-1"
                                    color="primary"
                                    v-model="item.sapCheck"
                                    @click="checkParam('sapCheck', item)"
                                />
                            </div>
                            <div class="d-flex pt-4">
                                <span
                                    class="py-0 mt-1"
                                    :style="{
                                        'font-size': '14px',
                                        color: '#2b81d6',
                                        'text-decoration': 'underline',
                                        cursor: 'pointer',
                                    }"
                                    @click="openFile(item, item.closingInSAP)"
                                    >{{ item.closingInSAP }}</span
                                >
                                <v-btn
                                    @click="onButtonClick(item, 'closingInSAP')"
                                    depressed
                                    fab
                                    x-small
                                    color="primary"
                                    class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                    :loading="loading"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <input
                                    ref="closingInSAP"
                                    class="d-none"
                                    type="file"
                                    name="closingInSAP"
                                    @change="onFileChanged"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <div class="d-flex justify-center my-4">
                            <v-btn
                                :disabled="
                                    !item.comexCheck ||
                                        !item.portfolioCheck ||
                                        !item.sapCheck ||
                                        !archivePermission
                                "
                                depressed
                                rounded
                                color="primary"
                                @click="openArchiveDialog(item)"
                                icon
                            >
                                <v-icon>mdi-archive</v-icon>
                            </v-btn>
                            <v-btn
                                :disabled="
                                    loading ||
                                        !returnProjectFromClosingPermission
                                "
                                icon
                                depressed
                                rounded
                                @click="
                                    openReturnProjectFromClosingDialog(item)
                                "
                            >
                                <v-icon>mdi-backup-restore</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="400px">
            <v-card :loading="loading">
                <!-- Archive Project Dialog -->
                <v-card-title v-if="archiveDialog" class="text-h5"
                    >Archive Project</v-card-title
                >
                <v-card-text v-if="archiveDialog">
                    Are you sure you want to archive this project?
                </v-card-text>
                <v-card-actions v-if="archiveDialog">
                    <v-btn text color="secondary" @click="closeDialog">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="archiveProject"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Archive
                    </v-btn>
                </v-card-actions>
                <!-- Return Project From Closing Dialog -->
                <v-card-title
                    v-if="returnProjectFromClosingDialog"
                    class="text-h5"
                    >Return Project From Closing</v-card-title
                >
                <v-card-text v-if="returnProjectFromClosingDialog">
                    Are you sure you want to move the project back to the main
                    board?
                </v-card-text>
                <v-card-actions v-if="returnProjectFromClosingDialog">
                    <v-btn text color="secondary" @click="closeDialog">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="restoreProject"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Return Project
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'
import { saveFile } from '@/services/storage/saveFile.js'
import { openFile } from '@/services/storage/openFile.js'

export default {
    name: 'ClosingProjects',
    components: {},
    data: () => ({
        projectClient: undefined,
        projectAManager: undefined,
        headers: [
            {
                text: 'Reference',
                value: 'reference',
                align: 'center',
                sortable: false,
                width: 150,
            },
            {
                text: 'SAP Code',
                value: 'sapCode',
                align: 'center',
                sortable: false,
                width: 150,
            },
            {
                text: 'Name',
                value: 'name',
                align: 'left',
                sortable: false,
                width: 250,
            },
            {
                text: 'Client',
                value: 'client',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Account M.',
                value: 'accountManager',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Project M.',
                value: 'projectManager',
                align: 'left',
                sortable: false,
                width: 200,
            },
            {
                text: 'Price',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'EXPORTS',
                value: 'exports',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLIENT PORTFOLIO',
                value: 'clientPortfolio',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLOSING IN SAP',
                value: 'closingInSAP',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTION',
                value: 'action',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        projects: [],
        loading: false,
        users: [],
        selectedProject: {},
        projectReference: '',
        projectSapCode: '',
        projectPManager: '',
        projectName: '',
        filterByExports: 'all',
        filterByFiles: ['all', 'checked', 'unchecked'],
        filterByClientPortfolio: 'all',
        filterByClosingInSAP: 'all',
        companyId: JSON.parse(localStorage.getItem('company')),
        archiveDialog: false,
        comexEvidencePermission: false,
        portfolioEvidencePermission: false,
        sapEvidencePermission: false,
        archivePermission: false,
        returnProjectFromClosingPermission: false,
        clients: [],
        insights: [
            {
                color: '#3498DB',
                icon: 'mdi-clipboard-multiple',
                iconColor: '#1A5276',
                title: 'PROJECTS',
            },
        ],
        dialog: false,
        returnProjectFromClosingDialog: false,
    }),
    async mounted() {
        try {
            this.loading = true
            // retrive project
            this.projects = await API.closingProjects()
            for (const project of this.projects) {
                if (project.closingData) {
                    project.comexCheck = project.closingData.comex
                        ? project.closingData.comex.check
                        : false
                    project.portfolioCheck = project.closingData.clientPortfolio
                        ? project.closingData.clientPortfolio.check
                        : false
                    project.sapCheck = project.closingData.sap
                        ? project.closingData.sap.check
                        : false
                } else {
                    project.comexCheck = false
                    project.portfolioCheck = false
                    project.sapCheck = false
                }
            }
            // set users
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // set clients
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
            // retrieve user auth
            const {
                data: { user },
            } = await API.getMyInfo()
            // set permissions
            this.comexEvidencePermission = user.permissions.includes(
                'comexEvidence'
            )
            this.portfolioEvidencePermission = user.permissions.includes(
                'portfolioEvidence'
            )
            this.sapEvidencePermission = user.permissions.includes(
                'sapEvidence'
            )
            this.archivePermission = user.permissions.includes('archiveProject')
            this.returnProjectFromClosingPermission = user.permissions.includes(
                'returnProjectFromClosing'
            )
            // complete data
            this.completeProjectData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    updated() {
        this.onResize()
    },
    computed: {
        filters() {
            let conditions = []
            this.sortByReference()

            if (this.projectName) {
                conditions.push(this.filterName)
            }

            if (this.projectClient) {
                conditions.push(this.filterClient)
            }

            if (this.projectAManager) {
                conditions.push(this.filterAManager)
            }

            if (this.projectPManager) {
                conditions.push(this.filterPManager)
            }

            if (this.projectReference) {
                conditions.push(this.filterReference)
            }

            if (this.projectSapCode) {
                conditions.push(this.filterSapCode)
            }

            if (this.filterByExports) {
                conditions.push(this.filterExports)
            }

            if (this.filterByClientPortfolio) {
                conditions.push(this.filterClientPortfolio)
            }

            if (this.filterByClosingInSAP) {
                conditions.push(this.filterClosingInSAP)
            }

            if (conditions.length > 0) {
                const result = this.projects.filter(project => {
                    return conditions.every(condition => {
                        return condition(project)
                    })
                })
                return result
            }
            return this.projects
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        formatCurrency(item) {
            return new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
                style: 'currency',
                currency: item.currency,
            }).format(item.price)
        },
        filterAManager(item) {
            return (
                item.accountManagerName &&
                item.accountManagerName
                    .toLowerCase()
                    .includes(this.projectAManager.toLowerCase())
            )
        },
        filterClient(item) {
            return (
                item.clientName &&
                item.clientName
                    .toLowerCase()
                    .includes(this.projectClient.toLowerCase())
            )
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        async checkParam(key, item) {
            try {
                this.loading = true
                this.selectedProject = _.cloneDeep(item)
                // update project
                await API.checkParamsForClosing({
                    id: this.selectedProject.id,
                    [key]: this.selectedProject[key],
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.selectedProject = {}
            }
        },
        remove() {
            try {
                this.projects = this.projects.filter(
                    project => project.id != this.selectedProject.id
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async archiveProject() {
            try {
                this.loading = true
                await API.updateProject(this.selectedProject.id, {
                    archive: true,
                })
                this.remove()
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDialog() {
            this.selectedProject = {}
            this.archiveDialog = false
            this.returnProjectFromClosingDialog = false
            this.dialog = false
        },
        openArchiveDialog(project) {
            this.selectedProject = _.cloneDeep(project)
            this.archiveDialog = true
            this.returnProjectFromClosingDialog = false
            this.dialog = true
        },
        openReturnProjectFromClosingDialog(project) {
            this.selectedProject = _.cloneDeep(project)
            this.archiveDialog = false
            this.returnProjectFromClosingDialog = true
            this.dialog = true
        },
        async openFile(item, file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${item.id}/closing`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile(type, file) {
            try {
                this.loading = true
                // update project
                await API.updateProject(this.selectedProject.id, {
                    [type]: file.name,
                })
                // save file in the storage
                await saveFile(
                    file,
                    `${this.companyId}/projects/${this.selectedProject.id}/closing`
                )
                // replace project
                const index = this.projects.findIndex(
                    project => project.id == this.selectedProject.id
                )
                this.$set(this.projects, index, {
                    [type]: file.name,
                    ...this.projects[index],
                })
                // clean selected project
                this.selectedProject = {}
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async onFileChanged(e) {
            if (e.target.files.length > 0) {
                const file = e.target.files[0]
                await this.saveFile(e.target.name, file)
                switch (e.target.name) {
                    case 'exports':
                        this.$refs.exports.value = ''
                        break
                    case 'clientPortfolio':
                        this.$refs.clientPortfolio.value = ''
                        break
                    case 'closingInSAP':
                        this.$refs.closingInSAP.value = ''
                        break
                    default:
                        return null
                }
            }
        },
        onButtonClick(item, type) {
            this.selectedProject = item
            if (type == 'exports') {
                this.$refs.exports.click()
            } else if (type == 'clientPortfolio') {
                this.$refs.clientPortfolio.click()
            } else if (type == 'closingInSAP') {
                this.$refs.closingInSAP.click()
            }
        },
        filterClosingInSAP(item) {
            if (this.filterByClosingInSAP == 'all') {
                return true
            } else if (
                this.filterByClosingInSAP == 'checked' &&
                item.sapCheck
            ) {
                return true
            } else if (
                this.filterByClosingInSAP == 'unchecked' &&
                !item.sapCheck
            ) {
                return true
            } else {
                return false
            }
        },
        filterClientPortfolio(item) {
            if (this.filterByClientPortfolio == 'all') {
                return true
            } else if (
                this.filterByClientPortfolio == 'checked' &&
                item.portfolioCheck
            ) {
                return true
            } else if (
                this.filterByClientPortfolio == 'unchecked' &&
                !item.portfolioCheck
            ) {
                return true
            } else {
                return false
            }
        },
        filterExports(item) {
            if (this.filterByExports == 'all') {
                return true
            } else if (this.filterByExports == 'checked' && item.comexCheck) {
                return true
            } else if (
                this.filterByExports == 'unchecked' &&
                !item.comexCheck
            ) {
                return true
            } else {
                return false
            }
        },
        sortByReference() {
            this.projects.sort((a, b) => {
                return b.reference - a.reference
            })
        },
        filterReference(item) {
            return (
                item.reference &&
                item.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectReference.toLowerCase())
            )
        },
        filterSapCode(item) {
            return (
                item.sapCode &&
                item.sapCode
                    .toString()
                    .toLowerCase()
                    .includes(this.projectSapCode.toLowerCase())
            )
        },
        filterPManager(item) {
            return (
                item.projectManagerName &&
                item.projectManagerName
                    .toLowerCase()
                    .includes(this.projectPManager.toLowerCase())
            )
        },
        filterName(item) {
            if (item.name) {
                return (
                    item.name &&
                    item.name
                        .toLowerCase()
                        .includes(this.projectName.toLowerCase())
                )
            } else {
                return false
            }
        },
        completeProjectData() {
            for (let project of this.projects) {
                project.clientName = this.getClientName(project.client)
                project.accountManagerName = this.getUserName(
                    project.accountManager
                )
                project.projectManagerName = this.getUserName(
                    project.projectManager
                )
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            const {
                tableHeader: { clientHeight: headerHeight },
            } = this.$refs
            this.height = window.innerHeight - headerHeight - 180
        },
        removeProject(id) {
            this.projects = this.projects.filter(p => p.id != id)
        },
        async restoreProject() {
            try {
                this.loading = true
                this.error = false
                this.errorMessage = ''
                API.returnProjectFromClosing(this.selectedProject.id)
                this.remove()
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scope>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background-color: #eeeeee !important;
}
thead th {
    background-color: #eeeeee !important;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
