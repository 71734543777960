<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="invoicesFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">INTERNATIONAL</h1>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="codeToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatDate(item.createdOn._seconds) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.createdBy" class="my-0 text-capitalize">
                        {{ item.createdBy.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.project" class="my-0">
                        {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.client`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.client" class="my-0">
                        {{ item.client.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.currency }}</p>
                </div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <div class="d-flex justify-end align-right">
                    <p class="my-0">
                        {{ numberFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.billed`]="{ item }">
                <div class="d-flex justify-end align-right">
                    <p class="my-0">
                        {{
                            item.type == 'proforma'
                                ? numberFormat(item)
                                : billedFormat(item)
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openInvoiceProcess(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--INVOICE DETAILS-->
        <v-dialog
            fullscreen
            v-model="invoiceProcess"
            :retain-focus="false"
            persistent
        >
            <InvoiceDetails
                v-if="invoiceProcess"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :setting="invoiceSettings"
                :inProcess="true"
                @close="closeInvoiceProcess"
                @sentToGenerate="sentToGenerate"
            />
        </v-dialog>
        <!--PROFORMA DETAILS-->
        <v-dialog
            fullscreen
            v-model="proformaDetails"
            :retain-focus="false"
            persistent
        >
            <ProformaFeatures
                v-if="proformaDetails"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project || {}"
                @close="closeInvoiceProcess"
                @sentToGenerate="sentToGenerate"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'International',
    components: {
        InvoiceDetails: () =>
            import('@/components/Invoicing/InvoiceDetails.vue'),
        ProformaFeatures: () =>
            import('@/components/Invoicing/ProformaFeatures.vue'),
    },
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        selectedInvoice: undefined,
        invoiceProcess: false,
        sendInvoice: false,
        codeToFilter: undefined,
        userToFilter: undefined,
        projectToFilter: undefined,
        loading: false,
        invoices: [],
        activateAlert: false,
        alertMessage: undefined,
        headers: [
            {
                text: 'ID',
                value: 'number',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLIENT',
                value: 'client',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CURRENCY',
                value: 'currency',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRICE',
                value: 'amount',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AMOUNT',
                value: 'billed',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        projects: [],
        users: [],
        clients: [],
        invoiceSettings: {},
        resourceId: undefined,
        proformaDetails: false,
    }),
    provide() {
        return {
            updateInvoice: this.updateInvoice,
        }
    },
    computed: {
        ...mapState(['notificationResource']),
        ...mapState(['invoiceUpdates']),
        invoicesFiltered() {
            this.openResource()
            let conditions = []
            if (this.codeToFilter) {
                conditions.push(this.filterCode)
            }
            if (this.userToFilter) {
                conditions.push(this.filterUser)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.invoices.filter(invoice => {
                    return conditions.every(condition => {
                        return condition(invoice)
                    })
                })
            }
            this.sort()
            return this.invoices
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
        invoiceUpdates: function(data) {
            if (data) {
                this.updateInvoice(data)
                this.setInvoiceUpdates(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            await this.getSetting()
            await this.loadData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        ...mapActions(['setInvoiceUpdates']),
        ...mapGetters(['getInvoiceUpdates']),
        async getSetting() {
            try {
                this.loading = true
                if (Object.keys(this.setting).length > 0) {
                    this.invoiceSettings = this.setting
                } else {
                    const {
                        data: { settings },
                    } = await API.getSettings()
                    const setting = settings.find(
                        setting => setting.name.toLowerCase() == 'invoicing'
                    )
                    if (setting) {
                        this.invoiceSettings = setting
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        sentToGenerate(id) {
            this.invoices = this.invoices.filter(invoice => invoice.id != id)
            this.activateAlert = true
            this.alertMessage =
                'Invoice Request was successfully send to generate.'
        },
        async loadData() {
            try {
                this.loading = true
                const invoices = await API.getInvoices({
                    status: 'international',
                })
                this.projects = await API.getLiteProjects({ all: true })
                const {
                    data: { users },
                } = await API.getLiteUsers()
                const {
                    data: { clients },
                } = await API.getLiteClients()
                this.clients = clients
                this.users = users
                for (let invoice of invoices) {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    const userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                    //set Project
                    const projectIndex = this.projects.findIndex(
                        project => project.id == invoice.projectId
                    )
                    if (projectIndex > -1) {
                        invoice.project = this.projects[projectIndex]
                    }
                    this.invoices.push(invoice)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openInvoiceProcess(item) {
            try {
                this.selectedInvoice = _.cloneDeep(item)
                if (this.selectedInvoice.type == 'proforma') {
                    this.proformaDetails = true
                } else {
                    this.selectedInvoice.project.quotes = await API.getProjectQuotes(
                        this.selectedInvoice.project.id
                    )
                    this.invoiceProcess = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeInvoiceProcess() {
            this.selectedInvoice = {}
            this.invoiceProcess = false
            this.proformaDetails = false
        },
        filterCode(invoice) {
            let number = invoice.number
            if (typeof invoice.number == 'number') {
                number = invoice.number.toString()
            }
            return number
                .toLowerCase()
                .includes(this.codeToFilter.toLowerCase())
        },
        filterUser(invoice) {
            if (invoice.createdBy.name) {
                return invoice.createdBy.name
                    .toLowerCase()
                    .includes(this.userToFilter.toLowerCase())
            }
        },
        filterProject(invoice) {
            if (invoice.project) {
                return (
                    invoice.project.name
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase()) ||
                    invoice.project.reference
                        .toString()
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase())
                )
            }
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('L LT')
        },
        numberFormat(invoice) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: 0,
            }).format(Math.ceil(invoice.amount))
        },
        billedFormat(invoice) {
            let invoiceAmount = 0
            for (let key in invoice.items) {
                for (let cost of invoice.items[key]) {
                    if (cost.currentInvoice) {
                        invoiceAmount += cost.currentInvoice
                    }
                }
            }
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: 0,
            }).format(Math.ceil(invoiceAmount))
        },
        sort() {
            this.invoices.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.invoices.find(
                    i => i.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openInvoiceProcess(resource)
                }
            }
        },
        async updateInvoice(invoice) {
            try {
                const index = this.invoices.findIndex(i => i.id == invoice.id)
                if (index >= 0) {
                    if (invoice.status == 'international') {
                        const updatedInvoice = {
                            ...this.invoices[index],
                            ...invoice,
                        }
                        this.invoices.splice(index, 1, updatedInvoice)
                    } else {
                        this.invoices = this.invoices.filter(
                            i => i.id != invoice.id
                        )
                    }
                } else if (invoice.status == 'international') {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    const userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                    //set Project
                    const projectIndex = this.projects.findIndex(
                        project => project.id == invoice.projectId
                    )
                    if (projectIndex > -1) {
                        invoice.project = this.projects[projectIndex]
                    }
                    this.invoices.push(invoice)
                }
            } catch (error) {
                console.error(error)
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
  max-height: 72vh;
  overflow-y: auto;
}

.v-data-table__row {
  height: 64px; 
}

.v-data-table__wrapper thead tr {
  position: sticky; 
  top: 0;
  z-index: 10;
}
</style>
