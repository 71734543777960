<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'lead-notifications',
                    params: {
                        setting,
                    },
                }"
            >
                Leads
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'NotificationSettings',
    props: {
        setting: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        show: false,
        loading: false,
        height: 0,
    }),
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async updateSetting() {
            try {
                this.loading = true
                await API.updateNotificationTemplate({
                    id: this.setting.id,
                    leads: this.setting.leads,
                    name: this.setting.name,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                // title: { clientHeight: titleHeight },
                // subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height = containerHeight - tabsHeight // - titleHeight - subtitleHeight
        },
    },
}
</script>

<style></style>
