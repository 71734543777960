<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab :to="{ name: 'companyGeneralInfo', params: { setting } }">
                General Information
            </v-tab>
            <v-tab
                :to="{
                    name: 'companyTags',
                    params: {
                        setting,
                    },
                }"
            >
                Tags
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-avatar v-if="setting" rounded size="36" class="mx-2">
                    <v-img
                        v-if="setting.generalInfo.image"
                        :src="setting.generalInfo.image.imageURL"
                        :alt="setting.generalInfo.image.imageURL"
                    />
                    <v-icon v-else>mdi-office-building-outline</v-icon>
                </v-avatar>
                <span>Company</span>
                <v-spacer />
                <v-btn
                    class="mx-1"
                    depressed
                    rounded
                    @click="upload"
                    color="success"
                    >Save</v-btn
                >
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text
                class="overflow-y-auto"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    name: 'CompanySettings',
    components: {},
    props: {
        setting: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            errorMsg: null,
            newSetting: this.setting,
            costSettings: null,
            logSettings: null,
            generalSettings: null,
            commercialSettings: null,
            companyId: JSON.parse(localStorage.getItem('company')),
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        upload: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const newSetting = _.cloneDeep(this.setting)
                if (this.setting.generalInfo.city) {
                    newSetting.generalInfo.city = this.setting.generalInfo.city.text
                }
                delete newSetting.generalInfo.image.imageURL
                await API.updateSetting(this.setting.id, newSetting)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
