<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Register Production</v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-3 mx-1">
                <v-row no-gutters>
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="d-flex align-center"
                    >
                        <v-combobox
                            v-model="selectedProcess"
                            hide-details
                            label="Process*"
                            prepend-icon="mdi-tools"
                            :items="processes"
                            item-text="name"
                            :rules="[rules.required]"
                            required
                            class="pr-3"
                            @change="calculateValues"
                            disabled
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="d-flex align-center"
                    >
                        <v-combobox
                            v-model="selectedMachine"
                            hide-details
                            label="Machine*"
                            prepend-icon="mdi-cog"
                            :items="processMachines"
                            item-text="name"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-3">
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="d-flex align-center"
                    >
                        <div class="d-flex align-center">
                            <v-icon class="pr-2">mdi-database-outline</v-icon>
                            <p class="mb-0">
                                Available Quantity: {{ availableQty }}
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="productionQty"
                            prepend-icon="mdi-database-plus-outline"
                            label="Produced Quantity*"
                            type="number"
                            required
                            :rules="[
                                rules.required,
                                rules.minValue,
                                maxValue(),
                            ]"
                            class="ma-0"
                            :max="availableQty"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="save">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
//import _ from 'lodash'

export default {
    name: 'WOGeneralInfo',
    props: {
        item: {
            type: Object,
            required: true,
        },
        workOrder: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        selectedProcess: undefined,
        confirmCode: undefined,
        code: undefined,
        productionQty: undefined,
        machines: [],
        processMachines: [],
        selectedMachine: undefined,
        availableQty: 0,
        processes: [],
        rules: {
            required: v => !!v || 'The value is required',
            minValue: v => v > 0 || 'The value must be greater than 0',
        },
    }),
    async mounted() {
        this.machines = this.settings.machines
        this.processMachines = this.machines.filter(
            machine => machine.process == this.user.process.id
        )
        this.item.processes.forEach(processId => {
            let process = this.item.dataProcesses.find(p => p.id == processId)
            this.processes.push(process)
        })
        this.selectedProcess = this.processes.find(
            p => p.id == this.user.process.id
        )
        this.calculateValues()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        calculateValues() {
            try {
                const processIndex = this.processes.findIndex(
                    p => p.id == this.selectedProcess.id
                )
                if (processIndex > -1) {
                    let totalProduced = 0
                    let totalDelivered = 0

                    this.prevProcess = this.processes[processIndex - 1]
                    if (this.item.deliveries) {
                        let delivered = this.item.deliveries.filter(
                            register =>
                                register.nextProcess.id ==
                                this.selectedProcess.id
                        )
                        totalDelivered = delivered.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                    if (
                        processIndex == 0 &&
                        !this.workOrder.assemblyWorkOrder
                    ) {
                        totalDelivered += this.item.quantity
                    }

                    if (processIndex == 0 && this.workOrder.assemblyWorkOrder) {
                        totalDelivered = this.item.quantity
                        if (this.item.assemblyItems) {
                            this.item.assemblyItems.forEach(ai => {
                                let receivedQty = 0
                                if (ai.deliveries) {
                                    receivedQty = ai.deliveries.reduce(
                                        (accumulator, delivery) =>
                                            accumulator + delivery.qty,
                                        0
                                    )
                                }
                                const assemblyQty =
                                    receivedQty / ai.qtyPerAssembly
                                if (assemblyQty < totalDelivered) {
                                    totalDelivered = assemblyQty
                                }
                            })
                        }
                    }

                    if (this.item.production) {
                        let produced = this.item.production.filter(
                            register =>
                                register.process.id == this.selectedProcess.id
                        )
                        totalProduced = produced.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                    this.availableQty = totalDelivered - totalProduced
                    this.processMachines = this.machines.filter(
                        machine => machine.process == this.selectedProcess.id
                    )
                }
            } catch (error) {
                console.error(error)
            }
        },
        async save() {
            try {
                this.loading = true
                const items = await API.registerProduction({
                    itemId: this.item.id,
                    workOrderId: this.workOrder.id,
                    qty: this.productionQty,
                    process: this.selectedProcess,
                    machine: this.selectedMachine,
                })
                this.$emit('closeDialog', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },
        maxValue() {
            if (this.productionQty > this.availableQty) {
                return 'The value exceeds existences'
            } else {
                return true
            }
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
