<template>
    <v-card
        v-if="task"
        flat
        :style="{ 'border-radius': '10px' }"
        outlined
        class="ma-0 pa-0"
        @click="openTask"
    >
        <div class="pa-0 ma-0">
            <v-row
                class="d-flex flex-wrap font-weight-bold mx-2 mt-2 mb-0 pa-0"
            >
                <p :style="{ 'font-weight': 'bold !important' }">
                    {{ formatDate(task.expectedDate) }}
                </p>
                <p
                    v-if="
                        $router.currentRoute.path == '/assign' &&
                            task.childs &&
                            task.childs.length > 0
                    "
                    class="ml-2"
                    :style="{ 'font-weight': 'normal !important' }"
                >
                    {{ task.progress || 0 }}%
                </p>
                <v-icon v-if="flag()" class="mt-n5" color="red"
                    >mdi-flag</v-icon
                >
                <v-btn
                    small
                    icon
                    class="mt-n1"
                    color="primary"
                    @click.stop="openHoursForm"
                    v-if="
                        task.status == 'done' &&
                            $router.currentRoute.path == '/tasks' &&
                            !task.hours
                    "
                >
                    <v-icon>mdi-clock-outline</v-icon>
                </v-btn>
                <v-spacer />
                <p
                    v-if="
                        $router.currentRoute.path == '/tasks' &&
                            task.childs &&
                            task.childs.length > 0
                    "
                    :style="{ 'font-weight': 'bold !important' }"
                >
                    {{ task.progress || 0 }}%
                </p>
                <p
                    v-if="$router.currentRoute.path == '/assign'"
                    :style="{ color: `${colors[task.status]}` }"
                >
                    {{
                        task.status == 'onHold'
                            ? 'ON HOLD'
                            : task.status == 'toDo'
                            ? 'TO DO'
                            : task.status == 'inProgress'
                            ? 'INPROGRESS'
                            : 'DONE'
                    }}
                </p>
            </v-row>
            <v-row
                class="d-flex flex-wrap font-weight-regular mt-n3 mb-0 mx-2 pa-0"
            >
                <v-col class="pa-0 ma-0" cols="12">
                    <p class="ma-0 pa-0">
                        {{ task.description }}
                    </p>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                    <v-row class="d-flex flex-wrap mt-2 mb-2 mx-0 pa-0">
                        <p
                            class="ma-0 pa-0 underlineTag"
                            v-if="task.projectId"
                            @click.stop="openProject(task.projectId)"
                        >
                            Project {{ task.projectReference }}
                        </p>
                        <p
                            class="ma-0 ml-2 pa-0 underlineTag"
                            v-if="task.quoteId"
                            @click.stop="openQuote(task.quoteId)"
                        >
                            Quote {{ task.quoteNumber }}
                        </p>
                        <p
                            class="ma-0 ml-2 pa-0 underlineTag"
                            v-if="task.cost"
                            @click.stop="openQuote(task.quoteId)"
                        >
                            Cost {{ ` ${task.cost.split('/')[0]}` }}
                        </p>
                        <v-spacer />
                        <v-avatar size="28">
                            <v-img
                                v-if="profilePicUrl"
                                :src="profilePicUrl"
                                alt="Samuel Romero"
                            />
                            <v-icon x-large color="#707070" v-else
                                >mdi-account-circle</v-icon
                            >
                        </v-avatar>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card v-if="dialogQuote" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeQuote">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <Quote
                        :id="selectedQuote.id"
                        :quote="selectedQuote"
                        :settings="settings"
                        :history="!!selectedQuote.archive"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import { loadImage } from '@/helpers/imageHandler'
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'TaskCard',
    props: {
        task: Object,
        users: Array,
    },
    components: {
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data() {
        return {
            settings: [],
            heightPopUp: 0,
            selectedQuote: {},
            dialogQuote: false,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            profilePicUrl: '',
            colors: {
                onHold: '#EDC43B',
                toDo: '#3277D1',
                inProgress: '#FF9800',
                done: '#27BC4F',
            },
        }
    },
    async mounted() {
        if (this.task) {
            const user = await this.getUser(this.task.createdBy)
            this.profilePicUrl = user?.avatar
        }
        this.heightPopUp = window.innerHeight - 62
    },
    methods: {
        ...mapMutations([
            'setItemsError',
            'setClientQuote',
            'setProjectId',
            'setRoute',
        ]),
        closeQuote() {
            this.dialogQuote = false
            this.selectedQuote = {}
        },
        openHoursForm() {
            this.$emit('openHoursDialog', this.task.id)
        },
        async getUser(userId) {
            try {
                const user = this.users.find(user => user.id === userId)
                if (user) {
                    if (user.picture) {
                        const path = `${this.companyId}/${this.folderUsersPictures}`
                        user.avatar = await loadImage(path, user.picture)
                    }
                    return user
                }
            } catch (error) {
                this.setItemsError(error.message)
            }
        },
        formatDate(date) {
            return moment
                .unix(date.seconds ? date.seconds : date._seconds)
                .format('MMM DD')
        },
        openTask() {
            this.$emit('openTaskDetails', this.task)
        },
        flag() {
            return (
                (this.task.expectedDate.seconds
                    ? this.task.expectedDate.seconds
                    : this.task.expectedDate._seconds) -
                    new Date().getTime() / 1000 <
                    0 && this.task.status !== 'done'
            )
        },
        async openProject(projectId) {
            try {
                this.setRoute(undefined)
                this.$emit('enableLoadAnimation', true)
                const project = await API.getProjects({ projectId })
                this.setProjectId(projectId)
                if (project.archive) {
                    this.$router.push({
                        path: '/archive-projects',
                    })
                } else {
                    this.$router.push({
                        path: 'projects',
                    })
                }
                this.setRoute('projects')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.$emit('enableLoadAnimation', false)
            }
        },
        async openQuote(quoteId) {
            try {
                this.$emit('enableLoadAnimation', true)
                // get quotes
                const {
                    data: { quotes },
                } = await API.getQuotes({ quoteId })
                // get settings
                const {
                    data: { settings },
                } = await API.getSettings()
                this.settings = settings
                // set quote
                const quote = quotes[0]
                this.selectedQuote = quote
                this.dialogQuote = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.$emit('enableLoadAnimation', false)
            }
        },
    },
}
</script>
<style scoped>
.borderRounded {
    border-radius: 15px;
}

.underlineTag {
    text-decoration: underline #3277d1;
    font-weight: bold;
    font-size: 13px;
    color: #3277d1;
}
</style>
