var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:(`height: ${_vm.height + 550}px`),attrs:{"loading":_vm.loading}},[_c('v-card-title',{ref:"title"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1),_c('h3',[_vm._v(" Forecast History ")]),_c('v-spacer')],1),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('v-card-text',{staticClass:"mt-6",style:({ height: `${_vm.height - 600}px` })},[_c('v-row',{ref:"totals",staticClass:"mt-8 align-center",style:({ 'background-color': '#eeeeee' }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","xl":"6","lg":"6","md":"6"}},[_c('p',{staticClass:"my-2 font-weight-black"},[_vm._v(" QUOTE PRICE: "+_vm._s(_vm.formatCurrency( _vm.project.currency, _vm.historyQuote.totalCosts ))+" ")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","xl":"6","lg":"6","md":"6"}},[_c('p',{staticClass:"my-2 font-weight-black"},[_vm._v(" CURRENT BALANCE TO BILL: "+_vm._s(_vm.formatCurrency( _vm.project.currency, _vm.historyQuote.balanceToBill ))+" ")])])],1),_c('v-col',{staticClass:"mb-6",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.quoteHeaders,"items":_vm.historyItems,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4 align-center",style:({ 'background-color': '#eeeeee' }),attrs:{"no-gutter":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('h2',{staticClass:"ml-4"},[_vm._v(" QUOTE: "+_vm._s(_vm.historyQuote.number)+" - "+_vm._s(_vm.historyQuote.name)+" ")])]),_c('v-spacer')],1)]},proxy:true},{key:`item.title`,fn:function({ item }){return [_c('v-col',{staticClass:"ma-0 pa-0 ml-n4",style:({
                            'background-color': '#eee',
                            width: '100%',
                            height: '100%',
                        })},[_c('p',{staticClass:"mt-4 ml-7 d-flex align-center justify-center",style:({
                                'font-size': '12px',
                                'font-weight': 500,
                                color: '#5f5f5f',
                            })},[_c('span',{staticClass:"mt-6 mb-n2"},[_vm._v(_vm._s(item.title))])])])]}},{key:`item.date`,fn:function({ item, header }){return [(item.title == 'FORECASTED')?_c('div',[(item[header.month])?_c('v-col',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.project.currency, item[header.month].amount || 0 ))+" ")])]):_c('p',[_vm._v(" No Forecast Exist ")])],1):_vm._e(),(item.title == 'INVOICED')?_c('div',[_c('p',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.project.currency, _vm.getInvoicedInMonth( header.month, _vm.historyQuote.id ) ))+" ")])]):_vm._e(),(item.title == 'COMPLIANCE PERCENTAGE')?_c('div',[_c('p',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.historyQuote[header.month] && _vm.historyQuote[header.month].amount ? ( (_vm.getInvoicedInMonth( header.month, _vm.historyQuote.id ) * 100) / _vm.historyQuote[header.month].amount ).toFixed(1) : '-')+" % ")])]):_vm._e()]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }