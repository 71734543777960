<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab :to="{ name: 'commercialDocuments', params: { tool } }">
                Commercial Documents
            </v-tab>
            <v-tab :to="{ name: 'technicalDocuments', params: { tool } }">
                Technical Library
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <span>Documents</span>
                <v-spacer />
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text
                class="overflow-y-auto"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'DocumentsTools',
    components: {},
    props: {
        tool: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            errorMsg: null,
            newSetting: this.setting,
            costSettings: null,
            logSettings: null,
            generalSettings: null,
            commercialSettings: null,
        }
    },
    updated() {
        this.onResize()
    },
    computed: {},

    methods: {
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
    },
}
</script>
