<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="ordersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openProcessWorkOrder"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n6">
                        <h1 class="mr-4">WORK ORDERS</h1>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateRangeText"
                                    label="Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="{ 'max-width': '250px' }"
                                    class="pt-1"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    :loading="loading"
                                    @click="loadData"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.type`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="typeToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center" v-if="item.createdBy">
                    <p class="my-0">{{ item.createdBy.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center" v-if="item.project">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.productionStart`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            formatDate(
                                item.productionStart
                                    ? item.productionStart._seconds
                                    : item.createdOn._seconds
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.assemblyWorkOrder ? 'Assembly' : 'Production' }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.weight }} kg</p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.quality`]="{ item }">
                <div>
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-col>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    :style="{ 'background-color': '#2b81d6' }"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOPending(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    :style="{ 'background-color': '#2fbf56' }"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOReleased(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    :style="{ 'background-color': '#ffa41d' }"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOReprocess(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    :style="{ 'background-color': '#FF2610' }"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWORejected(item) }}
                                    </p>
                                </v-btn>
                            </v-col>
                        </template>
                        <span>
                            Pending :
                            {{ calculateWOPending(item) || 0 }}
                            <br />
                            Released :
                            {{ calculateWOReleased(item) }} <br />
                            Reprocess :
                            {{ calculateWOReprocess(item) }} <br />
                            Rejected :
                            {{ calculateWORejected(item) }} <br />
                        </span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <div class="d-flex justify-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                icon
                                @click.stop="openRestoreOrder(item)"
                            >
                                <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-lock-open
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Restore</span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS WORK ORDER-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <OperatorProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>
        <!-- close work order -->
        <v-dialog v-model="restoreOrderDialog" persistent max-width="400px">
            <v-card :loading="loading">
                <v-card-title class="text-h5">Restore Work Order</v-card-title>
                <v-card-text>
                    Are you sure you want to restore this work order?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRestoreOrder">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="restoreOrder"
                        text
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import API from '@/services/api'
import SocketioService from '@/services/websocket/socket.service.js'
import _ from 'lodash'

export default {
    name: 'OperatorClosedWOs',
    components: {
        OperatorProcessWorkOrder: () =>
            import('@/components/WorkOrders/OperatorProcessWorkOrder.vue'),
    },
    data: () => ({
        minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        dates: [],
        dateRanges: null,
        menu: false,
        statusToFilter: undefined,
        projectToFilter: undefined,
        typeToFilter: undefined,
        loading: false,
        orders: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRODUCTION START',
                value: 'productionStart',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUALITY',
                value: 'quality',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTION',
                value: 'action',
                align: 'center',
                sortable: false,
            },
        ],
        statusColor: {
            production: '#FFC000',
            cancelled: 'red',
            closed: 'primary',
            inProgress: '#9E9FA0',
        },
        projects: [],
        users: [],
        resourceId: undefined,
        processWorkOrder: false,
        selectedOrder: {},
        processToShow: undefined,
        showProcesses: false,
        settingProcesses: undefined,
        restoreOrderDialog: false,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
    }),
    computed: {
        ...mapState(['notificationResource']),
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        ordersFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterStatus)
            }
            if (this.typeToFilter) {
                conditions.push(this.filterByType)
            }
            if (conditions.length > 0) {
                return this.orders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            return this.orders
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async created() {
        try {
            this.loading = true
            this.projects = await API.getLiteProjects({ all: true })
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            const {
                data: { user },
            } = await API.getMyInfo()
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }
            let currentDate = new Date()
            let date1 = new Date(currentDate)
            date1.setMonth(date1.getMonth() - 3)
            date1.setHours(0, 0, 0, 0)
            this.dates[0] = date1.toISOString().split('T')[0]
            this.dates[1] = currentDate.toISOString().split('T')[0]
            this.orders = await API.getClosedWorkOrders({
                startDate: this.dates[0],
                endDate: this.dates[1],
            })

            for (const order of this.orders) {
                order.createdBy = { id: order.createdBy }
                const user = this.users.find(
                    user => user.id == order.createdBy.id
                )
                if (user) order.createdBy.name = user.name
                order.project = this.projects.find(
                    project => project.id == order.projectId
                )
                this.setProcessesInfo(order)
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        async restoreOrder() {
            try {
                this.loading = true
                await API.restoreWorkOrder(
                    this.selectedOrder.id,
                    this.selectedOrder.code
                )
                this.orders = this.orders.filter(
                    order => order.id != this.selectedOrder.id
                )
                this.closeRestoreOrder()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeRestoreOrder() {
            this.selectedOrder = {}
            this.restoreOrderDialog = false
        },
        openRestoreOrder(order) {
            this.selectedOrder = _.cloneDeep(order)
            this.restoreOrderDialog = true
        },
        async loadData() {
            try {
                this.loading = true
                let date1 = new Date(this.dates[0]).getTime()
                let date2 = new Date(this.dates[1]).getTime()
                if (date1 > date2) {
                    date1 = this.dates[1]
                    date2 = this.dates[0]
                } else {
                    date1 = this.dates[0]
                    date2 = this.dates[1]
                }
                this.orders = await API.getClosedWorkOrders({
                    startDate: date1,
                    endDate: date2,
                })
                for (const order of this.orders) {
                    order.createdBy = { id: order.createdBy }
                    const user = this.users.find(
                        user => user.id == order.createdBy.id
                    )

                    order.createdBy.name = user ? user.name : ''

                    order.project = this.projects.find(
                        project => project.id == order.projectId
                    )
                    this.setProcessesInfo(order)
                }
                this.menu = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterStatus(order) {
            return order.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterByType(order) {
            const type = order.assemblyWorkOrder ? 'assembly' : 'production'
            return type.includes(this.typeToFilter.toLowerCase())
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.workOrders.find(
                    w => w.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessWorkOrder(resource)
                }
            }
        },
        openProcessWorkOrder(item) {
            if (item.project) {
                this.selectedOrder = _.cloneDeep(item)
                this.processWorkOrder = true
            }
        },
        closeProcessWorkOrder() {
            this.processWorkOrder = false
        },
        setProcessesInfo(wo) {
            wo.processesInfo = []

            wo.items.forEach(item => {
                if (item.processes) {
                    item.processesToShow = _.cloneDeep(item.processes)
                    if (this.processToShow) {
                        item.processesToShow = item.processesToShow.filter(
                            p => p == this.processToShow.id
                        )
                    }
                    item.processesToShow.forEach(process => {
                        let processInfo = wo.processesInfo.find(
                            info => info.process.id == process
                        )

                        if (!processInfo) {
                            let totalDelivered = item.deliveries
                                ? item.deliveries.reduce(
                                      (total, register) =>
                                          register.prevProcess.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            let totalProduced = item.production
                                ? item.production.reduce(
                                      (total, register) =>
                                          register.process.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            let processIndex = this.settingProcesses.processes.findIndex(
                                p => p.id == process
                            )

                            wo.processesInfo.push({
                                process: {
                                    id: this.settingProcesses.processes[
                                        processIndex
                                    ].id,
                                    name: this.settingProcesses.processes[
                                        processIndex
                                    ].name,
                                },
                                qty: item.quantity,
                                delivered: totalDelivered,
                                produced: totalProduced,
                            })
                        } else {
                            processInfo.qty += item.quantity
                            processInfo.delivered += item.deliveries
                                ? item.deliveries.reduce(
                                      (total, register) =>
                                          register.prevProcess.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            processInfo.produced += item.production
                                ? item.production.reduce(
                                      (total, register) =>
                                          register.process.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                        }
                    })
                }
            })
            if (this.selectedOrder && this.selectedOrder.id == wo.id) {
                this.selectedOrder = _.cloneDeep(wo)
            }
        },
        calculateWOPending(wo) {
            try {
                let totalPending = 0
                wo.items.forEach(item => {
                    let pendingQty = 0
                    let lastProcessIndex = item.processes.length - 1
                    if (wo.assemblyWorkOrder != undefined) {
                        lastProcessIndex =
                            item.processes.findIndex(
                                p => p == this.qualityProcess.id
                            ) - 1
                    }
                    if (lastProcessIndex > -1) {
                        const lastProductionProcess =
                            item.processes[lastProcessIndex]
                        if (item.production) {
                            const production = item.production.filter(
                                entry =>
                                    entry.process.id == lastProductionProcess
                            )
                            if (production.length > 0) {
                                pendingQty = production.reduce(
                                    (accumulator, entry) =>
                                        accumulator + entry.qty,
                                    0
                                )
                            }
                        }
                        if (item.quality) {
                            const rejected = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.rejectQty || 0),
                                0
                            )
                            const released = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.releaseQty || 0),
                                0
                            )
                            pendingQty -=
                                Number(released || 0) + Number(rejected || 0)
                        }
                    }
                    totalPending += pendingQty
                })

                return totalPending
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOReleased(wo) {
            try {
                let totalReleased = 0
                wo.items.forEach(item => {
                    let released = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        released += Number(value || 0)
                    }
                    totalReleased += released
                })
                return totalReleased
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOReprocess(wo) {
            try {
                let totalReprocess = 0
                wo.items.forEach(item => {
                    let reprocess = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.reprocessQty || 0),
                            0
                        )
                        reprocess += Number(value || 0)
                    }
                    totalReprocess += reprocess
                })

                return totalReprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWORejected(wo) {
            try {
                let totalRejected = 0
                wo.items.forEach(item => {
                    let rejected = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        rejected += Number(value || 0)
                    }
                    totalRejected += rejected
                })

                return totalRejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
