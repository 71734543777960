<template>
    <v-card :loading="loading" width="100%">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="!createForm"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="toUpdate"
            >
                <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid" ref="costForm" class="we">
                <v-col
                    no-gutters
                    class="align-center"
                    :style="{ 'row-gap': '10px' }"
                >
                    <v-row>
                        <v-col>
                            <v-text-field
                                dense
                                hide-details
                                required
                                :rules="[rules.required]"
                                class="text-body-2 pt-3"
                                v-model="cost.reference"
                                placeholder="Detailed name of the scope"
                                prepend-icon="mdi-file-document-edit"
                                label="Reference*"
                                :disabled="
                                    lead.status == 'rejected' || !isEditing
                                "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-autocomplete
                                flat
                                hide-details
                                required
                                placeholder="Reviewer By"
                                class="text-body-2 mt-0 pt-0"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                v-model="cost.reviewer"
                                prepend-icon="mdi-account"
                                label="Scope Reviewer"
                                :disabled="
                                    (lead && lead.status == 'rejected') ||
                                        !isEditing
                                "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-model="menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :disabled="
                                            (lead &&
                                                lead.status == 'rejected') ||
                                                !isEditing
                                        "
                                        v-model="cost.engineeringDate"
                                        label="Engineering Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        required
                                        hide-details
                                        class="pt-0"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="cost.engineeringDate"
                                    scrollable
                                    :min="minDate"
                                    :disabled="
                                        (lead && lead.status == 'rejected') ||
                                            !isEditing
                                    "
                                >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-autocomplete
                                flat
                                hide-details
                                required
                                placeholder="Cost category for invoicing"
                                :rules="[rules.required]"
                                class="text-body-2 pt-0 mt-0"
                                :items="categories"
                                item-text="name"
                                item-value="name"
                                prepend-icon="mdi-text-box-search-outline"
                                v-model="cost.category"
                                label="Category *"
                                :disabled="
                                    (lead && lead.status == 'rejected') ||
                                        !isEditing
                                "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-autocomplete
                                flat
                                hide-details
                                required
                                value="ft2"
                                :rules="[rules.required]"
                                class="text-body-2 pt-0 mt-0"
                                :items="units"
                                prepend-icon="mdi-tape-measure"
                                v-model="cost.measure"
                                :disabled="
                                    (lead && lead.status == 'rejected') ||
                                        !isEditing
                                "
                                label="Measure*"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                dense
                                type="number"
                                hide-details
                                class="text-body-2 pt-0 mt-0"
                                required
                                :rules="[rules.number2]"
                                placeholder="Item units"
                                prepend-icon="mdi-chart-box-plus-outline"
                                v-model="cost.quantity"
                                :disabled="
                                    lead.status == 'rejected' || !isEditing
                                "
                                label="Units*"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <v-textarea
                                hide-details
                                :style="{ 'min-height': '100px' }"
                                rows="3"
                                class="text-body-2 pt-0 mt-0 pb-0"
                                placeholder="Detailed description of the scope"
                                flat
                                v-model="cost.description"
                                prepend-icon="mdi-pencil-box-outline"
                                :disabled="
                                    lead.status == 'rejected' || !isEditing
                                "
                                label="Description"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-form>
        </v-card-text>

        <v-card-actions class="mr-3 ml-5 mt-0 pb-5 pt-0">
            <small>* indicates required field</small>
            <v-spacer></v-spacer>
            <v-btn
                v-if="createForm || isEditing"
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="saveCost"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff'

export default {
    name: 'LeadCostsForm',
    props: {
        originalCost: {
            type: Object,
            default: () => {},
        },
        lead: {
            type: Object,
            default() {
                return {
                    name: '',
                    userId: '',
                    notes: '',
                    contactId: '',
                    files: '',
                }
            },
        },
        createForm: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('userId')),
            company: JSON.parse(localStorage.getItem('company')),
            loading: false,
            error: false,
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
                number2: v => v > 0 || 'Error',
            },
            companyId: JSON.parse(localStorage.getItem('company')),
            users: [],
            cost: {},
            categories: [],
            units: [],
            settings: {},
            isEditing: this.createForm,
            minDate: '',
            menu: false,
        }
    },
    computed: {
        costDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalCost, this.cost)
            } else {
                return null
            }
        },
    },
    async mounted() {
        try {
            this.cost = _.cloneDeep(this.originalCost)
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            const invoiceSettings = settings.find(
                setting => setting.name.toLowerCase() == 'invoicing'
            )
            this.categories = invoiceSettings.categories
            const indexCosts = this.settings.findIndex(u => u.name === 'Quotes')

            this.settings[indexCosts].measureUnits.forEach(x => {
                this.units.push(x.abbr)
            })
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            if (!this.createForm && this.originalCost) {
                this.cost.reference = this.originalCost.reference
                this.cost.viwer = this.originalCost.viwer
                this.cost.category = this.originalCost.category
                this.cost.measure = this.originalCost.measure
                this.cost.units = this.originalCost.units
                this.cost.description = this.originalCost.description
            }
            this.minDate = new Date().toISOString()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        toUpdate() {
            this.isEditing = !this.isEditing
        },
        closeDialog: function() {
            this.$emit('closeDialog', this.cost)
            this.$refs.costForm.reset()
            this.error = false
            this.errorMsg = null
            this.image = null
        },
        saveCost() {
            if (this.createForm) {
                this.createCost()
            } else {
                this.updateCost()
            }
        },
        async createCost() {
            try {
                this.loading = true
                this.cost.type = 'cost'
                this.cost.quantity = Number(this.cost.quantity)

                const cost = await API.createCostsDetails({
                    leadId: this.lead.id,
                    cost: this.cost,
                })

                if (cost.engineeringDate) {
                    cost.engineeringDate = cost.engineeringDate.substring(0, 10)
                }
                this.$emit('addCost', cost)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                return error
            } finally {
                this.loading = false
            }
        },
        async updateCost() {
            try {
                this.loading = true
                if (this.costDiff.quantity) {
                    this.costDiff.quantity = Number(this.costDiff.quantity)
                }
                const scope = await API.updateCostsDetails({
                    leadId: this.lead.id,
                    costId: this.cost.id,
                    costDiff: this.costDiff,
                })
                if (this.costDiff.engineeringDate) {
                    this.cost.engineeringDate = this.cost.engineeringDate.substring(
                        0,
                        10
                    )
                }
                this.cost.reviewed = false
                this.$emit('replaceCost', scope)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                return error
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
