<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab
                :to="{
                    name: 'main-work-orders',
                }"
            >
                WORK ORDERS
            </v-tab>
            <v-tab
                :to="{
                    name: 'approval-wo',
                }"
            >
                APPROVAL WO
            </v-tab>
            <v-tab
                v-if="approvedPermission"
                :to="{
                    name: 'approved-wo',
                }"
            >
                APPROVED WO
            </v-tab>
            <v-tab
                v-if="markQualityRegisters"
                :to="{
                    name: 'qa-pending-wo',
                }"
            >
                QA PENDING
            </v-tab>
            <v-tab
                v-if="updateWOProcesses"
                :to="{
                    name: 'qa-rejected-wo',
                }"
            >
                QA REJECTED
            </v-tab>
            <v-tab
                :to="{
                    name: 'approval-bom',
                }"
            >
                APPROVAL BOM
            </v-tab>
            <v-tab
                :to="{
                    name: 'approved-bom',
                }"
            >
                APPROVED BOM
            </v-tab>
            <v-tab
                :to="{
                    name: 'closed',
                }"
            >
                CLOSED
            </v-tab>
            <v-tab
                :to="{
                    name: 'canceled',
                }"
            >
                CANCELED
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import SocketioService from '@/services/websocket/socket.service.js'
import API from '@/services/api'

export default {
    name: 'WorkOrders',
    data: () => ({
        loading: true,
        height: 0,
        companyId: JSON.parse(localStorage.getItem('company')),
        approvedPermission: undefined,
        markQualityRegisters: undefined,
        updateWOProcesses: undefined,
    }),
    async mounted() {
        this.onResize()
        const {
            data: { user },
        } = await API.getMyInfo()
        this.approvedPermission = user.permissions.includes('approvedWO')
        this.markQualityRegisters = user.permissions.includes(
            'markQualityRegisters'
        )
        this.updateWOProcesses = user.permissions.includes('updateWOProcesses')
        SocketioService.connect({
            companyId: this.companyId,
        })
        setTimeout(() => {
            SocketioService.joinRoom(`${this.companyId}-WO`)
        }, 3000)
    },
    beforeDestroy() {
        SocketioService.leaveRoom(`${this.companyId}-WO`)
        SocketioService.leaveRoom(`${this.companyId}-BOM`)
        SocketioService.disconnect()
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = window.innerHeight - containerHeight - tabsHeight - 25
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
