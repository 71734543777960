var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-m2"},[_c('v-row',{style:({ 'background-color': 'white' }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"3","lg":"3","md":"4","sm":"12"}},[_c('v-autocomplete',{staticClass:"ml-2",attrs:{"flat":"","items":_vm.users,"item-text":"name","item-value":"name","label":"Search","prefix":"User :   "},model:{value:(_vm.userToFilter),callback:function ($$v) {_vm.userToFilter=$$v},expression:"userToFilter"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"3","lg":"3","md":"4","sm":"12"}},[_c('v-autocomplete',{staticClass:"ml-2",attrs:{"flat":"","slolo":"","items":_vm.headers,"item-text":"text","item-value":"permissionString","label":"Search","prefix":"Permission :   "},model:{value:(_vm.permissionToFilter),callback:function ($$v) {_vm.permissionToFilter=$$v},expression:"permissionToFilter"}})],1)],1),_c('table',{style:({ 'border-collapse': 'collapse' })},[_c('thead',{style:({ 'background-color': 'lightgray' })},[_c('tr',[_c('th',{style:({
                        'background-color': '#eee',
                        position: 'sticky',
                        top: 0,
                        'z-index': 2,
                    })}),_vm._l((_vm.permissionsTitles),function(title,i){return _c('th',{key:i,staticClass:"px-2 pt-2",style:({
                        'background-color': `${title.color}`,
                        position: 'sticky',
                        top: 0,
                        'z-index': 2,
                    }),attrs:{"colspan":title.items}},[_vm._v(" "+_vm._s(title.text)+" ")])})],2),_c('tr',[_c('td',{style:({
                        'background-color': '#eee',
                        position: 'sticky',
                        top: '30px',
                        'z-index': 1,
                    })},[_c('span',{attrs:{"id":"header-user"}},[_vm._v("User")])]),_vm._l((_vm.headers),function(header,j){return _c('td',{key:j,style:({
                        'background-color': `${header.color}`,
                        'justify-items': 'center',
                        'align-items': 'center',
                        position: 'sticky',
                        top: '30px',
                        'z-index': 1,
                    })},[_c('span',{attrs:{"id":"header"}},[_vm._v(" "+_vm._s(header.value)+" ")])])})],2)]),_c('tbody',_vm._l((_vm.filteredUsers),function(user,k){return _c('tr',{key:k},[_c('td',{attrs:{"id":"info"}},[_c('span',[_vm._v(" "+_vm._s(user.name))])]),_vm._l((_vm.headers),function(header,j){return _c('td',{key:j,attrs:{"id":"info"}},[(
                            user.permissions.includes(
                                header.permissionString
                            )
                        )?_c('v-icon',{staticClass:"pa-2 d-flex justify-center align-center",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-check ")]):_vm._e()],1)})],2)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }