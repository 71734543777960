<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-3"
        ref="container"
        style="height:100%"
    >
        <v-progress-linear v-if="loading" indeterminate color="primary" />
        <v-data-table
            v-else
            id="virtual-scroll-table"
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="filteredItems"
            :mobile-breakpoint="0"
            :height="height"
            item-key="orden_CO"
            class="elevation-0 poc-table"
            :loading="loading"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    :style="{ 'background-color': '#eeeeee' }"
                    :class="
                        $vuetify.breakpoint.xs
                            ? 'mt-0 mx-0 pt-3 pb-0 pb-3 align-center'
                            : 'mt-0 mx-0 pt-3 pb-0 pb-3 align-center'
                    "
                >
                    <v-col cols="12" class="d-flex">
                        <h2 class="ml-4">POC</h2>
                        <v-spacer v-if="!$vuetify.breakpoint.xs" />
                        <v-btn
                            v-if="isSaveEnabled"
                            color="success"
                            rounded
                            class="mr-2"
                            @click="saveChanges"
                        >
                            Save
                        </v-btn>
                        <v-btn
                            depressed
                            color="primary"
                            @click="openUploadFile"
                            rounded
                            class="ml-2"
                            :disabled="!uploadPocPermission"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ !$vuetify.breakpoint.xs ? 'New' : '' }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    no-gutter
                    :class="
                        $vuetify.breakpoint.xs
                            ? 'mt-0 mx-0 pt-4 pb-0 pb-4 align-center'
                            : 'mt-0 mx-0 pt-4 pb-0 pb-4 align-center'
                    "
                    style="background-color: #eeeeee"
                >
                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[0].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[0].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[0].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{ formatNumber(currency, pocTRM) }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[1].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[1].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[1].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{ cutOffDate }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.date`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="dateToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.job`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.client`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="clientToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.project_id`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="referenceToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.orden_CO`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="orderToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.date`]="{ item }">
                <div class="my-4">
                    {{ `${item.year}-${item.month}` }}
                </div>
            </template>
            <template v-slot:[`item.total_amount_before_taxes`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.total_amount_before_taxes) }}
                </div>
            </template>
            <template v-slot:[`item.balance_to_bill`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.balance_to_bill) }}
                </div>
            </template>
            <template v-slot:[`item.billed_to_date`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.billed_to_date) }}
                </div>
            </template>
            <template v-slot:[`item.estimated_margin`]="{ item }">
                <div class="my-4">
                    <v-text-field
                        :value="item.estimated_margin * 100"
                        type="number"
                        dense
                        @input="value => updateMargin(item, value)"
                        :rules="[v => v >= 0 || 'only positive values']"
                        :suffix="'%'"
                    ></v-text-field>
                </div>
            </template>
            <template v-slot:[`item.estimated_revenues`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.estimated_revenues) }}
                </div>
            </template>
            <template v-slot:[`item.estimated_gross_profit`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.estimated_gross_profit) }}
                </div>
            </template>
            <template v-slot:[`item.estimated_cost`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.estimated_cost) }}
                </div>
            </template>
            <template v-slot:[`item.costs`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.costs) }}
                </div>
            </template>
            <template v-slot:[`item.nomina`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.nomina) }}
                </div>
            </template>
            <template v-slot:[`item.cost_of_revenues_(COP)`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item['cost_of_revenues_(COP)']) }}
                </div>
            </template>
            <template v-slot:[`item.percentage_completed`]="{ item }">
                <div class="my-4">
                    {{ ((item.percentage_completed || 0) * 100).toFixed(2) }} %
                </div>
            </template>
            <template v-slot:[`item.revenues_earned`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.revenues_earned) }}
                </div>
            </template>
            <template v-slot:[`item.gross_profit`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item.gross_profit) }}
                </div>
            </template>
            <template v-slot:[`item.total_revenues_for_project`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{
                        formatNumber(
                            'COP',
                            item[`total_revenues_${item.year}_for_project`]
                        )
                    }}
                </div>
            </template>
            <template v-slot:[`item.total_gross_profit_for_project`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{
                        formatNumber(
                            'COP',
                            item[`total_gross_profit_${item.year}_for_project`]
                        )
                    }}
                </div>
            </template>
            <template v-slot:[`item.revenues_earned_(COP)`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item['revenues_earned_(COP)']) }}
                </div>
            </template>
            <template v-slot:[`item.gross_profit_(COP)`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{ formatNumber('COP', item['gross_profit_(COP)']) }}
                </div>
            </template>
            <template v-slot:[`item.billed_to_date_for_this_month`]="{ item }">
                <div class="d-flex justify-end align-right">
                    {{
                        formatNumber(
                            'COP',
                            item['billed_to_date_for_this_month']
                        )
                    }}
                </div>
            </template>
            <template
                v-slot:[`item.estimated_cost_to_complete_(COP)`]="{ item }"
            >
                <div class="d-flex justify-end align-right">
                    {{
                        formatNumber(
                            'COP',
                            item['estimated_cost_to_complete_(COP)']
                        )
                    }}
                </div>
            </template>
        </v-data-table>

        <!--UPLOAD FILE-->
        <v-dialog v-model="openDialog" persistence max-width="400">
            <UploadPOC
                v-if="openDialog"
                @close="closeUploadFile"
                @addPOC="addPOC"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'POC',
    components: {
        UploadPOC: () => import('@/components/Projects/UploadPOC.vue'),
    },
    data: () => ({
        height: 0,
        loading: false,
        isSaveEnabled: false,
        modifiedItems: new Set(),
        openDialog: false,
        items: [],
        headers: [
            {
                text: 'Order CO',
                value: 'orden_CO',
                sortable: false,
                width: 100,
            },
            {
                text: 'Project ID',
                value: 'project_id',
                sortable: false,
                width: 100,
            },
            {
                text: 'Job',
                value: 'job',
                sortable: false,
                width: 250,
            },
            {
                text: 'Client',
                value: 'client',
                sortable: false,
                width: 150,
            },
            {
                text: 'Total amount before taxes',
                value: 'total_amount_before_taxes',
                sortable: false,
                width: 200,
            },
            {
                text: 'Balance to bill',
                value: 'balance_to_bill',
                sortable: false,
                width: 150,
            },
            {
                text: 'Billed to date',
                value: 'billed_to_date',
                sortable: false,
                width: 150,
            },
            {
                text: 'Estimated margin',
                value: 'estimated_margin',
                sortable: false,
                width: 150,
            },
            {
                text: 'Estimated revenues (COP)',
                value: 'estimated_revenues',
                sortable: false,
                width: 200,
            },
            {
                text: 'Estimated gross profit (COP)',
                value: 'estimated_gross_profit',
                sortable: false,
                width: 200,
            },
            {
                text: 'Estimated cost (COP)',
                value: 'estimated_cost',
                sortable: false,
                width: 200,
            },
            {
                text: 'Costs',
                value: 'costs',
                sortable: false,
                width: 150,
            },
            {
                text: 'Payroll',
                value: 'nomina',
                sortable: false,
                width: 150,
            },
            {
                text: 'Cost of revenues (COP)',
                value: 'cost_of_revenues_(COP)',
                sortable: false,
                width: 200,
            },
            {
                text: '% completed',
                value: 'percentage_completed',
                sortable: false,
                width: 100,
            },
            {
                text: 'Revenues earned',
                value: 'revenues_earned',
                sortable: false,
                width: 200,
            },
            {
                text: 'Gross profit',
                value: 'gross_profit',
                sortable: false,
                width: 200,
            },
            {
                text: 'Billed to date for this month',
                value: 'billed_to_date_for_this_month',
                sortable: false,
                width: 200,
            },
            {
                text: 'Estimated cost to complete (COP)',
                value: 'estimated_cost_to_complete_(COP)',
                sortable: false,
                width: 200,
            },
        ],
        projectToFilter: null,
        clientToFilter: null,
        dateToFilter: null,
        referenceToFilter: null,
        orderToFilter: null,
        uploadPocPermission: false,
        insights: [
            {
                color: '#ffa414',
                icon: 'mdi-currency-usd',
                iconColor: '#1A5276',
                title: 'TRM',
            },
            {
                color: '#20844c',
                icon: 'mdi-calendar-outline',
                iconColor: '#1A5276',
                title: `CUT-OFF DATE`,
            },
        ],
        cutOffDate: null,
        pocTRM: null,
        currency: null,
    }),
    async mounted() {
        try {
            this.loading = true
            this.items = await API.getAllPOC()
            this.cutOffDate = this.items[0].cutOffDate
            this.pocTRM = this.items[0].trm_used
            this.currency = this.items[0].currency

            const {
                data: { user },
            } = await API.getMyInfo()
            this.uploadPocPermission = user.permissions.includes('uploadPOC')
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    updated() {
        this.onResize()
    },
    computed: {
        filteredItems() {
            let conditions = []

            if (this.referenceToFilter) {
                conditions.push(this.filterByReference)
            }

            if (this.orderToFilter) {
                conditions.push(this.filterByOrder)
            }

            if (this.dateToFilter) {
                conditions.push(this.filterByDate)
            }

            if (this.projectToFilter) {
                conditions.push(this.filterByProject)
            }

            if (this.clientToFilter) {
                conditions.push(this.filterByClient)
            }

            if (conditions.length > 0) {
                return this.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.items
        },
    },
    methods: {
        //
        ...mapMutations(['setErrorItems']),
        filterByReference(item) {
            return `${item.project_id}`
                .toLowerCase()
                .includes(this.referenceToFilter.toLowerCase())
        },
        filterByOrder(item) {
            return `${item.orden_CO}`
                .toLowerCase()
                .includes(this.orderToFilter.toLowerCase())
        },
        filterByDate(item) {
            return `${item.year}-${item.month}`
                .toLowerCase()
                .includes(this.dateToFilter.toLowerCase())
        },
        filterByClient(item) {
            return item.client
                .toLowerCase()
                .includes(this.clientToFilter.toLowerCase())
        },
        filterByProject(item) {
            return item.job
                .toLowerCase()
                .includes(this.projectToFilter.toLowerCase())
        },
        formatNumber(currency, value) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
                maximumFractionDigits: currency == 'USD' ? 2 : 0,
            }).format(value)
        },
        addPOC(data) {
            if (data.oldVersions) {
                for (const id of data.oldVersions) {
                    this.items = this.items.filter(item => item.id != id)
                }
            }
            if (data.newVersions) {
                this.pocTRM = data.newVersions[0].trm_used
                this.cutOffDate = data.newVersions[0].cutOffDate
            }
            this.items = [...this.items, ...data.newVersions]
        },
        closeUploadFile() {
            this.openDialog = false
        },
        openUploadFile() {
            this.openDialog = true
        },
        onFieldChange(item) {
            const itemUpload = item
            delete itemUpload.uploadBy
            this.modifiedItems.add(itemUpload.id)
            this.isSaveEnabled = true
        },
        async saveChanges() {
            try {
                const updatedItems = this.filteredItems.filter(item =>
                    this.modifiedItems.has(item.id)
                )
                await API.updatePOC({
                    items: updatedItems.map(obj => this.reCalculateData(obj)),
                })
                this.modifiedItems.clear()
                this.isSaveEnabled = false
            } catch (error) {
                console.error('Error when saving changes:', error)
            }
        },
        updateMargin(item, value) {
            item.estimated_margin = value / 100
            this.onFieldChange(item)
        },
        reCalculateData(item) {
            item.estimated_gross_profit =
                item.estimated_revenues * item.estimated_margin
            item.estimated_cost = Math.abs(
                item.estimated_revenues - item.estimated_gross_profit
            )
            item.percentage_completed =
                item['cost_of_revenues_(COP)'] / item.estimated_cost
            item.revenues_earned =
                item.percentage_completed * item.estimated_revenues
            item.gross_profit =
                item.revenues_earned - item['cost_of_revenues_(COP)']

            item.percentage_completed =
                item['cost_of_revenues_(COP)'] / item.estimated_cost
            item[`revenues_earned_(COP)`] =
                item.estimated_revenues * item.percentage_completed
            item[`gross_profit_(COP)`] =
                item.estimated_revenues * item.percentage_completed -
                item['cost_of_revenues_(COP)']
            item['estimated_cost_to_complete_(COP)'] =
                item.estimated_cost - item['cost_of_revenues_(COP)']
            return item
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight - 180
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
.custom-label .v-label {
    font-size: 12px;
}
#virtual-scroll-table {
    width: 100%;
    overflow-y: auto;
}
.poc-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 3;
}
.poc-table th:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 4;
}
.poc-table td:nth-child(2) {
    position: sticky;
    left: 100px;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 3;
}
.poc-table th:nth-child(2) {
    position: sticky;
    left: 100px;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 4;
}
.poc-table td:nth-child(3) {
    position: sticky;
    left: 200px;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 3;
}
.poc-table th:nth-child(3) {
    position: sticky;
    left: 200px;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 4;
}
.poc-table th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
}
</style>
