<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'commercialImages',
                    params: { tool },
                }"
            >
                Photo Gallery
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <span>Images</span>
                <v-spacer />
                <v-btn @click="openDialog" depressed color="primary" rounded>
                    <v-icon left>mdi-plus</v-icon>
                    <span>New</span>
                </v-btn>

                <ImageForm
                    v-model="dialogImage"
                    title="Upload Image"
                    :tool="this.tool"
                    @closeDialog="closeDialog"
                />
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text
                class="overflow-y-hidden"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ImageForm from '@/components/Settings/ImageForm'
export default {
    name: 'ImagesTools',
    components: {
        ImageForm,
    },
    props: {
        tool: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            errorMsg: null,
            dialogImage: false,
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        openDialog() {
            this.dialogImage = true
        },
        closeDialog() {
            this.dialogImage = false
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
    },
}
</script>
