<template>
    <div ref="container" class="mt-n3 mx-n3">
        <v-card :loading="loading" elevation="0">
            <v-card-title ref="title">
                <h3>RESERVES</h3>
                <v-card
                    class="pt-3 pl-2 pr-5 mx-3"
                    min-width="180px"
                    height="75px"
                >
                    <v-card-text
                        class="d-flex my-0 py-0 mx-0 px-0"
                        width="100%"
                    >
                        <v-chip
                            class="mb-1 mt-0 mx-2 pt-0 white--text"
                            :color="insights[0].color"
                            label
                            :style="{ height: '50px' }"
                        >
                            <v-icon center>
                                {{ insights[0].icon }}
                            </v-icon>
                        </v-chip>
                        <div class="mb-1 mt-0 pt-0">
                            <div class="d-flex mr-0 pr-0 text-capitalize">
                                <span
                                    :style="{
                                        'font-size': '14px',
                                        'font-weight': 'bold',
                                    }"
                                    >{{ insights[0].title }}</span
                                >
                            </div>
                            <p class="text-h6 black--text">
                                $ {{ calculateTotal() }}
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="mt-n5">
                <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="items"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    calculate-widths
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :height="$vuetify.breakpoint.mdAndDown ? '60vh' : '58vh'"
                    fixed-header
                >
                    <!--ITEMS-->
                    <template v-slot:[`item.project_name`]="{ item }">
                        <p class="my-0">
                            {{
                                item.project_name == ''
                                    ? 'Free'
                                    : item.project_name
                            }}
                        </p>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <p
                            v-if="
                                item.price != undefined && item.price != 'NaN'
                            "
                            class="my-0"
                        >
                            $ {{ item.price }}
                        </p>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        <p class="my-0">$ {{ numberFormat(item.total) }}</p>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'ProjectRequested',
    props: {
        projectId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        height: 0,
        sortBy: 'total',
        sortDesc: true,
        items: [],
        project: {},
        loading: false,
        headers: [
            {
                text: 'PROJECT',
                value: 'project_name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ITEM',
                value: 'short_material_text',
                align: 'left',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'material',
                align: 'center',
            },
            {
                text: 'STORE',
                value: 'storage',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'free_use',
                align: 'right',
            },
            {
                text: 'PRICE (UNIT)',
                value: 'price',
                align: 'right',
            },
            {
                text: 'TOTAL PRICE',
                value: 'total',
                align: 'right',
            },
        ],
        insights: [
            {
                color: '#3498DB',
                icon: 'mdi-currency-usd',
                iconColor: '#1A5276',
                title: 'TOTAL STOCK',
            },
        ],
    }),
    async mounted() {
        try {
            this.loading = true
            this.project = await API.getProject(this.projectId)
            await this.getStock()
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - titleHeight - 130
        },
        numberFormat(amount) {
            return new Intl.NumberFormat('de-DE').format(Math.ceil(amount))
        },
        async getStock() {
            try {
                const stock = await API.getStock()
                for (const element of stock) {
                    element.items.forEach(item => {
                        if (
                            (item.order || item.orderNumber) &&
                            this.project.sapCode &&
                            (item.order || item.orderNumber) ==
                                this.project.sapCode
                        ) {
                            this.items.push(item)
                        }
                    })
                }
                this.items.forEach(item => {
                    item.available = this.numberFormat(item.available)
                    item.reserved = this.numberFormat(item.reserved)
                    item.price = this.numberFormat(
                        Number(item.usable_free_value) / Number(item.free_use)
                    )
                    item.usable_free_value = this.numberFormat(
                        Number(item.usable_free_value)
                    )
                    item.total = Number(
                        item.usable_free_value.replace(/\./g, '')
                    )
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateTotal() {
            let total = 0
            total = this.items.reduce(
                (accumulator, item) =>
                    accumulator +
                    Number(item.usable_free_value.replace(/\./g, '')),
                0
            )

            return this.numberFormat(total)
        },
    },
}
</script>

<style></style>
