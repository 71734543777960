var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"overflow-y-hidden pa-0",style:({ height: '100%' }),attrs:{"fluid":""}},[_c('v-row',{ref:"followUpHeader",staticClass:"d-flex"},[_c('v-col',{staticClass:"ml-5 my-0 pa-0 d-flex align-center"},[_c('v-btn',{staticClass:"white--text",attrs:{"rounded":"","depressed":"","color":"primary","hide-details":""},on:{"click":_vm.openTaskFormDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("New")])],1),_c('v-card-title',{style:({ 'max-width': '220px' })},[_c('v-combobox',{attrs:{"items":_vm.collaborators,"label":"Projects","flat":"","solo":"","multiple":"","text":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function({ index }){return [(index === 0)?_c('div',{staticClass:"mr-6"},[_c('span',[_vm._v("Users")])]):_vm._e(),(index === 0)?_c('v-badge',{staticClass:"mt-n2",attrs:{"content":_vm.value.length,"value":_vm.collaborators,"color":"primary","overlap":""}}):_vm._e()]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-card-title',{style:({ 'max-width': '220px' })},[_c('v-combobox',{attrs:{"items":_vm.collaborators,"label":"Quotes","flat":"","solo":"","multiple":"","text":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function({ index }){return [(index === 0)?_c('div',{staticClass:"mr-6"},[_c('span',[_vm._v("Users")])]):_vm._e(),(index === 0)?_c('v-badge',{staticClass:"mt-n2",attrs:{"content":_vm.value.length,"value":_vm.collaborators,"color":"primary","overlap":""}}):_vm._e()]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('v-divider',{staticClass:"mb-2"}),_c('v-card',{class:{
            'overflow-x-auto': _vm.$vuetify.breakpoint.smAndDown,
            '': _vm.$vuetify.breakpoint.mdAndUp,
        },attrs:{"flat":""}},[_c('div',[_c('v-row',{staticClass:"flex-nowrap ma-0"},[_c('v-col',{staticClass:"pl-2 pr-0 py-0 ma-0",attrs:{"cols":"8","xl":"3","lg":"3","md":"5"}},[_c('v-card',{staticClass:"ma-0 pa-0",style:({
                            'background-color': '#fafafa',
                            'border-radius': '10px',
                        }),attrs:{"flat":""}},[_c('p',{staticClass:"ma-0 pa-3 pb-1 d-flex font-weight-bold",style:({ color: '#EDC43B' })},[_vm._v(" ON HOLD ")]),_c('v-row',{staticClass:"flex-nowrap ma-0 pa-0"},[_c('draggable',{staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({
                                    height: `${this.height - 62}px`,
                                    width: '100%',
                                }),attrs:{"group":"tasks"},on:{"change":_vm.onHoldLog},model:{value:(_vm.onHold),callback:function ($$v) {_vm.onHold=$$v},expression:"onHold"}},_vm._l((_vm.onHold),function(task,index){return _c('div',{key:index,staticClass:"list-group-item py-0 mx-0 my-3 px-1"},[_c('v-sheet',{staticClass:"fill-height",attrs:{"min-height":"100","color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":task,"options":{
                                                threshold: 0.5,
                                            }}},[_c('TaskCard',{attrs:{"task":task,"users":_vm.users},on:{"openTaskDetails":_vm.openTaskDetails,"enableLoadAnimation":_vm.enableLoadAnimation}})],1)],1)],1)}),0)],1)],1)],1),_c('v-col',{staticClass:"px-2 py-0 ma-0",attrs:{"cols":"8","xl":"3","lg":"3","md":"5"}},[_c('v-card',{staticClass:"ma-0 pa-0",style:({
                            'background-color': '#fafafa',
                            'border-radius': '10px',
                        }),attrs:{"flat":""}},[_c('p',{staticClass:"ma-0 pa-3 pb-1 d-flex font-weight-bold",style:({ color: '#3277D1' })},[_vm._v(" TO DO ")]),_c('v-row',{staticClass:"flex-nowrap ma-0 pa-0"},[_c('draggable',{staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({
                                    height: `${this.height - 62}px`,
                                    width: '100%',
                                }),attrs:{"group":"tasks"},on:{"change":_vm.toDoLog},model:{value:(_vm.toDo),callback:function ($$v) {_vm.toDo=$$v},expression:"toDo"}},_vm._l((_vm.toDo),function(task,index){return _c('div',{key:index,staticClass:"list-group-item py-0 mx-0 my-3 px-1"},[_c('v-sheet',{staticClass:"fill-height",attrs:{"min-height":"100","color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":task,"options":{
                                                threshold: 0.5,
                                            }}},[_c('TaskCard',{attrs:{"task":task,"users":_vm.users},on:{"openTaskDetails":_vm.openTaskDetails,"enableLoadAnimation":_vm.enableLoadAnimation}})],1)],1)],1)}),0)],1)],1)],1),_c('v-col',{staticClass:"pr-2 pl-0 py-0 ma-0",attrs:{"cols":"8","xl":"3","lg":"3","md":"5"}},[_c('v-card',{staticClass:"ma-0 pa-0",style:({
                            'background-color': '#fafafa',
                            'border-radius': '10px',
                        }),attrs:{"flat":""}},[_c('p',{staticClass:"ma-0 pa-3 pb-1 d-flex font-weight-bold",style:({ color: '#FF9800' })},[_vm._v(" IN PROGRESS ")]),_c('v-row',{staticClass:"flex-nowrap ma-0 pa-0"},[_c('draggable',{staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({
                                    height: `${this.height - 62}px`,
                                    width: '100%',
                                }),attrs:{"group":"tasks"},on:{"change":_vm.inProgressLog},model:{value:(_vm.inProgress),callback:function ($$v) {_vm.inProgress=$$v},expression:"inProgress"}},_vm._l((_vm.inProgress),function(task,index){return _c('div',{key:index,staticClass:"list-group-item py-0 mx-0 my-3 px-1"},[_c('v-sheet',{staticClass:"fill-height",attrs:{"min-height":"100","color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":task,"options":{
                                                threshold: 0.5,
                                            }}},[_c('TaskCard',{attrs:{"task":task,"users":_vm.users},on:{"openTaskDetails":_vm.openTaskDetails,"enableLoadAnimation":_vm.enableLoadAnimation}})],1)],1)],1)}),0)],1)],1)],1),_c('v-col',{staticClass:"pr-2 pl-0 py-0 ma-0",attrs:{"cols":"8","xl":"3","lg":"3","md":"5"}},[_c('v-card',{staticClass:"ma-0 pa-0",style:({
                            'background-color': '#fafafa',
                            'border-radius': '10px',
                        }),attrs:{"flat":""}},[_c('p',{staticClass:"ma-0 pa-3 pb-1 d-flex font-weight-bold",style:({ color: '#27BC4F' })},[_vm._v(" DONE ")]),_c('v-row',{staticClass:"flex-nowrap ma-0 pa-0"},[_c('draggable',{staticClass:"list-group overflow-y-auto px-2 py-0 ma-0",style:({
                                    height: `${this.height - 62}px`,
                                    width: '100%',
                                }),attrs:{"group":"tasks"},on:{"change":_vm.doneLog},model:{value:(_vm.done),callback:function ($$v) {_vm.done=$$v},expression:"done"}},_vm._l((_vm.done),function(task,index){return _c('div',{key:index,staticClass:"list-group-item py-0 mx-0 my-3 px-1"},[_c('v-sheet',{staticClass:"fill-height",attrs:{"min-height":"100","color":"transparent"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"v-model":task,"options":{
                                                threshold: 0.5,
                                            }}},[_c('TaskCard',{attrs:{"task":task,"users":_vm.users},on:{"openTaskDetails":_vm.openTaskDetails,"enableLoadAnimation":_vm.enableLoadAnimation,"openHoursDialog":_vm.openHoursDialog}})],1)],1)],1)}),0)],1)],1)],1)],1)],1)]),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"640px"},model:{value:(_vm.openTaskForm),callback:function ($$v) {_vm.openTaskForm=$$v},expression:"openTaskForm"}},[(_vm.openTaskForm)?_c('TaskForm',{attrs:{"task":_vm.selectedTask,"originalTask":_vm.originalTask,"createForm":_vm.createForm},on:{"closeDialog":_vm.closeTaskFormDialog,"replaceTask":_vm.replaceTask,"addTask":_vm.addTask}}):_vm._e()],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"640px"},model:{value:(_vm.viewTaskDetails),callback:function ($$v) {_vm.viewTaskDetails=$$v},expression:"viewTaskDetails"}},[(_vm.viewTaskDetails)?_c('TaskDetails',{attrs:{"task":_vm.selectedTask,"users":_vm.users},on:{"openEditDialog":_vm.openEditDialog,"closeDialog":_vm.closeTaskDetails,"replaceTask":_vm.replaceTask}}):_vm._e()],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"340px"},model:{value:(_vm.openHoursForm),callback:function ($$v) {_vm.openHoursForm=$$v},expression:"openHoursForm"}},[(_vm.openHoursForm)?_c('HoursForm',{attrs:{"taskHours":_vm.taskHours},on:{"closeHoursDialog":_vm.closeHoursDialog,"updateHours":_vm.updateHours}}):_vm._e()],1),_c('v-alert',{style:({ position: 'fixed', bottom: '30px', right: 0 }),attrs:{"value":_vm.activateAlert,"color":"blue","dismissible":"","type":"success","transition":"scale-transition"}},[_vm._v(" The task was successfully created. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }