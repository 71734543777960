<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="bomsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openProcessBOM"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">BILL OF MATERIALS</h1>
                        <v-btn
                            v-if="$route.name !== 'requested-boms'"
                            rounded
                            color="primary"
                            @click="openFindBOM"
                        >
                            <v-icon class="mr-1">
                                mdi-database-search
                            </v-icon>
                            FIND
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADER FILTER-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.index`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.code`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.code }}</p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0" v-if="item.creator">
                        {{ item.creator.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0" v-if="item.project">
                        {{
                            item.project.reference
                                ? `${item.project.reference} - ${item.project.name}`
                                : item.project.id
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            formatDate(
                                item.createdOn.seconds ||
                                    item.createdOn._seconds
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.deliveries`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <p class="my-0 text-center" v-on="on">
                            {{ item.totalDelivered }}/{{ item.totalQty }} ({{
                                (
                                    (item.totalDelivered / item.totalQty) *
                                    100
                                ).toFixed(0)
                            }}%)
                        </p>
                    </template>
                    <span>
                        Total Qty:
                        {{ item.totalQty }}<br />
                        Delivered:
                        {{ item.totalDelivered }}<br />
                        Remaining:
                        {{ item.totalRemaining }}<br />
                    </span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.notes }}
                    </p>
                </div>
            </template>
        </v-data-table>
        <!--PROCESS BOM-->
        <v-dialog
            v-model="processBOM"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessBOM
                v-if="processBOM"
                :bom="selectedBOM"
                :process="processName"
                :partialDelivery="true"
                :bomsDelivered="true"
                @close="closeProcessBOM"
                @closeBOM="closeBOM"
                @sendToDeliver="sendToDeliver"
            />
        </v-dialog>
        <!--FIND BOM-->
        <v-dialog
            v-model="findBOM"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindBOM v-if="findBOM" @addBOM="addBOM" @close="closeFindBOM" />
        </v-dialog>
    </div>
</template>

<script>
import SocketioService from '@/services/websocket/socket.service.js'
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
export default {
    name: 'BOMsDelivered',
    components: {
        ProcessBOM: () => import('@/components/WorkOrders/ProcessBOM.vue'),
        FindBOM: () => import('@/components/WorkOrders/FindBOM.vue'),
    },
    data: () => ({
        seeWarning: false,
        findBOM: false,
        selectedBOM: {},
        processBOM: false,
        processName: 'Bill Of Material',
        projectToFilter: undefined,
        loading: false,
        boms: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 170,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'APPROVED BY',
                value: 'approvedBy.name',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELIVERIES',
                value: 'deliveries',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            production: '#FFC000',
            cancelled: 'red',
            closed: 'primary',
        },
        users: [],
        sendBOMPermission: false,
        projects: [],
        resourceId: undefined,
        rejectPartialsForm: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        rejectLoading: false,
        rejectedNotes: null,
        rejectionTable: false,
    }),
    computed: {
        ...mapState(['notificationResource']),
        ...mapState(['bomUpdates']),
        bomsFiltered() {
            this.openResource()
            let conditions = []
            if (this.$router.currentRoute.name == 'requested-boms') {
                conditions.push(this.filterRequested)
            }
            if (this.search) {
                conditions.push(this.filterBOM)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.boms.filter(bom => {
                    return conditions.every(condition => {
                        return condition(bom)
                    })
                })
            }
            this.sort()
            return this.boms
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
        bomUpdates: function(data) {
            if (data) {
                this.UpdateBom(data)
                this.setBomUpdates(undefined)
            }
        },
    },
    provide() {
        return {
            addDeliveries: this.addDeliveries,
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            SocketioService.joinRoom(`${this.companyId}-BOM`)
            SocketioService.leaveRoom(`${this.companyId}-WO`)
            // get users and projects
            await this.loadSecondaryData()
            // get approved boms
            this.boms = await API.getBOMsChangelog({ status: ['approved'] })
            // filter boms deliveries
            this.boms = this.boms.filter(bom => {
                return !!bom.items.find(
                    item => item.deliveries && item.deliveries.length > 0
                )
            })
            // sort boms
            this.boms.sort((a, b) => {
                const operator1 = a.deliver ? 1 : 0
                const operator2 = b.deliver ? 1 : 0
                return operator2 - operator1
            })
            // autocomplete boms
            for (const bom of this.boms) {
                bom.project = this.projects.find(
                    project => project.id == bom.projectId
                )
                if (!bom.project) {
                    bom.project = { id: bom.projectId }
                }
                if (bom.project && bom.project.projectManager) {
                    bom.projectManager = this.users.find(
                        user => user.id == bom.project.projectManager
                    )
                }
                bom.creator = this.users.find(user => user.id == bom.createdBy)
                bom.approvedBy = this.users.find(
                    user => user.id == bom.approvedBy
                )
                bom.totalQty = bom.items.reduce(
                    (accumulator, item) => accumulator + item.quantity,
                    0
                )
                bom.totalRemaining = bom.items.reduce(
                    (accumulator, item) => accumulator + item.remaining,
                    0
                )
                bom.totalDelivered = bom.totalQty - bom.totalRemaining
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        ...mapActions(['setBomUpdates']),
        ...mapGetters(['getBomUpdates']),
        async loadSecondaryData() {
            try {
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers()
                this.users = users
                // retrieve projects
                this.projects = await API.getLiteProjects({ all: true })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async addBOM(bom) {
            try {
                this.loading = true
                const index = this.boms.findIndex(b => b.id == bom.id)
                if (index >= 0) {
                    this.openProcessBOM(this.boms[index])
                } else {
                    bom.project = await API.getProject(bom.projectId)
                    if (!bom.project) {
                        bom.project = { id: bom.projectId }
                    }
                    if (bom.project && bom.project.projectManager) {
                        bom.projectManager = this.users.find(
                            user => user.id == bom.project.projectManager
                        )
                    }
                    bom.creator = this.users.find(
                        user => user.id == bom.createdBy
                    )
                    bom.approvedBy = this.users.find(
                        user => user.id == bom.approvedBy
                    )
                    this.boms.splice(0, 0, bom)
                    this.openProcessBOM(bom)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        sendToDeliver() {
            this.processBOM = false
        },
        filterBOM(bom) {
            return bom.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterRequested(bom) {
            return bom.deliver == true
        },
        openFindBOM() {
            this.findBOM = true
        },
        closeFindBOM() {
            this.findBOM = false
        },
        openProcessBOM(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.processBOM = true
        },
        closeProcessBOM() {
            const bomIndex = this.boms.findIndex(
                b => b.code == this.selectedBOM.code
            )
            if (bomIndex > -1) {
                this.boms[bomIndex] = this.selectedBOM
            }
            this.boms = _.cloneDeep(this.boms)
            this.selectedBom = {}
            this.processBOM = false
        },
        closeBOM() {
            const bomIndex = this.boms.findIndex(
                b => b.code == this.selectedBOM.code
            )
            if (bomIndex > -1) {
                this.boms.splice(bomIndex, 1)
            }
            this.boms = _.cloneDeep(this.boms)
            this.selectedBom = {}
            this.processBOM = false
        },
        filterProject(bom) {
            if (!bom.project.name && !bom.project.reference) {
                return false
            }
            return (
                bom.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                bom.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        sort() {
            this.boms.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.boms.find(b => b.id == this.resourceId)
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessBOM(resource)
                }
            }
        },
        async UpdateBom(bom) {
            try {
                const index = this.boms.findIndex(b => b.id == bom.bomId)
                if (index >= 0) {
                    if (bom.status == 'approved') {
                        if (bom.items) {
                            bom.totalQty = bom.items.reduce(
                                (accumulator, item) =>
                                    accumulator + item.quantity,
                                0
                            )
                            bom.totalRemaining = bom.items.reduce(
                                (accumulator, item) =>
                                    accumulator + item.remaining,
                                0
                            )
                            bom.totalDelivered =
                                bom.totalQty - bom.totalRemaining
                        }
                        const updatedBom = {
                            ...this.boms[index],
                            ...bom,
                        }
                        this.boms.splice(index, 1, updatedBom)
                        if (this.selectedBOM.id == bom.bomId) {
                            this.selectedBOM = _.cloneDeep(this.boms[index])
                        }
                    } else {
                        this.boms = this.boms.filter(b => b.id != bom.bomId)
                    }
                } else if (bom.status == 'approved') {
                    const newBom = await API.getBoms({
                        id: bom.bomId,
                    })
                    for (let bom of newBom) {
                        bom.project = this.projects.find(
                            project => project.id == bom.projectId
                        )
                        if (!bom.project) {
                            bom.project = { id: bom.projectId }
                        }
                        if (bom.project && bom.project.projectManager) {
                            bom.projectManager = this.users.find(
                                user => user.id == bom.project.projectManager
                            )
                        }
                        bom.creator = this.users.find(
                            user => user.id == bom.createdBy
                        )
                        bom.approvedBy = this.users.find(
                            user => user.id == bom.approvedBy
                        )
                        bom.totalQty = bom.items.reduce(
                            (accumulator, item) => accumulator + item.quantity,
                            0
                        )
                        bom.totalRemaining = bom.items.reduce(
                            (accumulator, item) => accumulator + item.remaining,
                            0
                        )
                        bom.totalDelivered = bom.totalQty - bom.totalRemaining
                        this.boms.push(bom)
                        if (this.selectedBOM.id == bom.bomId) {
                            this.selectedBOM = _.cloneDeep(bom)
                        }
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
    },
}
</script>

<style></style>
