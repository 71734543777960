<template>
    <div>
        <v-card>
            <v-card-title class="text-h5 py-2">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog1()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <span>{{ title }}</span>
                <v-spacer />
                <v-btn
                    v-if="
                        quote &&
                            (quote.status === 'OPEN' ||
                                quote.status === 'APPROVAL') &&
                            !version
                    "
                    :disabled="!validateForm"
                    color="primary"
                    :loading="valueLoading"
                    elevation="0"
                    rounded
                    @click="saveCost"
                    >Save</v-btn
                >
            </v-card-title>

            <v-divider class="mx-4" />

            <v-alert
                dismissible
                v-if="loadingError"
                text
                type="error"
                prominent
            >
                <v-row text-align="center">
                    <v-col class="grow">
                        The exchange data is missing
                    </v-col>
                    <v-col class="shrink">
                        <v-btn color="error" outlined :to="{ name: 'settings' }"
                            >Add Exchange Rate</v-btn
                        >
                    </v-col>
                </v-row>
            </v-alert>

            <v-card-text class="overflow-y-auto" max-height="900">
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-form v-model="valid">
                            <v-row no-gutters class="align-center">
                                <v-col
                                    class="d-flex align-center"
                                    cols="4"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >
                                    <v-subheader>Reference *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-row>
                                        <v-text-field
                                            flat
                                            dense
                                            hide-details
                                            solo
                                            required
                                            :rules="[rules.required]"
                                            class="text-body-2"
                                            v-model="cost.reference"
                                            placeholder="Detailed name of the scope"
                                            :disabled="
                                                (quote &&
                                                    quote.status !== 'OPEN' &&
                                                    quote.status !==
                                                        'APPROVAL') ||
                                                    version ||
                                                    readOnly
                                            "
                                        ></v-text-field>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="align-center">
                                <v-col
                                    class="d-flex align-center"
                                    cols="4"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >
                                    <v-subheader>Category *</v-subheader>
                                </v-col>
                                <v-col
                                    class="d-flex align-center"
                                    cols="8"
                                    sm="10"
                                    md="10"
                                    lg="10"
                                    xl="10"
                                >
                                    <v-autocomplete
                                        flat
                                        dense
                                        hide-details
                                        solo
                                        required
                                        placeholder="Cost category for invoicing"
                                        :rules="[rules.required]"
                                        class="text-body-2 ml-n3"
                                        :items="categories"
                                        item-text="name"
                                        item-value="name"
                                        v-model="cost.category"
                                        :disabled="
                                            (quote &&
                                                quote.status !== 'OPEN' &&
                                                quote.status !== 'APPROVAL') ||
                                                version ||
                                                readOnly
                                        "
                                        :style="{ 'max-width': '280px' }"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="d-flex align-center">
                                <v-col
                                    class="d-flex align-center"
                                    cols="4"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >
                                    <v-subheader>Measure *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-autocomplete
                                        flat
                                        dense
                                        hide-details
                                        solo
                                        required
                                        value="ft2"
                                        :rules="[rules.required]"
                                        class="text-body-2 ml-n3"
                                        :items="units"
                                        @change="setPricePerQ"
                                        v-model="cost.measure"
                                        :disabled="
                                            (quote &&
                                                quote.status !== 'OPEN' &&
                                                quote.status !== 'APPROVAL') ||
                                                version ||
                                                readOnly
                                        "
                                        :style="{ 'max-width': '150px' }"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                                    <v-subheader>Units *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-text-field
                                        flat
                                        dense
                                        type="number"
                                        solo
                                        hide-details
                                        class="text-body-2 ml-n3"
                                        required
                                        :rules="[rules.number2]"
                                        placeholder="840"
                                        @input="setPricePerQ"
                                        v-model="cost.quantity"
                                        :disabled="
                                            (quote &&
                                                quote.status !== 'OPEN' &&
                                                quote.status !== 'APPROVAL') ||
                                                version ||
                                                readOnly
                                        "
                                        :style="{ 'max-width': '150px' }"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center" no-gutters>
                                <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                                    <v-subheader>Margin *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-text-field
                                        flat
                                        dense
                                        class="text-body-2 ml-n3"
                                        hide-details
                                        solo
                                        placeholder="44"
                                        type="number"
                                        suffix="%"
                                        required
                                        :rules="[rules.number]"
                                        @input="setTotalMargin"
                                        v-model="cost.margin"
                                        :disabled="
                                            (quote &&
                                                quote.status !== 'OPEN' &&
                                                quote.status !== 'APPROVAL') ||
                                                version ||
                                                readOnly
                                        "
                                        :style="{ 'max-width': '150px' }"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-form v-model="valid2">
                            <v-row class="mt-1">
                                <v-col class="d-flex align-center" cols="12">
                                    <v-row>
                                        <h4>
                                            Engineering Status:
                                        </h4>
                                        <span class="ml-2">
                                            {{
                                                cost.engineering
                                                    ? 'Engineering'
                                                    : 'Outside Engineering'
                                            }}</span
                                        >
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="d-flex align-center" cols="12">
                                    <v-row>
                                        <div class="mt-3">
                                            <h4>
                                                Engineer:
                                            </h4>
                                        </div>
                                        <v-col
                                            class="my-0 py-0"
                                            cols="8"
                                            sm="10"
                                            md="10"
                                            lg="10"
                                            xl="10"
                                        >
                                            <v-autocomplete
                                                class="py-0"
                                                flat
                                                dense
                                                hide-details
                                                solo
                                                :rules="[rules.string]"
                                                :items="users"
                                                item-text="name"
                                                item-value="id"
                                                v-model="cost.engineer"
                                                :style="{
                                                    'max-width': 'fit-content',
                                                }"
                                                :disabled="
                                                    quote.status ===
                                                        'AWARDED' ||
                                                        quote.status ===
                                                            'LOST' ||
                                                        quote.status ===
                                                            'SENT' ||
                                                        version ||
                                                        quote.archive == true
                                                "
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="
                                    ((quote &&
                                        quote.collaborators.includes(
                                            user.id
                                        )) ||
                                        (engineeringCostPermission &&
                                            !version)) &&
                                        ['OPEN', 'APPROVAL'].includes(
                                            quote.status
                                        ) &&
                                        !quote.archive
                                "
                                class="mt-3"
                            >
                                <v-col class="d-flex align-center" cols="12">
                                    <v-row>
                                        <h4
                                            v-if="!cost.engineering"
                                            class="mt-2"
                                        >
                                            Send To Engineering Process
                                        </h4>
                                        <h4 v-else class="mt-2">
                                            Return from Engineering Process
                                        </h4>
                                        <v-simple-checkbox
                                            v-if="!cost.engineering"
                                            class="ml-2"
                                            color="primary"
                                            @click="setForEngineering"
                                            v-model="sendToEngineering"
                                        ></v-simple-checkbox>

                                        <v-simple-checkbox
                                            v-if="cost.engineering"
                                            class="ml-2"
                                            color="primary"
                                            @click="unSetForEngineering"
                                            v-model="returnFromEngineering"
                                        ></v-simple-checkbox>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="d-flex align-center" cols="12">
                                    <v-row>
                                        <h4 class="mt-2">
                                            Engineering Due Date:
                                        </h4>
                                        <span class="ml-1 mt-2">{{
                                            cost &&
                                            cost.engineeringDate &&
                                            cost.engineeringDate._seconds
                                                ? formatDate(
                                                      cost.engineeringDate
                                                          ._seconds
                                                  )
                                                : cost.engineeringDate
                                                ? cost.engineeringDate
                                                : 'No Date'
                                        }}</span>
                                        <v-btn
                                            v-if="
                                                quote &&
                                                    quote.status === 'OPEN' &&
                                                    cost.engineering &&
                                                    editEngineeringDueDatePermission &&
                                                    !quote.archive
                                            "
                                            :disabled="
                                                quote &&
                                                    !quote.collaborators.includes(
                                                        user.id
                                                    ) &&
                                                    !editEngineeringDueDatePermission
                                            "
                                            class=" ml-2"
                                            color="primary"
                                            elevation="0"
                                            small
                                            icon
                                            @click="openEngDueDateDialog"
                                        >
                                            <v-icon>mdi-pencil</v-icon></v-btn
                                        >
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row v-if="cost.id" class="d-flex flex-wrap mt-5">
                            <h4 class="mt-2">Pre-Design:</h4>
                            <v-btn
                                color="transparent"
                                elevation="0"
                                small
                                class="ma-1 text-capitalize text--darken-2  pa-2"
                                v-for="(item, i) in cost.preDesign || []"
                                :key="i"
                            >
                                <span
                                    @click.stop="openFile(item.file)"
                                    :style="{
                                        'font-size': '11px',
                                        color: '#2b81d6',
                                        'text-decoration': 'underline',
                                        cursor: 'pointer',
                                    }"
                                    >{{ item.file }}</span
                                >
                                <v-icon
                                    v-if="
                                        editEngineeringDueDatePermission &&
                                            (quote.status == 'OPEN' ||
                                                quote.status == 'APPROVAL') &&
                                            !version &&
                                            quote.archive == false
                                    "
                                    @click.stop="deleteFile(item.file)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                class="mx-4"
                                v-if="
                                    (quote.status == 'OPEN' ||
                                        quote.status == 'APPROVAL') &&
                                        quote.archive == false
                                "
                                depressed
                                fab
                                x-small
                                color="primary"
                                @click="onButtonClick"
                                :disabled="loading"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <input
                                ref="preDesignFiles"
                                class="d-none"
                                type="file"
                                name="preDesignFiles"
                                @change="onFileChanged"
                            />
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-start" no-gutters>
                    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                        <v-subheader>Description</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                        <v-textarea
                            hide-details
                            solo
                            rows="4"
                            dense
                            class="text-body-2 pt-2 ml-n3"
                            placeholder="Detailed description of the scope"
                            flat
                            v-model="cost.description"
                            :readonly="
                                (quote &&
                                    quote.status !== 'OPEN' &&
                                    quote.status !== 'APPROVAL') ||
                                    version ||
                                    readOnly
                            "
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />

                <!--Edit engineering due Date-->
                <v-dialog
                    v-model="engineeringDateDialog"
                    :retain-focus="false"
                    persistent
                    max-width="400"
                >
                    <v-card :loading="loading" v-if="engineeringDateDialog">
                        <v-card-title class="text-h5">
                            <v-btn
                                small
                                icon
                                @click="closeEngDueDateDialogBtn"
                                color="primary"
                                class="mr-2"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <h3 v-if="originalCost.engineering">
                                Change Eng Due Date
                            </h3>
                            <h3 v-else>
                                Set Eng Due Date
                            </h3>
                        </v-card-title>
                        <v-divider class="ml-4 mr-5" />
                        <v-card-text class="pt-4">
                            <v-form>
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-4">
                                        <v-menu
                                            ref="menu"
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-combobox
                                                    class="py-0 my-0"
                                                    v-model="engineeringDate"
                                                    color="primary"
                                                    label="Eng Due Date"
                                                    readonly
                                                    flat
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    prepend-icon="mdi-calendar-blank-outline"
                                                    :rules="[rules.required]"
                                                ></v-combobox>
                                            </template>
                                            <v-date-picker
                                                v-model="engineeringDate"
                                                scrollable
                                                :min="minDate"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="pb-4">
                            <v-spacer />
                            <v-btn
                                color="primary"
                                :loading="loading"
                                rounded
                                :disabled="!engineeringDate"
                                @click="setEngDueDate"
                            >
                                CONFIRM
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <NewCostForm
                    v-for="sec in costsSections"
                    :key="sec.name"
                    @totalCostFunction="totalCost"
                    @saveItems="saveSectionItems"
                    :quoteCurrency="quoteCurrency"
                    :quoteExchange="Number(quoteExchange)"
                    :createCost="value"
                    :section="sec"
                    :reload="reload"
                    :itemSections="cost.sections"
                    :settingsExchange="settingExchange"
                    :settingsUnits="settingUnits"
                    :quoteStatus="quote ? quote.status : ''"
                    :version="version"
                />
                <v-simple-table dense class="mb-4">
                    <template v-slot:default>
                        <thead class="grey lighten-3">
                            <tr>
                                <th class="text-center no-border"></th>
                                <th class="text-center no-border">
                                    TOTAL COST
                                </th>
                                <th class="text-center no-border">
                                    MARGIN
                                </th>
                                <th class="text-center no-border">
                                    SALE PRICE
                                </th>
                                <th class="text-center no-border">
                                    COMMISSIONS
                                </th>
                                <th class="text-center no-border">
                                    UNITS
                                </th>
                                <th class="text-center no-border">
                                    PRICE PER UNIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            totalC
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            totalMargin
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.salePrice
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            commission
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">{{ cost.quantity }}</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.pricePerQuantity.toFixed(2)
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'm'
                                            : 'm2'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.total2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.totalMargin2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.salePrice2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.commission2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">{{ cost.quantity }}</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.pricePerQuantity2.toFixed(2)
                                        )
                                    }}
                                    {{
                                        quote ? quote.currency : quoteCurrency
                                    }}/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'ft'
                                            : 'ft2'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-simple-table dense class="mb-4">
                    <template v-slot:default>
                        <thead class="grey lighten-3">
                            <tr>
                                <th class="text-center no-border"></th>
                                <th class="text-center no-border">
                                    TOTAL WEIGHT
                                </th>
                                <th class="text-center no-border">
                                    WEIGHT PER UNIT
                                </th>
                                <th class="text-center no-border">
                                    COST/WEIGHT
                                </th>
                                <th class="text-center no-border">
                                    PRICE/WEIGHT
                                </th>
                                <th class="text-center no-border">
                                    COST PER UNIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>AMERICAN</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.tw
                                        )
                                    }}
                                    lb
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.wa
                                        )
                                    }}
                                    {{
                                        cost.american.wa == 0
                                            ? ''
                                            : `lb/${
                                                  cost.measure === 'qt' ||
                                                  cost.measure === 'QT'
                                                      ? 'qt'
                                                      : isLength
                                                      ? 'ft'
                                                      : 'ft2'
                                              }`
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.cw
                                        )
                                    }}
                                    {{
                                        quote ? quote.currency : quoteCurrency
                                    }}/lb
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.pw
                                        )
                                    }}
                                    {{
                                        quote ? quote.currency : quoteCurrency
                                    }}/lb
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.ca
                                        )
                                    }}
                                    {{
                                        quote ? quote.currency : quoteCurrency
                                    }}/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'ft'
                                            : 'ft2'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>INTERNATIONAL</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.tw
                                        )
                                    }}
                                    kg
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.wa
                                        )
                                    }}
                                    {{
                                        cost.international.wa == 0
                                            ? ''
                                            : `kg/${
                                                  cost.measure === 'qt' ||
                                                  cost.measure === 'QT'
                                                      ? 'qt'
                                                      : isLength
                                                      ? 'm'
                                                      : 'm2'
                                              }`
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.cw
                                        )
                                    }}
                                    COP/kg
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.pw
                                        )
                                    }}
                                    COP/kg
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.ca
                                        )
                                    }}
                                    COP/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'm'
                                            : 'm2'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-dialog
            :retain-focus="false"
            v-model="dialogSave"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Save Cost</v-card-title>
                <v-card-text>
                    Do you want to save the changes?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="dontSaveCost">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveCost" text color="error">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            :type="action.type"
            :color="action.color"
            :style="{ position: 'absolute', bottom: 0, right: 0 }"
            dismissible
            v-if="action.active && action.type == 'error'"
        >
            {{ action.message }}
        </v-alert>
    </div>
</template>

<script>
import NewCostForm from '@/components/Quotes/NewCostForm'
import { mapActions, mapMutations } from 'vuex'
import convert from 'convert-units'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'
import moment from 'moment'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { deleteFile } from '@/services/storage/deleteFile.js'

export default {
    name: 'NewQuoteCostForm',
    components: {
        NewCostForm,
    },
    props: {
        createCostForm: Boolean,
        editAlternative: Boolean,
        alternative: Boolean,
        title: String,
        value: Boolean,
        quote: Object,
        settings: Array,
        copyCost: Boolean,
        cost: Object,
        originalCost: Object,
        costs: Array,
        loading: Boolean,
        lockView: Boolean,
        version: {
            type: Boolean,
            default() {
                return false
            },
        },
        costExchanges: {
            type: Object,
            default() {
                return undefined
            },
        },
        currency: {
            type: String,
            default() {
                return ''
            },
        },
        exchange: {
            type: Number,
            default() {
                return 1
            },
        },
        action: {
            type: Object,
            default() {
                return {}
            },
        },
        readOnly: {
            type: Boolean,
            default() {
                return false
            },
        },
    },
    data() {
        return {
            editEngineeringDueDatePermission: false,
            engineeringCostPermission: false,
            units: [],
            newTotal: 0,
            quoteCurrency: null,
            quoteExchange: null,
            newItem: {},
            dialogSave: false,
            settingExchange: [],
            loadingError: false,
            sumCommissions: 0,
            totalWeight: this.cost.tw,
            totalMargin: this.cost.totalMargin,
            total: this.cost.total,
            totalSections: this.cost.totalSections,
            totalCommission: this.cost.commission,
            costsSections: [],
            descriptionCost: this.cost.descriptionCost,
            valid: false,
            valid2: false,
            enableSave: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => (v <= 100 && v >= 0) || 'Error',
                number2: v => v > 0 || 'Error',
                string:
                    (v => (typeof v == 'string' && v != '') || !v) || 'Error',
            },
            quantityAmerican: 0,
            quantityInternational: 0,
            isLength: false,
            reload: false,
            isOperable: true,
            categories: [],

            user: {},
            engineeringDialog: false,
            engineeringDateDialog: false,
            selectedCost: null,
            sendToEngineering: false,
            returnFromEngineering: null,
            minDate: '',
            engineeringDate: '',
            validation: null,
            localFiles: [],
            companyId: JSON.parse(localStorage.getItem('company')),
            users: [],
        }
    },
    created() {
        const indexCosts = this.settings.findIndex(u => u.name === 'Quotes')
        this.costsSections = this.settings[indexCosts].costSections
        if (!this.cost.sections) {
            this.cost.sections = []
        }
        this.settings[indexCosts].measureUnits.forEach(x => {
            this.units.push(x.abbr)
        })

        if (this.version) {
            this.quoteCurrency = this.currency
            this.quoteExchange = this.exchange
        } else {
            this.quoteCurrency = this.quote.currency
            this.quoteExchange = this.quote.exchange
        }
        if (this.costExchanges) {
            this.settingExchange = this.costExchanges
        } else {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
        }
        this.settingUnits = this.settings[indexCosts]
    },
    async mounted() {
        if (this.quote) {
            this.setTotalMargin(this.cost.margin)
        }
        if (this.cost && !this.cost.id) {
            delete this.cost.engineer
            delete this.cost.engineering
            delete this.cost.engineeringDate
            delete this.cost.engineeringExitDate
            delete this.cost.engineeringIngressDate
        }
        const {
            data: { settings },
        } = await API.getSettings()

        const {
            data: { user },
        } = await API.getMyInfo()
        this.user = user
        this.engineeringCostPermission = user.permissions.includes(
            'engineeringCost'
        )
        this.editEngineeringDueDatePermission = user.permissions.includes(
            'editEngineeringDueDate'
        )
        const invoiceSettings = settings.find(
            setting => setting.name.toLowerCase() == 'invoicing'
        )
        this.categories = invoiceSettings.categories
        // get users
        const {
            data: { users },
        } = await API.getLiteUsers()
        this.users = users
        this.loadData()
    },
    computed: {
        totalC: function() {
            return this.total
        },
        costDiff: function() {
            return objDiff(this.originalCost, this.cost)
        },
        commission: function() {
            return this.totalCommission
        },
        valueLoading: function() {
            return this.loading
        },
        show: {
            get() {
                this.changeStartupStatus()
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        validateForm: function() {
            let diff = objDiff(this.originalCost, this.cost)
            delete diff.pricePerQuantity
            delete diff.pricePerQuantity2
            delete diff.salePrice
            delete diff.salePrice2
            delete diff.preDesign
            delete diff.american
            delete diff.international
            return (
                (this.alternative || Object.keys(diff).length > 0) &&
                this.valid &&
                this.valid2 &&
                this.cost.sections.find(section => section.items.length > 0)
            )
        },
    },
    watch: {
        totalC: function(newTotal) {
            this.totalCostAction(newTotal)
        },
    },
    methods: {
        ...mapActions(['totalCostAction', 'quoteChangesAction']),
        ...mapMutations(['setErrorItems']),
        async deleteFile(file) {
            try {
                this.loading = true
                const preDesign = this.cost.preDesign.filter(
                    item => item.file != file
                )
                await API.removePreDesign({
                    quoteId: this.quote.id,
                    costId: this.cost.id,
                    preDesign: file,
                })
                await deleteFile(
                    file,
                    `${this.companyId}/quotes/${this.quote.id}/costs/${this.cost.id}`
                )
                this.$set(this.cost, 'preDesign', preDesign)
                this.$emit('updateCostInLocal', this.cost)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile(preDesign, file) {
            try {
                this.loading = true
                await API.addPreDesign({
                    quoteId: this.quote.id,
                    costId: this.cost.id,
                    preDesign: { file: preDesign.file, date: preDesign.date },
                })
                await saveFile(
                    file,
                    `${this.companyId}/quotes/${this.quote.id}/costs/${this.cost.id}`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async onFileChanged(e) {
            try {
                this.loading = true
                if (e.target.files.length > 0) {
                    let file = e.target.files[0]
                    file = new File(
                        [file],
                        moment().format('YYYY-MM-DD HH:mm:ss ') + file.name,
                        {
                            type: file.type,
                        }
                    )
                    const newFile = {
                        file: file.name,
                        date: new Date(),
                        userId: this.user.id,
                    }
                    if (this.cost.preDesign) {
                        this.$set(this.cost, 'preDesign', [
                            ...this.cost.preDesign,
                            newFile,
                        ])
                        this.$emit('updateCostInLocal', this.cost)
                    } else {
                        this.$set(this.cost, 'preDesign', [newFile])
                    }
                    if (!this.cost.id) {
                        this.localFiles = [
                            ...this.localFiles,
                            {
                                url: URL.createObjectURL(file),
                                file,
                            },
                        ]
                    } else {
                        await this.saveFile(newFile, file)
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onButtonClick() {
            this.$refs.preDesignFiles.click()
        },
        async openFile(fileName) {
            try {
                this.loading = true
                if (!this.cost.id && !this.cost.document_id) {
                    const file = this.localFiles.find(
                        item => item.file.name == fileName
                    )
                    if (file) {
                        window.open(file.url, '_blank')
                    }
                } else {
                    await openFile(
                        fileName,
                        `${this.companyId}/quotes/${this.quote.id}/costs/${this
                            .cost.id || this.cost.document_id}`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        changeStartupStatus() {
            this.reload = !this.reload
        },
        totalCost(parcial, sectionName, totalW) {
            if (
                this.totalSections == undefined ||
                this.totalSections == null ||
                this.cost.sections.length != this.totalSections.length
            ) {
                const newTotalSections = []
                for (const section of this.cost.sections) {
                    let totalSection = {
                        secName: section.name,
                        total: 0,
                        totalW: 0,
                    }
                    for (const item of section.items) {
                        totalSection.total += item.parcial
                        totalSection.totalW += item.totalWeight
                    }
                    newTotalSections.push(totalSection)
                }
                this.totalSections = newTotalSections
            }
            const indexSection = this.totalSections.findIndex(
                element =>
                    element.secName.toLowerCase() == sectionName.toLowerCase()
            )
            this.newItem = {
                secName: sectionName,
                total: parcial,
                totalW: totalW,
            }
            if (indexSection >= 0) {
                this.totalSections.splice(indexSection, 1, this.newItem)
            } else {
                this.totalSections.push(this.newItem)
            }
            this.total = 0
            this.totalWeight = 0
            //TODO:Use section id as reference to calculate
            this.totalSections.forEach(element => {
                this.total +=
                    element.secName !== 'Engineering & Desing'
                        ? element.total
                        : 0
                this.totalWeight +=
                    element.secName !== 'Engineering & Desing'
                        ? element.totalW
                        : 0
            })
            this.cost.total = this.total
            this.cost.international.tw = this.totalWeight
            this.cost.american.tw = parseFloat(this.totalWeight * 2.20462)
            this.setTotalMargin(this.cost.margin)
            this.setPricePerQ(this.cost.quantity)
        },

        setTotalMargin(event) {
            if (this.cost.total != undefined) {
                if (this.quote.commissions) {
                    if (this.quote.commissions.length > 0) {
                        this.sumCommissions = 0
                        this.quote.commissions.forEach(x => {
                            this.sumCommissions += parseFloat(x.percentage)
                        })
                    }
                }
                const marginp = event / 100
                const item1 = this.cost.total / (1 - marginp)
                const item2 = item1 * (this.sumCommissions / 100)
                const salesPrice1 = item1 + item2
                const salesPrice = item1 + item2
                this.totalCommission = salesPrice * (this.sumCommissions / 100)
                this.cost.commission = parseFloat(this.totalCommission)
                let salesPrice2 = salesPrice1
                const margin =
                    salesPrice - this.totalCommission - this.cost.total
                this.totalMargin = margin
                this.cost.totalMargin = parseFloat(this.totalMargin)
                this.cost.salePrice = parseFloat(salesPrice2)
                this.cost.total2 = parseFloat(
                    this.cost.total / this.quoteExchange
                )
                this.cost.totalMargin2 = parseFloat(
                    this.cost.totalMargin / this.quoteExchange
                )
                this.cost.salePrice2 = parseFloat(
                    this.cost.salePrice / this.quoteExchange
                )
                this.cost.commission2 = parseFloat(
                    this.cost.commission / this.quoteExchange
                )

                this.convertUnits(this.cost.quantity)

                this.cost.pricePerQuantity = parseFloat(
                    this.cost.salePrice / this.quantityInternational
                )

                this.cost.pricePerQuantity2 = parseFloat(
                    this.cost.salePrice2 / this.cost.quantity2
                )

                this.setAmericanStatistics()
                this.setInternationalStatistics()
            }
        },
        closeDialog: function() {
            this.enableSave = false
            this.totalMargin = 0
            this.totalCommission = 0
            this.totalSections = []
            this.$emit('closeDialog')
        },
        closeDialog1: function() {
            if (this.costDiff) {
                if (
                    Object.entries(this.costDiff).length == 0 ||
                    this.quote.status !== 'OPEN'
                ) {
                    this.closeDialog()
                } else {
                    this.dialogSave = true
                }
            } else {
                if (this.version) {
                    this.dialogSave = false
                    this.closeDialog()
                } else {
                    this.total = 0
                    this.totalSections = []
                    this.dialogSave = true
                }
            }
        },
        setPricePerQ(event) {
            if (isNaN(parseFloat(event))) {
                event = this.cost.quantity
            }
            this.convertUnits(event)
            if (this.quote.commissions) {
                if (this.quote.commissions.length > 0) {
                    this.sumCommissions = 0
                    this.quote.commissions.forEach(x => {
                        this.sumCommissions += parseFloat(x.percentage)
                    })
                }
            }

            this.cost.pricePerQuantity = parseFloat(
                this.cost.salePrice / this.quantityInternational
            )
            this.cost.pricePerQuantity2 = parseFloat(
                this.cost.salePrice2 / this.cost.quantity2
            )

            this.convertToQuoteCurrency()
            this.setAmericanStatistics()
            this.setInternationalStatistics()
        },
        setAmericanStatistics() {
            if (this.cost.american.tw > 0) {
                this.cost.american.cw = parseFloat(
                    (this.cost.total2 / this.cost.american.tw).toFixed(2)
                )
                this.cost.american.pw = parseFloat(
                    (this.cost.salePrice2 / this.cost.american.tw).toFixed(2)
                )
            }
            if (this.cost.quantity > 0) {
                if (this.cost.american.tw > 0) {
                    this.cost.american.wa = parseFloat(
                        (this.cost.american.tw / this.cost.quantity2).toFixed(2)
                    )
                }
                this.cost.american.ca = parseFloat(
                    (this.cost.total2 / this.cost.quantity2).toFixed(2)
                )
            }
            if (this.cost.american.tw === 0) {
                this.cost.american.wa = 0
                this.cost.american.cw = 0
                this.cost.american.pw = 0
            }
        },
        setInternationalStatistics() {
            if (this.cost.international.tw > 0) {
                this.cost.international.cw = parseFloat(
                    (this.cost.total / this.cost.international.tw).toFixed(2)
                )
                this.cost.international.pw = parseFloat(
                    (this.cost.salePrice / this.cost.international.tw).toFixed(
                        2
                    )
                )
            }

            if (this.cost.quantity > 0) {
                if (this.cost.international.tw > 0) {
                    this.cost.international.wa = parseFloat(
                        (
                            this.cost.international.tw /
                            this.quantityInternational
                        ).toFixed(2)
                    )
                }
                this.cost.international.ca = parseFloat(
                    (this.cost.total / this.quantityInternational).toFixed(2)
                )
            }

            if (this.cost.international.tw === 0) {
                this.cost.international.wa = 0
                this.cost.international.cw = 0
                this.cost.international.pw = 0
            }
        },
        convertToQuoteCurrency() {
            if (this.quote.currency != this.settingExchange.globalCurrency) {
                this.cost.total2 = parseFloat(
                    (this.total / this.quoteExchange).toFixed(2)
                )
                this.cost.totalMargin2 = parseFloat(
                    (this.totalMargin / this.quote.exchange).toFixed(2)
                )
                this.cost.salePrice2 = parseFloat(
                    (this.cost.salePrice / this.quote.exchange).toFixed(2)
                )
                this.cost.commission2 = parseFloat(
                    (this.cost.commission / this.quote.exchange).toFixed(2)
                )
                this.cost.pricePerQuantity2 = parseFloat(
                    this.cost.salePrice2 / this.cost.quantity2
                )
            } else {
                this.cost.total2 = this.total
                this.cost.totalMargin2 = this.totalMargin
                this.cost.salePrice2 = this.cost.salePrice
                this.cost.commission2 = this.cost.commission
                this.cost.pricePerQuantity2 = this.cost.pricePerQuantity
            }
        },
        makeid() {
            var length = 6
            var result = ''
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result.toUpperCase()
        },
        dontSaveCost() {
            this.dialogSave = false
            this.closeDialog()
        },
        async saveCost() {
            try {
                this.descriptionCost = ''
                this.cost.sections.forEach(element => {
                    for (const i of element.items) {
                        if (this.descriptionCost) {
                            this.descriptionCost =
                                this.descriptionCost + ',' + i.name
                        } else {
                            this.descriptionCost = i.name
                        }
                    }
                })
                this.cost.descriptionCost = this.descriptionCost
                this.descriptionCost = null
                if (this.costs) {
                    const indexCost = this.costs.findIndex(
                        element => element.id == this.cost.id
                    )

                    if (indexCost >= 0) {
                        this.costs.splice(indexCost, 1, this.cost)
                    }
                }
                this.quoteChangesAction(false)
                if (this.alternative) {
                    await this.$emit(
                        'saveAlternative',
                        this.cost,
                        this.localFiles
                    )
                } else if (this.editAlternative) {
                    await this.$emit('updateAlternativeCosts')
                } else {
                    if (this.createCostForm) {
                        await this.$emit('createCosts', this.localFiles)
                    } else {
                        await this.$emit('updateCosts', this.cost)
                    }
                }
                this.totalMargin = 0
                this.totalCommission = 0
                this.sendToEngineering = false
                this.returnFormEngineering = false
                this.dialogSave = false
            } catch (error) {
                console.error(error)
            }
        },
        saveSectionItems(sectionName, sectionItems) {
            if (sectionName === 'Engineering & Desing') return
            const section = { name: sectionName, items: sectionItems }
            const indexSection = this.cost.sections.findIndex(
                element => element.name == sectionName
            )
            if (indexSection >= 0) {
                this.cost.sections.splice(indexSection, 1, section)
            } else {
                this.cost.sections.push(section)
            }
            this.cost.totalSections = this.totalSections
        },
        convertUnits(quantity) {
            if (this.cost.measure == 'qt' || this.cost.measure == 'QT') {
                this.isOperable = false
                this.cost.quantity2 = quantity
                this.quantityInternational = quantity
            } else if (
                this.settingUnits.measureUnits.find(
                    unit =>
                        unit.abbr == this.cost.measure && unit.measure == 'time'
                )
            ) {
                this.isOperable = false
                this.cost.quantity2 = quantity
                this.quantityInternational = quantity
            } else if (
                this.settingUnits.measureUnits.find(
                    unit =>
                        unit.abbr == this.cost.measure && unit.measure == 'mass'
                )
            ) {
                this.isOperable = false
                this.cost.quantity2 = quantity
                this.quantityInternational = quantity
            } else if (this.cost.measure == 'yd2') {
                this.isOperable = true
                this.cost.quantity2 = parseFloat(
                    (quantity / 0.111111).toFixed(2)
                )
                this.quantityInternational = parseFloat(
                    (quantity / 1.19599).toFixed(2)
                )
            } else if (
                this.settingUnits.measureUnits.find(unit => {
                    if (
                        unit.abbr == this.cost.measure &&
                        unit.measure == 'length'
                    ) {
                        this.isOperable = true
                        this.isLength = true
                        return true
                    }
                })
            ) {
                this.cost.quantity2 = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('ft')
                )
                this.quantityInternational = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('m')
                )
            } else {
                this.isOperable = true
                this.isLength = false
                this.cost.quantity2 = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('ft2')
                )
                this.quantityInternational = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('m2')
                )
            }
        },
        closeEngineeringDialog() {
            this.sendToEngineering = !this.engineerig
            this.engineeringDialog = false
        },
        setForEngineering() {
            if (this.sendToEngineering == true) {
                this.cost.engineering = this.sendToEngineering
                this.engineeringDateDialog = true
            } else {
                this.cost.engineering = this.originalCost.engineering
                this.cost.engineeringDate = this.originalCost.engineeringDate
                this.engineeringDate = this.originalCost.engineeringDate
                this.loadData()
            }
        },
        unSetForEngineering() {
            if (this.returnFromEngineering == true) {
                this.cost.engineering = !this.returnFromEngineering
            } else {
                this.cost.engineering = this.originalCost.engineering
            }

            this.cost = { ...this.cost }
        },
        setEngDueDate() {
            this.cost.engineeringDate = this.engineeringDate
            this.cost = { ...this.cost }
            this.engineeringDateDialog = false
        },
        openEngDueDateDialog() {
            this.engineeringDateDialog = true
        },

        closeEngDueDateDialogBtn() {
            if (this.cost.engineerig == this.originalCost.engineerig) {
                this.cost.engineeringDate =
                    this.originalCost.engineeringDate &&
                    this.originalCost.engineeringDate._seconds
                        ? this.formatDate(
                              this.originalCost.engineeringDate._seconds
                          )
                        : this.originalCost.engineeringDate
                        ? this.originalCost.engineeringDate
                        : ''

                this.engineeringDate =
                    this.originalCost.engineeringDate &&
                    this.originalCost.engineeringDate._seconds
                        ? this.formatDate(
                              this.originalCost.engineeringDate._seconds
                          )
                        : this.originalCost.engineeringDate
                        ? this.originalCost.engineeringDate
                        : ''
                this.sendToEngineering = !this.sendToEngineering
            }
            this.engineeringDateDialog = false
        },

        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        updateCost() {
            this.$emit('updateCost', this.cost)
        },
        loadData() {
            this.engineeringDate = this.originalCost.engineeringDate?._seconds
                ? this.formatDate(this.originalCost.engineeringDate._seconds)
                : this.originalCost.engineeringDate
                ? this.originalCost.engineeringDate
                : ''
            this.minDate = new Date().toISOString()
        },
    },
}
</script>

<style scoped>
.no-border {
    border: none !important;
}
.v-textarea >>> textarea {
    line-height: 22px !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0;
}
</style>
