<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            NEW TASK
            <v-btn
                v-if="!createForm"
                depressed
                rounded
                color="primary"
                @click="archiveDialog = true"
                icon
                class="mx-2"
            >
                <v-icon>mdi-archive</v-icon>
            </v-btn>
            <v-dialog v-model="archiveDialog" persistent max-width="400px">
                <v-card :loading="loading">
                    <v-card-title class="text-h5">Archive Task</v-card-title>
                    <v-card-text>
                        Are you sure you want to archive this task?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            color="secondary"
                            @click="archiveDialog = false"
                        >
                            Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="archiveTask"
                            text
                            color="error"
                            :loading="loading"
                        >
                            Archive
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="moreDetails = !moreDetails">
                {{ moreDetails ? 'Less details' : 'More details' }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="task.description"
                            prepend-icon="mdi-book-open-outline"
                            label="Description *"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" v-if="moreDetails">
                        <v-menu
                            ref="menu1"
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="startDate"
                                    label="Start date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                @input="menuStartDate = false"
                                :min="minDate"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col :cols="moreDetails ? 6 : 12">
                        <v-menu
                            ref="menu2"
                            v-model="menuExpectedDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="expectedDate"
                                    label="Expected date *"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="expectedDate"
                                :min="minDate"
                                @input="menuExpectedDate = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="moreDetails ? 6 : 12">
                        <v-autocomplete
                            v-model="task.assignedTo"
                            :items="users"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-account-tie-outline"
                            label="Assign *"
                            required
                            :rules="[rules.required]"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.name }}
                                    </p>
                                    <h5 class="grey--text ma-0 ml-1 mt-1 pa-0">
                                        ({{ item.role }})
                                    </h5>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6" v-if="moreDetails">
                        <v-autocomplete
                            v-model="selectedProject"
                            :items="projects"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-office-building"
                            label="Project"
                            :disabled="fromCosts"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.name }}
                                    </p>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="moreDetails">
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="quote"
                            :items="quotes"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-content-paste"
                            label="Quote"
                            :readonly="fromCosts"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.name }}
                                    </p>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="task.cost"
                            :items="costs.costs"
                            item-text="reference"
                            item-value="id"
                            prepend-icon="mdi-account-cash-outline"
                            label="Cost"
                            :readonly="fromCosts"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.reference }}
                                    </p>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="moreDetails">
                    <v-col cols="12">
                        <v-text-field
                            v-model="task.notes"
                            prepend-icon="mdi-book-open-outline"
                            label="Notes"
                        />
                    </v-col>
                </v-row>
            </v-form>

            <small v-if="createForm">* indicates required field</small>

            <v-row class="my-2" v-if="createForm">
                <v-col cols="12">
                    <TaskTags
                        :task="task"
                        @replaceTask="replaceTask"
                        :createTask="createForm"
                    />
                </v-col>
            </v-row>

            <v-row class="my-2" v-if="createForm">
                <v-col cols="12">
                    <TaskChild :task="task" :children="children" />
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :loading="loading"
                @click="saveTask"
                :disabled="!valid"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import _ from 'lodash'
import { mapMutations, mapActions } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import TaskChild from '@/components/Tasks/TaskChild.vue'
import TaskTags from '@/components/Tasks/TaskTags.vue'
//import { mapActions } from 'vuex/dist/vuex.common.js'

export default {
    name: 'TaskForm',
    props: {
        task: Object,
        originalTask: Object,
        createForm: Boolean,
        projectId: String,
        quoteId: String,
        fromCosts: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        TaskChild,
        TaskTags,
    },
    data() {
        return {
            company: JSON.parse(localStorage.getItem('company')),
            rules: {
                required: v => !!v || 'Required',
                name: v =>
                    /^[a-zA-Z]+ [a-zA-Z]+$/.test(v) || 'Not a valid Name',
            },
            users: [],
            menuStartDate: false,
            menuExpectedDate: false,
            minDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            loading: false,
            moreDetails: false,
            valid: false,
            projects: [],
            quotes: [],
            costs: {
                costs: [],
            },
            selectedProject: undefined,
            quote: undefined,
            startDate: undefined,
            expectedDate: undefined,
            archiveDialog: false,
            children: [],
            prevAssigned: undefined,
        }
    },
    inject: {
        closeTaskDetails: {
            from: 'closeTaskDetails',
            default() {
                return null
            },
        },
    },
    async mounted() {
        try {
            this.loading = true
            // set tasks params
            if (this.task.description) {
                if (this.task.startDate) {
                    this.startDate = moment
                        .unix(
                            this.task.startDate.seconds ||
                                this.task.startDate._seconds
                        )
                        .format('YYYY-MM-DD')
                }
                this.expectedDate = moment
                    .unix(
                        this.task.expectedDate.seconds ||
                            this.task.expectedDate._seconds
                    )
                    .format('YYYY-MM-DD')
                this.prevAssigned = this.task.assignedTo
            }
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.projects = await API.getLiteProjects({ all: true })

            // retrieve quotes
            this.quotes = await API.getLiteQuotes()
            if (this.projectId || this.task.projectId) {
                this.selectedProject = this.projectId || this.task.projectId
            }
            if (!this.task.projectId && (this.task.quoteId || this.quoteId)) {
                this.quote = this.quoteId || this.task.quoteId
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        selectedProject: async function(value) {
            try {
                this.loading = true
                this.quotes = []
                this.costs = { costs: [] }
                const project = this.projects.find(p => p.id == value)
                const quotes = project.quotes.map(quote => {
                    return quote.split('/')[0]
                })
                for (let id of quotes) {
                    const quote = this.quotes.find(q => q.id == id)
                    if (quote) {
                        this.quotes.push(quote)
                    } else {
                        const {
                            data: { quotes },
                        } = await API.getQuotes({ quoteId: id })
                        this.quotes.push(quotes[0])
                    }
                }
                if (this.task.quoteId) {
                    this.quote = this.task.quoteId
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        quote: async function() {
            await this.getCosts()
            if (this.task.cost) {
                this.task.cost = this.task.cost.split('/')[0]
            }
        },
    },
    computed: {
        taskDiff: function() {
            if (!this.createForm) {
                return this.objDiff(this.originalTask, this.task)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['archiveTaskInfo']),
        async replaceTask(task) {
            Object.keys(task).forEach(key => {
                this.task[key] = task[key]
            })
        },
        async archiveTask() {
            try {
                this.loading = true
                const payload = {
                    taskId: this.task.id,
                    userId: this.task.assignedTo,
                }
                await this.archiveTaskInfo(payload)
                await API.updateTask(this.task.id, {
                    archive: true,
                    assignedTo: this.task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
                this.closeDialog()
                this.closeTaskDetails()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getCosts() {
            try {
                this.loading = true
                const {
                    data: { costs },
                } = await API.getCosts(this.quote)
                if (costs.length > 0) {
                    const index = costs.findIndex(c => !c.alternative)
                    this.costs = costs[index]
                } else {
                    this.costs = {}
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        objDiff(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0

                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        saveTask() {
            if (this.createForm) {
                this.createTask()
            } else {
                this.updateTask()
            }
        },
        async updateTask() {
            try {
                this.loading = true
                const taskToUpdate = {
                    description: this.task.description,
                    projectId: this.task.projectId,
                    quoteId: this.task.quoteId,
                    cost: this.task.cost,
                    assignedTo: this.task.assignedTo,
                    createdBy: this.task.createdBy,
                    expectedDate: this.task.expectedDate,
                    startDate: this.task.startDate,
                    notes: this.task.notes,
                    prevAssigned: this.prevAssigned,
                }
                if (this.selectedProject) {
                    const { id, reference } = this.projects.find(
                        p => p.id === this.selectedProject
                    )
                    taskToUpdate.projectId = id
                    taskToUpdate.projectReference = reference.toString()
                }
                if (this.quote) {
                    const { id, number } = this.quotes.find(
                        q => q.id === this.quote
                    )
                    taskToUpdate.quoteId = id
                    taskToUpdate.quoteNumber = parseInt(number)
                }
                if (this.task.cost) {
                    const { id, reference } = this.costs.costs.find(
                        cost => cost.id === this.task.cost
                    )
                    taskToUpdate.cost = id + '/' + reference
                }
                if (this.startDate) {
                    taskToUpdate.startDate = this.startDate
                }
                taskToUpdate.expectedDate = this.expectedDate
                const task = await API.updateTask(this.task.id, {
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                    ...taskToUpdate,
                })
                this.$emit('replaceTask', task)
                this.closeDialog()
                if (
                    taskToUpdate.prevAssigned &&
                    taskToUpdate.prevAssigned != taskToUpdate.assignedTo
                ) {
                    this.$emit('closeTaskDetails')
                    this.$emit('deleteTask', task.id)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async createTask() {
            try {
                this.loading = true
                const newTask = { ...this.task }
                if (this.selectedProject) {
                    const { id, reference } = this.projects.find(
                        p => p.id === this.selectedProject
                    )
                    newTask.projectId = id
                    newTask.projectReference = reference.toString()
                }
                if (this.quote) {
                    const { id, number } = this.quotes.find(
                        q => q.id === this.quote
                    )
                    newTask.quoteId = id
                    newTask.quoteNumber = parseInt(number)
                }
                if (this.task.cost) {
                    const { id, reference } = this.costs.costs.find(
                        cost => cost.id === this.task.cost
                    )
                    newTask.cost = id + '/' + reference
                }
                if (this.startDate) {
                    newTask.startDate = this.startDate
                }
                newTask.expectedDate = this.expectedDate
                if (this.children.length > 0) {
                    newTask.childs = this.children
                }
                const resTask = await API.createTask({
                    notificationId: 'spnIVZA9IB6tsWj8OD46',
                    ...newTask,
                })
                this.$emit('addTask', resTask)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
