var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{ref:"menu",staticClass:"border-right d-flex flex-column flex-nowrap",style:({ height: `${_vm.height}px` }),attrs:{"cols":"12","md":"3","sm":"12","lg":"3"}},[(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center flex-grow-1"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_c('v-list',{staticClass:"overflow-y-auto pa-0"},[(_vm.settings.length > 0)?[_c('v-list-item-group',_vm._l((_vm.settings),function(setting,i){return _c('v-list-item',{key:i,class:{
                            'border-bottom': i < _vm.settings.length - 1,
                        },attrs:{"color":"primary","three-line":"","link":"","to":{
                            name:
                                setting.nameRoute ||
                                setting.name.toLowerCase(),
                            params: { setting: setting },
                        }},on:{"click":_vm.hide}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mt-3"},[_vm._v(_vm._s(setting.name))]),_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-icon',{staticClass:"mt-2 mb-0"},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-list-item-subtitle',{staticClass:"mt-0"},[_vm._v(" Settings ")])],1)],1)],1)}),1)]:_vm._e()],2)],1),_c('v-col',{staticClass:"overflow-y-auto",style:({ height: `${_vm.height}px` }),attrs:{"sm":"0","cols":"12","md":"9","lg":"9"}},[_c('router-view',{on:{"show":_vm.show}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }