<template>
    <div>
        <!-- User details -->
        <v-row v-if="user" no-gutters class="pb-2">
            <v-col cols="3" class="d-flex align-center">
                <span>Role</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ user.role }}</span>
            </v-col>
        </v-row>
        <v-row v-if="user" no-gutters class="pb-2">
            <v-col cols="3" class="d-flex align-center">
                <span>Email</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ user.email }}</span>
            </v-col>
        </v-row>
        <v-row v-if="user" no-gutters class="pb-2">
            <v-col cols="3" class="d-flex align-center">
                <span>Phone</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ user.phone }}</span>
            </v-col>
        </v-row>
        <v-row v-if="user" no-gutters class="pb-2">
            <v-col cols="3" class="d-flex align-center">
                <span>Alternative Phone</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ user.phone2 }}</span>
            </v-col>
        </v-row>

        <v-row v-if="user" no-gutters>
            <v-col cols="3" class="d-flex align-center">
                <span>Language</span>
            </v-col>
            <v-col class="d-flex align-center">
                <v-avatar size="24" class="me-2 my-1">
                    <v-img
                        :src="
                            user.language &&
                            user.language.toLowerCase() === 'english'
                                ? require('@/assets/usa_flag.png')
                                : require('@/assets/col_flag.png')
                        "
                    />
                </v-avatar>
            </v-col>
        </v-row>
        <v-row no-gutters :disabled="!createUsersPermission" class="mt-2">
            <v-btn
                color="red"
                class="white--text"
                @click="resetPassword"
                :loading="loading"
                >RESET PASSWORD</v-btn
            >
        </v-row>
        <v-divider class="my-4" />
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', left: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { auth } from '@/services/firebase'

export default {
    name: 'UserGeneralInfo',
    props: {
        id: String,
        user: Object,
    },
    data() {
        return {
            loading: false,
            createUsersPermission: false,
            activateAlert: false,
            alertMessage: '',
        }
    },
    mounted() {
        this.createUsersPermission = this.user.permission.includes(
            'createUsers'
        )
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async resetPassword() {
            try {
                this.loading = true
                await auth().sendPasswordResetEmail(this.user.email)
                this.alertMessage =
                    'A password reset link has been sent to the user.'
                this.activateAlert = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
</style>
