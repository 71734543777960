<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            v-if="!!localSetting"
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab
                :to="{
                    name: 'lead-notifications-reject',
                    params: {
                        setting: localSetting,
                        type: 'reject',
                    },
                }"
            >
                REJECT
            </v-tab>
            <v-tab
                :to="{
                    name: 'lead-notifications-bidding',
                    params: {
                        setting: localSetting,
                        type: 'bidding',
                    },
                }"
            >
                BIDDING
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="`height: ${height}px`"
            >
                <router-view :key="$route.fullPath" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'LeadNotifications',
    props: {
        setting: { type: Object, required: true },
    },
    data: () => ({
        loading: true,
        height: 0,
        localSetting: {},
    }),
    mounted() {
        this.localSetting = _.cloneDeep(this.setting)
        this.onResize()
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - tabsHeight - 104
        },
    },
}
</script>

<style></style>
