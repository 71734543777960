import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueTelInput from './plugins/vueTelInput'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import vuetify from './plugins/vuetify'
import { auth } from './services/firebase'
import viewer from './plugins/viewer'
import device from 'vue-device-detector'
import DatetimePicker from 'vuetify-datetime-picker'
import LazyComponent from 'v-lazy-component'
import VueQuillEditor from 'vue-quill-editor'
import 'quill-paste-smart'
import { analytics } from './services/firebase'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// detect if you are loading from an iframe
if (window.self !== window.top) {
    Vue.prototype.$isIframe = true // Indicador para el resto de la app
} else {
    Vue.prototype.$isIframe = false
}

Vue.use(LazyComponent)

Vue.use(device)

Vue.config.productionTip = false

Vue.config.errorHandler = function(error) {
    //Toast.error(error.message)
    console.warn(error.message)
    //send error as event to google analytcs...
    if (error) {
        let message = error.stack
        analytics.logEvent('vue_error', {
            error_source: 'Vue.config.errorHandler',
            message,
        })
    }
}

window.onerror = function(message, source, lineno, colno, error) {
    // maybe we need to also catch errors here and send to GA
    analytics.logEvent('window_error', {
        error_source: source,
        message,
        lineno,
        colno,
        error,
    })
}

Vue.use(VueTelInputVuetify, {
    vuetify,
})
Vue.use(DatetimePicker)
Vue.use(VueQuillEditor)

let app

auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            viewer,
            vueTelInput,
            DatetimePicker,
            LazyComponent,
            VueQuillEditor,
            render: h => h(App),
        }).$mount('#app')
    }
})
