<template>
    <div>
        <div v-if="!$isIframe">
            <router-view />
        </div>
        <div v-else>
            <!-- Mensaje de advertencia -->
            <h1 style="color: red; text-align: center; margin-top: 20%;">
                Access denied. This page cannot be loaded in an iframe.
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        if (this.$isIframe) {
            setTimeout(() => {
                window.top.location = window.location
            }, 3000)
        }
    },
    data: () => ({
        userId: JSON.parse(localStorage.getItem('userId')),
    }),
    computed: {
        isAllowedToRender() {
            // Check if the current URL and origin are allowed
            const currentOrigin = window.location.origin
            const expectedOrigin = process.env.VUE_APP_CLIENT_HOST
            return currentOrigin === expectedOrigin
        },
    },
}
</script>
