<template>
    <div style="max-width: 300px">
        <a
            class="my-0"
            target="_blank"
            :style="{ 'text-decoration': 'none' }"
            :href="pdfFile"
            ><pdf :src="pdfFile"></pdf
        ></a>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
    name: 'PdfThumbnail',
    props: {
        pdfFile: {
            type: String, // La URL del archivo PDF
            required: true,
        },
    },
    components: {
        pdf,
    },
    data: () => ({}),
}
</script>

<style scoped>
canvas {
    border: 1px solid #000;
}
</style>
