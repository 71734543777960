<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="codesFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">NC Codes</h1>
                        <v-btn
                            rounded
                            color="primary"
                            @click="openCreateCode"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByCode"
                    dense
                />
            </template>
            <template v-slot:[`header.type`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByType"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openEditCode(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small icon @click="openDeleteCode(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--CODE FORM-->
        <v-dialog
            v-model="openCodeForm"
            :retain-focus="false"
            persistent
            width="300px"
        >
            <NCCodesForm
                v-if="openCodeForm"
                :createForm="createForm"
                :ncCode="selectedCode"
                :originalNCCode="originalCode"
                :codeSettings="setting.quality"
                @addCode="addCode"
                @replaceCode="replaceCode"
                @close="closeEditCode"
            />
        </v-dialog>
        <!--Delete Code-->
        <v-dialog
            v-model="openDeleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openDeleteWarning">
                <v-card-title class="text-h5">Delete NC Code</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this NC code?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteCode">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteCode"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'NCCodes',
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        NCCodesForm: () => import('@/components/Quality/NCCodesForm.vue'),
    },
    data: () => ({
        loading: false,
        codes: [],
        selectedCode: {},
        originalCode: {},
        createForm: true,
        openCodeForm: false,
        openDeleteWarning: false,
        codeSettings: {},
        headers: [
            {
                text: 'CODE',
                value: 'code',
                sortable: false,
                width: '200',
            },
            {
                text: 'TYPE',
                value: 'type',
                sortable: false,
                width: '200',
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        searchByCode: undefined,
        searchByType: undefined,
        settingId: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            this.settingsId = this.setting.quality.id
            this.codes = await API.getNCCodes(this.settingsId)
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        codesFiltered() {
            const conditions = []
            if (this.searchByCode) {
                conditions.push(this.filterByCode)
            }
            if (this.searchByType) {
                conditions.push(this.filterByType)
            }
            if (conditions.length > 0) {
                return this.codes.filter(code => {
                    return conditions.every(condition => {
                        return condition(code)
                    })
                })
            }
            return this.codes
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByCode(item) {
            return item.code
                .toLowerCase()
                .includes(this.searchByCode.toLowerCase())
        },
        filterByType(item) {
            return item.type
                .toLowerCase()
                .includes(this.searchByType.toLowerCase())
        },
        replaceCode(codeToUpdate) {
            const index = this.codes.findIndex(
                code => code.id == codeToUpdate.id
            )
            if (index >= 0) {
                this.codes.splice(index, 1, {
                    ...this.codes[index],
                    ...codeToUpdate,
                })
            }
        },
        addCode(code) {
            this.codes.push(code)
            this.searchByCode = ''
        },
        removeCode() {
            const index = this.codes.findIndex(
                code => code.id == this.selectedCode.id
            )
            if (index >= 0) {
                this.codes.splice(index, 1)
            }
        },
        async deleteCode() {
            try {
                this.loading = true
                await API.deleteNCCode({
                    settingId: this.settingsId,
                    id: this.selectedCode.id,
                })
                this.removeCode()
                this.closeDeleteCode()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteCode() {
            this.selectedCode = {}
            this.openDeleteWarning = false
        },
        openDeleteCode(item) {
            this.selectedCode = _.cloneDeep(item)
            this.openDeleteWarning = true
        },
        closeEditCode() {
            this.createForm = true
            this.selectedCode = {}
            this.originalCode = {}
            this.openCodeForm = false
        },
        openEditCode(item) {
            this.createForm = false
            this.selectedCode = _.cloneDeep(item)
            this.originalCode = _.cloneDeep(item)
            this.openCodeForm = true
        },
        openCreateCode() {
            this.createForm = true
            this.openCodeForm = true
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
</style>
