<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-card :loading="loading" flat>
            <v-row>
                <v-col>
                    <v-expansion-panels flat class="mb-4">
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                color="grey lighten-3"
                                expand-icon="mdi-plus"
                                class="pa-2"
                                :style="{ 'min-height': 'unset' }"
                            >
                                <template>
                                    <v-row
                                        class="d-flex align-center justify-start"
                                        no-gutters
                                    >
                                        MASS
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="p-0">
                                <v-card class="elevation-0">
                                    <v-data-table
                                        v-if="setting"
                                        dense
                                        @toggle-select-all="saveButton"
                                        @item-selected="saveButton"
                                        show-select
                                        :single-select="false"
                                        v-model="setting.measureUnits"
                                        item-key="abbr"
                                        class="elevation-0 mt-2"
                                        :items="mass"
                                        hide-default-footer
                                        :headers="header"
                                        :mobile-breakpoint="0"
                                    >
                                    </v-data-table>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col>
                    <v-expansion-panels flat class="mb-4">
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                color="grey lighten-3"
                                expand-icon="mdi-plus"
                                class="pa-2"
                                :style="{ 'min-height': 'unset' }"
                            >
                                <template>
                                    <v-row
                                        class="d-flex align-center justify-start"
                                        no-gutters
                                    >
                                        VOLUME
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="p-0">
                                <v-card class="elevation-0">
                                    <v-data-table
                                        v-if="setting"
                                        dense
                                        @toggle-select-all="saveButton"
                                        @item-selected="saveButton"
                                        show-select
                                        :single-select="false"
                                        v-model="setting.measureUnits"
                                        item-key="abbr"
                                        class="elevation-0 mt-2"
                                        :items="volume"
                                        hide-default-footer
                                        :headers="header"
                                        :mobile-breakpoint="0"
                                    >
                                    </v-data-table>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-expansion-panels flat class="mb-4">
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                color="grey lighten-3"
                                expand-icon="mdi-plus"
                                class="pa-2"
                                :style="{ 'min-height': 'unset' }"
                            >
                                <template>
                                    <v-row
                                        class="d-flex align-center justify-start"
                                        no-gutters
                                    >
                                        LENGTH
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="p-0">
                                <v-card class="elevation-0">
                                    <v-data-table
                                        v-if="setting"
                                        dense
                                        @toggle-select-all="saveButton"
                                        @item-selected="saveButton"
                                        show-select
                                        :single-select="false"
                                        v-model="setting.measureUnits"
                                        item-key="abbr"
                                        class="elevation-0 mt-2"
                                        :items="length"
                                        hide-default-footer
                                        :headers="header"
                                        :mobile-breakpoint="0"
                                    >
                                    </v-data-table>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col>
                    <v-expansion-panels flat class="mb-4">
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                color="grey lighten-3"
                                expand-icon="mdi-plus"
                                class="pa-2"
                                :style="{ 'min-height': 'unset' }"
                            >
                                <template>
                                    <v-row
                                        class="d-flex align-center justify-start"
                                        no-gutters
                                    >
                                        TIME
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="p-0">
                                <v-card class="elevation-0">
                                    <v-data-table
                                        v-if="setting"
                                        dense
                                        @toggle-select-all="saveButton"
                                        @item-selected="saveButton"
                                        show-select
                                        :single-select="false"
                                        v-model="setting.measureUnits"
                                        item-key="abbr"
                                        class="elevation-0 mt-2"
                                        :items="time"
                                        hide-default-footer
                                        :headers="header"
                                        :mobile-breakpoint="0"
                                    >
                                    </v-data-table>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-expansion-panels flat class="mb-4">
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                color="grey lighten-3"
                                expand-icon="mdi-plus"
                                class="pa-2"
                                :style="{ 'min-height': 'unset' }"
                            >
                                <template>
                                    <v-row
                                        class="d-flex align-center justify-start"
                                        no-gutters
                                    >
                                        AREA
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="p-0">
                                <v-card class="elevation-0">
                                    <v-data-table
                                        v-if="setting"
                                        dense
                                        @toggle-select-all="saveButton"
                                        @item-selected="saveButton"
                                        show-select
                                        :single-select="false"
                                        v-model="setting.measureUnits"
                                        item-key="abbr"
                                        class="elevation-0 mt-2"
                                        :items="area"
                                        hide-default-footer
                                        :headers="header"
                                        :mobile-breakpoint="0"
                                    >
                                    </v-data-table>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        setting: Object,
        commercialTerms: Array,
        pdfImages: Object,
    },
    name: 'SettingsUnits',
    data() {
        return {
            units: [],
            mass: [],
            area: [],
            time: [],
            length: [],
            selected: [],
            loading: false,
            volume: [],
            header: [
                { text: 'NAME', value: 'singular', class: 'grey lighten-3' },
                { text: 'ABBR', value: 'abbr', class: 'grey lighten-3' },
                { value: 'data-table-select', class: 'grey lighten-3' },
            ],
        }
    },
    computed: {
        ...mapState(['saveBtn']),
    },
    methods: {
        ...mapActions(['quoteChangesAction']),
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        onResize() {
            this.height = window.innerHeight - this.$vuetify.application.top
        },
        saveButton() {
            this.quoteChangesAction(false)
        },
    },
    created() {
        var convert = require('convert-units')
        this.mass = convert().list('mass')
        this.mass.push({
            abbr: 'QT',
            measure: 'mass',
            plural: 'quantities',
            singular: 'quantity',
            system: 'metric',
        })
        this.volume = convert().list('volume')
        this.time = convert().list('time')
        this.length = convert().list('length')
        this.area = convert().list('area')
        if (this.setting) {
            if (!this.setting.measureUnits) {
                this.setting.measureUnits = []
            }
        }
    },
}
</script>

<style scoped>
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0;
}
</style>
