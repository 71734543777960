<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            v-model="tab"
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab v-for="(item, index) in items" :key="index">
                {{ item.title }}
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title class="py-3" ref="title">
                <v-row>
                    <v-col
                        v-if="
                            $vuetify.breakpoint.smAndDown &&
                                $router.currentRoute.name === 'quoteFollowUp'
                        "
                        class="ml-n2 mr-1 mt-n1"
                        cols="1"
                    >
                        <v-btn small fab depressed @click.stop="show" icon>
                            <v-icon>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.smAndDown ? 11 : 12"
                        class="d-flex"
                    >
                        <span v-if="quote">{{ quote.name }}</span>
                        <v-btn
                            small
                            icon
                            class="ml-2"
                            @click.stop="OpenTheTitleUpdate = true"
                            v-if="
                                (quote.status === 'OPEN' ||
                                    quote.status === 'APPROVAL') &&
                                    !quote.archive &&
                                    !history
                            "
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-spacer />
                <v-menu
                    v-if="quoteDetails && seeMenu && !quote.archive && !history"
                    rounded
                    offset-y
                >
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            @click.stop="openPdfPreview"
                            :disabled="loading"
                        >
                            <v-list-item-title>
                                Preview Quote
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click.stop="openDialog"
                            :disabled="!sendQuote"
                            v-if="quote.status === 'APPROVAL'"
                        >
                            <v-list-item-title>
                                Send Quote
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click.stop="approveQuote"
                            v-if="quote.status === 'OPEN'"
                        >
                            <v-list-item-title>
                                For Approval
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="quote.status == 'SENT'"
                            @click.stop="modifyQuote"
                        >
                            <v-list-item-title>
                                Modify
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="quote.status == 'SENT'"
                            @click.stop="awarded"
                        >
                            <v-list-item-title>
                                Awarded
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="quote.status == 'SENT'"
                            @click.stop="rejectedQuote"
                        >
                            <v-list-item-title>
                                Lost
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.stop="archive = true">
                            <v-dialog
                                v-model="archive"
                                persistent
                                max-width="400px"
                                :retain-focus="false"
                            >
                                <v-card :loading="loading">
                                    <v-card-title class="text-h5"
                                        >Archive Quote</v-card-title
                                    >
                                    <v-card-text>
                                        Are you sure you want to archive this
                                        quote?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            text
                                            color="secondary"
                                            @click="archive = false"
                                        >
                                            Close
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="archiveFromQuote"
                                            text
                                            color="error"
                                            :loading="loading"
                                        >
                                            Archive
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-list-item-title>
                                Archive Quote
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                    v-if="
                        (quote.status === 'OPEN' ||
                            quote.status === 'APPROVAL') &&
                            !quote.archive &&
                            !history &&
                            validateTab
                    "
                    @click="updateQuote"
                    class="mx-1"
                    depressed
                    rounded
                    :loading="loading"
                    color="success"
                    >Save</v-btn
                >
            </v-card-title>
            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>
            <v-card-text
                class="overflow-y-auto"
                :style="{ height: `${height}px` }"
            >
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item v-for="(item, index) in items" :key="index">
                        <item.component
                            v-model="tab"
                            :is="item.component"
                            v-if="tab === index"
                            :id="id"
                            :quoteId="id"
                            :quote="newQuote"
                            :settings="settings"
                            :costVersions="costVersions"
                            :costs="costs"
                            :history="history"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <v-alert
            :value="successEmail"
            :style="{ position: 'fixed', bottom: '30px', right: 0 }"
            color="blue"
            dismissible
            type="success"
            transition="scale-transition"
        >
            Quote was successfully sent
        </v-alert>
        <v-dialog
            :retain-focus="false"
            v-model="OpenTheTitleUpdate"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Update Name</v-card-title>
                <v-card-text>
                    <v-form ref="nameQuote">
                        <v-text-field
                            v-model="quote.name"
                            :rules="[rules.required]"
                            prepend-icon="mdi-file-document-edit"
                            label="Name *"
                            required
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="OpenTheTitleUpdate = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        @click="updateQuote"
                        text
                        color="error"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Errors />
        <SendQuote
            v-if="dialogSend"
            :quote="newQuote"
            :client="newQuote ? newQuote.client : ''"
            :costs="costs"
            :commercialTermsToPDF="commercialTermsToPDF"
            @closeDialog="closeDialog"
            v-model="dialogSend"
            @openFilePDF="openFile"
            @saveCostHistory="saveCostHistory"
            @activateSuccessAlert="activateSuccessAlert"
            @getHistoryCosts="getHistoryCosts"
        />
    </div>
</template>

<script>
import SendQuote from '@/components/Quotes/SendQuote'
import { storage } from '@/services/firebase'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import moment from 'moment'
import Errors from '@/components/Layout/Errors.vue'
import QuoteGeneralInfo from '@/views/Quotes/QuoteGeneralInfo.vue'
import QuoteCommercialTerms from '@/views/Quotes/QuoteCommercialTerms.vue'
import QuoteCosts from '@/views/Quotes/QuoteCosts.vue'
import QuoteChangelog from '@/views/Quotes/QuoteChangelog.vue'
import AssignBoard from '@/views/Tasks/Assign.vue'
import QuoteFollowUp from '@/views/Quotes/QuoteFollowUp.vue'
import QuoteLogs from '@/views/Quotes/QuoteLogs.vue'

export default {
    name: 'Quote',
    components: {
        SendQuote,
        Errors,
    },
    props: {
        id: String,
        quote: {
            type: Object,
            default() {
                return {
                    client: '',
                }
            },
        },
        settings: Array,
        followUp: {
            type: Boolean,
            default: () => false,
        },
        history: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            validateTab: true,
            tab: null,
            height: 0,
            loading: false,
            error: null,
            costs: null,
            costVersions: null,
            dialogSend: false,
            deleteError: false,
            deleteDialog: false,
            quoteDetails: true,
            newQuote: {},
            errorMsg: null,
            loadingArchive: false,
            archive: false,
            archiveError: false,
            quoteToArchive: {},
            user: JSON.parse(localStorage.getItem('userId')),
            companyId: JSON.parse(localStorage.getItem('company')),
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            sendQuote: false,
            validateMenu: true,
            generatePdf: false,
            commercialTermsToPDF: null,
            idSettingQuote: null,
            notShow: false,
            url: null,
            file: null,
            deleteQuotes: false,
            successEmail: false,
            globalCurrency: 1,
            quantityInternational: 0,
            quoteAux: null,
            OpenTheTitleUpdate: false,
            rules: {
                required: v => !!v || 'Required',
            },
            folderPdfImage: 'pdf_images',
            oldQuote: {},
            folderDocuments: 'quotes_documents',
            items: [],
        }
    },
    inject: {
        replaceQuote: {
            from: 'replaceQuote',
            default: () =>
                function() {
                    return null
                },
        },
        closeSendQuote: {
            from: 'closeSendQuote',
            default: () =>
                function() {
                    return null
                },
        },
        archiveQuote: {
            from: 'archiveQuote',
            default: () =>
                function() {
                    return null
                },
        },
        backToMainRoute: {
            from: 'backToMainRoute',
            default: () =>
                function() {
                    return null
                },
        },
        addQuoteForApproval: {
            from: 'addQuoteForApproval',
            default: () =>
                function() {
                    return null
                },
        },
        reOpenQuote: {
            from: 'reOpenQuote',
            default: () =>
                function() {
                    return null
                },
        },
        modificationRequestProcess: {
            from: 'modificationRequestProcess',
            default: () =>
                function() {
                    return null
                },
        },
        rejectedDialog: {
            from: 'rejectedDialog',
            default: () =>
                function() {
                    return null
                },
        },
        quoteAwarded: {
            from: 'quoteAwarded',
            default: () =>
                function() {
                    return null
                },
        },
        spreadCosts: {
            from: 'spreadCosts',
            default: null,
        },
    },
    async created() {
        try {
            this.loading = true
            const {
                data: { quotes: quote },
            } = await API.getQuote(this.id)
            quote.date = moment
                .unix(quote.createdOn._seconds || quote.createdOn.seconds)
                .format('MMM/DD/YYYY')
            quote.createdOn = moment
                .unix(quote.createdOn._seconds || quote.createdOn.seconds)
                .format('MMM/DD/YYYY h:mm a')

            quote.lastUpdate = quote.updatedOn
            if (quote.updatedOn) {
                quote.updatedOn = moment
                    .unix(quote.updatedOn._seconds || quote.updatedOn.seconds)
                    .format('MMM/DD/YYYY h:mm a')
            }
            this.newQuote = quote
            this.replaceQuote(quote.status, quote)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    provide() {
        return {
            replaceQuoteLocal: this.replaceQuoteLocal,
            spreadCosts: this.spreadCostsLocal,
        }
    },
    beforeUpdate() {
        if (!this.quote && this.id) {
            const quote = this.quotes.find(i => i.id == this.id)
            this.newQuote = quote
        }
    },
    updated() {
        this.onResize()
    },
    async mounted() {
        try {
            if (!this.quote.collaborators) {
                this.quote.collaborators = []
            }
            this.oldQuote = _.cloneDeep(this.quote)
            this.newQuote = this.quote
            // get costs
            await this.getCosts()
            await this.getHistoryCosts()
            // set permissions
            const {
                data: { user },
            } = await API.getMyInfo()
            this.quoteDetails = user.permissions.includes('quoteDetails')
            const sendQuote = user.permissions.find(x => x == 'sendQuote')
            const deleteQuotes = user.permissions.find(x => x == 'deleteQuotes')
            // validate permissions
            if (sendQuote) {
                this.sendQuote = true
            }
            if (deleteQuotes) {
                this.deleteQuotes = true
            }
            // set tab headers
            this.items.push({
                title: 'General Information',
                component: QuoteGeneralInfo,
            })
            if (this.quoteDetails) {
                this.items.push({
                    title: 'Commercial Terms',
                    component: QuoteCommercialTerms,
                })
                this.items.push({ title: 'Costs', component: QuoteCosts })
            }
            this.items.push({ title: 'Versions', component: QuoteChangelog })
            this.items.push({ title: 'Tasks', component: AssignBoard })
            this.items.push({ title: 'Follow Up', component: QuoteFollowUp })
            this.items.push({ title: 'Logs', component: QuoteLogs })
            if (this.followUp) {
                this.tab = 5
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    watch: {
        id: async function(newId) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (newId) {
                    this.newQuote = this.quote
                    this.newQuote.attached = this.quote.files.filter(
                        x => x.attach == true
                    )
                    this.newQuote.docs = this.quote.files.filter(
                        x => x.attach == false
                    )
                    await this.getCosts()
                    await this.getHistoryCosts()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        'newQuote.clientId': function() {
            if (this.newQuote && this.newQuote.clientId) {
                const indexExchanges = this.settings.findIndex(
                    u => u.name === 'Exchange'
                )
                this.globalCurrency = this.settings[
                    indexExchanges
                ].globalCurrency
                if (
                    this.newQuote.oldClient &&
                    this.newQuote.oldClient.currency !==
                        this.newQuote.client.currency
                ) {
                    this.convertCost()
                }
            }
        },
        'newQuote.image': function() {
            this.quote.image = this.newQuote.image
        },
        'newQuote.country': function() {
            this.quote.country = this.newQuote.country
        },
        'newQuote.state': function() {
            this.quote.state = this.newQuote.state
        },
        'newQuote.city': function() {
            this.quote.city = this.newQuote.city
        },
        'newQuote.notes': function() {
            this.quote.notes = this.newQuote.notes
        },
        tab() {
            if (this.tab != null) {
                this.validateTab =
                    this.items[this.tab].title == 'General Information' ||
                    this.items[this.tab].title == 'Commercial Terms'
            }
        },
    },
    computed: {
        ...mapState([
            'saveBtn',
            'quotes',
            'costQuotes',
            'historyCosts',
            'seeMenu',
            'historyQuotes',
            'fromMainBoard',
            'commercialTerms',
        ]),
        ...mapGetters(['getAlternatives']),
        filterItems() {
            if (!this.quoteDetails) {
                if (!this.loading) {
                    return this.items.filter(
                        item =>
                            item.title != 'Commercial Terms' &&
                            item.title != 'Costs'
                    )
                }
            }
            if (!this.loading) {
                return this.items
            }
            return []
        },
    },
    methods: {
        awarded() {
            const copyCost = _.cloneDeep(this.quote)
            this.quoteAwarded(copyCost)
            this.backToMainRoute()
        },
        rejectedQuote() {
            const copyCost = _.cloneDeep(this.quote)
            this.rejectedDialog(copyCost)
            this.backToMainRoute()
        },
        async modifyQuote() {
            try {
                this.loading = true
                this.error = false
                await this.modificationRequestProcess(this.quote)
                this.backToMainRoute()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async archiveFromQuote() {
            try {
                this.loading = true
                this.error = false
                await this.archiveQuote(this.quote)
                this.backToMainRoute()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        ...mapActions([
            'replaceQuoteAction',
            'quoteChangesAction',
            'removeQuoteAction',
            'addCostAction',
            'addHistoryCostAction',
            'updateQuoteAction',
            'addQuoteAction',
            'updateSeeMenuAction',
            'addTermsAction',
        ]),
        ...mapMutations(['setAlternatives', 'setErrorItems']),
        approveQuote: async function() {
            this.quote.status = 'APPROVAL'
            await this.updateQuote()
        },
        updateQuote: async function() {
            try {
                this.loading = true
                const { id } = this.quote
                this.quote.commercialTerms = this.newQuote.commercialTerms
                if (!this.quote.collaborators) {
                    this.quote.collaborators = []
                    this.quote.collaborators.splice(0, 0, this.quote.userId)
                } else if (
                    !this.quote.collaborators.includes(this.quote.userId)
                ) {
                    this.quote.collaborators.splice(0, 0, this.quote.userId)
                }
                let changes = {}
                Object.keys(this.quote).forEach(key => {
                    if (
                        key != 'clients' &&
                        key !== 'client' &&
                        ((this.oldQuote[key] === undefined &&
                            this.quote[key] !== undefined) ||
                            (this.quote[key] !== undefined &&
                                JSON.stringify(this.quote[key]) !==
                                    JSON.stringify(this.oldQuote[key])))
                    ) {
                        changes[key] =
                            key === 'city'
                                ? this.quote[key].text
                                : this.quote[key]
                    }
                })
                if (Object.keys(changes).length > 0) {
                    const {
                        data: { quote },
                    } = await API.updateQuote(id, {
                        notificationId: 'LrubMkCSfpNwEoXemQKL',
                        ...changes,
                    })
                    this.oldQuote = Object.assign({}, this.quote)
                    quote.createdOn = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                    quote.updatedOn = moment
                        .unix(quote.updatedOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                    this.replaceQuoteLocal(id, Object.assign(this.quote, quote))
                    this.quoteChangesAction(true)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.OpenTheTitleUpdate = false
            }
        },
        spreadCostsLocal(costs) {
            if (this.$router.currentRoute.name === 'estimating-plan') {
                this.spreadCosts(costs)
            } else {
                this.costs = costs
            }
        },
        replaceQuoteLocal(id, quote) {
            this.replaceQuoteAction({ id, quote })
            this.newQuote = { ...this.newQuote, ...quote }
            this.quote = _.cloneDeep(this.newQuote)
            if (quote.status === 'APPROVAL') {
                this.addQuoteForApproval(quote)
            } else {
                this.replaceQuote({status: quote.status, quote})
            }
        },
        deleteDocument: async function(doc) {
            const path = `${this.companyId}/quotes_documents/${this.quote.id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete()
            return true
        },
        saveCostHistory: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                for (let file of this.quote.files) {
                    if (!file.versionQuote) {
                        file.versionQuote = false
                    }
                }
                //Save business exchange rate within the quote document
                const indexExchanges = this.settings.findIndex(
                    u => u.name === 'Exchange'
                )
                const { exchanges, globalCurrency } = this.settings[
                    indexExchanges
                ]
                this.quote.costExchanges = {
                    exchanges,
                    globalCurrency,
                }
                //UPDATE QUOTE
                await this.updateQuote()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.createDialogNewCost = false
                this.editDialog = false
            }
        },
        getCosts: async function() {
            try {
                this.setAlternatives([])
                this.loading = true
                this.error = false
                this.errorMsg = null
                let costs = await API.getCosts(this.id)
                costs = costs.filter(cost => cost.type)
                this.costs = costs.filter(cost => cost.type == 'cost')
                this.setAlternatives(
                    costs.filter(cost => cost.type == 'alternate')
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getHistoryCosts: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { historyCosts },
                } = await API.getHistoryCosts(this.id)
                if (historyCosts.length > 0) {
                    historyCosts.forEach(cost => {
                        cost.createdOn = moment
                            .unix(cost.createdOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    })
                    this.addHistoryCostAction({
                        idQuote: this.id,
                        historyCosts: historyCosts,
                    })
                    const versions = this.historyCosts.find(
                        x => x.idQuote == this.id
                    )
                    this.costVersions = versions
                } else {
                    this.costVersions = {}
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadImageByDefaultAndTerms() {
            if (!this.newQuote.image) {
                const indexQuotes = this.settings.findIndex(
                    u => u.name === 'Quotes'
                )
                this.idSettingQuote = this.settings[indexQuotes].id
                const {
                    data: { pdfImages },
                } = await API.getPdfImages(this.idSettingQuote)
                for (let image of pdfImages[0].images) {
                    if (image.defaultImage) {
                        const path = `${this.companyId}/${this.folderPdfImage}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(image.file)
                        await profilePicRef
                            .getDownloadURL()
                            .then(URL => {
                                image.imageURL = URL
                            })
                            .catch(error => {
                                image.imageURL = ''
                                error && true
                            })
                        this.newQuote.image = image
                    }
                }
            }
            if (this.newQuote.commercialTerms) {
                this.commercialTermsToPDF = this.stringToHTML(
                    this.newQuote.commercialTerms
                )
            } else {
                const indexQuotes = this.settings.findIndex(
                    u => u.name === 'Quotes'
                )
                this.idSettingQuote = this.settings[indexQuotes].id
                await this.getCommercialTerms()
                await this.updateQuote()
                this.commercialTermsToPDF = this.stringToHTML(
                    this.newQuote.commercialTerms
                )
            }
        },
        async openDialog() {
            try {
                this.loading = true
                await this.loadImageByDefaultAndTerms()
                this.dialogSend = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        activateSuccessAlert() {
            this.successEmail = true
            this.closeSendQuote()
        },
        closeDialog() {
            this.dialogSend = false
        },
        async openPdfPreview() {
            try {
                this.loading = true
                if (!this.newQuote.image) {
                    const indexQuotes = this.settings.findIndex(
                        u => u.name === 'Quotes'
                    )
                    this.idSettingQuote = this.settings[indexQuotes].id
                    const {
                        data: { pdfImages },
                    } = await API.getPdfImages(this.idSettingQuote)
                    for (let image of pdfImages[0].images) {
                        if (image.defaultImage) {
                            const path = `${this.companyId}/${this.folderPdfImage}`
                            const storageRef = storage().ref(path)
                            const profilePicRef = storageRef.child(image.file)
                            await profilePicRef
                                .getDownloadURL()
                                .then(URL => {
                                    image.imageURL = URL
                                })
                                .catch(error => {
                                    image.imageURL = ''
                                    error && true
                                })
                            this.newQuote.image = image
                        }
                    }
                }
                if (this.newQuote.commercialTerms) {
                    this.commercialTermsToPDF = this.stringToHTML(
                        this.newQuote.commercialTerms
                    )
                } else {
                    const indexQuotes = this.settings.findIndex(
                        u => u.name === 'Quotes'
                    )
                    this.idSettingQuote = this.settings[indexQuotes].id
                    await this.getCommercialTerms()
                    this.commercialTermsToPDF = this.stringToHTML(
                        this.newQuote.commercialTerms
                    )
                    await this.updateQuote()
                }
                await API.getPreviewPDF(this.quote.id)
                await this.openFile('preview.pdf')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openFile(filename) {
            try {
                this.loading = true
                const id = this.quote.id
                const path = `${this.companyId}/${this.folderDocuments}/${id}`
                const storageRef = storage().ref(path)
                const documentRef = storageRef.child(filename)
                let documentURL
                await documentRef
                    .getDownloadURL()
                    .then(url => {
                        documentURL = url
                    })
                    .catch(() => {})
                if (documentURL) {
                    setTimeout(() => {
                        if (this.$device.ios) {
                            window.location.assign(documentURL)
                        } else {
                            window.open(
                                documentURL,
                                'popup',
                                'width=800px,height=800px'
                            )
                        }
                    })
                } else {
                    this.error = true
                    this.errorMsg = 'An error ocurred getting the PDF link.'
                    this.setErrorItems(this.errorMsg)
                }
            } finally {
                this.loading = false
            }
        },
        async getCommercialTerms() {
            try {
                this.error = false
                this.errorMsg = null
                const {
                    data: { commercialTerms },
                } = await API.getCommercialTerms(this.idSettingQuote)
                await this.addTermsAction(commercialTerms)
                if (!this.newQuote.client) {
                    const {
                        data: { clients },
                    } = await API.getClient({
                        clientId: this.newQuote.clientId,
                    })
                    this.newQuote.client = clients
                }
                let settingCommercial

                if (this.newQuote.client.language == 'English') {
                    settingCommercial = this.commercialTerms.find(
                        x => x.id == 'english'
                    )
                } else {
                    settingCommercial = this.commercialTerms.find(
                        x => x.id == 'spanish'
                    )
                }
                if (settingCommercial.terms) {
                    this.newQuote.commercialTerms = settingCommercial.terms
                    this.quote.commercialTerms = settingCommercial.terms
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        stringToHTML(str) {
            var parser = new DOMParser()
            var doc = parser.parseFromString(str, 'text/html')
            return doc.body.innerHTML
        },
        closePdf() {
            this.generatePdf = false
        },
        addFile(file) {
            this.file = file
            this.loading = false
            this.url = URL.createObjectURL(file)
            this.generatePdf = false
            if (!this.notShow) {
                this.onFocusFile()
            }
        },
        onFocusFile() {
            if (this.$device.ios) {
                window.location.assign(this.url)
            } else {
                window.open(this.url, 'popup', 'width=800px,height=800px')
            }
        },
        convertCost() {
            if (this.quote.exchange) {
                this.costs.costs.forEach(element => {
                    if (
                        this.quote.currency == this.globalCurrency &&
                        this.quote.exchange == 1
                    ) {
                        element.salePrice2 = element.salePrice
                        element.commission2 = element.commission
                        element.pricePerQuantity2 = element.pricePerQuantity
                        element.total2 = element.total
                        element.totalMargin2 = element.totalMargin
                    } else {
                        element.salePrice2 = parseFloat(
                            (element.salePrice / this.quote.exchange).toFixed(2)
                        )
                        element.commission2 = parseFloat(
                            (element.commission / this.quote.exchange).toFixed(
                                2
                            )
                        )
                        element.total2 = parseFloat(
                            (element.total / this.quote.exchange).toFixed(2)
                        )
                        element.totalMargin2 = parseFloat(
                            (element.totalMargin / this.quote.exchange).toFixed(
                                2
                            )
                        )
                    }

                    if (element.measure == 'ft2') {
                        element.quantity2 = element.quantity
                        this.quantityInternational = parseFloat(
                            (element.quantity * 0.09290304).toFixed(2)
                        )
                    } else if (element.measure == 'm2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 0.09290304).toFixed(2)
                        )
                        this.quantityInternational = element.quantity
                    } else if (
                        element.measure == 'qt' ||
                        element.measure == 'QT'
                    ) {
                        element.quantity2 = element.quantity
                        this.quantityInternational = element.quantity
                    } else if (element.measure == 'mm2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 92903).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 1000000).toFixed(2)
                        )
                    } else if (element.measure == 'cm2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 929.03).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 10000).toFixed(2)
                        )
                    } else if (element.measure == 'ha') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 9.2903e-6).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 1e-4).toFixed(2)
                        )
                    } else if (element.measure == 'km2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 9.2903e-8).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 1e-6).toFixed(2)
                        )
                    } else if (element.measure == 'in2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 144).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 1550).toFixed(2)
                        )
                    } else if (element.measure == 'yd2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 0.111111).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 1.19599).toFixed(2)
                        )
                    } else if (element.measure == 'ac') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 2.2957e-5).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 0.000247105).toFixed(2)
                        )
                    } else if (element.measure == 'mi2') {
                        element.quantity2 = parseFloat(
                            (element.quantity / 3.587e-8).toFixed(2)
                        )
                        this.quantityInternational = parseFloat(
                            (element.quantity / 3.861e-7).toFixed(2)
                        )
                    }

                    element.pricePerQuantity2 = parseFloat(
                        (element.salePrice2 / element.quantity2).toFixed(2)
                    )

                    this.convertAmericanStatistics(element)
                    this.convertInternationalStatistics(element)
                    this.setTotal()
                })
            }
        },
        convertAmericanStatistics(element = null) {
            if (!element) {
                this.costs.costs.forEach(element => {
                    if (element.american.tw > 0) {
                        element.american.cw = parseFloat(
                            (element.total2 / element.american.tw).toFixed(2)
                        )
                        element.american.pw = parseFloat(
                            (element.salePrice2 / element.american.tw).toFixed(
                                2
                            )
                        )
                    }
                    if (element.quantity > 0) {
                        if (element.american.tw > 0) {
                            element.american.wa = parseFloat(
                                (
                                    element.american.tw / element.quantity2
                                ).toFixed(2)
                            )
                        }
                        element.american.ca = parseFloat(
                            (element.total2 / element.quantity2).toFixed(2)
                        )
                    }
                    if (element.american.tw === 0) {
                        element.american.wa = 0
                        element.american.cw = 0
                        element.american.pw = 0
                    }
                })
            } else {
                if (element.american.tw > 0) {
                    element.american.cw = parseFloat(
                        (element.total2 / element.american.tw).toFixed(2)
                    )
                    element.american.pw = parseFloat(
                        (element.salePrice2 / element.american.tw).toFixed(2)
                    )
                }
                if (element.quantity > 0) {
                    if (element.american.tw > 0) {
                        element.american.wa = parseFloat(
                            (element.american.tw / element.quantity2).toFixed(2)
                        )
                    }
                    element.american.ca = parseFloat(
                        (element.total2 / element.quantity2).toFixed(2)
                    )
                }
                if (element.american.tw === 0) {
                    element.american.wa = 0
                    element.american.cw = 0
                    element.american.pw = 0
                }
            }
        },
        convertInternationalStatistics(element = null) {
            if (!element) {
                this.costs.costs.forEach(element => {
                    if (element.international.tw > 0) {
                        element.international.cw = parseFloat(
                            (element.total / element.international.tw).toFixed(
                                2
                            )
                        )
                        element.international.pw = parseFloat(
                            (
                                element.salePrice / element.international.tw
                            ).toFixed(2)
                        )
                    }
                    if (element.quantity > 0) {
                        if (element.international.tw > 0) {
                            element.international.wa = parseFloat(
                                (
                                    element.international.tw /
                                    this.quantityInternational
                                ).toFixed(2)
                            )
                        }
                        element.international.ca = parseFloat(
                            (
                                element.total / this.quantityInternational
                            ).toFixed(2)
                        )
                    }

                    if (element.international.tw === 0) {
                        element.international.wa = 0
                        element.international.cw = 0
                        element.international.pw = 0
                    }
                })
            } else {
                if (element.international.tw > 0) {
                    element.international.cw = parseFloat(
                        (element.total / element.international.tw).toFixed(2)
                    )
                    element.international.pw = parseFloat(
                        (element.salePrice / element.international.tw).toFixed(
                            2
                        )
                    )
                }
                if (element.quantity > 0) {
                    if (element.international.tw > 0) {
                        element.international.wa = parseFloat(
                            (
                                element.international.tw /
                                this.quantityInternational
                            ).toFixed(2)
                        )
                    }
                    element.international.ca = parseFloat(
                        (element.total / this.quantityInternational).toFixed(2)
                    )
                }

                if (element.international.tw === 0) {
                    element.international.wa = 0
                    element.international.cw = 0
                    element.international.pw = 0
                }
            }
        },
        setTotal(element = null) {
            if (!element) {
                let totalCosts = 0
                if (this.costs.costs) {
                    this.costs.costs.forEach(element => {
                        totalCosts += element.salePrice2
                    })
                }
                this.quote.totalCosts = totalCosts
                this.costs.totalCosts = totalCosts
            }
        },
    },
}
</script>
