<template>
    <div v-if="client" v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab :to="{ name: 'clientGeneralInfo', params: { client } }">
                General Information
            </v-tab>
            <v-tab :to="{ name: 'clientDocuments', params: { client } }">
                Documents
            </v-tab>
            <v-tab :to="{ name: 'clientProjects', params: { client } }">
                Quotes
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title class="py-2" ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-avatar :aspect-ratio="4 / 3" size="47" class="mx-2">
                    <v-img
                        contain
                        :aspect-ratio="4 / 3"
                        v-if="client.profilePicURL"
                        :src="client.profilePicURL"
                        :alt="client.name"
                    />
                    <v-btn
                        v-else
                        class="mx-2 clientIcon"
                        fab
                        dark
                        :plain="true"
                        large
                        color="grey darken-1"
                    >
                        <v-icon rounded large>mdi-office-building</v-icon>
                    </v-btn>
                </v-avatar>
                <span>{{ client.name }}</span>
                <v-spacer />
                <v-switch
                    v-if="client"
                    v-model="client.enabled"
                    color="primary"
                    class="my-0 py-0 mr-4"
                    hide-details
                    inset
                    :loading="loading"
                    @change="toggleClient"
                />
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="openDialog">
                            <v-list-item-title>
                                Update Client</v-list-item-title
                            >
                        </v-list-item>
                        <v-dialog
                            :retain-focus="false"
                            v-model="deleteDialog"
                            persistent
                            max-width="400px"
                        >
                            <v-card :loading="loading">
                                <v-card-title class="text-h5"
                                    >Delete Client</v-card-title
                                >
                                <v-card-text>
                                    Are you sure you want to delete this client?
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="deleteDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="deleteClient"
                                        text
                                        color="error"
                                        :loading="loading"
                                    >
                                        Delete
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-list-item
                            :disabled="!permDelete || client.enabled"
                            @click.stop="deleteDialog = true"
                        >
                            <v-list-item-title>
                                Delete Client
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <ClientForm
                    v-if="clientDialog"
                    v-model="clientDialog"
                    title="Client Information"
                    :create-form="false"
                    @closeDialog="closeDialog"
                    :client="this.clientToUpdate"
                    :originalClient="this.originalClient"
                    @replaceClient="this.replaceClient"
                />
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text
                class="overflow-y-auto"
                :style="{ height: `${height}px` }"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ClientForm from '@/components/Clients/ClientForm'
import { mapState, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
import API from '@/services/api'
import { storage } from '@/services/firebase'
export default {
    name: 'Client',
    components: {
        ClientForm,
    },
    props: {
        id: String,
        client: Object,
    },
    data() {
        return {
            height: 0,
            clientDialog: false,
            clientToUpdate: null,
            permDelete: false,
            originalClient: null,
            user: JSON.parse(localStorage.getItem('userId')),
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'clients_pictures',
            deleteDialog: false,
            loading: false,
            deleteError: false,
            error: false,
            errorMsg: null,
        }
    },
    inject: {
        closeDetail: {
            from: 'closeDetail',
            default() {
                return null
            },
        },
    },
    updated() {
        this.onResize()
    },
    computed: {
        ...mapState(['clients']),
        dinamicInfo: function() {
            return this.client
        },
    },
    async created() {
        const {
            data: { user },
        } = await API.getMyInfo()
        const deleteClients = user.permissions.find(x => x == 'deleteClients')
        if (deleteClients) {
            this.permDelete = true
        }
    },
    watch: {
        dinamicInfo: {
            deep: true,
            handler: function(client) {
                this.client = client
            },
        },
        client: async function() {
            const {
                data: { user },
            } = await API.getMyInfo()
            const deleteClients = user.permissions.find(
                x => x == 'deleteClients'
            )
            if (deleteClients) {
                this.permDelete = true
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
            this.closeDetail(false)
        },
        ...mapActions(['replaceClientAction', 'removeClientAction']),
        openDialog: function() {
            this.clientToUpdate = _.cloneDeep(this.client)
            this.originalClient = _.cloneDeep(this.client)
            this.clientDialog = true
        },
        closeDialog: function() {
            this.clientDialog = false
        },
        deleteDocument: async function(doc) {
            const path = `${this.companyId}/clients_documents/${this.client.id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete()
            return true
        },
        deleteClientContacts(contacts) {
            contacts.forEach(contact => {
                API.deleteContact(contact.id)
            })
        },
        deleteClientPicture: async function(picture) {
            const path = `${this.companyId}/clients_pictures`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(picture)
            await refDoc.delete()
            return true
        },
        deleteQuoteDocuments: async function(doc, id) {
            const path = `${this.companyId}/quotes_documents/${id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete()
            return true
        },
        deleteClientQuotes: async function(quotes) {
            quotes.forEach(quote => {
                if (quote.files.length > 0) {
                    quote.files.forEach(doc => {
                        this.deleteQuoteDocuments(doc.file, quote.id)
                    })
                }
                API.deleteQuote(quote.id)
            })
        },
        deleteClient: async function() {
            try {
                this.loading = true
                this.deleteError = false
                this.errorMsg = null
                const { id } = this.client
                if (this.client.contacts) {
                    await this.deleteClientContacts(this.client.contacts)
                }
                if (this.client.picture) {
                    await this.deleteClientPicture(this.client.picture)
                }
                if (this.client.quotes) {
                    await this.deleteClientQuotes(this.client.quotes)
                }
                const {
                    data: { documents },
                } = await API.getClientDocuments(this.id, this.companyId)
                if (documents) {
                    documents.forEach(doc => {
                        this.deleteDocument(doc.file)
                    })
                }
                await API.deleteClient(id, this.client)
                this.removeClientAction(id)
                this.$router.push('/clients')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        replaceClient: function(id, client) {
            this.replaceClientAction({ id, client })
            this.client.name = client.name
            this.client.profilePicURL = client.profilePicURL
            this.client.city = client.city
            this.client.currency = client.currency
            this.client.currency2 = client.currency2
            this.client.language = client.language
        },
        toggleClient: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                await API.updateClient(this.client.id, {
                    enabled: this.client.enabled,
                })
            } catch (error) {
                this.user.enabled = !this.user.enabled
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
<style scoped>
.clientIcon {
    cursor: default;
}
</style>
